import MoreVertIcon from "@material-ui/icons/MoreVert";
import ReplyIcon from "@material-ui/icons/Reply";
import React from "react";

const SMSHeader = (props) => {

    let isNumber = "<" + props.contactNumber + ">";
    let senderNumber = props.contactNumber ? isNumber : " ";

    return (
        <>
            <div className="emailHeader">
                <div className="emailToFrom">
                    <p>
                        <b>{props.senderName}</b>
                        {senderNumber}
                    </p>
                    <p>{props.receiverList ? props.receiverList : "to me"}</p>
                </div>
                <div className="emailHeaderButtons">
                    <ReplyIcon style={{ fontSize: 15 }} />
                    <MoreVertIcon style={{ fontSize: 15 }} />
                </div>
            </div>
        </>
    );
};

export default SMSHeader;