import React from 'react';
import MediaAsset from './MediaAsset';

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

const AssetList = (props) => {
    //props.title -> Header for expansion pannel
    //props.mediaType -> points to correct bucket type in firebase
    return (
        <>
            <div className="media_asset_category">{capitalize(props.title)}</div>
            <div>
                {props.list.length < 1 && <div className="media_row">No {props.type} assets attached to this simulation.</div>}
                {
                    props.list.map((val) => {
                        return <MediaAsset
                            key={val.id}
                            val={val}
                            type={props.type}
                            handleDelete={props.handleDelete}
                            handleSelect={props.handleSelect}
                            setOpenAlert={props.type === "pdf" ? props.setOpenAlert : "undefined"}
                        />;
                    })
                }
            </div>
        </>
    );
}

export default AssetList;