import { SIM_BUILDER_API_END_POINT } from "../Config";
import RequestService, { HttpMethods } from "../utils/requestService";

const CharacterService = () => {
    return Object.freeze({
        getCharctersBySimId: async (simId, token) => {
            const options = RequestService.makeRequest("GET", token);

            const result = await fetch(SIM_BUILDER_API_END_POINT + '/characters/' + simId, options);
            const response = await result.json();

            return response;
        },
        createCharcter: async (character, token) => {
            const options = RequestService.makeRequest(HttpMethods.POST, token);
            options['body'] = JSON.stringify(character);

            const result = await fetch(SIM_BUILDER_API_END_POINT + '/characters', options);
            const response = await result.json();

            return response;
        },
        updateCharcter: async (character, token) => {
            const options = RequestService.makeRequest(HttpMethods.PUT, token);
            options['body'] = JSON.stringify(character);

            const result = await fetch(SIM_BUILDER_API_END_POINT + '/characters', options);
            const response = await result.json();

            return response;
        },
        deleteCharacter: async (id, token) => {
            const options = RequestService.makeRequest("DELETE", token);

            const result = await fetch(SIM_BUILDER_API_END_POINT + '/characters/' + id, options);
            const response = await result.json();

            return response;
        }
    });
}

export default CharacterService;