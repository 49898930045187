import React, { useState, useRef, useContext } from 'react';
import AddBoxIcon from '@material-ui/icons/AddBox';
import SimBuilderTrackList from './SimBuilderTrackList';
import SimBuilderTrackPreview from './SimBuilderTrackPreview';
import SimBuilderTrackControlPanel from './SimBuilderTrackControlPanel';
import SimBuilderNewTrackDialog from './SimBuilderNewTrackDialog';
import { IconButton, Tooltip } from '@material-ui/core';
import {
    ArrowDownwardOutlined,
    CheckBox,
    CheckBoxOutlineBlank,
    CloudDownload,
    PlayCircleFilled,
    Search
} from '@material-ui/icons';
import SimBuilderReportDialog from './SimBuilderReportDialog';
import SimBuilderTrackReport from './SimBuilderTrackReport';
import SimpleReactValidator from 'simple-react-validator';
import { LibraryContext } from '../../../context/LibraryContext';
import { PlayerContext } from '../../../context/PlayerContext';
import { EditSimContext } from '../../../context/EditSimContext';
import { useHistory } from 'react-router-dom';
import useExpandCollapse from "../../../hooks/useExpandCollapse";
import { IsExpandAllContext } from "../../../context/IsExpandAllContext";
import FindAndReplaceDialog from "../../generic/FindAndReplaceDialog";
import Alert from "../../generic/Alerts";
import TrackService from "../../../services/TrackService";
import {UserContext} from "../../../context/UserContext";
import Loader from "../../loader";
import EditTrackContextProvider from '../../../context/EditTrackContext';
import SimPlayTypeDialog from '../../generic/SimPlayTypeDialog';

const TrackTab = () => {
    const { token } = useContext(UserContext);
    const history = useHistory();
    const [isExpand, markup] = useExpandCollapse({ vertical: true, className: "top_position" });
    const [, forceUpdate] = useState(0);
    const simpleValidator = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } }));
    const [open, setOpen] = useState(false);
    const [openReport, setOpenReport] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [alertProps, setAlertProps] = useState({ msg: "", type: "" });
    const [openAlert, setOpenAlert] = useState(false);
    const { getFullSim } = useContext(LibraryContext);
    const { setSimToPlay, setLinkId, setShowFlashAlert, setIsPlayingFromTrack, setIsPlaySimLineraly } = useContext(PlayerContext);
    const { editSimState } = useContext(EditSimContext);
    const [markupSetting, setMarkupSetting] = useState("");
    const [isAllTrackChecked, setIsAllTrackChecked] = useState(false);
    const [tracksSelected, setTracksSelected] = useState(new Set());
    const [isLoading, setIsLoading] = useState(false);
    const [markupExpandALL, setMarkupExapndALL] = useState({ isExpandALL: true, markupExpandALL: "" });
    const expandALL = markupExpandALL.markupExpandALL !== "" ? `${markupExpandALL.isExpandALL ? "Collapse ALL" : "Expand ALL"}` : "";
    const [isSimPlayTypeDialogOpen, setIsSimPlayTypeDialogOpen] = useState(false);

    const handleIsAllTrackChecked = (isChecked) => {
        setIsAllTrackChecked(isChecked)
    }

    const handleExportTrack = ()=>{
        const trackId = [...tracksSelected];
        if(trackId.length === 0) {
            setAlertProps({msg: "Please Select Track", type: "error"});
            setOpenAlert(true);
            return;
        }
        setIsLoading(true);
        TrackService().exportTrackData(trackId, token).then((response)=>{
                const blob = response.slice(0, response.size, 'application/vnd.ms-excel');
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                document.body.appendChild(a);
                a.href = url;
                a.target = "_blank";
                a.download = "trackData"
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();
                setTracksSelected(new Set());
                setIsAllTrackChecked(false);
                setIsLoading(false);
        })
            .catch(error => {
                setIsLoading(false);
                console.error(error);
            });
    }

    const handleOpenDialog = () => {
        setOpen(true);
    }

    const handleCloseDiag = () => {
        setOpen(false);
    }

    const handleOpenReportDialog = () => {
        setOpenReport(true);
    }

    const handleCloseReportDiag = () => {
        setOpenReport(false);
    }

    const handleSimPlayType = () => {
        setIsSimPlayTypeDialogOpen(!isSimPlayTypeDialogOpen);
    }

    const handlePlaySim = () => {
        try {
            localStorage.removeItem("playerState");
        } catch (error) {
            console.error(error.message);
        }
        const sim = getFullSim(editSimState.id);
        setLinkId('');
        setIsPlayingFromTrack(true);
        setSimToPlay(sim);
        setShowFlashAlert(true);
        history.push({
            pathname: '/play',
            search: '?isPlayingFromTrack'
        })
    }

    const isExpandClass = isExpand ? "block-width" : "track_nav_collapse"

    return (
        <div className="track">
            <EditTrackContextProvider>
                <div className={`track_nav ${isExpandClass}`}>
                    {markup}
                    <div className="d-flex p-0 m-0 justify-content-between align-baseline">
                        <div className="align-self-center">
                            <Tooltip title="Export Tracks" placement="top">
                                <IconButton onClick={handleExportTrack}>
                                    <ArrowDownwardOutlined color="primary" />
                                </IconButton>
                            </Tooltip>
                                <>
                                    {isAllTrackChecked &&
                                    <Tooltip title="Select All Tracks" placement="top">
                                        <IconButton onClick={()=>handleIsAllTrackChecked(false)}>
                                            <CheckBox color="primary" />
                                        </IconButton>
                                    </Tooltip>
                                    }
                                    {!isAllTrackChecked &&
                                    <Tooltip title="Select All Tracks" placement="top">
                                        <IconButton onClick={()=>handleIsAllTrackChecked(true)}>
                                            <CheckBoxOutlineBlank color="primary" />
                                        </IconButton>
                                        </Tooltip>
                                    }
                                    </>
                        </div>
                        <div>
                            <Tooltip title="Play Sim" placement="top">
                                <IconButton onClick={handleSimPlayType} aria-label="play" className="margin-right-n8">
                                    <PlayCircleFilled color="primary" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Find And Replace Word" placement="top">
                                <IconButton onClick={() => { setIsSearch(true) }} aria-label="find" className="margin-right-n8">
                                    <Search color="primary" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Sim Decisions Report" placement="top">
                                <IconButton onClick={handleOpenReportDialog}>
                                    <CloudDownload color="primary" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Add Track" placement="top">
                                <IconButton onClick={handleOpenDialog}>
                                    <AddBoxIcon color="primary" />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                    <SimBuilderTrackList
                        simpleValidator={simpleValidator}
                        isExpand={isExpand}
                        isSelectedAll={isAllTrackChecked}
                        setTrackSelection = {setTracksSelected}
                        trackSelected={tracksSelected}
                    />
                    {open && <SimBuilderNewTrackDialog handleCloseDiag={handleCloseDiag} />}
                    {openReport &&
                        <SimBuilderReportDialog handleCloseDiag={handleCloseReportDiag} reportTitle="Sim Decisions Report">
                            <SimBuilderTrackReport />
                        </SimBuilderReportDialog>
                    }
                </div>
                <div className="track_content">
                    <div id="add_preview">
                        <div className="track_preview">
                            <SimBuilderTrackPreview />
                        </div>
                    </div>
                    <div className="track_settings">
                        <div className="mr-1">
                            <div className="expand_all mb-2">{expandALL} {markupExpandALL.markupExpandALL}</div>
                            <div className="heading">
                                <h5>Slide Control Panel</h5>
                                {markupSetting}
                            </div>
                            <IsExpandAllContext.Provider value={markupExpandALL.isExpandALL}>
                                <SimBuilderTrackControlPanel
                                    simpleValidator={simpleValidator}
                                    setMarkupSetting={setMarkupSetting}
                                    setMarkupExapndALL={setMarkupExapndALL}
                                />
                            </IsExpandAllContext.Provider>
                        </div>
                    </div>
                </div>
                {isSearch &&
                    <FindAndReplaceDialog
                        isOpen={isSearch}
                        setIsOpen={setIsSearch}
                        title="Find And Replace"
                        cancel={"Cancel"}
                        save={"Replace"}
                        simId={editSimState.id}
                        findLable="Find Word"
                        setAlert={setAlertProps}
                        setOpenAlert={setOpenAlert}
                        length={5000}
                    />
                }
            {openAlert && <Alert {...alertProps} openAlert={openAlert} setOpenAlert={setOpenAlert} />}
            {isLoading &&
            <div className="overlay">
                <Loader />
            </div>
            }
            {isSimPlayTypeDialogOpen && <SimPlayTypeDialog open={isSimPlayTypeDialogOpen} handleClose={handleSimPlayType} handlePlaySim={handlePlaySim} setIsPlaySimLineraly={setIsPlaySimLineraly} />}
        </EditTrackContextProvider>
    </div>
)}

export default TrackTab;