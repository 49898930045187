import React, { useContext, useState, useEffect, useRef } from "react";
import Media from "./Media";
import { IconButton, Tooltip } from "@material-ui/core";
import { Clear, Print } from "@material-ui/icons";
import {
    defultReportPrintStyle,
    isNullOrEmpty,
    printDocument,
} from "../../utils/utils";
import { defultEditorText } from "../third-party/Editor";
import { PlayerContext } from "../../context/PlayerContext";
import ModelLoader from "../../components/loaderForModels";

const MediaList = (props) => {
    const { playerState } = useContext(PlayerContext);
    const headerMsg = useRef(
        `These are the video track scripts of the "${playerState.title}" simulation.`
    );
    const isVideoTrackFound = useRef(false);
    const [file, setFile] = useState("");
    const [loading, setLoading] = useState(false);
    let pdfCount = 0;

    /*
                      If you want to show all types of media files then use this component
                      window.open(window.location.origin + "/view?simId=" + simId + "&fileName=" + fileName + "&type=" + type, "_blank");  
                     */
    const getUrl = (url) => {
        setLoading(true);
        const xhr = new XMLHttpRequest();
        xhr.responseType = "blob";
        xhr.onload = () => {
            url = URL.createObjectURL(xhr.response);
            setLoading(false);
            setFile(url);
        };
        xhr.open("GET", url);
        xhr.send();
    };

    useEffect(() => {
        if (file !== "") {
            document.getElementById("viewPdf").setAttribute("href", file);
            document.getElementById("viewPdf").click();
        }
    }, [file]);

    const updateHeaderMsg = (msg) => {
        headerMsg.current = msg;
    };

    const handleVideoTextScriptReportPrint = () => {
        document.getElementById("headerMsg").innerHTML = headerMsg.current;
        const div = document.getElementById("videoScriptReport");
        printDocument(
            "Simulation Video Script Report",
            "Video Script",
            defultReportPrintStyle,
            div.innerHTML
        );
    };

    return (
        <div
            className="popup_background"
        >
            <div className="popup">
                <div className="popup_close">
                    <Tooltip title="Close Button">
                        <IconButton
                            onClick={props.handleClosePopup}
                            aria-label="Close Button"
                            aria-describedby="Close Button"
                        >
                            <Clear style={{ color: "black" }} aria-label="Close Button" />
                        </IconButton>
                    </Tooltip>
                </div>
                <div className="popup_content">
                    {loading && <ModelLoader />}
                    <div
                        id="videoScriptReport"
                        className="report"
                        style={{ display: "none" }}
                    >
                        <h1>Simulation Video Track Script Report</h1>
                        <div className="report_header">
                            <p id="headerMsg"></p>
                        </div>
                        {playerState.tracks.map((track) => {
                            if (
                                track.type === "video" &&
                                !isNullOrEmpty(track.body) &&
                                track.body !== defultEditorText
                            ) {
                                isVideoTrackFound.current = true;
                                return (
                                    <div className="report_item">
                                        <h2>{`Track: ${track.order} -- ${track.title}`}</h2>
                                        <p dangerouslySetInnerHTML={{ __html: track.body }} />
                                    </div>
                                );
                            }
                            return "";
                        })}
                        {!isVideoTrackFound.current &&
                            updateHeaderMsg("No video track script found!")}
                    </div>
                    <div className="row">
                        <div className="col-sm-9">
                            <h3>Video Track Script Report</h3>
                        </div>
                        <div className="col-sm-3 text-right">
                            <Tooltip title="Print Button">
                                <IconButton
                                    onClick={handleVideoTextScriptReportPrint}
                                    aria-label="Print Button"
                                    aria-describedby="Print Button"
                                >
                                    <Print fontSize="large" />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                    <hr />
                    <a
                        href="/"
                        style={{ display: "none" }}
                        id="viewPdf"
                        target={"_blank"}
                    >
                        view
                    </a>
                    {playerState.media.map((media) => {
                        if (media.type === "pdf") {
                            pdfCount++;
                        }
                        return (
                            media.type !== undefined &&
                            media.type !== "" &&
                            media.type === "pdf" && (
                                <>
                                    <Media {...media} popup={true} getUrl={getUrl} />
                                    <hr />
                                </>
                            )
                        );
                    })}
                    {(playerState.media.length === 0 || pdfCount === 0) &&
                        "No PDF file exists."}
                </div>
            </div>
        </div>
    );
};

export default MediaList;
