import React, { createContext, useEffect, useState, useContext, useMemo } from 'react';
import { UserContext } from './UserContext';
import SimService from "../services/SimService";
import { Roles } from '../utils/userUtil';
import { SimFoldersContext } from './SimFoldersContext';
import { isNullOrEmpty } from '../utils/utils';

export const LibraryContext = createContext();

//Provide a cached list of simulations in the library.
const LibraryContextProvider = (props) => {
    const { token, user, logout } = useContext(UserContext);
    const { getAllSimFolders } = useContext(SimFoldersContext);
    const [sim, setSim] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (sim.length === 0 && !isNullOrEmpty(token) && user.roleByRole.roleName === Roles.Admin) {
            SimService()
                .getAllSims(token)
                .then((sims) => {
                    if (sims.status === 401) {
                        logout();
                    } else {
                        setSim(sims);
                    }
                })
                .catch((err) => {
                    console.error("Error while settings sims :", err);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    const getFullSim = (id) => {
        return sim.find((sim) => sim.id === id);
    }

    const addSimToLibrary = (newSim) => {
        const tempSimsList = sim.concat(newSim);
        const sortedSims = tempSimsList.sort(function (a, b) {
            var simA = a.title.toUpperCase();
            var simB = b.title.toUpperCase();
            if (simA < simB) {
                return -1;
            }
            if (simA > simB) {
                return 1;
            }
            return 0;
        });
        setSim(sortedSims);
    }

    const setCurrentSim = (id) => {
        setSim(...sim);
    }

    const updateSim = (newVals) => {
        let updateSimsList = sim.map((sim) => { return (sim.id === newVals.id ? newVals : sim); });
        const sortedSims = updateSimsList.sort(function (a, b) {
            var simA = a.title.toUpperCase();
            var simB = b.title.toUpperCase();
            if (simA < simB) {
                return -1;
            }
            if (simA > simB) {
                return 1;
            }
            return 0;
        });
        console.log(sortedSims, 'in update sim')
        setSim(sortedSims);
    }

    const deleteSim = (id) => {
        setIsLoading(true);
        SimService().deleteSim(id, token).then((response) => {
            if (response.status === 200) {
                setSim(response.data);
                getAllSimFolders();
            } else {
                console.error(response.status, "Error: while deleting sim!");
            }
            setIsLoading(false);
        }).catch((e) => {
            console.log('Error: could not delete sim', e.message);
            setIsLoading(false);
        })
    }

    const copySim = (simDetail) => {
        setIsLoading(true);
        SimService().copySim(simDetail, token).then((response) => {
            console.debug(response);
            if (response.status === 200) {
                setSim(response.data);
            } else {
                console.error(response.status, "Error: while copying sim!");
            }
            setIsLoading(false);
        }).catch((e) => {
            console.log('Error: could not copy sim', e.message);
            setIsLoading(false);
        })
    }

    const LibraryContextProviderValue = useMemo(() => {
        return {
            isLoading,
            setIsLoading,
            sim,
            setSim,
            addSimToLibrary,
            setCurrentSim,
            updateSim,
            getFullSim,
            deleteSim,
            copySim
        }
        // eslint-disable-next-line
    }, [isLoading, sim]);

    return (
        <LibraryContext.Provider value={LibraryContextProviderValue} >
            {props.children}
        </LibraryContext.Provider>
    );
}

export default LibraryContextProvider;