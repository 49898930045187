import DateUtil from "../../utils/dateUtil";

const InternalSimGeneralInfo = ({ internalSim }) => {
  return (
    <>
      <div className="col-12 bg-heading mb-3">
        <h4>General Info</h4>
      </div>
      <div className="col-2">
        <p className="font-weight-bold">Sim Title:</p>
      </div>
      <div className="col-10">{internalSim?.title}</div>
      <div className="col-2">
        <p className="font-weight-bold">Description:</p>
      </div>
      <div className="col-10 text-justify">
        {internalSim?.description || ""}
      </div>
      <div className="col-2">
        <p className="font-weight-bold">Creator Email:</p>
      </div>
      <div className="col-10">
        <p>{internalSim?.createdBy}</p>
      </div>
      <div className="col-2">
        <p className="font-weight-bold">Created Date:</p>
      </div>
      <div className="col-10">
        {DateUtil.formatDate(new Date(internalSim?.createdAt), "dd/mm/yyyy")}
      </div>
    </>
  );
};

export default InternalSimGeneralInfo;
