import { IconButton } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import React, { useContext } from 'react';
import { CharacterContext } from '../../../context/CharacterContext';
import SimBuilderCharacterEdit from './SimBuilderCharacterEdit';
import SimBuilderCharacterList from './SimBuilderCharacterList';


const SimBuilderCharacterTab = () => {

    const { selectedCharacterState, clearSelectedCharacterState } = useContext(CharacterContext);

    return (
        <div className="actorTab">
            <SimBuilderCharacterList />
            <div className="actor_edit">
                {selectedCharacterState.id !== null && selectedCharacterState.id !== "" && <IconButton onClick={clearSelectedCharacterState}><AddCircleIcon color="primary" /></IconButton>}
                <SimBuilderCharacterEdit />
            </div>
        </div>
    )
}

export default SimBuilderCharacterTab;