import { Button } from '@material-ui/core';
import { useContext, useState } from 'react';
import { EditSimContext } from '../../../../context/EditSimContext';
import { EditTrackContext } from '../../../../context/EditTrackContext';
import Alert from '../../../generic/Alerts';
import Loader from '../../../loader';

const TrackFormActions = (props) => {

    const { updateTrack } = useContext(EditSimContext);
    const { editTrackState, setEditTrackState, setPrevTrackState, setShowValidationError } = useContext(EditTrackContext);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertProps, setAlertProps] = useState({ msg: "", type: "" });

    const [loading, setLoading] = useState(false);
    const handleSave = async () => {
        if (props.simpleValidator.current.allValid()) {
            setLoading(true);
            await updateTrack(editTrackState, (status) => {
                if (status) {
                    setEditTrackState(null);
                    setPrevTrackState(null);
                } else {
                    setAlertProps({ msg: "Error: while saving track details!", type: "error" })
                    setOpenAlert(true);
                    setLoading(false);
                }
                setShowValidationError(false);
                props.simpleValidator.current.hideMessages();
            });
        } else {
            setShowValidationError(true);
            props.simpleValidator.current.showMessages();
        }
    };

    const handleCancel = () => {
        setEditTrackState(null);
        setPrevTrackState(null);
    };

    return (
        <>
            <div className="formActions">
                <Button variant="contained" color="secondary" onClick={handleCancel}>Cancel</Button>
                <Button variant="contained" color="primary" onClick={handleSave}>Save Track Details</Button>
                {openAlert && <Alert {...alertProps} openAlert={openAlert} setOpenAlert={setOpenAlert} />}
            </div>
            {loading &&
                <div className="overlay">
                    <Loader />
                </div>
            }
        </>
    );
};

export default TrackFormActions;