import React, { Fragment, useContext, useEffect, useState } from "react";
import { FormControl, IconButton, InputLabel, Select, TextField, FormHelperText, Typography } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import { EditTrackContext } from "../../../../context/EditTrackContext";
import { VariableContext } from "../../../../context/VariableContext";
import { MuiThemeProvider } from '@material-ui/core/styles';
import formLabelsTheme from '../../../generic/FormLabelsThem';
import { isNullOrEmpty } from "../../../../utils/utils";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { HtmlTooltip } from "../../../../utils/utils";
import useExpandCollapse from "../../../../hooks/useExpandCollapse";
import { IsExpandAllContext } from "../../../../context/IsExpandAllContext";

const TrackConditionalVisibility = (props) => {
  const [isExpand, markup, toggleTrackConditionalVisibility] = useExpandCollapse({ vertical: false });
  //Used to get list of variables in the simulation
  const { variableState } = useContext(VariableContext);

  //State of track being edited
  const { editTrackState, setEditTrackState } = useContext(EditTrackContext);

  //Local form selections
  const [defSelectedVar, setDefSelectedVar] = useState("");
  const [selectedVar, setSelectedVar] = useState();
  const [operand, setOperand] = useState("");
  const [equationValue, setEquationValue] = useState("");
  const [cvErrorFlag, setCvErrorFlag] = useState(false);
  const [selectedLogicGate, setSelectedLogicGate] = useState("");
  const isExpandALL = useContext(IsExpandAllContext);

  useEffect(() => {
    toggleTrackConditionalVisibility(isExpandALL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpandALL]);

  const handleSelectVarChange = (e) => {
    if (e.target.value !== "" && e.target.value !== undefined) {
      const [value, name] = e.target.value.split(":::");
      setSelectedVar({ value, name });
    } else {
      setSelectedVar(e.target.value);
    }
    setDefSelectedVar(e.target.value);
  };

  const handleSelectOpperandChange = (e) => {
    setOperand(e.target.value);
  };

  const handleSelectLogicChange = (event) => {
    setSelectedLogicGate(event.target.value);
  }

  const handleEquationValueChange = (e) => {
    setEquationValue(e.target.value);
  };

  const resetVisiblitySetting = () => {
    setDefSelectedVar("");
    setSelectedVar("");
    setOperand("");
    setEquationValue("");
    setSelectedLogicGate("");
    setCvErrorFlag(false);
  }

  const addVisiblitySetting = () => {
    let errorFlag = false;
    if (isNullOrEmpty(selectedVar) || isNullOrEmpty(operand)
      || isNullOrEmpty(equationValue) || isNullOrEmpty(selectedLogicGate)) {
      setCvErrorFlag(true);
      errorFlag = true;
    }

    if (!errorFlag) {
      const newVisSetting = {
        var: selectedVar,
        operand,
        val: equationValue,
        logic: selectedLogicGate,
      };

      const visSettingList = editTrackState.visibilitySettings
        ? editTrackState.visibilitySettings
        : [];
      setEditTrackState({
        ...editTrackState,
        visibilitySettings: visSettingList.concat(newVisSetting),
      });
      resetVisiblitySetting();
    }
  };

  const handleConfirmation = (id) => {
    props.setCurrentVS(id);
    let currentVS = editTrackState.visibilitySettings[id];
    let selectedVS = currentVS.var.name + " | " + getOperandLabel(currentVS.operand) + " | " + currentVS.val;
    props.setMsg("<h4>Are you sure you want to delete <span class='text-secondary'>" + selectedVS + "</span> Conditional Visibility Setting?");
    props.setHandleDelete("deleteVS");
    props.setOpenCnfDia(true);
  };

  const getVisiblitySettingList = () => {
    if (editTrackState.visibilitySettings !== undefined && editTrackState.visibilitySettings !== null) {
      return editTrackState.visibilitySettings.map((s, i) => {
        return (
          <p className="listItem" key={i}>
            <span>
              {i + 1}. &nbsp; {s.var.name} <b>| {getOperandLabel(s.operand)} |</b> {s.val} <b>| {getLogicLabel(s.logic)}</b>
            </span>
            <IconButton onClick={() => handleConfirmation(i)}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          </p>
        );
      });
    }
  };

  const getExpressionForVisiblitySettingList = () => {
    let andLogicResult = [];
    let initialExpression = "(";
    let andExpression;
    let embedOR;
    if (!isNullOrEmpty(editTrackState.visibilitySettings)) {
      for (let vs = 0; vs < editTrackState.visibilitySettings.length; vs++) {
        andExpression = "";
        if (editTrackState.visibilitySettings[vs].logic === "OR") {
          if (andLogicResult.length > 1) {
            andExpression = "(";
            for (let j = 0; j < andLogicResult.length; j++) {
              let embedAND = andLogicResult[j + 1] ? " AND " : "";
              andExpression += andLogicResult[j] + embedAND;
            }
            andLogicResult = [];
            embedOR = editTrackState.visibilitySettings[vs + 1] ? " OR " : !editTrackState.visibilitySettings[vs + 1] && editTrackState.visibilitySettings[vs].logic === "OR" ? " OR " : "";
            andExpression += ")" + embedOR;
            initialExpression += andExpression;
          }
          else if (andLogicResult.length === 1) {
            embedOR = editTrackState.visibilitySettings[vs + 1] ? " OR " : !editTrackState.visibilitySettings[vs + 1] && editTrackState.visibilitySettings[vs].logic === "OR" ? " OR " : "";
            initialExpression += andLogicResult[0] + embedOR;
            andLogicResult = []
          }
          embedOR = editTrackState.visibilitySettings[vs + 1] ? " OR " : "";
          initialExpression += `cvs${vs + 1}` + embedOR;
        } else {
          andLogicResult.push(`cvs${vs + 1}`);
        }
      }

      if (andLogicResult.length > 1) {
        andExpression = initialExpression === "(" ? "" : "(";
        for (let j = 0; j < andLogicResult.length; j++) {
          let embedAND = andLogicResult[j + 1] ? " AND " : "";
          andExpression += andLogicResult[j] + embedAND;
        }
        andLogicResult = [];
        andExpression += initialExpression === "(" ? "" : ")";
        initialExpression += andExpression;
      } else if (andLogicResult.length === 1) {
        initialExpression += andLogicResult[0];
      }
      initialExpression += ")";

      return (
        <p className="pl-3">
          <b>Expression: </b>
          <code className="code-block"><em>{initialExpression}</em></code>
        </p>
      )
    }
  };



  const operandList = ["==", "!=", "<", "<=", ">", ">="];

  const getOperandLabel = (val) => {
    let condition = null;
    switch (val) {
      case "==":
        condition = "Equal To";
        break;
      case "!=":
        condition = "Not Equal To";
        break;
      case "<":
        condition = "Less Than";
        break;
      case "<=":
        condition = "Less Than Or Equal To";
        break;
      case ">":
        condition = "Greater Than";
        break;
      case ">=":
        condition = "Greater Than Or Equal To";
        break;
      default:
        condition = null;
        break;
    }
    return condition;
  };

  const logicList = ["AND", "OR"];
  const getLogicLabel = (logic) => {
    let logicCondition = null;
    switch (logic) {
      case "AND":
        logicCondition = "AND";
        break;
      case "OR":
        logicCondition = "OR";
        break;
      default:
        logicCondition = null;
        break;
    }
    return logicCondition;
  };

  const trackConditionalVisibilityClass = isExpand ? "expand" : "collapse";

  return (
    <>
      <div className="heading">
        <h3>Conditional Visibility Settings
          <HtmlTooltip className="ml-1" title={
            <Fragment>
              <Typography color="inherit">
                <p>You can add condition visibility settings by "OR" and "AND" logic or a combination of both.</p>
                <p>Note: Single "AND" is treated as "OR", so avoid using a single "AND".</p>
                <hr />
                <p>You can get a better understanding of conditions execution by looking at these examples:</p>
                <ul>
                  <li className="code-block">V1 = OR <b>=&gt;</b> (V1)</li>
                  <li className="code-block">V1 = AND <b>=&gt;</b> (V1)</li>
                  <li className="code-block">V1 = AND, V2 = OR <b>=&gt;</b> (V1 OR V2)</li>
                  <li className="code-block">V1 = OR, V2 = AND <b>=&gt;</b> (V1 OR V2)</li>
                  <li className="code-block">V1 = AND , V2 = AND , V3 = AND <b>=&gt;</b> (v1 AND v2 AND v3)</li>
                  <li className="code-block">V1 = AND , V2 = AND , V3 = OR  <b>=&gt;</b> ((V1 AND V2) OR V3)</li>
                  <li className="code-block">V1 = AND , V2 = OR , V3 = AND <b>=&gt;</b> (V1 OR V2 OR V3)</li>
                  <li className="code-block">V1 = AND , V2 = OR , V3 = OR <b>=&gt;</b> (V1 OR V2 OR V3)</li>
                  <li className="code-block">V1 = OR , V2 = AND , V3 = AND <b>=&gt;</b> (V1 OR (V2 AND V3))</li>
                  <li className="code-block">V1 = OR , V2 = AND , V3 = OR <b>=&gt;</b> (V1 OR V2 OR V3)</li>
                  <li className="code-block">V1 = OR , V2 = OR , V3 = AND <b>=&gt;</b> (V1 OR V2 OR V3)</li>
                  <li className="code-block">V1 = OR , V2 = OR , V3 = OR <b>=&gt;</b> (V1 OR V1 OR V3)</li>
                </ul>
              </Typography>
            </Fragment>
          }>
            <InfoOutlinedIcon fontSize="inherit" />
          </HtmlTooltip>
        </h3>
        {markup}
      </div>
      <div className={trackConditionalVisibilityClass}>
        <MuiThemeProvider theme={formLabelsTheme}>
          <form noValidate autoComplete="off">
            <div className="visibility_setting_row">
              <FormControl
                variant="outlined"
                required
                error={(selectedVar === "" || selectedVar === undefined) && cvErrorFlag && true}>
                <InputLabel htmlFor="varList">Sim Variable</InputLabel>
                <Select
                  native
                  name="varList"
                  id="varList"
                  value={defSelectedVar}
                  onChange={handleSelectVarChange}
                >
                  <option aria-label="None" value="" />
                  {variableState.map((v) => {
                    const newVal = v.id + ":::" + v.name;
                    return (
                      <option key={v.id} value={newVal} name={v.name} title={v.desc}>
                        {v.name}
                      </option>
                    );
                  })}
                </Select>
                {(selectedVar === "" || selectedVar === undefined) && cvErrorFlag && <FormHelperText style={{ color: "red" }}>The sim variable option is required.</FormHelperText>}
              </FormControl>
              <FormControl
                variant="outlined"
                required
                error={(operand === "" || operand === undefined) && cvErrorFlag && true}>
                <InputLabel htmlFor="operandList">Operand</InputLabel>
                <Select
                  native
                  name="operandList"
                  id="operandList"
                  value={operand}
                  onChange={handleSelectOpperandChange}
                >
                  <option aria-label="None" value=""></option>
                  {operandList.map((v) => {
                    return (
                      <option key={v} value={v}>
                        {getOperandLabel(v)}
                      </option>
                    );
                  })}
                </Select>
                {(operand === "" || operand === undefined) && cvErrorFlag && <FormHelperText style={{ color: "red" }}>The operand option is required.</FormHelperText>}
              </FormControl>
              <TextField
                error={(equationValue === "" || equationValue === undefined) && cvErrorFlag && true}
                required
                name="equationValue"
                id="equationValue"
                label="Value"
                variant="outlined"
                value={equationValue}
                onChange={handleEquationValueChange}
                helperText={(equationValue === "" || equationValue === undefined) && cvErrorFlag && "The value field is required."}
              />
              <FormControl
                variant="outlined"
                required
                error={(selectedLogicGate === "" || selectedLogicGate === undefined) && cvErrorFlag && true}>
                <InputLabel htmlFor="logic">Logic</InputLabel>
                <Select
                  native
                  name="logic"
                  id="logic"
                  value={selectedLogicGate}
                  onChange={handleSelectLogicChange}
                >
                  <option aria-label="None" value=""></option>
                  {logicList.map((l) => {
                    return (
                      <option value={l} key={l}>
                        {getLogicLabel(l)}
                      </option>
                    );
                  })}
                </Select>
                {(selectedLogicGate === "" || selectedLogicGate === undefined) && cvErrorFlag && <FormHelperText style={{ color: "red" }}>The logic option is required.</FormHelperText>}
              </FormControl>
              <IconButton onClick={addVisiblitySetting}>
                <AddCircleIcon />
              </IconButton>
            </div>
          </form>
        </MuiThemeProvider>
        {getExpressionForVisiblitySettingList()}
        {getVisiblitySettingList()}
      </div>
    </>
  );
};

export default TrackConditionalVisibility;