import React from "react";
import ReactDOM from "react-dom";

import App from './components/App';
import UserContextProvider from './context/UserContext';
import SSOContextProvider from './context/SSOContext';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

ReactDOM.render(
    <DndProvider backend={HTML5Backend}>
        <SSOContextProvider>
            <UserContextProvider>
                <App />
            </UserContextProvider>
        </SSOContextProvider>
    </DndProvider>,
    document.querySelector("#root")
);