import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import React, { useEffect, useState } from "react";
import dateUtil from "../../utils/dateUtil";

export const CustomDatePicker = (props) => {
  const [lastUpdated, setLastUpdated] = useState(null);

  useEffect(() => {
    const dateParam = props.lastUpdated && !isNullOrEmpty(props.lastUpdated) ? new Date(props.lastUpdated) : null;
    setLastUpdated(dateParam);
  }, [props.lastUpdated]);

  const isNullOrEmpty = (str) =>{
    return str === null || str === "";
  }

  const isValidDate = (date) =>{
    var md = moment(date);
    return md.isValid() && md.diff(new moment(), 'days') > -1;
  }

  const onDateChange = (date) => {
    if (isNullOrEmpty(date)) {
      props.handleDateChange({ isValid: true, date: '' });
    } else if (!isNullOrEmpty(date) && isValidDate(date)) {
      let lastUpdated = dateUtil.formatDateInYearMonthDay(date);
      setLastUpdated(date);
      props.handleDateChange({ isValid: true, date: lastUpdated });
    }else {
      setLastUpdated(date);
      props.handleDateChange({ isValid: false, date });}
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          style={{ width: "100%" }}
          variant="inline"
          placeholder="Last Updated  (MM-DD-YYYY)"
          value={lastUpdated}
          onChange={onDateChange}
          format="MM-dd-yyyy"
          autoOk={true}
          disablePast={true}
          InputProps={{
            disableUnderline: true,
            readOnly: false,
          }}
          helperText={""}
        />
      </MuiPickersUtilsProvider>
    </div >
  );
};
