export const SIM_BUILDER_API_END_POINT = process.env.REACT_APP_SIM_BUILDER_API_URL;
export const ELS_PORTAL_URL = process.env.REACT_APP_PORTAL_URL;
export const SHORT_DATE_FORMAT = process.env.REACT_APP_SHORT_DATE_FORMAT;
export const TEXT_DELIMITER = process.env.REACT_APP_TEXT_DELIMITER;

const Configs = {
    aws: {
        s3: {
            bucketName: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
            region: process.env.REACT_APP_AWS_S3_BUCKET_REGION,
            s3Url: process.env.REACT_APP_AWS_S3_BUCKET_URL,
            portalBucketName: process.env.REACT_APP_AWS_S3_PORTAL_BUCKET_NAME,
        }
    }
};

export default Configs;