import { useHistory } from "react-router-dom";
import { useState, useContext } from "react";
import InternalSimService from "../../services/InternalSimService";
import { UserContext } from "../../context/UserContext";
import JSZip from "jszip";
import { read, utils } from "xlsx";
import {
  getFolderCaseInsensitive,
  uploadMediaFilesToS3,
  uploadCharactersFilesToS3,
  transformResponseOptions,
  getMediaAssignedToTrack,
  getLessonFeildValue,
  processMediaFiles,
  FOLDERS_NAME,
} from "./internalSimUtils";
import { v4 as uuidv4 } from "uuid";

import {
  capitalizeFirstLetter,
  removeSpecialCharcaters,
  TRACK_TYPE,
  validXlsTypes,
  validZipTypes,
} from "../../utils/utils";
import { INTERNAL_SIM_ALERTS } from "../../utils/AlertDialogMessages";
import { LibraryContext } from "../../context/LibraryContext";

const initialState = {
  title: "",
  description: "",
  tracks: [],
  characters: [],
  media: [],
  export: false,
};

export const useAddInternalSimHook = () => {
  const history = useHistory();
  const { token } = useContext(UserContext);
  const libContext = useContext(LibraryContext);

  const [fileNames, setFileNames] = useState({
    xls: "",
    zip: "",
  });
  const [simData, setSimData] = useState(initialState);
  const [titleError, setTitleError] = useState(false);
  const [isXlsFile, setIsXlsFile] = useState(false);
  const [isXlsFileValid, setIsXlsFileValid] = useState(true);
  const [isZipFileValid, setIsZipFileValid] = useState(true);
  const [fileError, setFileError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showMediaAlert, setShowMediaAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({ msg: "", type: "" });
  const [openAlert, setOpenAlert] = useState(false);
  const [showMissingXLSSheetAlert, setShowMissingXLSSheetAlert] =
    useState(false);
  const [xlsSheetMessage, setXLSSheetMessage] = useState("");
  const [showMissingZipFolderAlert, setShowMissingZipFolderAlert] =
    useState(false);
  const [zipFolderMessage, setZipFolderMessage] = useState("");
  const [inputKey, setInputKey] = useState(Date.now());
  const [simMedia, setSimMedia] = useState({
    charcaters: [],
    media: [],
  });
  const [isColumnsPresent, setIsColumnPresent] = useState({
    track: true,
    characters: true,
  });
  const [emptyFileError, setEmptyFileError] = useState(false);

  // function to get back to the internal sims listing page
  const backToInternalSimsList = () => {
    history.push("/internalSimulations");
  };

  // function to handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTitleError(false);
    setSimData({ ...simData, [name]: value });
  };

  // function to handle export checkbox
  const handleExportChange = (e) => {
    setSimData({ ...simData, export: e.target.checked });
  };

  // function to handle xls file as input and then processing it for further use
  const handleInputXLSFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Check if the uploaded file type is valid
      if (!validXlsTypes.includes(file.type)) {
        setIsXlsFileValid(false); // Mark that the file is not an XLS
        setIsXlsFile(false);
        setFileNames({
          ...fileNames,
          xls: "",
        });
        return; // Stop further processing
      }
      setFileNames({
        ...fileNames,
        xls: file.name,
      });
      setIsXlsFileValid(true);
      setShowMissingXLSSheetAlert(false);
      setFileError(false);
      setIsXlsFile(true);
      setEmptyFileError(false);
      setIsColumnPresent({ track: true, characters: true });

      preProcessXlsFileData(file, simData, setSimData);
      // After processing the file, change the input's key to force re-render
      setInputKey(Date.now());
    }
  };

  // function to save internal simualtion and checking validations
  const validateInternalSimData = () => {
    // if there is no title, show the error and halt execution
    if (simData.title.trim().length === 0) {
      setTitleError(true);
      return;
    }
    // if there is no xls file, show the error and halt execution
    if (!isXlsFile) {
      setFileError(true);
      return;
    }

    if (!isColumnsPresent.characters || !isColumnsPresent.track) {
      return;
    }

    // if there is no  media attached, show the message that no media is attached
    if (simMedia.charcaters?.length === 0 && simMedia.media?.length === 0) {
      setShowMediaAlert(true);
      return;
    }
    saveInternalSim();
  };

  const saveInternalSim = async () => {
    // if there is sim's title and xls file is attached then handle the media upload and POST method
    if (
      simData.title.trim().length > 0 &&
      isXlsFile &&
      isColumnsPresent.track &&
      isColumnsPresent.characters &&
      isXlsFileValid &&
      isZipFileValid
    ) {
      const simId = uuidv4();
      setLoading(true);
      const uploadedCharacters = await uploadCharactersFilesToS3(
        simMedia.charcaters,
        simId
      );
      const medias = await uploadMediaFilesToS3(simMedia.media, simId);

      const addSimObj = {
        title: simData.title.trim(),
        description: simData.description.trim(),
        export: simData.export,
        id: simId,
        characters: simData.characters?.map((character) => {
          const avatarUrl = character["avatarUrl"].toLowerCase();
          const matchingKey = Object.keys(uploadedCharacters).find(
            (key) => key.toLowerCase() === avatarUrl
          );
          return {
            ...character,
            avatarUrl: uploadedCharacters[matchingKey] || null,
          };
        }),
        tracks: simData.tracks,
        media: medias,
      };

      const response = await InternalSimService().saveInternalSim(
        addSimObj,
        token
      );

      if (response.status === 200) {
        if (simData.export) {
          libContext.addSimToLibrary(response.data);
          setAlertProps({ msg: response.message, type: "success" });
        }
        history.push("/internalSimulations");
      } else {
        console.debug(INTERNAL_SIM_ALERTS.errorSaveInternalSimulation.msg);
        setAlertProps({
          msg: INTERNAL_SIM_ALERTS.errorSaveInternalSimulation.msg,
          type: INTERNAL_SIM_ALERTS.errorSaveInternalSimulation.type,
        });
      }
      setLoading(false);
      setOpenAlert(true);
    }
  };

  // function to process the xls file, to get the sheets, check if there are all required sheets avialable
  // and then remove anomalies in the header/keys of file and then use  data
  const preProcessXlsFileData = (file, simData, setSimData) => {
    const reader = new FileReader();

    reader.onload = function (e) {
      const data = new Uint8Array(e.target.result);
      const workbook = read(data, { type: "array" });

      // Get all sheet names and create a case-insensitive mapping
      const sheetMap = {};
      workbook.SheetNames.forEach((sheet) => {
        sheetMap[sheet.toLowerCase()] = sheet; // Map lower case to actual sheet name
      });

      // Define required sheets in lower case
      const requiredSheets = ["tracks", "characters"];

      // Check for missing sheets
      const missingSheets = requiredSheets.filter((sheet) => !sheetMap[sheet]);

      // Case 1: Both sheets are missing, show the alert and halt execution
      if (missingSheets.length === 2) {
        setShowMissingXLSSheetAlert(true);
        setXLSSheetMessage(INTERNAL_SIM_ALERTS.bothXLSSheetsMissing);
        return;
      } else if (missingSheets.length === 1) {
        // Case 2: One sheet is missing, ask for confirmation
        const missingSheet =
          capitalizeFirstLetter(missingSheets[0]) + missingSheets[0].slice(1);
        setShowMissingXLSSheetAlert(true);
        setXLSSheetMessage(INTERNAL_SIM_ALERTS.missingSheet(missingSheet));
      }

      // Case 3: Proceed with reading the available sheets

      // Check and get the track sheet (if available)
      let tracksData,
        updatedTracks = [];
      if (sheetMap["tracks"]) {
        const trackSheetName = sheetMap["tracks"];
        const tracks = workbook.Sheets[trackSheetName];

        // Convert the worksheet to JSON
        tracksData = utils.sheet_to_json(tracks);
      }

      // Check and get the character sheet (if available)
      let charactersData;
      if (sheetMap["characters"]) {
        const characterSheetName = sheetMap["characters"];
        const characters = workbook.Sheets[characterSheetName];

        // Convert the worksheet to JSON
        charactersData = utils.sheet_to_json(characters);
      }

      if (tracksData?.length === 0 && charactersData?.length === 0) {
        setEmptyFileError(true);
        return;
      }

      if (tracksData?.length > 0 || charactersData?.length > 0) {
        if (tracksData) {
          // Remove Special characters and  transform tracks data
          const removeSpecialCharactersFromTracks = (tracksData) => {
            return tracksData.map(removeSpecialCharcaters);
          };

          if (
            Object.keys(removeSpecialCharcaters(tracksData[0])).includes(
              "Title Header"
            )
          ) {
            setIsColumnPresent({ ...isColumnsPresent, track: true });
            // Transform the array
            updatedTracks = transformResponseOptions(
              removeSpecialCharactersFromTracks(tracksData)
            ).map((track, index) => ({
              title: track["Title Header"] || "New Track",
              body:
                TRACK_TYPE[track["Track Type"]] !== TRACK_TYPE.Q
                  ? track["Body"]
                  : null,
              prompt: track["Prompt"] || null,
              type: TRACK_TYPE[track["Track Type"]] || null,
              order: index + 1,
              options:
                TRACK_TYPE[track["Track Type"]] === TRACK_TYPE.Q
                  ? track["responseOptions"]
                  : [],
              lesson: getLessonFeildValue(track),
              audio: getMediaAssignedToTrack(track, "Track Audio"),
              video: getMediaAssignedToTrack(track, "Video Asset"),
              image: getMediaAssignedToTrack(track, "Image Asset"),
              promptDetails:
                TRACK_TYPE[track["Track Type"]] === TRACK_TYPE.Q
                  ? track["Body"]
                  : null,
              description: track["Body"] || null,
            }));
          } else {
            setIsColumnPresent({ ...isColumnsPresent, track: false });
            return;
          }
        }

        if (charactersData) {
          if (
            !Object.keys(removeSpecialCharcaters(charactersData[0])).includes(
              "Character s Name"
            )
          ) {
            setIsColumnPresent({ ...isColumnsPresent, characters: false });
            return;
          }
          setIsColumnPresent({ ...isColumnsPresent, characters: true });
        }
      }

      // Update the simData state with the processed tracks and characters
      setSimData({
        ...simData,
        tracks: updatedTracks,
        characters: !charactersData
          ? []
          : charactersData?.map((character) => {
              const updatedCharacter = removeSpecialCharcaters(character);
              return {
                title: updatedCharacter["Character s Title"],
                name: updatedCharacter["Character s Name"] || "",
                description:
                  updatedCharacter["Character s Personal Description"],
                avatarUrl: updatedCharacter["Graphic File"] || null,
              };
            }),
      });
    };

    reader.readAsArrayBuffer(file);
  };

  // function to handle the uploading of zip file and reading its content
  const handleMediaUpload = async (e) => {
    setShowMissingZipFolderAlert(false);
    setShowMediaAlert(false);
    const file = e.target.files[0];
    if (file) {
      // Check if the uploaded file type is valid
      if (!validZipTypes.includes(file.type) && !file.name.endsWith(".zip")) {
        setIsZipFileValid(false);
        setFileNames({
          ...fileNames,
          zip: "",
        });
        return; // Stop further processing
      }
      setFileNames({
        ...fileNames,
        zip: file.name,
      });
      setIsZipFileValid(true);
      await readFoldersFromZipFile(file);
      // After processing the file, change the input's key to force re-render
      setInputKey(Date.now()); // This forces the input to reset
    }
  };

  // function to read folders inside a zip file, root or sub folders
  const readFoldersFromZipFile = async (file) => {
    const zip = new JSZip();
    setLoading(true);

    // Load the zip file content
    const zipContent = await zip.loadAsync(file);

    // Try to get root folders (those ending with /)
    const rootFolders = Object.keys(zipContent.files).filter((filePath) =>
      filePath.endsWith("/")
    );

    const charactersFiles = [];
    const mediaFiles = [];

    let processedCharactersFiles = [];
    let processedMediaFiles = [];

    // If rootFolders is empty, look for files starting with "characters/" or "media/"
    if (rootFolders.length === 0) {
      // Iterate through the zip files
      // Filter files starting with "characters/" or "media/" (case-insensitive)
      Object.keys(zipContent.files).forEach((filePath) => {
        const lowerCasePath = filePath.toLowerCase();
        const fileEntry = zipContent.files[filePath];
        if (
          lowerCasePath.startsWith(`${FOLDERS_NAME.characters}/`) &&
          !fileEntry.dir
        ) {
          charactersFiles.push(zipContent.files[filePath]);
        } else if (
          lowerCasePath.startsWith(`${FOLDERS_NAME.media}/`) &&
          !fileEntry.dir
        ) {
          mediaFiles.push(zipContent.files[filePath]);
        }
      });
    } else {
      let charactersFolder = "";
      let mediaFolder = "";
      // Get the ZIP file name without extension
      const zipFileName = file.name.split(".")[0];

      // Get all root-level directories from the ZIP
      const rootFolders = Object.keys(zipContent.files).filter((filePath) => {
        const fileEntry = zipContent.files[filePath];
        return fileEntry.dir && filePath.split("/").length === 2; // Root-level directories
      });

      // Dynamically determine the parent folder name from the ZIP structure (if any)
      const parentFolderName =
        rootFolders.length > 0 ? rootFolders[0].split("/")[0] : "";

      // Decide which folder name to use: zipFileName or parentFolderName
      const folderToUse =
        parentFolderName && parentFolderName !== zipFileName
          ? parentFolderName
          : zipFileName;
      const caseInsensitveFolderToUse = folderToUse.toLowerCase();

      // Check if "characters" or "media" exist either in the root or in a parent folder
      charactersFolder =
        getFolderCaseInsensitive(zipContent, FOLDERS_NAME.characters) ||
        getFolderCaseInsensitive(
          zipContent,
          `${caseInsensitveFolderToUse}/${FOLDERS_NAME.characters}`
        );
      mediaFolder =
        getFolderCaseInsensitive(zipContent, FOLDERS_NAME.media) ||
        getFolderCaseInsensitive(
          zipContent,
          `${caseInsensitveFolderToUse}/${FOLDERS_NAME.media}`
        );

      if (charactersFolder || mediaFolder) {
        // If either folder is found, proceed to extract files
        Object.keys(zipContent.files).forEach((filePath) => {
          const lowerCasePath = filePath.toLowerCase();
          const fileEntry = zipContent.files[filePath];

          if (!fileEntry.dir) {
            if (
              lowerCasePath.startsWith(FOLDERS_NAME.characters) ||
              lowerCasePath.startsWith(
                `${caseInsensitveFolderToUse}/${FOLDERS_NAME.characters}`
              )
            ) {
              charactersFiles.push(fileEntry); // Should now add the file
            } else if (
              lowerCasePath.startsWith(FOLDERS_NAME.media) ||
              lowerCasePath.startsWith(
                `${caseInsensitveFolderToUse}/${FOLDERS_NAME.media}`
              )
            ) {
              mediaFiles.push(fileEntry); // Should now add the file
            }
          }
        });
      }
    }

    // Handle missing folders
    if (charactersFiles.length === 0 && mediaFiles.length === 0) {
      setLoading(false);
      handleZipFileAlert();
      setZipFolderMessage(INTERNAL_SIM_ALERTS.bothFoldersMissing);
      return;
    }

    if (charactersFiles.length === 0 || mediaFiles.length === 0) {
      handleZipFileAlert();
      const missingFolderName =
        charactersFiles.length === 0 ? "Characters" : "Media";
      setZipFolderMessage(INTERNAL_SIM_ALERTS.missingFolder(missingFolderName));
    }

    processedCharactersFiles = await processMediaFiles(charactersFiles);
    processedMediaFiles = await processMediaFiles(mediaFiles);

    setSimMedia({
      charcaters: processedCharactersFiles || null,
      media: processedMediaFiles || null,
    });
    setLoading(false);
  };

  const handleYesForWithoutMedia = (confirmed) => {
    setShowMediaAlert(false); // Close the alert
    if (confirmed) {
      // If user confirms, trigger the saveInternalSimulation function
      saveInternalSim();
    }
  };

  const handleNoForXLSFileAlert = () => {
    setFileNames({ ...fileNames, xls: "" });
    setIsColumnPresent({ track: true, characters: true });
    setShowMissingXLSSheetAlert(false);
  };

  const handleNoForZipFileAlert = () => {
    setFileNames({ ...fileNames, zip: "" });
    handleZipFileAlert();
  };

  // Handle user clicking "Yes" or "No" in the alert dialog
  const handleZipFileAlert = () => {
    setShowMissingZipFolderAlert((prevState) => !prevState);
  };

  const handleYesForZipFileAlert = () => {
    if (simMedia.charcaters.length === 0 && simMedia.media.length === 0) {
      setFileNames({ ...fileNames, zip: "" });
    }
    handleZipFileAlert();
  };

  return {
    simData,
    titleError,
    fileError,
    loading,
    showMediaAlert,
    xlsSheetMessage,
    showMissingXLSSheetAlert,
    handleInputChange,
    handleExportChange,
    handleInputXLSFile,
    backToInternalSimsList,
    setShowMediaAlert,
    handleMediaUpload,
    validateInternalSimData,
    setShowMissingXLSSheetAlert,
    showMissingZipFolderAlert,
    zipFolderMessage,
    fileNames,
    openAlert,
    alertProps,
    setOpenAlert,
    handleYesForWithoutMedia,
    handleNoForXLSFileAlert,
    handleNoForZipFileAlert,
    handleYesForZipFileAlert,
    inputKey,
    isColumnsPresent,
    emptyFileError,
    isXlsFileValid,
    isZipFileValid,
  };
};
