
export const HttpMethods = Object.freeze({
    POST: "POST",
    PUT: "PUT",
    GET: "GET",
    DELETE: "DELETE",
});

class RequestService {
    makeRequest = (method, token = "") => {
        var requestHeader = new Headers();
        requestHeader.append("Content-Type", "application/json");
        const options = {
            method: method,
            headers: requestHeader
        };

        if (token !== "") {
            requestHeader.append("Authorization", `Bearer ${token}`);
        }
        return options;
    }
}

export default new RequestService();