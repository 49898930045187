import { UploadS3Files } from "../../services/S3UplodService";
import { v4 as uuidv4 } from "uuid";
import { getFileExtension, getMimeType, TRACK_TYPE } from "../../utils/utils";

// function to matching folder name without case-sensitivity
export const getFolderCaseInsensitive = (zip, folderName) => {
  const lowerCaseFolderName = folderName.toLowerCase();

  // all file paths in the ZIP file
  const allZipFilePaths = Object.keys(zip.files);

  // Filter root folder names or folder names inside any parent directory
  const matchingFolder = allZipFilePaths.find((filePath) => {
    // removed extra forward and back slash in file paths
    const normalizedFolderPath = filePath
      .toLowerCase()
      .replace(/\\/g, "/")
      .replace(/\/$/, "");

    // Check if it matches folderName in root or within any parent folder
    return (
      normalizedFolderPath === lowerCaseFolderName || // Exact root match
      normalizedFolderPath.endsWith(`/${lowerCaseFolderName}`) || // Nested folder match
      normalizedFolderPath.includes(`/${lowerCaseFolderName}/`) // Folder somewhere in the path
    );
  });

  // Return the folder object if found, otherwise null
  return matchingFolder ? zip.folder(matchingFolder) : null;
};

// Function to process a folder's files
export const processMediaFiles = async (filesArray) => {
  const mediaFiles = []; // Initialize an array to hold the files
  const mediaFilesPromises = [];

  filesArray.forEach((file) => {
    const promise = file.async("blob").then((blobContent) => {
      const fileName = file.name.split("/").pop(); // Extracting just the file name
      // Create a new File object from the Blob
      const newFile = new File(
        [blobContent],
        `${uuidv4()}.${getMimeType(fileName)}`,
        {
          type: getMimeType(fileName),
        }
      );
      newFile.extension = getFileExtension(fileName);
      newFile.tempName = fileName;
      if (getMimeType(fileName) !== "application/octet-stream") {
        mediaFiles.push(newFile);
      }
    });
    mediaFilesPromises.push(promise);
  });

  // Wait for all file processing to finish
  await Promise.all(mediaFilesPromises);
  return mediaFiles;
};

// upload sim media to S3
export const uploadMediaFilesToS3 = async (mediaFiles, simId) => {
  const promises = [];
  let mediaUpload = [];

  // upload files when we have character's media
  if (mediaFiles !== null) {
    mediaFiles.forEach((file) => {
      const uploadTask = UploadS3Files(file, `${simId}/`);
      promises.push(uploadTask);
    });
  }

  await Promise.all(promises)
    .then((uploadedFiles) => {
      const nameMaxLength = 250;
      mediaFiles.forEach((file, i) => {
        let name = file.tempName.split(".")[0];
        mediaUpload.push({
          name:
            name.length > nameMaxLength
              ? name.substring(0, nameMaxLength)
              : name,
          extension: file.extension,
          type: file.type,
          url: uploadedFiles[i].fileKey,
        });
      });
    })
    .catch((err) => console.error(err));
  return mediaUpload;
};

// upload sim's characters to s3
export const uploadCharactersFilesToS3 = async (characters, simId) => {
  const promises = [];
  let characterUrl = {};
  if (characters !== null) {
    characters.forEach((file) => {
      const key = `${simId}/characters/${file.name.split(".")[0]}/`;
      const uploadTask = UploadS3Files(file, key);
      promises.push(uploadTask);
    });
  }
  await Promise.all(promises)
    .then((uploadedFiles) => {
      characters.forEach((file, i) => {
        characterUrl[file.tempName] = uploadedFiles[i].fileKey;
      });
    })
    .catch((err) => console.error(err));
  return characterUrl;
};

// function to convert an array of objects having responses in it from a track object
export const transformResponseOptions = (tracksData) => {
  return tracksData.map((track) => {
    // Create the responseOptions array from Feedback, Info, and Response keys
    const responseOptions = [];

    // Assuming there are 8 Feedback, Info, and Response fields, loop through them
    for (let optionIndex = 1; optionIndex <= 8; optionIndex++) {
      const option = {
        feedback: track[`Feedback ${optionIndex}`] || null,
        info: track[`Info ${optionIndex}`] || null,
        response: track[`Response ${optionIndex}`] || null,
      };

      if (option.response !== null) {
        responseOptions.push(option);
      }
    }

    // Return the transformed object with responseOptions and other fields
    return {
      ...track,
      responseOptions, // New array of grouped feedback, info, response
    };
  });
};

// function to get Media assigned to the track
export const getMediaAssignedToTrack = (track, media) => {
  return track[media] ? track[media].split(".")[0] : null;
};

// function to get lesson value based on Track Type
export const getLessonFeildValue = (track) => {
  if (TRACK_TYPE[track["Track Type"]] === TRACK_TYPE.F) {
    return track["Considerations"];
  } else if (TRACK_TYPE[track["Track Type"]] === TRACK_TYPE.Q) {
    return track["Learning objectives"];
  }
  return null;
};

export const FOLDERS_NAME = Object.freeze({
  characters: "characters",
  media: "media",
});
