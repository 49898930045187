import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import React, { useContext, useState } from "react";
import "regenerator-runtime/runtime";
import Loader from "../loader";
import TrackService from "../../services/TrackService";
import { UserContext } from "../../context/UserContext";
import Table from "./Table";
import { EditSimContext } from "../../context/EditSimContext";
import { EditTrackContext } from "../../context/EditTrackContext";

const FindAndReplaceDialog = (props) => {
  const [inputFind, setInputFind] = useState({
    value: "",
    error: false,
    submit: false,
  });
  const [inputReplace, setInputReplace] = useState({
    value: "",
    error: false,
    submit: false,
    checkBoxError: false,
  });
  const [isFinding, setIsFinding] = useState(false);
  const [isSearched, setIsSearched] = useState({ value: "", isData: false });
  const [foundAcrossTracks, setFoundAcrossTracks] = useState([]);
  const { token } = useContext(UserContext);
  const { setSimToEdit, editSimState } = useContext(EditSimContext);
  const { setEditTrackState, setPrevTrackState } = useContext(EditTrackContext);
  const [selected, setSelectedRows] = useState([]);

  const columns = [
    {
      dataField: "order",
      text: "Track Number",
      align: "left",
    },
    {
      dataField: "title",
      text: "Track Title",
      align: "left",
    },
  ];

  const inputFindHandler = (e) => {
    const value = e.target.value;
    const error = inputFind.submit && (value === "" ? true : false);
    setInputFind((prevState) => {
      return { ...prevState, value, error };
    });
  };

  const inputReplaceHandler = (e) => {
    const value = e.target.value;
    const error = inputReplace.submit && (value === "" ? true : false);
    setInputReplace((prevState) => {
      return { ...prevState, value, error };
    });
  };

  const findHandler = () => {
    setInputReplace((prevState) => {
      return { ...prevState, error: false };
    });
    const value = inputFind.value.trim();
    if (value === "") {
      setInputFind({
        value: value,
        error: true,
        submit: true,
        checkBoxError: false,
      });
      return false;
    }
    setInputFind({
      value: value,
      error: false,
      submit: false,
      checkBoxError: false,
    });
    setIsFinding(true);
    TrackService()
      .findWordOccurrencesAcrossTrack(props.simId, value, token)
      .then((response) => {
        if (response.status === 200) {
          setFoundAcrossTracks(response.data);
          if (!isSearched.isData && response.data?.length > 0) {
            setIsSearched((prevState) => {
              return { ...prevState, isData: true };
            });
          }
          setIsSearched((prevState) => {
            return { ...prevState, value: value };
          });
          setIsFinding(false);
        } else {
          setIsFinding(false);
          props.setAlert({
            msg: "Error: while finding word across track!",
            type: "error",
          });
          props.setOpenAlert(true);
          console.error(
            "Error: while finding word across track!",
            response.message
          );
        }
      })
      .catch((e) => {
        setIsFinding(false);
        props.setAlert({ msg: e.message, type: "error" });
        props.setOpenAlert(true);
        console.error(e.message);
      });
  };

  const replaceWordHandler = () => {
    const value = inputReplace.value.trim();
    if (value === "") {
      setInputReplace({ value: value, error: true, submit: true });
      return false;
    }
    if (selected.length === 0) {
      setInputReplace({
        value: value,
        error: false,
        submit: true,
        checkBoxError: true,
      });
      return false;
    }
    setIsFinding(true);
    TrackService()
      .replaceWordAcrossTrack(
        props.simId,
        JSON.stringify(selected),
        isSearched.value,
        value,
        token
      )
      .then((response) => {
        if (response.status === 200) {
          setSimToEdit(editSimState, token);
          setEditTrackState(null);
          setPrevTrackState(null);
          props.setIsOpen(false);
          props.setAlert({ msg: response.message, type: "success" });
          props.setOpenAlert(true);
          setIsFinding(false);
        } else {
          setIsFinding(false);
          props.setAlert({
            msg: "Error: while replacing word across track!",
            type: "error",
          });
          props.setOpenAlert(true);
          console.error(
            "Error: while replacing word across track!",
            response.message
          );
        }
      })
      .catch((e) => {
        setIsFinding(false);
        props.setAlert({ msg: e.message, type: "error" });
        props.setOpenAlert(true);
        console.error(e.message);
      });
  };

  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      setSelectedRows([...selected, row.trackId]);
      setInputReplace({
        ...inputReplace,
        error: false,
        submit: true,
        checkBoxError: false,
      });
    } else {
      setSelectedRows(selected.filter((x) => x !== row.trackId));
    }
  };

  const handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map((r) => r.trackId);
    if (isSelect) {
      setSelectedRows([...selected, ...ids]);
      setInputReplace({
        ...inputReplace,
        error: false,
        submit: true,
        checkBoxError: false,
      });
    } else {
      setSelectedRows(selected.filter((element) => !ids.includes(element)));
    }
  };

  return (
    <div>
      <Dialog
        open={props.isOpen}
        fullWidth
        maxWidth="md"
        onClose={(event, reason) => {
          if (reason !== "escapeKeyDown" || reason !== "backdropClick") {
            props.setIsOpen(false);
          }
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className="color-blue">
          {props.title}
        </DialogTitle>
        <DialogContent dividers>
          <div className="row align-bottom">
            <>
              <div className="col-9">
                <TextField
                  variant="outlined"
                  error={inputFind.error}
                  id="find"
                  name="find"
                  label="Find Word"
                  value={inputFind.value}
                  inputProps={{ maxLength: props.length }}
                  fullWidth
                  onChange={inputFindHandler}
                />
              </div>
              <div className="col-3 align-self-center">
                <Button
                  onClick={findHandler}
                  variant="contained"
                  className="w-100"
                  color="primary"
                >
                  {props.findLable}
                </Button>
              </div>
              {inputFind.error && (
                <div className="col-12 error ml-1 mt-1 mb-1">
                  Find Word field is required
                </div>
              )}
            </>
            {isSearched.isData && (
              <>
                <div className="col-9 mt-3">
                  <TextField
                    variant="outlined"
                    error={inputReplace.error || inputReplace.checkBoxError}
                    id="find"
                    name="find"
                    label="Replace Word"
                    value={inputReplace.value}
                    fullWidth
                    onChange={inputReplaceHandler}
                    inputProps={{ maxLength: props.length }}
                    disabled={foundAcrossTracks.length === 0 ? true : false}
                  />
                </div>
                <div className="col-3 align-self-center mt-3">
                  <Button
                    onClick={replaceWordHandler}
                    variant="contained"
                    className="w-100"
                    color="primary"
                    disabled={foundAcrossTracks.length === 0 ? true : false}
                  >
                    {props.save}
                  </Button>
                </div>
                {inputReplace.error && (
                  <div className="col-12 error ml-1 mt-1 mb-1">
                    Replace Word field is required
                  </div>
                )}
                {inputReplace.checkBoxError && (
                  <div className="col-12 error ml-1 mt-1 mb-1">
                    Please select the track(s) you want to replace the specified
                    word
                  </div>
                )}
                <div className=" col-12 mt-3 mb-3">
                  <div className="mb-3 ml-3 font-weight-bold">
                    {foundAcrossTracks.length === 0 ? (
                      <>
                        No data found for{" "}
                        <span className="font-italic text-danger break-word">
                          &ldquo;{isSearched.value}&rdquo;
                        </span>
                      </>
                    ) : (
                      <>
                        Below are the tracks given in which the word{" "}
                        <span className="font-italic text-success break-word">
                          &ldquo;{isSearched.value}&rdquo;
                        </span>{" "}
                        exist
                      </>
                    )}
                  </div>
                  <Table
                    data={foundAcrossTracks}
                    columns={columns}
                    remote={false}
                    id="order"
                    select={true}
                    handleOnSelect={handleOnSelect}
                    handleOnSelectAll={handleOnSelectAll}
                  />
                </div>
              </>
            )}
            {isSearched.value && !isSearched.isData && (
              <div className="col-12 d-flex justify-content-center mt-3 mb-3 font-weight-bold text-danger">
                <div>No data found</div>
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              props.setIsOpen(false);
            }}
            variant="contained"
            color="secondary"
          >
            {props.cancel}
          </Button>
        </DialogActions>
      </Dialog>

      {isFinding && (
        <Dialog
          open={props.isOpen}
          fullWidth
          maxWidth="md"
          onClose={(event, reason) => {
            if (reason !== "escapeKeyDown" || reason !== "backdropClick") {
              props.setIsOpen(false);
            }
          }}
          aria-labelledby="form-dialog-title"
        >
          <div className="overlay">
            <Loader />
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default FindAndReplaceDialog;
