import MoreVertIcon from "@material-ui/icons/MoreVert";
import ReplyIcon from "@material-ui/icons/Reply";
import React from "react";


const EmailHeader = (props) => {
  
  let isEmail = "<" + props.senderEmail + ">";
  let senderEmail = props.senderEmail ? isEmail  : " "; 
  
  return (
    <>
      <h3>{props.subject}</h3>
      <div className="emailHeader">
        <div className="emailToFrom">
          <p>
            <b>{props.senderName}</b>
            {senderEmail}
          </p>
          <p>{props.receiverList ? props.receiverList : "to me"}</p>
        </div>
        <div className="emailHeaderButtons">
          <ReplyIcon style={{ fontSize: 15 }} />
          <MoreVertIcon style={{ fontSize: 15 }} />
        </div>
      </div>
    </>
  );
};

export default EmailHeader;