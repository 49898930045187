import React, { createContext, useState, useEffect, useContext, useMemo } from 'react';
import FolderService from "../services/FolderService";
import { UserContext } from './UserContext';
import { isNullOrEmpty } from "../utils/utils";

export const SimFoldersContext = createContext();

const SimFoldersContextProvider = (props) => {
    const { token, logout } = useContext(UserContext);
    const [simFolders, setSimFolders] = useState([]);
    const [currentFolder, setCurrentFolder] = useState({});

    useEffect(() => {
        if (!isNullOrEmpty(token)) {
            getAllSimFolders();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    const setFolderEdit = (folderId) => {
        for (let i = 0; i < simFolders.length; i++) {
            if (simFolders[i].id === folderId) {
                setCurrentFolder({ ...simFolders[i] });
                break;
            }
        }
    };

    const getAllSimFolders = () => {
        FolderService()
            .getAllFolders(token)
            .then((folders) => {
                if (folders.status === 401) {
                    logout();
                } else {
                    setSimFolders(folders);;
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const getFolderById = (folderId) => {
        return simFolders.filter(
            (folder) => { return folder.id === folderId }
        )[0];
    };

    const addSimFolder = (folderName) => {
        const folder = { name: folderName, open: false, simFolders: [] };
        FolderService()
            .craeteFolder(folder, token)
            .then(response => {
                if (response.status === 200) {
                    const tempFoldersList = simFolders.concat({ ...response.data, simFolders: [] })
                    const sortedFolders = tempFoldersList.sort(function (a, b) {
                        var folderA = a.name.toUpperCase();
                        var folderB = b.name.toUpperCase();
                        if (folderA < folderB) {
                            return -1;
                        }
                        if (folderA > folderB) {
                            return 1;
                        }
                        return 0;
                    });
                    setSimFolders(sortedFolders);
                }
            })
            .catch((err) => {
                console.error("Error while adding folder!", err);
            });
    };

    const updateSimFolder = (folderName) => {
        const updatedFolder = { ...currentFolder, name: folderName };
        FolderService()
            .updateFolder(updatedFolder, token)
            .then(response => {
                if (response.status === 200) {
                    let folderList = [];
                    simFolders.forEach((folder) => {
                        if (folder.id === updatedFolder.id) {
                            folderList.push({ ...updatedFolder });
                        } else {
                            folderList.push({ ...folder });
                        }
                    });
                    const sortedFolders = folderList.sort(function (a, b) {
                        var folderA = a.name.toUpperCase();
                        var folderB = b.name.toUpperCase();
                        if (folderA < folderB) {
                            return -1;
                        }
                        if (folderA > folderB) {
                            return 1;
                        }
                        return 0;
                    });
                    setSimFolders(sortedFolders);
                }
            })
            .catch((err) => {
                console.error("Error while updating folder!", err);
            });
    };

    const moveToFolder = (simId, folder) => {
        let sims = folder.simFolders === undefined ? [] : folder.simFolders;
        const updatedFolder = { ...folder, simFolders: sims.concat({ simId: simId }), simulationId: simId };
        FolderService()
            .moveSimToFolder({ ...updatedFolder }, token)
            .then(response => {
                if (response.status === 200) {
                    getAllSimFolders();
                } else {
                    console.error("Error while updating folder!");
                }
            })
            .catch((e) => {
                console.error("Error while updating folder!", e);
            });
    };

    /**
     * This method will remove sim from the selected folder and update the list
     * 
     * @param {*} simId 
     * @param {*} folderId 
     */
    const removeSimFromFolder = (simId, folderId) => {
        let sims = [];
        let currentFolder = getFolderById(folderId);
        if (currentFolder.simFolders.length > 0) {
            currentFolder.simFolders.forEach(sim => {
                if (sim.simId !== simId) {
                    sims.push(sim);
                }
            });
        }

        const updatedFolder = { ...currentFolder, simFolders: sims };

        FolderService()
            .removeSimFromFolder(updatedFolder.id, simId, token)
            .then(response => {
                if (response.status === 200) {
                    let folderList = [];
                    simFolders.forEach((folder) => {
                        if (folder.id === updatedFolder.id) {
                            folderList.push({ ...updatedFolder });
                        } else {
                            folderList.push({ ...folder });
                        }
                    });
                    setSimFolders(folderList);
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    const deleteFolder = (folderId) => {
        FolderService()
            .deleteFolder(folderId, token)
            .then(response => {
                if (response.status === 200) {
                    let folderList = [];
                    simFolders.forEach((folder) => {
                        if (folder.id !== folderId) {
                            folderList.push({ ...folder });
                        }
                    });
                    const sortedFolders = folderList.sort(function (a, b) {
                        var folderA = a.name.toUpperCase();
                        var folderB = b.name.toUpperCase();
                        if (folderA < folderB) {
                            return -1;
                        }
                        if (folderA > folderB) {
                            return 1;
                        }
                        return 0;
                    });
                    setSimFolders(sortedFolders);
                }
            })
            .catch(error => {
                console.error(error);
            });
    };

    const simFoldersStateProviderValue = useMemo(() => {
        return {
            getAllSimFolders,
            getFolderById,
            addSimFolder,
            updateSimFolder,
            moveToFolder,
            removeSimFromFolder,
            deleteFolder,
            simFolders,
            setSimFolders,
            setFolderEdit,
            currentFolder,
            setCurrentFolder
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [simFolders, currentFolder]);

    return (
        <SimFoldersContext.Provider value={simFoldersStateProviderValue} >
            {props.children}
        </SimFoldersContext.Provider>
    );
}
export default SimFoldersContextProvider;