import React, { useContext, useState } from "react";
import MediaAssetList from "./MediaAssetList";
import MediaAssetEdit from "./MediaAssetEdit";
import { MediaContext } from "../../../context/MediaContext";
import AddCircle from "@material-ui/icons/AddCircle";
import IconButton from "@material-ui/core/IconButton";
import Alert from "../../generic/Alerts";

const MediaTab = () => {
    const { images, videos, sounds, pdfs, captions } = useContext(MediaContext);
    const [selected, setSelected] = useState();
    const { addMedia, updateMedia, deleteMedia } = useContext(MediaContext);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertProps] = useState({
        msg: "PDF document link is genrated and copy to clipboard successfully!",
        type: "success"
    });

    const handleDelete = (id) => {
        deleteMedia(id);
    };

    const handleAdd = (media) => {
        if (selected) {
            updateMedia(media);
            setSelected(null);
        } else {
            addMedia(media);
            setSelected(null);
        }
    };
    const resetForm = () => {
        setSelected(null);
    };

    const handleSelect = (id) => {
        const allAssets = images
            .concat(videos)
            .concat(sounds)
            .concat(pdfs)
            .concat(captions);

        const found = allAssets.filter((val) => {
            return val.id === id;
        });
        setSelected(found[0]);
    };

    return (
        <div className="mediaTab">
            <input type="text" id="pdfDocUrl" style={{ display: 'none' }} />
            <div id="mediaTab_asset_lists" className="block-width">
                <MediaAssetList
                    key={"images"}
                    type={"image"}
                    title={"Image Assets"}
                    list={images}
                    handleSelect={handleSelect}
                    handleDelete={handleDelete}
                />
                <MediaAssetList
                    key={"videos"}
                    type={"video"}
                    title={"Video Assets"}
                    list={videos}
                    handleSelect={handleSelect}
                    handleDelete={handleDelete}
                />
                <MediaAssetList
                    key={"sounds"}
                    type={"audio"}
                    title={"Audio Assets"}
                    list={sounds}
                    handleSelect={handleSelect}
                    handleDelete={handleDelete}
                />
                <MediaAssetList
                    key={"pdfs"}
                    type={"pdf"}
                    title={"PDF Assets"}
                    handleSelect={handleSelect}
                    handleDelete={handleDelete}
                    list={pdfs}
                    setOpenAlert={setOpenAlert}
                />
                <MediaAssetList
                    key={"captions"}
                    type={"captions"}
                    title={"Caption Assets"}
                    handleSelect={handleSelect}
                    handleDelete={handleDelete}
                    list={captions}
                />
            </div>
            {selected && (
                <div>
                    <IconButton onClick={resetForm}>
                        <AddCircle color="primary" />
                    </IconButton>
                </div>
            )}
            <MediaAssetEdit
                val={selected}
                handleAdd={handleAdd}
            />
            {openAlert && <Alert {...alertProps} openAlert={openAlert} setOpenAlert={setOpenAlert} />}
        </div>
    );
};


export default MediaTab;