import React, { useContext, useEffect } from "react";
import { PlayerContext } from "../../context/PlayerContext";

const Character = (props) => {
  const { setShowPlayButton } = useContext(PlayerContext);

  useEffect(() => {
    const intro = document.getElementById("intro");
    const playPromise = intro.play();

    if (playPromise !== undefined) {
      playPromise.then(function () {
        console.debug("Audio play started in Character:");
        setShowPlayButton(false);
      }).catch(function (error) {
        console.debug("Audio Play exception  in Character:", error);
        setShowPlayButton(true, error);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.play]);

  return (
    <>
      {props.avatarUrl && (
        <img src={props.avatarUrl} alt={props.name} />
      )}

      {
        !props.popup && (
          <audio id="intro" muted={props.isMuted} autoPlay={true}>
            {props.audioUrl && <source src={props.audioUrl} type="audio/mpeg" />}
          </audio>
        )
      }

      <div className="welcome_text">
        <p>
          Name: {props.name} {props.age > 0 && `  (${props.age})`}
        </p>
        <p>Title: {props.title}</p>
        <p>{props.desc}</p>
      </div>
    </>
  );
};

export default Character;