import React from "react";
import { Button, IconButton } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import RightArrow from '@material-ui/icons/ArrowForwardRounded';

const AlertDialog = (props) => {
    const textAlign = props.textAlign ? props.textAlign : "text-center";

    return (
        <div>
            <Dialog
                open={true}
                fullWidth={true}
                maxWidth={props.size}
                onClose={props.isSticky === undefined ? () => props.handleClose() : () => { }}
                aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title" className={textAlign}><strong>{props.title}</strong>{props.icon}</DialogTitle>
                {props.msg && <DialogContent dangerouslySetInnerHTML={{ __html: props.msg }} />}
                {props.showGoToFlashTrack &&
                    <DialogContent className={textAlign}>
                        <IconButton onClick={() => props.handleGoToFlashTrack()}>
                            <RightArrow fontSize="large" />
                        </IconButton>
                    </DialogContent>
                }
                {props.showDialogActions &&
                    <DialogActions>
                        <Button onClick={() => props.handleClose()} color="primary">
                            No
                        </Button>
                        <Button onClick={() => props.handleDelete()} color="secondary">
                            Yes
                        </Button>
                    </DialogActions>
                }
                {props.showInfoAction &&
                    <DialogActions>
                        <Button onClick={() => props.handleClose()} color="secondary">
                            Ok
                        </Button>
                    </DialogActions>
                }
            </Dialog>
        </div >
    );
}

export default AlertDialog;