import { useEffect, useRef, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import PaginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
  PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import asc from "../../assets/images/asc.png";
import desc from "../../assets/images/desc.png";

let timer;
const MySearch = (props) => {
  const [inputSearch, setInputSearch] = useState("");

  const handleInputSearch = (e, search) => {
    const value = e.target.value;
    setInputSearch(value);
    timer && clearTimeout(timer);
    timer = setTimeout(() => search(value), 500);
  };

  return (
    <div>
      <input
        className="form-control"
        placeholder="Search in records..."
        onChange={(e) => {
          handleInputSearch(e, props.onSearch);
        }}
        value={inputSearch}
        onKeyDown={(e) => {
          if (inputSearch && e.key === "Enter") {
            props.onSearch(new String(inputSearch));
          }
        }}
        type="text"
      />
    </div>
  );
};

const Table = ({
  data,
  columns,
  id,
  total = null,
  getData = null,
  apiUrl = null,
  defaultSort = "",
  defaultOrder = "",
  remote = true,
  select = true,
  isMultiSelect = false,
  handleOnRowSelect = null,
  prvSelectedRows = null,
  handleOnSelect = null,
  handleOnSelectAll = null,
}) => {
  const [sizePerPageState, setSizePerPageState] = useState(10);
  const [dataLength, setDataLength] = useState(null);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const selectedRows = useRef(prvSelectedRows);
  const totalRecords = remote ? total : data.length;
  const pageRef = useRef(1);
  const perPageRef = useRef(10);
  const columnName = useRef(defaultSort);
  const order = useRef(defaultOrder);

  useEffect(() => {
    document.getElementById("no_rows_found")?.remove();
    if (dataLength == 0) {
      const noRowsFoundNode =
        '<tbody id="no_rows_found">' +
        '<tr><td colspan="40" align="center">No rows found</td>' +
        "</tr>" +
        "</tbody>";
      const thead = document.getElementsByTagName("thead");
      thead[0].insertAdjacentHTML("afterend", noRowsFoundNode);
    }
  }, [dataLength]);

  const dataLengthHandler = ({ dataSize }) => {
    setDataLength(dataSize);
  };

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange,
  }) => (
    <div className="d-flex mb-3">
      <div className="input-group-addon input-group-prepend">
        <span className="input-group-text table_size_per_page_text">Show </span>
      </div>
      <select
        className="form-control table_size_per_page_input"
        onChange={(e) => {
          onSizePerPageChange(e.target.value);
        }}
      >
        {options.map((option) => {
          return (
            <option key={option.text} value={option.page}>
              {option.text}
            </option>
          );
        })}
      </select>
      <div className="input-group-addon input-group-prepend">
        <span className="input-group-text table_size_per_page_text">
          records per page
        </span>
      </div>
    </div>
  );

  const sortOption = {
    sortCaret: (order, column) => {
      if (!order) return;
      else if (order === "asc")
        return (
          <span className="sort_symbol">
            <img src={asc} alt="asc" />
          </span>
        );
      else if (order === "desc")
        return (
          <span className="sort_symbol">
            <img src={desc} alt="desc" />
          </span>
        );
      return null;
    },
  };

  const options = {
    sizePerPageRenderer,
    custom: true,
    paginationSize: 1,
    pageStartIndex: 1,
    alwaysShowAllBtns: true,
    firstPageText: "First",
    prePageText: "Previous",
    nextPageText: "Next",
    lastPageText: "Last",
    showTotal: true,
    sizePerPage: sizePerPageState,
    totalSize: totalRecords,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "All",
        value: totalRecords === 0 ? 1 : totalRecords,
      },
    ],
  };

  const remoteOptions = { ...options, page };

  const handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder, searchText }
  ) => {
    let pageValue = page;
    if (sizePerPage !== sizePerPageState) {
      setSizePerPageState(sizePerPage);
    }
    if (searchValue != searchText) {
      pageValue = 1;
      setSearchValue(searchText);
      setPage(pageValue);
      pageRef.current = pageValue;
    } else {
      setPage(pageValue);
      pageRef.current = pageValue;
    }
    perPageRef.current = sizePerPage;
    columnName.current = sortField ? sortField : columnName.current;
    order.current = sortOrder ? sortOrder : order.current;
    getData(
      `${apiUrl}?filter_value=${searchText && searchText}&page_number=${
        pageValue === 0 ? 1 : pageValue
      }&page_size=${sizePerPage === 0 ? 1 : sizePerPage}&sort_column=${
        sortField ? sortField : defaultSort
      }&sort_order=${sortOrder ? sortOrder : defaultOrder}`
    );
  };

  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        selectedRows.current = selectedRows.current.concat(row);
        handleOnRowSelect(
          selectedRows.current,
          pageRef.current,
          perPageRef.current,
          columnName.current,
          order.current
        );
      } else {
        let rows = selectedRows.current.filter(
          (selectedRow) => selectedRow.id !== row.id
        );
        selectedRows.current = rows;
        handleOnRowSelect(
          selectedRows.current,
          pageRef.current,
          perPageRef.current,
          columnName.current,
          order.current
        );
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      if (isSelect) {
        selectedRows.current = selectedRows.current.concat(rows);
        handleOnRowSelect(
          selectedRows.current,
          pageRef.current,
          perPageRef.current,
          columnName.current,
          order.current
        );
      } else {
        selectedRows.current = selectedRows.current.filter(
          (selectedRow) => !rows.map((row) => row.id).includes(selectedRow.id)
        );
        handleOnRowSelect(
          selectedRows.current,
          pageRef.current,
          perPageRef.current,
          columnName.current,
          order.current
        );
      }
    },
  };

  useEffect(() => {
    selectedRows.current = prvSelectedRows;
  }, [prvSelectedRows]);

  const selectRowStatic = {
    mode: "checkbox",
    clickToSelect: true,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
  };

  return (
    <PaginationProvider
      pagination={PaginationFactory(remote ? remoteOptions : options)}
    >
      {({ paginationProps, paginationTableProps }) => (
        <>
          <ToolkitProvider keyField={id} data={data} columns={columns} search>
            {(toolkitprops) => [
              <div className="d-flex justify-content-between">
                <SizePerPageDropdownStandalone {...paginationProps} />
                <MySearch {...toolkitprops.searchProps} />
              </div>,
              remote && !isMultiSelect && (
                <BootstrapTable
                  {...paginationTableProps}
                  {...toolkitprops.baseProps}
                  onDataSizeChange={dataLengthHandler}
                  remote={{ sort: true, pagination: true, search: true }}
                  striped
                  onTableChange={handleTableChange}
                  sort={sortOption}
                />
              ),
              remote && isMultiSelect && (
                <BootstrapTable
                  {...paginationTableProps}
                  {...toolkitprops.baseProps}
                  onDataSizeChange={dataLengthHandler}
                  remote={{ sort: true, pagination: true, search: true }}
                  striped
                  onTableChange={handleTableChange}
                  sort={sortOption}
                  selectRow={selectRow}
                />
              ),
              !remote && select && (
                <BootstrapTable
                  {...paginationTableProps}
                  {...toolkitprops.baseProps}
                  onDataSizeChange={dataLengthHandler}
                  striped
                  sort={sortOption}
                  selectRow={selectRowStatic}
                />
              ),
              !remote && !select && (
                <BootstrapTable
                  {...paginationTableProps}
                  {...toolkitprops.baseProps}
                  onDataSizeChange={dataLengthHandler}
                  striped
                  sort={sortOption}
                />
              ),
              <div className="d-flex justify-content-between">
                <PaginationTotalStandalone {...paginationProps} />
                <PaginationListStandalone {...paginationProps} />
              </div>,
            ]}
          </ToolkitProvider>
        </>
      )}
    </PaginationProvider>
  );
};

export default Table;
