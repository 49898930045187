import { Portal } from "@material-ui/core";

const LoaderWrapper = ({ children }) => {
  return (
    <Portal>
      <div className="loaderWrapper">{children}</div>
    </Portal>
  );
};

export default LoaderWrapper;
