const InternalSimCharactersInfo = ({ characters }) => {
  return (
    <>
      <div className="col-12 bg-heading mb-3">
        <h4>Characters Info</h4>
      </div>
      {characters.length > 0 &&
        characters.map((charcater, index) => (
          <>
            <div className="col-2 border-bottom mb-2">
              <p className="font-weight-bold">Charcater {index + 1}:</p>
            </div>
            <div className="col-10 border-bottom mb-2">
              <div className="row">
                <div className="col-2">
                  <p className="font-weight-bold">Name:</p>
                </div>
                <div className="col-10">{charcater.name}</div>
                <div className="col-2">
                  <p className="font-weight-bold">Title:</p>
                </div>
                <div className="col-10">{charcater.title}</div>
                <div className="col-2">
                  <p className="font-weight-bold">Description:</p>
                </div>
                <div className="col-10 text-justify">
                  {charcater.description}
                </div>
              </div>
            </div>
          </>
        ))}
    </>
  );
};

export default InternalSimCharactersInfo;
