import React, { createContext, useState, useContext } from 'react';
import { UserContext } from "../context/UserContext";
import SimIdeaService from '../services/SimIdeaService';

export const SimIdeasContext = createContext();

const SimIdeasContextProvider = (props) => {
    const { token, logout } = useContext(UserContext);
    const [simIdeasList, setSimIdeasList] = useState([]);
    const [simIdea, setSimIdea] = useState();

    const getAllIdeas = (callback) => {
        if (simIdeasList.length === 0) {
            SimIdeaService().getAllSimIdeas(token).then((response) => {
                if (response.status === 401) {
                    logout();
                } else {
                    setSimIdeasList(response.data);
                    callback(true);
                }

            }).catch((e) => {
                console.log('Error: Could not load ideas from DB', e.message);
            });
        } else {
            callback(true);
        }
    }

    const simIdeasStateProviderValue =
    {
        getAllIdeas,
        simIdeasList,
        setSimIdeasList,
        simIdea,
        setSimIdea
    }

    return (
        <SimIdeasContext.Provider value={simIdeasStateProviderValue} >
            {props.children}
        </SimIdeasContext.Provider>
    );
}
export default SimIdeasContextProvider;