import React, { useContext, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import DragAndDropService from "../../../../services/DragAndDropService";
import { IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { EditTrackContext } from "../../../../context/EditTrackContext";

const ResponseOptionList = (props) => {

    const { editTrackState } = useContext(EditTrackContext);
    const index = props.listIndex;
    const val = props.val;

    //drag and drop
    const ref = useRef(null);

    const [{ isDragging }, drag] = useDrag({
        type: "option",
        item: { listIndex: index, isShuffle: false, prevIndex: "" },
        collect: (monitor) => {
            return DragAndDropService().collectInfo(monitor);
        },
        end(item, monitor) {
            DragAndDropService().dragEnd(item, monitor, props.setOptions, editTrackState.options);
        }
    });

    const [, drop] = useDrop({
        accept: "option",
        hover(item, monitor) {
            DragAndDropService().hoverEffect(item, props, ref, monitor, props.setOptions);
        },
    });

    drag(drop(ref));

    const classes = isDragging ? "ifDrag" : "ifNotDrag";

    return (
        <p className={`listItem cursor-move ${classes}`} ref={ref}>
            <span className="pr-4">
                <strong>{val.optionOrder}.</strong>
            </span>
            <span>
                <strong>Response:</strong> {val.response}
            </span>
            <span>
                <strong>Info:</strong> {val.info}
            </span>
            <span>
                <strong>Feedback:</strong> {val.feedback}
            </span>
            <IconButton
                onClick={() =>
                    props.editResponse(index, val.response, val.feedback, val.info)
                }
            >
                <EditIcon fontSize="small" />
            </IconButton>
            <IconButton onClick={() => props.handleConfirmation(index)}>
                <DeleteIcon fontSize="small" />
            </IconButton>
        </p>
    );
}

export default ResponseOptionList;