import React, { useContext, useState, memo } from "react";
import { LibraryContext } from "../context/LibraryContext";
import { SimFoldersContext } from "../context/SimFoldersContext";
import SimulationLibraryRow from "./SimulationLibraryRow";
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import FolderIcon from '@material-ui/icons/Folder';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmationDilaog from "./generic/ConfirmationDilaog";
import _ from "lodash";
import { compareObjetcs } from "../utils/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(3),
  },
  divider: {
    borderBottom: '1px black solid !important'
  }
}));

const SimFolderRow = (props) => {
  console.debug("Rendring Check: SimFolderRow");
  const { getFullSim } = useContext(LibraryContext);
  const { simFolders, setSimFolders, deleteFolder, setFolderEdit } = useContext(SimFoldersContext);
  const classes = useStyles();
  const collapseSettings = [];
  const [currentFolderId, setCurrentFolderId] = useState();
  const [openCnfDia, setOpenCnfDia] = useState(false);
  const [msg, setMsg] = useState("");

  const handleClick = (id) => {
    simFolders.forEach((folder) => {
      folder.id === id ? collapseSettings.push({ ...folder, open: !folder.open }) : collapseSettings.push(folder);
    });
    setSimFolders(collapseSettings);
  };

  const handleConfirmation = (id, folderName) => {
    setCurrentFolderId(id);
    setMsg("<h4>Are you sure you want to delete <span class='text-secondary'>" + folderName + "</span> folder?");
    setOpenCnfDia(true);
  };

  const handleDelete = () => {
    deleteFolder(currentFolderId);
    handleClose();
  };

  const handleClose = () => {
    setOpenCnfDia(false);
  }

  const handleUpdate = (id) => {
    setFolderEdit(id);
    props.setIsFolderEdit(true);
    props.setOpenFolderDialog(true);
  };

  return (
    <>
      {simFolders.length > 0 &&
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <h3 className="color-blue">Sim Folders</h3>
          }
          className={classes.root}
        >
          {
            simFolders.map((folder) =>
              <div key={`${_.uniqueId("folder-row-")}`}>
                <ListItem className={classes.divider} key={folder.id} disableGutters={true} divider={true}>
                  <ListItemIcon className="folder-row" onClick={() => handleClick(folder.id)}>
                    {folder.open ? <FolderOpenIcon /> : <FolderIcon />}
                  </ListItemIcon>
                  <Tooltip title={folder.open ? "Click To Unexpand" : "Click To Expand"} placement="top">
                    <ListItemText primary={folder.name} className="folder-row" onClick={() => handleClick(folder.id)} />
                  </Tooltip>
                  <Tooltip title="Edit Name" placement="top">
                    <IconButton onClick={() => handleUpdate(folder.id)}>
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete Folder" placement="top">
                    <IconButton onClick={() => handleConfirmation(folder.id, folder.name)}>
                      <DeleteIcon fontSize="small" color="secondary" />
                    </IconButton>
                  </Tooltip>
                </ListItem>
                <Collapse in={folder.open} timeout="auto">
                  <List component="div" disablePadding>
                    {
                      folder.simFolders !== undefined &&
                      folder.simFolders.map((folderSim) =>
                        <ListItem className={classes.nested} key={folderSim.simId}>
                          {
                            <SimulationLibraryRow
                              key={folderSim.simId}
                              folderId={folder.id}
                              folderName={folder.name}
                              {...getFullSim(folderSim.simId)}
                              handleOpenCopyDialog={props.handleOpenCopyDialog}
                              handleOpenDeleteDialog={props.handleOpenDeleteDialog}
                              handleActionMenuClick={props.handleActionMenuClick}
                              setOpenAlert={props.setOpenAlert}
                              isMoveToFolder={false}
                              isRemoveToFolder={true} />
                          }
                        </ListItem>
                      )
                    }
                  </List>
                </Collapse>
              </div>
            )
          }
        </List>
      }
      {openCnfDia && <ConfirmationDilaog msg={msg} handleDelete={handleDelete} handleClose={handleClose} />}
    </>
  );
}

export default memo(SimFolderRow, compareObjetcs);