import React from "react";

import { IconButton, Tooltip } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import LinkIcon from "@material-ui/icons/InsertLink";
import { copyTextToClipBoard } from "../../../utils/utils";
import { TEXT_DELIMITER } from "../../../Config";

const MediaAsset = (props) => {
  const handleSelect = () => {
    props.handleSelect(props.val.id);
  };

  const handleDelete = () => {
    props.handleDelete(props.val);
  };

  const createPdfLink = () => {
    props.setOpenAlert(copyTextToClipBoard(props.val.name + TEXT_DELIMITER + props.val.objectKey.split("/")[1], "pdfDocUrl"));
  }

  return (
    <div className="media_row">
      <div className="media_row_header">
        <span className="media_row_header_title">{props.val.name}</span>
        {props.type === "pdf" &&
          <Tooltip title="Create Link" placement="top">
            <IconButton onClick={createPdfLink} aria-label="Create Link">
              <LinkIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        }
        <Tooltip title="Edit" placement="top">
          <IconButton onClick={handleSelect}>
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete" placement="top">
          <IconButton onClick={handleDelete}>
            <DeleteForeverIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </div>
      <div className="media_row_desc">{props.val.description}</div>
    </div>
  );
};

export default MediaAsset;