import React, { useEffect, useState } from "react";
import { Roles } from "../utils/userUtil";

const ProtectedRoutes = (props) => {
  const [role, setRole] = useState(null);

  useEffect(() => {
    if (props.uContext.user) {
      let user = props.uContext.user;
      setRole(user.roleByRole.roleName);
    }
  }, [props.uContext.user]);

  //Prevent users with simUser claims from accessing protected resources (Library, SimBuilder, Dashboard, etc)
  return role && role === Roles.Admin ? (
    <>{props.children}</>
  ) : (
    <div>Access Denied</div>
  );
};

export default ProtectedRoutes;
