import { SIM_BUILDER_API_END_POINT } from "../Config";
import RequestService, { HttpMethods } from "../utils/requestService";

const S3Service = () => {
    return Object.freeze({
        getSigendUrlForView: async (objectKey, contentType, token, bucketName = null) => {
            const options = RequestService.makeRequest(HttpMethods.POST, token);
            options['body'] = JSON.stringify({ objectKey, contentType, bucketName });

            const result = await fetch(SIM_BUILDER_API_END_POINT + '/awsS3/getSignedUrlForView', options);
            const response = await result.json();

            return response;
        }
    });
};

export default S3Service;