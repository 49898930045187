import { SIM_BUILDER_API_END_POINT } from "../Config";
import RequestService, { HttpMethods } from "../utils/requestService";

const UserService = () => {
    return Object.freeze({
        userLogin: async (userInfo) => {
            const options = RequestService.makeRequest(HttpMethods.POST, false);
            options['body'] = JSON.stringify(userInfo);

            const result = await fetch(SIM_BUILDER_API_END_POINT + '/authenticate', options);
            const response = await result.json();

            return response;
        },
        getAllUsers: async (token) => {
            const options = RequestService.makeRequest(HttpMethods.GET, token);

            const result = await fetch(SIM_BUILDER_API_END_POINT + '/users', options);
            const response = await result.json();

            return response;
        },
        saveUser: async (userDto, token) => {
            const options = RequestService.makeRequest(HttpMethods.POST, token);
            options['body'] = JSON.stringify(userDto);

            const result = await fetch(SIM_BUILDER_API_END_POINT + '/users', options);
            const response = await result.json();

            return response;
        },
        verifyPublicUser: async () => {
            const options = RequestService.makeRequest(HttpMethods.GET);

            const result = await fetch(SIM_BUILDER_API_END_POINT + '/users/verifyPublicUser', options);
            const response = await result.json();

            return response;
        },
        verifyJwtSimToken: async (JwtRequest) => {
            const options = RequestService.makeRequest(HttpMethods.POST);
            options['body'] = JSON.stringify(JwtRequest);

            const result = await fetch(SIM_BUILDER_API_END_POINT + '/users/verifyJwtSimToken', options);
            const response = await result.json();

            return response;
        }

    });
}

export default UserService;