import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { openLinkNewTab } from "../../utils/utils";

const CkEditorReadOnly = (props) => {
    return <CKEditor
        data={props.data}
        editor={Editor}
        config={{}}
        onReady={(editor) => {
            editor.on('change:isReadOnly', (evt, propertyName, isReadOnly) => {
                const editorUiView = editor.ui.view;
                const toolbarContainer = editorUiView.stickyPanel;

                if (isReadOnly) {
                    //remove the toolbar of ckeditor
                    editorUiView.top.remove(toolbarContainer);

                    //remove the tooltip elements
                    const ckEditorWrapperForTooltip = document.getElementsByClassName("ck-body-wrapper")[0];

                    ckEditorWrapperForTooltip.remove();

                    //open the link on a new tab
                    const handleImageLinkClick = (event) => {
                        const targetElement = event.target;

                        openLinkNewTab(event, targetElement);

                        // Check if the clicked element is an image inside a link
                        if (targetElement.tagName === 'IMG') {
                            let linkElement = targetElement.parentElement;
                            linkElement = linkElement.tagName === 'A' ? linkElement : linkElement.parentElement;
                            openLinkNewTab(event, linkElement);
                        }
                    };

                    // Attach the click event listener to the CKEditor content area
                    const editorContainer = editorUiView.editable.element;
                    editorContainer.addEventListener('click', handleImageLinkClick);
                }
            })

            //enable the readOnly mode
            editor.enableReadOnlyMode('readOnly')
        }}
    />
}
export default CkEditorReadOnly;