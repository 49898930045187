import React, { useState, useRef, useContext } from "react";
import { IconButton, Button, TextField } from "@material-ui/core";
import { AddCircle, Save } from "@material-ui/icons";
import { SimIdeasContext } from "../../context/SimIdeasContext";
import Loader from "../loader";
import SimpleReactValidator from 'simple-react-validator';
import SimWizardRow from "../public/SimWizardRow";
import { useHistory } from "react-router-dom";
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import SimIdeaService from "../../services/SimIdeaService";
import { UserContext } from "../../context/UserContext";

const EditSimIdea =() => {
    const history = useHistory();
    const { token } = useContext(UserContext);
    const { simIdea, setSimIdea, setSimIdeasList } = useContext(SimIdeasContext);
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState(simIdea.tracks);
    const [, forceUpdate] = useState(0);
    const simpleValidator = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } }));
    const [showError, setShowError] = useState(false);
    const [isValidEail, setIsValidEmail] = useState(true);
    const [totalTracks] = useState(simIdea.tracks.length);

    const handleChange = (name, value, idx) => {
        const updatedRows = rows;
        const track = rows[idx];
        updatedRows[idx] = { ...track, [name]: value };
        setRows(updatedRows);
    };

    const handlePersonalInfoChange = (e) => {
        const name = e.target.name;
        if (name === 'clientEmail') {
            setSimIdea({ ...simIdea, [name]: e.target.value });
            validateEmail(e.target.value);
        } else {
            setSimIdea({ ...simIdea, [name]: e.target.value });
        }
    };

    const addAnotherRow = () => {
        let updatedRows = rows;
        let idx = rows[updatedRows.length - 1].idx + 1;
        if (idx > totalTracks) {
            let closeBtn = document.getElementById(rows[updatedRows.length - 1].idx);
            closeBtn.style.display = "none";
        }
        updatedRows[updatedRows.length] = {
            idx: rows[updatedRows.length - 1].idx + 1,
            id: Math.random(),
            name: "",
            comments: "",
            type: "text",
            isAdminAdded: true
        };
        setRows([...updatedRows]);
    };

    const deleteRow = (idx) => {
        if (idx > totalTracks) {
            let closeBtn = document.getElementById(idx - 1);
            closeBtn.style.display = "inline-flex";
        }
        let newRow = rows;
        const index = newRow.findIndex(a => a.idx === idx);
        if (index === -1) return;
        newRow.splice(index, 1);
        setRows([...newRow]);
    };

    const save = () => {
        if (simpleValidator.current.allValid()) {
            setSimIdea({ ...simIdea, tracks: rows });
            setLoading(true);
            SimIdeaService().updateSimIdea(token, { ...simIdea, tracks: rows })
                .then((response) => {
                    if (response.status === 200) {
                        setSimIdeasList(response.data);
                        setLoading(false);
                        history.push('/simIdeas');
                    } else {
                        console.error("Error: while saving sim idea!");
                    }
                }).catch((e) => {
                    console.log('Error: could not update idea! ', e.message);
                });
        } else {
            simpleValidator.current.showMessages();
            setShowError(true);
        }
    };

    const validateEmail = (email) => {
        let reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w\w+)+$/;
        if (reg.test(email)) {
            setIsValidEmail(true);
        } else {
            setIsValidEmail(false);
        }
    }

    const formLabelsTheme = createTheme({
        overrides: {
            MuiFormLabel: {
                asterisk: {
                    color: '#db3131',
                    '&$error': {
                        color: '#db3131'
                    },
                }
            }
        }
    });

    return (
        <div className="card">
            <h3 className="card-header h4">Edit Simulation Idea</h3>
            <div className="card-body">
                {loading &&
                    <div className="overlay">
                        <Loader />
                    </div>
                }
                <div className="row">
                    <div className="col-8 offset-2 text-center">
                        <h3 className="text-left">General Info</h3>
                        <MuiThemeProvider theme={formLabelsTheme}>
                            <form>
                                <TextField
                                    required
                                    error={showError && simIdea.clientName === '' && true}
                                    variant="outlined"
                                    id="clientName"
                                    name="clientName"
                                    label="Client Name"
                                    value={simIdea.clientName}
                                    onChange={handlePersonalInfoChange}
                                    size={"small"}
                                    style={{ marginBottom: 15, fontSize: "12%" }}
                                    fullWidth={true}
                                    inputProps={{ maxLength: 200 }}
                                    helperText={simpleValidator.current.message('Client Name', simIdea.clientName, 'required')}
                                />
                                <TextField
                                    required
                                    error={showError && !isValidEail && true}
                                    variant="outlined"
                                    name="clientEmail"
                                    label="Client Email"
                                    value={simIdea.clientEmail}
                                    onChange={handlePersonalInfoChange}
                                    size={"small"}
                                    style={{ marginBottom: 15, fontSize: "12%" }}
                                    fullWidth={true}
                                    inputProps={{ maxLength: 250 }}
                                    helperText={simpleValidator.current.message('Client Email', simIdea.clientEmail, 'required|email')}
                                />
                                <TextField
                                    required
                                    error={showError && simIdea.title === '' && true}
                                    variant="outlined"
                                    id="title"
                                    name="title"
                                    label="Sim Title"
                                    value={simIdea.title}
                                    onChange={handlePersonalInfoChange}
                                    size={"small"}
                                    style={{ marginBottom: 15, fontSize: "12%" }}
                                    fullWidth={true}
                                    inputProps={{ maxLength: 250 }}
                                    helperText={simpleValidator.current.message('Sim Title', simIdea.title, 'required')}
                                />
                                <TextField
                                    required
                                    error={showError && simIdea.desc === '' && true}
                                    variant="outlined"
                                    id="desc"
                                    name="desc"
                                    label="Sim Description"
                                    size={"small"}
                                    value={simIdea.desc}
                                    onChange={handlePersonalInfoChange}
                                    minRows={3}
                                    maxRows={5}
                                    multiline={true}
                                    fullWidth={true}
                                    inputProps={{ maxLength: 5000 }}
                                    helperText={simpleValidator.current.message('Sim Description', simIdea.desc, 'required')}
                                />
                                {rows.map((r) => (
                                    <SimWizardRow
                                        key={r.id} {...r}
                                        handleChange={handleChange}
                                        handleDelete={deleteRow}
                                        simpleValidator={simpleValidator}
                                        showError={showError}
                                        totalTracks={totalTracks}
                                    />
                                ))}
                                <h3>
                                    Add Another Track{" "}
                                    <IconButton onClick={() => addAnotherRow()}>
                                        <AddCircle color="primary" fontSize="large" />
                                    </IconButton>
                                </h3>
                                <h3>
                                    <Button onClick={() => save()} color="primary" variant="contained">
                                        Submit Simulation Idea
                                        <Save fontSize="large" />
                                    </Button>
                                </h3>
                            </form>
                        </MuiThemeProvider>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default  EditSimIdea;