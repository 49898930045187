import React, { useContext } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { LibraryContext } from "../../../context/LibraryContext";
import Loader from "../../loader";

const DeleteSimDialog = (props) => {
    const libraryContext = useContext(LibraryContext);

    const handleClose = () => {
        props.handleCloseDeleteDialog();
    }

    const handelSimDeletion = () => {
        libraryContext.deleteSim(props.currentSimId);
        handleClose();
    }

    return (
        <div>
            {libraryContext.isLoading &&
                <div className="overlay">
                    <Loader />
                </div>
            }
            <Dialog
                open={true}
                fullWidth
                maxWidth="sm"
                onClose={handleClose}
                aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title"><strong>Warning!</strong></DialogTitle>
                <DialogContent>
                    <h4>Are you sure you want to delete <span className="MuiButton-textSecondary">{props.currentSimTitle}</span>?</h4>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        No
                    </Button>
                    <Button onClick={handelSimDeletion} color="secondary">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default DeleteSimDialog;