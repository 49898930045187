import { Button } from "@material-ui/core";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const LinkButton = ({ title, onClick }) => {

    return (
        <>
            <Button
                style={{ color: 'blue', width: 200, margin:0 }}
                size="small"
                onClick={onClick}
                startIcon={<ArrowBackIosIcon />}
            >
                {title}
            </Button>
        </>
    );

}

export default LinkButton;