import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import React, { useState } from "react";

const QuestionAnswer = (props) => {
  const [showInfo, setShowInfo] = useState(false);

  const optionCssClass = props.option.unAvail ? `un-available-options questionLabel` : `questionLabel`;

  return (
    <>
      <div className="questionDiv">
        <div className="info">
          {props.option.info ? (
            <InfoOutlinedIcon
              size="x-small"
              onClick={() => setShowInfo(!showInfo)}
            />
          ) : (
            <div className="spacer" />
          )}
        </div>
        <div className="questionInputDiv">
          <input
            type={props.responseType}
            id={props.option.id}
            name="answer"
            value={props.option.response}
            feedback={props.option.feedback}
            onClick={() => props.setPicked(true)}
            disabled={props.option.unAvail}
          />
          <label
            className={optionCssClass}
            htmlFor={props.option.id}
          >
            {props.option.response}
          </label>
        </div>
      </div>
      {showInfo && (
        <div className="questionInfoDiv">
          {props.option.info
            ? props.option.info
            : "No additional information is available"}
        </div>
      )}
    </>
  );
};


export default QuestionAnswer;