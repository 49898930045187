import React, { useContext, useRef, useState } from "react";
import { EditTrackContext } from "../../../context/EditTrackContext";
import { IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { EditSimContext } from "../../../context/EditSimContext";
import { isNullOrEmpty, compareObjetcs } from "../../../utils/utils";
import Loader from "../../../components/loader";
import Alert from '../../generic/Alerts';
import { useDrag, useDrop } from "react-dnd";
import DragAndDropService from "../../../services/DragAndDropService";
import CopyIcon from "@material-ui/icons/FileCopy";
import AlertDialog from "../../generic/CustomizeAlertDilaog";
import { Warning } from "@material-ui/icons";
import Tooltip from '@material-ui/core/Tooltip';

const TrackListItem = (props) => {
    const { editTrackState, setEditTrackState, prevTrackState, setPrevTrackState, setShowValidationError } = useContext(EditTrackContext);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [openConfirmTrackChanges, setOpenConfirmTrackChanges] = useState(false);
    const [loading, setLoading] = useState(false);
    const { removeTrack, updateTrack, editSimState } = useContext(EditSimContext);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertProps, setAlertProps] = useState({ msg: "", type: "" });

    const itemSelected = () => {
        return !isNullOrEmpty(editTrackState) && props.track.id === editTrackState.id;
    };

    const handleSelection = () => {
        if (!itemSelected()) {
            props.simpleValidator.current.hideMessages();
            if (!isNullOrEmpty(prevTrackState) && !isNullOrEmpty(editTrackState)
                && prevTrackState.id === editTrackState.id && !compareObjetcs(prevTrackState, editTrackState)) {
                setOpenConfirmTrackChanges(true);
            } else {
                setPrevTrackState({ ...props.track });
                setEditTrackState({ ...props.track });
            }
        }
        setShowValidationError(false);
    };

    const cssRules = () => {
        let css = "track_list_item";
        if (props.track.parent) {
            css += " indent";
        }
        if (itemSelected()) {
            css += " selected";
        }
        return css;
    };

    const handleClose = () => {
        setOpenConfirmDelete(false);
    }

    const savePrevTrackStateClose = async () => {
        prevTrackState.options = prevTrackState.options.map((option, index) => {
            return { ...option, optionOrder: index + 1 };
        })
        setOpenConfirmTrackChanges(false);
        await updateTrack(prevTrackState, (status) => {
            if (status) {
                setEditTrackState(props.track);
                setPrevTrackState(props.track);
            } else {
                setAlertProps({ msg: "Error: while saving track details!", type: "error" })
                setOpenAlert(true);
            }
        });
    }

    const confirmDelete = async (trackId) => {
        props.setTrackSelection(prevState => {
            prevState.delete(trackId)
            return new Set([...prevState]);
        });
        await removeTrack(props.track, () => {
            handleClose();
        });
    }

    const savePrevTrackState = async () => {
        setOpenConfirmTrackChanges(false);
        if (props.simpleValidator.current.allValid()) {
            setLoading(true);
            await updateTrack(editTrackState, (status) => {
                if (status) {
                    setEditTrackState(props.track);
                    setPrevTrackState(props.track);
                    setShowValidationError(false);
                    setLoading(false);
                } else {
                    setAlertProps({ msg: "Error: while saving track details!", type: "error" })
                    setOpenAlert(true);
                    setLoading(false);
                }
            });
        } else {
            setShowValidationError(true);
            props.simpleValidator.current.showMessages();
        }
    }

    const handleDelete = () => {
        setEditTrackState(null);
        setOpenConfirmDelete(true);
    };

    const ref = useRef(null);

    const [, drop] = useDrop({
        accept: "list",
        hover(item, monitor) {
            DragAndDropService().hoverEffect(item, props, ref, monitor, props.setTracks);
        },
    });


    const [{ isDragging }, drag] = useDrag({
        type: "list",
        item: { listIndex: props.listIndex, isShuffle: false, prevIndex: "" },
        collect: (monitor) => {
            return DragAndDropService().collectInfo(monitor);
        },
        end(item, monitor) {
            DragAndDropService().dragEnd(item, monitor, props.setTracks, editSimState.tracks);
        }
    });

    drag(drop(ref));

    const classes = isDragging ? "ifDrag" : "ifNotDrag";

    const ellipsis = props.isExpand ? "inline" : "ellipsis"

    return (
        <>
            <div className={`${cssRules()} ${classes}`} ref={ref}>
                <input type="checkbox" onClick={props.handleTrackSelected} value={props.track.trackId} checked={props.trackSelected.has(props.track.trackId)}/>
                <div
                    className={"track_list_item_title"}
                    title={props.track.desc}
                    onClick={handleSelection}
                >
                    <div className={ellipsis}>{props.track.order}. {props.track.title}</div> - [{props.track.type}]
                </div>
                <Tooltip title="Copy Track" >
                    <IconButton onClick={() => { props.copyTrackHandler(props.track); }} style={{ margin: 2 }}>
                        <CopyIcon style={{ fontSize: 20 }} />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete Track">
                    <IconButton onClick={handleDelete} style={{ margin: 2 }}>
                        <DeleteIcon style={{ fontSize: 20 }} />
                    </IconButton>
                </Tooltip>
                {openAlert && <Alert {...alertProps} openAlert={openAlert} setOpenAlert={setOpenAlert} />}
            </div>
            {openConfirmDelete &&
                <AlertDialog
                    msg={`<h4 class="warning-text">This action is irreversible.</h4> <h4>All associated data with this track <span class="warning-text">${props.track.title}</span> will also be deleted. Are you sure you want to proceed?</h4>`}
                    handleDelete={()=>{confirmDelete(props.track.trackId)}}
                    handleClose={handleClose}
                    size="sm"
                    isSticky={true}
                    showDialogActions={true}
                    textAlign="left"
                    icon={<Warning className="warning-icon" />}
                    title="Warning!"
                />
            }
            {openConfirmTrackChanges &&
                <AlertDialog
                    msg={`<h4>Do you want to save your progress for the <span class="text-secondary">${editTrackState.title}</span> track before switching to another track?</h4>`}
                    handleDelete={savePrevTrackState}
                    handleClose={savePrevTrackStateClose}
                    isSticky={true}
                    showDialogActions={true}
                    textAlign="left"
                    size="sm"
                    title="Warning!"
                />
            }
            {loading &&
                <div className="overlay">
                    <Loader />
                </div>
            }
        </>
    );
};


export default TrackListItem;