import React, { useContext, useEffect, useState } from "react";
import TrackListItem from "./SimBuilderTrackListItem";
import { EditSimContext } from "../../../context/EditSimContext";
import { useDrop } from "react-dnd";
import ConfirmationDilaog from "../../generic/ConfirmationDilaog";
import DragAndDropService from "../../../services/DragAndDropService";
import { EditTrackContext } from "../../../context/EditTrackContext";
import FunctionService from "../../../services/FunctionService";
import TrackService from "../../../services/TrackService";
import { UserContext } from "../../../context/UserContext";
import Loader from "../../loader";
import Alert from "../../generic/Alerts";
import CopyDialog from "../../generic/CopyDialog";
import { isNullOrEmpty } from "../../../utils/utils";

let copyTrackData = null;

const TrackList = (props) => {
  const { token } = useContext(UserContext);
  const { editSimState, updateTrackList, setEditSimState } = useContext(EditSimContext);
  const { setEditTrackState, setPrevTrackState } = useContext(EditTrackContext);
  const tracksList = [...editSimState.tracks];
  const [tracks, setTracks] = useState(tracksList);
  const [alertProps, setAlertProps] = useState({ msg: "", type: "" });
  const [openAlert, setOpenAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openConfirmTrackChanges, setOpenConfirmTrackChanges] = useState(false);
  const [openCopyTrackDialog, setOpenCopyTrackDialog] = useState(false);

  useEffect(()=>{
    if(props.isSelectedAll) {
    props.setTrackSelection(prevState => {
          const allTrackSelected = tracksList.map((track)=>{
            return track.trackId;
          });
          return new Set([...allTrackSelected])
        });
    } else {
      props.setTrackSelection(new Set());
    }
  }, [props.isSelectedAll])

  const handleTrackSelected = (e)=>{
    const trackId = Number(e.target.value);
    if(props.trackSelected.has(trackId)) {
      props.setTrackSelection(prevState => {
        prevState.delete(trackId)
        return new Set([...prevState]);
      });
    } else {
      props.setTrackSelection(prevState => {
        return new Set([...prevState, trackId]);
      });
    }
  }

  const confirmTrackChangesHandler = (setOpenConfirmTrackChanges) => {
    setOpenConfirmTrackChanges(prevState => !prevState)
  };

  const closeCopyTrackDialog = () => {
    confirmTrackChangesHandler(setOpenCopyTrackDialog);
  }

  const copyTrackHandler = (trackData) => {
    setEditTrackState(null);
    setPrevTrackState(null);
    confirmTrackChangesHandler(setOpenCopyTrackDialog);
    copyTrackData = { ...trackData };
  }

  const saveCopyTrack = (inputTitle) => {
    const title = inputTitle.trim();
    setLoading(true);
    copyTrackData.checkPriorOptions = false;
    copyTrackData.checkUnavailableOptions = false;
    copyTrackData.flashSettingId = null;
    copyTrackData.flashTrack = false;
    copyTrackData.flashTrackId = "";
    copyTrackData.optionVisibilitySettings = [];
    copyTrackData.responseEffects = [];
    copyTrackData.responseLoops = [];
    copyTrackData.visibilitySettings = [];

    if (copyTrackData.scoreCardVars) {
      copyTrackData.scoreCardVars = [];
    }

    copyTrackData.id = "";
    copyTrackData.trackId = "";

    copyTrackData.options = copyTrackData.options.map(option => {
      return { ...option, id: "", trackId: "" };
    });

    copyTrackData.order = editSimState.tracks.length + 1;
    copyTrackData.title = title;

    TrackService()
      .addTrack(copyTrackData, token)
      .then((response) => {
        if (response.status === 200) {
          setEditSimState(prevState => {
            return { ...prevState, tracks: prevState.tracks.concat(response.data) }
          });
          setLoading(false);
        } else {
          setLoading(false);
          setAlertProps({ msg: "Error: while copying track!", type: "error" });
          setOpenAlert(true);
          console.error("Error: while copying track!", response.message);
        }
      })
      .catch((e) => {
        setLoading(false);
        setAlertProps({ msg: e.message, type: "error" });
        setOpenAlert(true);
        console.error(e.message);
      });
  }


  useEffect(() => {
    setTracks([...editSimState.tracks]);
  }, [editSimState]);


  const [, drop] = useDrop({
    accept: "list",
    drop(item, monitor) {
      if (item.listIndex === item.prevIndex || item.prevIndex === "") {
        return;
      }
      confirmTrackChangesHandler(setOpenConfirmTrackChanges);
    },
  });

  //computer the nav list only when nav list changes
  const getDisplayList = () => {
    return tracks.map((track, index) => (
      <TrackListItem
        key={track.id}
        track={track}
        simpleValidator={props.simpleValidator}
        handleTrackSelected={handleTrackSelected}
        trackSelected={props.trackSelected}
        setTrackSelection={props.setTrackSelection}
        listIndex={index}
        setTracks={setTracks}
        copyTrackHandler={copyTrackHandler}
        isExpand={props.isExpand}
      />
    ));
  };

  return (
    <>
      <div className="tracklist" ref={drop}>{getDisplayList()}</div>
      {openCopyTrackDialog &&
        <CopyDialog
          dialogTitle="Copy Track"
          id="copyTrack"
          name="copyTrack"
          lable="Track Title"
          type="text"
          helperText="Track title is required!"
          cancel="Cancel"
          save="Save"
          handleCloseCopyDialog={closeCopyTrackDialog}
          action={saveCopyTrack}
          check={(title) => !isNullOrEmpty(title.trim())}
          length={250}
        />
      }
      {openConfirmTrackChanges &&
        <ConfirmationDilaog
          title="Confirmation!"
          msg={`<h4>Are you sure you want to save the new order of the tracks?</h4>`}
          handleDelete={() => DragAndDropService().drop(() => FunctionService().trackOrderChange(setTracks, updateTrackList, editSimState, setEditTrackState, setPrevTrackState), confirmTrackChangesHandler, setOpenConfirmTrackChanges)}
          handleClose={() => DragAndDropService().notDrop(setTracks, tracksList, confirmTrackChangesHandler, setOpenConfirmTrackChanges)}
          isSticky={true}
        />
      }
      {loading &&
        <div className="overlay">
          <Loader />
        </div>
      }
      {openAlert && <Alert {...alertProps} openAlert={openAlert} setOpenAlert={setOpenAlert} />}
    </>
  );
};

export default TrackList;