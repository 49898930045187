import React from "react";
import { Icon, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  imageIcon: {
    display: "flex",

  },
  iconRoot: {
    textAlign: "center",
    fontSize: '36px !important'
  },
});

function IconsSVG(props) {
  const classes = useStyles();

  const classWithWidth = props.width ? `${classes.imageIcon} play-button-width` : classes.imageIcon

  return (
    <Tooltip title={props.title} className="content-body">
      <Icon classes={{ root: classes.iconRoot }}>
        <img src={props.src} className={classWithWidth} alt={props.title} />
      </Icon>
    </Tooltip>
  );
}

export default IconsSVG;
