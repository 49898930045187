import React, { useEffect, useMemo, useState } from "react";
import { Tooltip, withStyles } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import TimeSpend from "./TimeSpend";

const CustomToolTip = withStyles(() => ({
    tooltip: {
        minWidth: '450px',
        position: 'absolute',
        top: '-50px',
        left: "18px",
        fontSize: '12px',

    },
}))(Tooltip);


const SimulationProgress = ({ playerState, getTimeFromSimulationProgress }) => {
    const [simulationProgress, setSimulationProgress] = useState(0);

    const [timer, setTimer] = useState();

    //calculate progress of the simulation
    useEffect(() => {
        const totalCharacters = playerState.showCharacterIntro ? playerState.characters.length : 0;
        const totalTracks = playerState.tracks.length;
        const totalSimulationStep = totalCharacters + totalTracks;
        let viewedCharacters = 0;
        let viewedTracks = 0;
        if (playerState.showCharacterIntro) {
            viewedCharacters = playerState.characterIdx + 1 > totalCharacters ? playerState.characterIdx
                : playerState.characterIdx + 1;
        }
        if (playerState.trackIdx > -1) {
            viewedTracks = playerState.trackIdx + 1 > totalTracks ? playerState.trackIdx
                : playerState.trackIdx + 1;
        }
        const totalSimulationStepTaken = viewedCharacters + viewedTracks;
        const simProgress = (totalSimulationStepTaken / totalSimulationStep) * 100;
        setSimulationProgress(simProgress);
    }, [playerState]);

    const fill = useMemo(() => {
        return Math.floor(isNaN(simulationProgress) ? 100 : simulationProgress) + "%";
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [simulationProgress]);

    const getTimeFromTimer = (timer) => {
        setTimer(timer);
    }

    getTimeFromSimulationProgress(timer);

    return (
        <>
            <div className="d-flex justify-content-between timerLabel">
                <div className="ml-1 mb-1">Timer&nbsp;&nbsp;
                    <CustomToolTip
                        title="This timer approximates the presumed number of tracks or time it takes to complete this simulation. The exact time is dependant on which branch is taken as well as time for discussion if the simulation is being played synchronously.">
                        <InfoOutlinedIcon fontSize="inherit" />
                    </CustomToolTip>
                    : -- {fill} Complete
                </div>
                <TimeSpend getTimeFromTimer={getTimeFromTimer} />
            </div>
            <div className="timerOutline">
                <div className="timerFill" style={{ width: fill }}></div>
            </div>
        </>
    );
};

export default SimulationProgress;