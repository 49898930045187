import React, { useContext, useEffect, useState } from "react";
import { FormControl, IconButton, InputLabel, Select, FormHelperText } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import { EditTrackContext } from "../../../../context/EditTrackContext";
import { VariableContext } from "../../../../context/VariableContext";
import { isNullOrEmpty } from "../../../../utils/utils";
import useExpandCollapse from "../../../../hooks/useExpandCollapse";
import { IsExpandAllContext } from "../../../../context/IsExpandAllContext";

const ScoreCardVariableSelector = (props) => {
  const [isExpand, markup, toggleScoreCardVariableSelector] = useExpandCollapse({ vertical: false });
  //Used to get list of variables in the simulation
  const { variableState } = useContext(VariableContext);

  //State of track being edited
  const { editTrackState, setEditTrackState } = useContext(EditTrackContext);

  //Local form selections
  const [defSelectedVar, setDefSelectedVar] = useState("");
  const [selectedVar, setSelectedVar] = useState();
  const [cvErrorFlag, setCvErrorFlag] = useState(false);
  const isExpandALL = useContext(IsExpandAllContext);

  useEffect(() => {
    toggleScoreCardVariableSelector(isExpandALL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpandALL]);

  const handleSelectScoreCardVarChange = (e) => {
    const [value, name] = e.target.value.split(":::");
    setSelectedVar({ value, name });
    setDefSelectedVar(e.target.value);
  };

  const addScoreCardVariable = () => {
    if (!isNullOrEmpty(selectedVar)) {
      const scoreCardVarsList = editTrackState.scoreCardVars
        ? editTrackState.scoreCardVars
        : [];
      setEditTrackState({
        ...editTrackState,
        scoreCardVars: scoreCardVarsList.concat(selectedVar),
      });
      setDefSelectedVar("");
      setSelectedVar("");
      setCvErrorFlag(false);
    } else {
      setCvErrorFlag(true);
    }
  };

  const deleteScoreCardVariable = (index) => {
    props.setCurrentSCV(index);
    let currentSCV = editTrackState.scoreCardVars[index];
    props.setMsg("<h4>Are you sure you want to delete <span class='text-secondary'>" + currentSCV.name + "</span> Scorecard Variable?");
    props.setHandleDelete("deleteSCV");
    props.setOpenCnfDia(true);
  };

  const getScoreCardVarListToDisplay = () => {
    if (editTrackState.scoreCardVars) {
      return editTrackState.scoreCardVars.map((s, i) => {
        if (s !== undefined && s !== null) {
          return (
            <p className="listItem" key={i}>
              <span>{s.name}</span>
              <IconButton onClick={() => deleteScoreCardVariable(i)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </p>
          );
        }
        return "";
      });
    }
  };

  const scoreCardVariableSelectorClass = isExpand ? "expand" : "collapse";

  return (
    <>
      <div className="heading">
        <h3>Variables to Show on Score Card</h3>
        {markup}
      </div>
      <div className={scoreCardVariableSelectorClass}>
        <div className="visibility_setting_row">
          <FormControl
            variant="outlined"
            required
            error={(selectedVar === "" || selectedVar === undefined) && cvErrorFlag && true}
          >
            <InputLabel htmlFor="varList">Sim Variable</InputLabel>
            <Select
              native
              name="scoreCardVarList"
              id="scoreCardVarList"
              onChange={handleSelectScoreCardVarChange}
              value={defSelectedVar}
            >
              <option aria-label="None" value="" />
              {variableState.map((v) => {
                const newVal = v.id + ":::" + v.name;
                return (
                  <option
                    key={v.id}
                    value={newVal}
                    name={v.name}
                    title={v.desc}
                  >
                    {v.name}
                  </option>
                );
              })}
            </Select>
            {(selectedVar === "" || selectedVar === undefined) && cvErrorFlag && <FormHelperText style={{ color: "red" }}>The sim variable option is required.</FormHelperText>}
          </FormControl>
          <IconButton onClick={addScoreCardVariable}>
            <AddCircleIcon />
          </IconButton>
        </div>
        {getScoreCardVarListToDisplay()}
      </div>
    </>
  );
};

export default ScoreCardVariableSelector;