import { Button, TextField } from '@material-ui/core';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import SpeakerIcon from '@material-ui/icons/Speaker';
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CharacterContext } from '../../../context/CharacterContext';
import { EditSimContext } from '../../../context/EditSimContext';
import FileUploader, { FileType } from '../../generic/FileUploader';
import Loader from '../../loader';

const SimBuilderCharacterEdit = () => {
    const { selectedCharacterState, setSelectedCharacterState, saveSelectedCharacter, loading } = useContext(CharacterContext);
    const { editSimState } = useContext(EditSimContext);
    const characterImageRef = useRef();
    const characterAudioRef = useRef();

    let isAudioUploaded = false;
    let isAvatarUploaded = false;

    //Not sure I love this but it works? 
    //used memo to render audio control when url changes, and fire off load event to make sure new file is pulled instead of using cached file
    const audio = useMemo(() => {
        if (selectedCharacterState.audioUrl) {
            setTimeout(() => { document.getElementById('soundClip').load(); }, 100);
            return (
                <audio id="soundClip" name="soundClip" controls preload="none" key={selectedCharacterState.audioUrl}>
                    <source src={selectedCharacterState.audioUrl} type="audio/mpeg" />
                </audio>
            );
        }
    }, [selectedCharacterState.audioUrl]);

    useEffect(() => {
        // If ID is null than it is a new character.
        if (selectedCharacterState.id === null) {
            selectedCharacterState.canAddNew = true;
            selectedCharacterState.id = uuidv4();
        }
    }, [selectedCharacterState])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSelectedCharacterState({ ...selectedCharacterState, [name]: value });
    }

    const handleAvatarFileChange = (file) => {
        setSelectedCharacterState({
            ...selectedCharacterState,
            avatarFile: file,
            avatarUrl: URL.createObjectURL(file),
            avatarChanged: true
        });
    }

    const handleAudioFileChange = (file) => {
        setSelectedCharacterState({
            ...selectedCharacterState,
            audioFile: file,
            audioUrl: URL.createObjectURL(file),
            audioChanged: true
        });
    }

    const handleAvatarUploadFinsing = (data) => {
        isAvatarUploaded = true;
        saveCharacterWithMedia();
    }

    const handleAudioUploadFinsing = (data) => {
        isAudioUploaded = true;
        saveCharacterWithMedia();
    }

    const uploadMediaFiles = () => {
        // If user did not change Avatar or Audio file than save character data.
        if (!selectedCharacterState.audioChanged && !selectedCharacterState.avatarChanged) {
            saveCharacterWithMedia();
            return;
        }

        if (selectedCharacterState.avatarChanged) {
            const avatarPath = editSimState.id + "/characters/" + selectedCharacterState.id + "/";
            characterImageRef.current.startUploading(avatarPath, selectedCharacterState.avatarFile);
        }

        if (selectedCharacterState.audioChanged) {
            const audioPath = editSimState.id + "/characters/" + selectedCharacterState.id + "/";
            characterAudioRef.current.startUploading(audioPath, selectedCharacterState.audioFile);
        }
    }

    function saveCharacterWithMedia() {
        let canUpdateCharacterData = true;

        if (selectedCharacterState.avatarChanged && !isAvatarUploaded) {
            canUpdateCharacterData = false;
        }

        if (selectedCharacterState.audioChanged && !isAudioUploaded) {
            canUpdateCharacterData = false;
        }

        if (canUpdateCharacterData) {
            saveSelectedCharacter();
        }
    }

    return (
        <>
            <div className="actorEditForm">
                <img className="actor_avatar" src={selectedCharacterState.avatarUrl} key={"character-avatar-1"} alt="Character" />
                <div className='character-file-upload'>
                    <FileUploader
                        ref={characterImageRef}
                        title={"Choose File"}
                        icon={<PhotoCameraIcon />}
                        fileType={FileType.IMAGE}
                        onFileSelected={handleAvatarFileChange}
                        onFinish={handleAvatarUploadFinsing}
                    />
                </div>
                Audio:{audio}
                <div className='character-file-upload'>
                    <FileUploader
                        ref={characterAudioRef}
                        title={"Choose File"}
                        icon={<SpeakerIcon />}
                        fileType={FileType.AUDIO}
                        uploadPath={"image/"}
                        onFileSelected={handleAudioFileChange}
                        onFinish={handleAudioUploadFinsing}
                    />
                </div>
                <TextField variant="outlined" name="name" id="name" label="Character's Name" onChange={handleChange} value={selectedCharacterState.name} />
                <TextField variant="outlined" name="title" id="title" label="Character's Title" onChange={handleChange} value={selectedCharacterState.title} />
                <TextField type={"number"} variant="outlined" name="age" id="age" label="Character's Age" onChange={handleChange} value={selectedCharacterState.age} />
                <TextField variant="outlined" name="desc" id="desc" label="Character's Personal Description" multiline minRows={3} maxRows={5} onChange={handleChange} value={selectedCharacterState.desc} />
                <Button
                    variant="contained"
                    color="primary"
                    id="save"
                    name="save"
                    onClick={uploadMediaFiles}>
                    {(selectedCharacterState.id !== null ? "Save Character Details" : "Add Character")}
                </Button>
                {loading &&
                    <div className="overlay">
                        <Loader />
                    </div>
                }
            </div>
        </>
    )
}

export default SimBuilderCharacterEdit;