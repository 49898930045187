import React from 'react';

import { Bar } from 'react-chartjs-2';

const Dashboard = () => {

    const data = {
        labels: ["January", "Febuary", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"],
        datasets: [{
            label: "Sims Created Per Month",
            data: [3, 5, 12, 9, 3, 13, 2, 17, 3, 16, 4, 1]
        }]
    };

    return (
        <>
            <h1 className="color-blue">Dashboard</h1>
            <div className="chart">
                <Bar data={data} options={{}} />
            </div>
        </>
    );
}

export default Dashboard;