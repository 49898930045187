import React, { useEffect, useContext, useState, useRef } from "react";
import { Button, TextField } from "@material-ui/core";
import { PlayerContext } from "../../context/PlayerContext";
import AlertDialog from "../generic/CustomizeAlertDilaog";
import CustomizedNestedList from "../generic/CustomizedNestedList";
import { getMarginTop } from "../../utils/utils";

const FreeTextResponseTrack = (props) => {
  const { setShowPlayButton, showFlashAlert, isPlayingFromTrack } =
    useContext(PlayerContext);
  const [isFocused, setIsFocused] = useState(true);
  const textFieldRef = useRef(null);

  useEffect(() => {
    if ((props.audioURL && !props.debug) && isFocused) {
      if (
        (props.alertMessage === "" && props.flashTrackId === "") ||
        !showFlashAlert
      ) {
        const clip = document.getElementById("soundclip");
        const playPromise = clip.play();
        if (playPromise !== undefined) {
          playPromise
            .then(function () {
              console.debug("Audio play started in FreeTextResponse Track");
              setShowPlayButton(false);
              // Automatic playback started!
            })
            .catch(function (error) {
              console.debug(
                "Audio Play exception in FreeTextResponse Track :",
                error
              );
              setShowPlayButton(true, error);
            });
        }
      }
    }
    setIsFocused(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused, props.play, props.prompt]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let resp = [];
    resp.push(e.target.response.value);
    if (!props.debug) {
      props.handleNextSlideWithAddAnswer(resp, null, null, null);
    } else {
      alert(e.target.response.value);
    }
  };

  const handleGoToFlashTrack = () => {
    props.handleGoToFlashTrack();
  };

  const conditionalRenderingOfCssClass = () => {
    let cssClass = "";
    if (props?.trackIdx) {
      if (props?.isPub) {
        cssClass = "submit-btn-align";
      } else {
        cssClass = "submit-btn-align-unpublish";
      }
    }
    return cssClass;
  };

  const handleFocus = () => {
    setIsFocused(prevState => !prevState);
  };

  return (
    <div>
      {!props.isPub && (
        <h1
          style={{ marginTop: getMarginTop(props.isPub, props.trackIdx) }}
        >
          Track: {props.order} -- {props.title} --{" "}
          {!props.isPub && !props.debug ? props.trackId : props.id}
        </h1>
      )}
      {props.visibilitySettings.length > 0 && isPlayingFromTrack && (
        <CustomizedNestedList
          text="Variables:"
          visibilitySettings={props.visibilitySettings}
        />
      )}
      <h1 style={{ marginTop: getMarginTop(props.isPub, props.trackIdx) }}>
        {props.header}
      </h1>
      <h1
        onFocus={handleFocus}
      >
        {props.prompt}
      </h1>
      <form onSubmit={handleSubmit}>
        <TextField
          multiline
          minRows={3}
          maxRows={9}
          variant="outlined"
          name="response"
          id="response"
          inputRef={textFieldRef}
          inputProps={{
            style: {
              height: "75px",
              resize: "vertical",
              overflow: "auto",
            },
          }}
        />
        <br />
        <br />
        {!props.flashTrack && (
          <Button
            type="submit"
            color="primary"
            variant="outlined"
            className={conditionalRenderingOfCssClass()}
          >
            Submit
          </Button>
        )}
      </form>
      {props.audioURL && (
        <audio
          key={props.file + "_" + props.audioURL}
          id="soundclip"
          controls={props.debug}
          muted={props.isMuted}
        >
          {props.file && <source src={props.file} type="audio/mpeg" />}
          {props.audioURL && (
            <source src={props.pubAudioUrl} type="audio/mpeg" />
          )}
        </audio>
      )}
      {!props.debug &&
        !props.flashTrack &&
        showFlashAlert &&
        props.alertMessage !== "" &&
        props.flashTrackId !== "" && (
          <AlertDialog
            size="lg"
            isSticky={true}
            title={props.alertMessage}
            showGoToFlashTrack={true}
            handleGoToFlashTrack={handleGoToFlashTrack}
          />
        )}
    </div>
  );
};

export default FreeTextResponseTrack;
