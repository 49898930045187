
import React, { useContext, useEffect } from 'react';
import { UsersContext } from "../../context/UsersContext";
import DateUtil from '../../utils/dateUtil';
import Table from '../generic/Table';
import Loader from "../loader";

const Users = () => {
    const { isLoading, users, loadUsers } = useContext(UsersContext);

    const columns = [
        {
            dataField: "id",
            text: "ID",
            sort: true,
            headerClasses: "table_header_color",
            align: "left",
        },
        {
            dataField: "username",
            text: "Email",
            align: "left",
        },
        {
            dataField: "roleByRole",
            text: "Role",
            sort: true,
            headerClasses: "table_header_color",
            align: "left",
            formatter: (cell, record) => {
                return record.roleByRole.roleName;
            }
        },
        {
            dataField: "createdAt",
            text: "Created Date",
            sort: true,
            headerClasses: "table_header_color",
            align: "left",
            formatter: (cell, record) => {
                return DateUtil.getShortDate(record.createdAt);
            }
        }
    ];

    useEffect(() => {
        loadUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users]);

    return (
        <div className="card">
            <h3 className="card-header h4 color-blue">Users</h3>
            <div className="card-body">
                {isLoading &&
                    <div className="overlay">
                        <Loader />
                    </div>
                }
                <Table
                    data={users}
                    columns={columns}
                    remote={false}
                    id="id"
                    select={false}
                />
            </div>
        </div>
    );
}

export default Users;