import React, { useContext, useEffect } from "react";
import { PlayerContext } from "../../context/PlayerContext";

const CharacterList = (props) => {
  const { setShowPlayButton } = useContext(PlayerContext);

  useEffect(() => {
    const intro = document.getElementById("intro");
    let playPromise;
    if (intro !== null) {
      playPromise = intro.play();
    }

    if (playPromise !== undefined) {
      playPromise.then(function () {
        console.debug("Audio play started  in Character List");
        setShowPlayButton(false);
        // Automatic playback started!
      }).catch(function (error) {
        console.debug("Audio Play exception  in Character List:", error);
        setShowPlayButton(true, error);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="row">
      {props.avatarUrl && (
        <div className="col-sm-12 text-center mb-2">
          <img src={props.avatarUrl} width="150" height="150" alt="Character" />
        </div>
      )}
      <div className="col-sm-12 text-center">
        <p>Name: {props.name} {props.age > 0 && `  (${props.age})`}</p>
        <p>Title: {props.title}</p>
        <p>{props.desc}</p>
      </div>
    </div>
  );
};

export default CharacterList;