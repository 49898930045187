import React, { createContext, useState, useMemo } from 'react';

export const SSOContext = createContext();

//Provide a cached list of simulations in the library.
const SSOContextProvider = (props) => {

  const [ssoSimId, setSsoSimId] = useState(null);
  const [linkId, setLinkId] = useState(null);
  const [accountId, setAccountId] = useState(null);
  const [linkAuditId, setLinkAuditId] = useState(null);
  const [portalSimCode, setPortalSimCode] = useState(null);
  const [adContent, setAdContent] = useState(null);
  const [accreditationClient, setAccreditationClient] = useState(false);

  const ssoProviderValue = useMemo(
    () => ({
      ssoSimId,
      setSsoSimId,
      linkId,
      setLinkId,
      linkAuditId,
      setLinkAuditId,
      accountId,
      setAccountId,
      portalSimCode,
      setPortalSimCode,
      adContent,
      setAdContent,
      accreditationClient,
      setAccreditationClient
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ssoSimId, linkId, accountId, linkAuditId, portalSimCode, adContent, accreditationClient]
  );

  return (
    <SSOContext.Provider value={ssoProviderValue} >
      {props.children}
    </SSOContext.Provider>
  );
}

export default SSOContextProvider;