import { SIM_BUILDER_API_END_POINT } from "../Config";
import RequestService, { HttpMethods } from "../utils/requestService";

const HttpService = () => {
    return Object.freeze({
        postRequest: async (payload, endPoint) => {
            const options = RequestService.makeRequest(HttpMethods.POST);
            options['body'] = JSON.stringify(payload);

            const result = await fetch(SIM_BUILDER_API_END_POINT + endPoint, options);
            const response = await result.json();

            return response;
        }
    });
}

export default HttpService;