import React from "react";

const SlideTextDisplay = (props) => {
  const addTargetToLinks = (text) => {
    if (text) {
      let strings = text.split("<a");
      let ret = "";
      for (let i = 0; i < strings.length; i++) {
        ret += strings[i];
        if (i !== strings.length - 1) {
          ret += "<a target='_blank' ";
        }
      }
      return ret;
    }

    return text;
  };

  return (
    <p
      className={props.className}
      dangerouslySetInnerHTML={{ __html: addTargetToLinks(props.html) }}
    />
  );
};

export default SlideTextDisplay;