
import React, { createContext, useState, useMemo, useContext } from "react";
import TrackService from "../services/TrackService";
import { UserContext } from "../context/UserContext";

export const EditSimContext = createContext();

//Provide a cached list of simulations in the library.
const EditSimContextProvider = (props) => {
    const { token } = useContext(UserContext);

    const [editSimState, setEditSimState] = useState({
        //UI State
        enableTabs: false,
        //DB Variables
        title: "",
        desc: "",
        contentPartner: "",
        time: 0,
        published: false,
        archived: false,
        showEmailReview: false,
        showSMSReview: false,
        showPhoneReview: false,
        showCharacterIntro: false,
        createdDate: null,
        editDate: 0,
        actors: [],
        tracks: [],
        vars: [],
        lastUpdated: '',
        hasError: false,
        active: false,
        today: '',
    });

    const resetEditSimContext = () => {
        setEditSimState({
            //UI State
            enableTabs: false,
            //DB Variables
            title: "",
            desc: "",
            contentPartner: "",
            time: 0,
            published: false,
            archived: false,
            showCharacterIntro: false,
            showEmailReview: false,
            showSMSReview: false,
            showPhoneReview: false,
            createdDate: null,
            editDate: 0,
            actors: [],
            tracks: [],
            vars: [],
            lastUpdated: '',
            hasError: false,
            active: false,
            today: '',
        });
    };
    const setSimToEdit = (sim, token = "") => {
        //Get Actors
        //Get Tracks
        TrackService()
            .getTracksBySimId(sim.id, token)
            .then((response) => {
                if (response.status === 200) {
                    let tracksList = response.data;
                    tracksList = tracksList.map((track) => {
                        if (track.options !== null) {
                            track.options = track.options.map((option) => {
                                if (option.response === null) {
                                    return { response: option, feedback: "" };
                                } else {
                                    return option;
                                }
                            });
                        }
                        return track;

                    });
                    setEditSimState({
                        ...editSimState,
                        ...sim,
                        enableTabs: true,
                        tracks: tracksList,
                    });
                } else {
                    console.log(response.message);
                }
            }).catch(error => {
                console.error(error);
            });
    };

    const addTrack = (type, callback) => {
        const order = editSimState.tracks.length + 1;

        let newTrack = {
            order: order,
            type: type,
            title: "New Track",
            header: "",
            simId: editSimState.id,
            parent: null,
            visibility: [],
            body: "",
            question: null,
            options: [],
            checkUnavailableOptions: false,
            prompt: ""
        };

        TrackService()
            .addTrack({ ...newTrack }, token)
            .then((response) => {
                if (response.status === 200) {
                    console.log(response.data);
                    callback({ ...response.data });
                    setEditSimState({
                        ...editSimState,
                        tracks: editSimState.tracks.concat(response.data),
                    });
                } else {
                    callback({ ...newTrack });
                    console.error("Error: while saving track!", response.message);
                }
            })
            .catch((e) => {
                callback({ ...newTrack });
                console.error(e.message);
            });
    };

    const updateTrack = (track, callback) => {
        const trackList = [...editSimState.tracks];
        trackList[track.order - 1] = { ...trackList[track.order - 1], ...track };
        track = { ...trackList[track.order - 1] };

        TrackService()
            .updateTrack({ ...track }, token)
            .then((response) => {
                if (response.status === 200) {
                    trackList[track.order - 1] = { ...response.data };
                    setEditSimState({ ...editSimState, tracks: trackList });
                    callback(true);
                } else {
                    console.error("Error: while updating tarck!", response.message);
                    callback(false);
                }
            })
            .catch((e) => {
                console.error(e.message);
                callback(false);
            });
    };

    /**
     * This method will update the track list in DB
     * @param tracks list of objects
     */
    const updateTrackList = (tracks) => {
        TrackService().updateTrackList(tracks, token)
            .then((response) => {
                if (response.status === 200) {
                    setEditSimState({ ...editSimState, tracks: response.data })
                } else {
                    console.error("Error: while updating track!", response.message);
                }
            })
            .catch((e) => {
                console.error(e.message)
            })
    }

    const removeTrack = (track, callback) => {
        TrackService().deleteTrack(track.id, token)
            .then(response => {
                if (response.status === 200) {
                    setEditSimState({ ...editSimState, tracks: response.data });
                } else {
                    console.error("Error: while deleteing track!");
                }
                callback(true);
            })
            .catch(e => {
                console.error(e.message);
                callback(true);
            });
    };

    /**
     * This method will update the option list in DB
     * @param optionsList list of objects
     */
    const updateOptionsOrder = (optionList) => {
        TrackService().updateOptionsOrder(optionList, token)
            .then((response) => {
                if (response.status !== 200) {
                    console.error("Error: while updating track!", response.message);
                }
            })
            .catch((e) => {
                console.error(e.message);
            })
    }

    const simProviderValue = useMemo(
        () => (
            {
                editSimState,
                setEditSimState,
                setSimToEdit,
                addTrack,
                updateTrack,
                updateTrackList,
                removeTrack,
                resetEditSimContext,
                updateOptionsOrder,
            }
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [editSimState, setEditSimState]
    );

    return (
        <EditSimContext.Provider value={simProviderValue}>
            {props.children}
        </EditSimContext.Provider>
    );
};

export default EditSimContextProvider;
