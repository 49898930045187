import {
  Button,
  Dialog,
  DialogActions,
  Box,
  Typography,
  OutlinedInput,
  Checkbox,
  Radio,
  InputAdornment,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import { useState, useContext } from "react";
import AIImagesNames from "./AIImagesNames";
import { UserContext } from "../../context/UserContext";
import AIService from "../../services/AIService";
import LoaderComponent from "../loader";
import AlertDialog from "./Alerts";
import LoaderWrapper from "./LoaderWrapper";
import { AI_IMAGES_ERROR_MSG } from "../../utils/utils";
import SearchIcon from "@material-ui/icons/Search";
import { AI_TEXT_LENGTH_LIMITATION } from "../../utils/fieldValidation";

const AIImagesLayout = ({
  open,
  handleOpenImageBox,
  value,
  isRadio,
  closeTextPrompt,
  searchedImages,
}) => {
  const [promptValue, setPromptValue] = useState(value);
  const [images, setImages] = useState(searchedImages);
  const [selectedImages, setSelectedImages] = useState([]);
  const [openImagesNamePrompt, setOpenImagesNamePrompt] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [alertProps, setAlertProps] = useState({ msg: "", type: "" });
  const [openAlert, setOpenAlert] = useState(false);

  const { token } = useContext(UserContext);

  const handlePromptValue = (e) => {
    if (e.target.value.trim().length >= AI_TEXT_LENGTH_LIMITATION) {
      e.preventDefault();
    }
    setPromptValue(e.target.value);
  };

  const handleCheckbox = (e, image) => {
    if (e.target.checked) {
      setSelectedImages([...selectedImages, image]);
    } else {
      setSelectedImages(
        selectedImages.filter((selectedImage) => selectedImage !== image)
      );
    }
  };

  const handleRadioChange = (e) => {
    setSelectedImages([e.target.value]);
  };

  const handleCloseAllPopups = () => {
    handleImageNamePrompt();
    handleOpenImageBox();
    closeTextPrompt();
  };

  const handleImageNamePrompt = () => {
    setOpenImagesNamePrompt(!openImagesNamePrompt);
  };

  const handleImageGeneration = async () => {
    try {
      setImages([]);
      setSelectedImages([]);

      const response = await AIService().getAIGeneratedImages(
        { prompt: promptValue.trim(), seed: 0 },
        token
      );

      if (response.status === 200) {
        setImages(response.data);
      } else if (response.status >= 400 && response.status < 500) {
        setAlertProps(AI_IMAGES_ERROR_MSG.alertMessage);
        setOpenAlert(true);
      } else if (response.status >= 500) {
        setAlertProps(AI_IMAGES_ERROR_MSG.timedOutAlert);
        setOpenAlert(true);
      } else {
        setAlertProps(AI_IMAGES_ERROR_MSG.unableAlert);
        setOpenAlert(true);
      }
    } catch (error) {
      setAlertProps(AI_IMAGES_ERROR_MSG.timedOutAlert);
      setOpenAlert(true);
    }
  };

  const handleSearchAgain = async () => {
    setIsLoading(true);
    await handleImageGeneration();
    setIsLoading(false);
  };

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="lg">
        <DialogTitle id="alert-dialog-title">Select Images</DialogTitle>

        <Box className="box">
          <DialogContent>
            <OutlinedInput
              className="outLineInput"
              fullWidth
              value={promptValue}
              onChange={handlePromptValue}
              inputProps={{ maxLength: AI_TEXT_LENGTH_LIMITATION }} // Set maxLength attribute
              endAdornment={
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              }
            />
            {promptValue.trim() !== "" && promptValue.trim().length < 3 && (
              <p className="text-danger m-1">
               {AI_IMAGES_ERROR_MSG.textValidationMessage}
              </p>
            )}
          </DialogContent>

          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              className="buttonStyle"
              onClick={handleSearchAgain}
              disabled={promptValue.trim().length < 3}
            >
              Search Again
            </Button>
          </DialogActions>

          <DialogContent>
            <Typography className="textMargin">
              {images.length > 0
                ? "Select images to save to asset library"
                : "No Images to show"}
            </Typography>
            {images.length > 0 &&
              images.map((image, index) => (
                <div className="imageDivLayout" key={index}>
                  {isRadio ? (
                    <Radio
                      value={image}
                      checked={selectedImages[0] === image}
                      onChange={handleRadioChange}
                      name="radio-buttons"
                    />
                  ) : (
                    <Checkbox onChange={(e) => handleCheckbox(e, image)} />
                  )}
                  <label className="label">
                    <img src={image} alt="images" className="imageStyle" />
                  </label>
                  <p className="text-center">image {index + 1}</p>
                </div>
              ))}
          </DialogContent>

          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              className="buttonStyle"
              onClick={handleImageNamePrompt}
              disabled={selectedImages.length === 0}
            >
              Save Selected Images
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleOpenImageBox}
              className="buttonStyle"
            >
              Cancel
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      {openImagesNamePrompt && alertProps.msg === "" && (
        <AIImagesNames
          open={openImagesNamePrompt}
          selectedImages={selectedImages}
          onCancel={handleImageNamePrompt}
          isRadio={isRadio}
          handleCloseAllPopups={handleCloseAllPopups}
        />
      )}
      {isLoading && (
        <LoaderWrapper>
          <LoaderComponent />
        </LoaderWrapper>
      )}
      {openAlert && (
        <LoaderWrapper>
          <AlertDialog
            {...alertProps}
            openAlert={openAlert}
            setOpenAlert={setOpenAlert}
          />
        </LoaderWrapper>
      )}
    </>
  );
};

export default AIImagesLayout;
