import React, { useState, useContext, useEffect } from "react";
import { TextField, Button, Switch, FormControl, FormControlLabel, InputLabel, Select } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Alert from "../../components/generic/Alerts";
import { UserContext } from "../../context/UserContext";
import { LibraryContext } from "../../context/LibraryContext";
import { EditSimContext } from "../../context/EditSimContext";
import { MediaContext } from "../../context/MediaContext";
import SimService from "../../services/SimService";
import dateUtil from "../../utils/dateUtil";
import { CustomDatePicker } from "../generic/CustomDatePicker";

let now = new Date(), minDate = now.toISOString().substring(0, 10);

const SimBuilderGeneral = () => {
    const history = useHistory();
    const { token } = useContext(UserContext);
    const libContext = useContext(LibraryContext);
    const { editSimState, setEditSimState, setSimToEdit, resetEditSimContext } = useContext(EditSimContext);
    const { images, sounds } = useContext(MediaContext);

    const [localState, setLocalState] = useState({
        title: "",
        desc: "",
        contentPartner: "",
        time: 0,
        published: false,
        archived: false,
        createdDate: null,
        showCharacterIntro: false,
        showEmailReview: false,
        showSMSReview: false,
        showPhoneReview: false,
        editDate: 0,
        lastUpdated: '',
        today: minDate,
        hasError: false,
        active: false,
        modifiedAt: "",
    });
    const [openAlert, setOpenAlert] = useState(false);
    const [alertProps, setAlertProps] = useState({ msg: "", type: "" });

    //SHould be a better way than this is effect hook to force the update...
    useEffect(() => {
        setLocalState({ ...localState, ...editSimState });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editSimState]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditSimState({ ...editSimState, [name]: value });
    };

    const handleDateChange = (lastUpdated) => {
        if (lastUpdated.isValid) {
            setEditSimState({ ...editSimState, lastUpdated: lastUpdated.date, hasError: false });
        } else {
            setEditSimState({ ...editSimState, hasError: true });
        }
    }

    const handleCheckChanged = (e) => {
        const { name, checked } = e.target;
        setEditSimState({ ...editSimState, [name]: checked });
    };

    const handleSelectChange = (event) => {
        const name = event.target.name;
        setEditSimState({
            ...editSimState,
            [name]: event.target.value !== "" ? event.target.value : null,
        });
    };

    const cancel = () => {
        history.goBack();
    };

    const saveNewSimulation = () => {
        if (editSimState.id) {
            SimService()
                .updateSim({ ...editSimState, modifiedAt: '' }, token)
                .then((response) => {
                    if (response.status === 200) {
                        libContext.updateSim({ ...response.data });
                        setSimToEdit({ ...response.data }, token);
                        setAlertProps({ msg: "Sim updated successfully!", type: "success" })
                        setOpenAlert(true);
                    } else {
                        console.log(response.message);
                        setAlertProps({ msg: "Error while saving, Please try again!", type: "error" })
                        setOpenAlert(true);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        } else {
            //Create a new simulation
            SimService()
                .createSim(editSimState, token)
                .then((response) => {
                    if (response.status === 200) {
                        libContext.addSimToLibrary({ ...response.data });
                        setSimToEdit({ ...response.data }, token);
                        setAlertProps({ msg: "Sim created successfully!", type: "success" })
                        setOpenAlert(true);
                    } else {
                        console.log(response.message);
                        setAlertProps({ msg: "Error while saving, Please try again!", type: "error" })
                        setOpenAlert(true);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }
    };

    return (
        <>
            <div className="simBuilderForm">
                <TextField
                    variant="outlined"
                    id="title"
                    name="title"
                    label="Title"
                    value={editSimState.title}
                    onChange={handleChange}
                />
                <TextField
                    variant="outlined"
                    id="desc"
                    name="desc"
                    label="Description"
                    multiline
                    minRows={4}
                    maxRows={4}
                    value={editSimState.desc}
                    onChange={handleChange}
                />
{/* 
                comment beacuse may be in future we use them
                <TextField
                    variant="outlined"
                    type="number"
                    id="time"
                    name="time"
                    label="Estimated Time (In Minutes)"
                    value={editSimState.time}
                    onChange={handleChange}
                /> */}
                <TextField
                    variant="outlined"
                    id="createdDate"
                    name="createdDate"
                    label="Created Date"
                    value={editSimState.createdAt ? dateUtil.getShortDate(editSimState.createdAt) : ''}
                    inputProps={{
                        readOnly: true,
                    }}
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    variant="outlined"
                    id="contentPartner"
                    name="contentPartner"
                    label="Content Partner"
                    inputProps={{
                        maxLength: 500
                    }}
                    multiline
                    minRows={4}
                    maxRows={4}
                    value={editSimState.contentPartner ? editSimState.contentPartner : ''}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                />
                <div className="datePicker">
                    <div className="col-sm-12">
                        <div className="form-control mb-4 fields-font" style={{ padding: 0, paddingTop: 1, paddingLeft: 8 }}>
                            <CustomDatePicker
                                lastUpdated={editSimState.lastUpdated}
                                handleDateChange={(lastUpdated) => handleDateChange(lastUpdated)}
                            />
                            {editSimState.hasError && <div className="text-error">Please enter valid date in MM-DD-YYYY format</div>}
                        </div>
                    </div>
                </div>
                <TextField
                    variant="outlined"
                    id="lastSaveDate"
                    name="lastSaveDate"
                    label="Last Save Date"
                    value={editSimState.modifiedAt ? dateUtil.getShortDate(editSimState.modifiedAt) : ''}
                    onChange={handleChange}
                    inputProps={{
                        readOnly: true,
                    }}
                    InputLabelProps={{ shrink: true }}
                />
                <br />
                <FormControl variant="outlined">
                    <InputLabel htmlFor="backgroundImgUrl" shrink={!!editSimState.backgroundImgUrl}>Sim Background</InputLabel>
                    <Select
                        native
                        label="Sim Background"
                        name="backgroundImgUrl"
                        id="backgroundImgUrl"
                        value={editSimState.backgroundImgUrl !== null ? editSimState.backgroundImgUrl : ""}
                        onChange={handleSelectChange}
                    >
                        <option aria-label="None" value="" />
                        {images.map((media) => {
                            return (
                                <option key={media.id} value={media.id}>
                                    {media.name}
                                </option>
                            );
                        })}
                    </Select>
                </FormControl>
                <br />
                <FormControl variant="outlined">
                    <InputLabel htmlFor="welcomeAudioUrl" shrink={!!editSimState.welcomeAudioUrl}>Welcome Audio</InputLabel>
                    <Select
                        native
                        label="Welcome Audio"
                        name="welcomeAudioUrl"
                        id="welcomeAudioUrl"
                        value={editSimState.welcomeAudioUrl !== null ? editSimState.welcomeAudioUrl : ""}
                        onChange={handleSelectChange}
                    >
                        <option aria-label="None" value="" />
                        {sounds.map((media) => {
                            return (
                                <option key={media.id} value={media.id}>
                                    {media.name}
                                </option>
                            );
                        })}
                    </Select>
                </FormControl>
                <br />
                <div className="centered_form_row">
                    <FormControlLabel
                        control={
                            <Switch
                                id="showCharacterIntro"
                                name="showCharacterIntro"
                                checked={(editSimState.showCharacterIntro !== null && editSimState.showCharacterIntro !== undefined) ? editSimState.showCharacterIntro : false}
                                onChange={handleCheckChanged}
                            />
                        }
                        label="Show Character Preview"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                id="showEmailReview"
                                name="showEmailReview"
                                checked={editSimState.showEmailReview}
                                onChange={handleCheckChanged}
                            />
                        }
                        label="Show Email Review"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                id="showSMSReview"
                                name="showSMSReview"
                                checked={editSimState.showSMSReview}
                                onChange={handleCheckChanged}
                            />
                        }
                        label="Show SMS Review"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                id="showPhoneReview"
                                name="showPhoneReview"
                                checked={editSimState.showPhoneReview}
                                onChange={handleCheckChanged}
                            />
                        }
                        label="Show Phonecall Review"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                id="published"
                                name="published"
                                checked={editSimState.published}
                                onChange={handleCheckChanged}
                            />
                        }
                        label="Is Published"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                id="archived"
                                name="archived"
                                checked={editSimState.archived}
                                onChange={handleCheckChanged}
                            />
                        }
                        label="Is Archived"
                    />
                </div>
                <div className="centered_form_row right">
                    <Button
                        variant="contained"
                        color="secondary"
                        name="cancel"
                        onClick={cancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        name="save"
                        onClick={saveNewSimulation}
                        disabled={editSimState.hasError ? true : false}
                    >
                        Save General Information
                    </Button>
                </div>
            </div>
            {openAlert && <Alert {...alertProps} openAlert={openAlert} setOpenAlert={setOpenAlert} />}
        </>
    );
};

export default SimBuilderGeneral;