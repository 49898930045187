import React, { useContext, useEffect } from "react";
import { PlayerContext } from "../../context/PlayerContext";

const WelcomeScreen = (props) => {
  const { setShowPlayButton } = useContext(PlayerContext);

  useEffect(() => {
    if (props.welcomeAudioUrl !== undefined && !props.debug) {
      const clip = document.getElementById("soundclip");
      const playPromise = clip?.play();
      if (playPromise !== undefined) {
        playPromise.then(function () {
          console.debug("Audio play started in Welcome Track");
          setShowPlayButton(false);
          // Automatic playback started!
        }).catch(function (error) {
          console.debug("Audio Play exception in Welcome Track:", error);
          setShowPlayButton(true, error);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <>
      <h1 className="welcome_title">
        {" "}
        Welcome to SchoolSims {props.title && "  --  " + props.title && !props.isPub && "  --  " + props.simId}
      </h1>
      <div className="welcome_text">
        <p>
          As in real life, you will not be able to go back in time during the
          simulation. There is no back button--you may only move forward.
        </p>
        <p>
          If you need to exit the simulation and return, you may restart or
          continue from where you left off.
        </p>
        <p>
          For a description of the buttons below, select the HELP button, which
          will display an Interface Overview page in a separate browser tab.
        </p>
        <p>
          Finally, keep moving forward by selecting the NEXT button to the lower
          right.
        </p>
        <br />

        {/* Commented for future use
        {props.desc && <p>{props.desc}</p>} */}

        {props.welcomeAudioUrl && (
          <audio
            key={props.simId + "_" + props.welcomeAudioUrl}
            id="soundclip"
            controls={props.debug}
            muted={props.isMuted}
          >
            {props.welcomeAudioUrl && <source src={props.pubWelcomeAudioUrl} type="audio/mpeg" />}
          </audio>
        )}
      </div>
    </>
  );
};

export default WelcomeScreen;