/**
 *  TITLE_LENGTH_LIMITATION will be used for the title field in track section
 */
export const TITLE_LENGTH_LIMITATION = 250;

/**
 * HEADER_LENGTH_LIMITATION will be used for the header field in track section
 */
export const HEADER_LENGTH_LIMITATION = 1000;

/**
 * PROMPT_LENGTH_LIMITATION will be used for the prompt field in track section
 */
export const PROMPT_LENGTH_LIMITATION = 1000;

/**
 * AI_TEXT_LENGTH_LIMITATION will be used for the prompt field in AI text box section
 */
export const AI_TEXT_LENGTH_LIMITATION = 500;

/**
 * AI_TEXT_LENGTH_LIMITATION will be used for the prompt field in AI text box section
 */
export const INTERNAL_SIM_DESC_LENGTH_LIMITATION = 5000;

