import React, { createContext, useState, useMemo, useEffect, useContext } from 'react';
import { EditSimContext } from './EditSimContext';
import { UserContext } from './UserContext';
import VariableService from "../services/VariableService";

export const VariableContext = createContext();

//Provide a cached list of variables for a simulation
//Automatically pull from DB as simEdit context chanegs
const VariableContextProvider = (props) => {
    const { token } = useContext(UserContext);
    const { editSimState } = useContext(EditSimContext);
    const [variableState, setVariableState] = useState([]);

    //Load from DB
    const loadContextFromDB = (id) => {
        VariableService().getVariablesBySimId(id, token).then((response) => {
            if (response.status === 200) {
                setVariableState(response.data);
            }
        }).catch((e) => {
            console.log('Error: Could not load variables from DB', e.message);
        });
    }

    const addVariable = (newVar) => {
        VariableService().addVariable({ ...newVar, simId: editSimState.id }, token).then((response) => {
            if (response.status === 200) {
                setVariableState(variableState.concat({ ...response.data }));
                console.log('Saved variable to DB');
            }
        }).catch(e => {
            console.log('Error: could not save variable', e.message);
        });
    }

    const updateVariable = (newVar) => {
        VariableService().updateVariable({ ...newVar }, token).then((response) => {
            if (response.status === 200) {
                const varsList = variableState.map((v) => {
                    return (v.id !== newVar.id ? v : response.data);
                });
                setVariableState(varsList);
                console.log('Updated variable in DB');
            }
        }).catch(e => {
            console.log('Error: could not save variable', e.message);
        });
    }

    const deleteVariable = (id, callback) => {
        VariableService().deleteVariable(id, token).then((response) => {
            if (response.status === 200) {
                const varsList = variableState.filter((v) => {
                    return v.id !== id;
                })
                setVariableState(varsList);
                callback(true)
            } else {
                callback(false);
            }
        }).catch((e) => {
            console.log('Error: could not delete variable', e.message);
            callback(false);
        })
    }

    //Attach to editSimState and load this subdomain for the variables state
    // updating this state as editSimState changes
    useEffect(() => {
        if (editSimState.id) {
            loadContextFromDB(editSimState.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editSimState])


    const variableStateProviderValue = useMemo(() => (
        {
            variableState,
            addVariable,
            updateVariable,
            deleteVariable
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [variableState, setVariableState]);

    return (
        <>
            <VariableContext.Provider value={variableStateProviderValue} >
                {props.children}
            </VariableContext.Provider>
        </>
    );
}

export default VariableContextProvider;