import { SIM_BUILDER_API_END_POINT } from "../Config";
import RequestService from "../utils/requestService";

const FolderService = () => {
    return Object.freeze({
        getAllFolders: async (token) => {
            const options = RequestService.makeRequest("GET", token);

            const result = await fetch(SIM_BUILDER_API_END_POINT + '/folders/withSims', options);
            const response = await result.json();

            return response;
        },
        craeteFolder: async (folderObj, token) => {
            const options = RequestService.makeRequest("POST", token);
            options['body'] = JSON.stringify(folderObj);

            const result = await fetch(SIM_BUILDER_API_END_POINT + '/folders', options);
            const response = await result.json();

            return response;
        },
        updateFolder: async (folderObj, token) => {
            const options = RequestService.makeRequest("PUT", token);
            options['body'] = JSON.stringify(folderObj);

            const result = await fetch(SIM_BUILDER_API_END_POINT + '/folders', options);
            const response = await result.json();

            return response;
        },
        deleteFolder: async (folderId, token) => {
            const options = RequestService.makeRequest("DELETE", token);

            const result = await fetch(SIM_BUILDER_API_END_POINT + '/folders/' + folderId, options);
            const response = await result.json();

            return response;
        },
        moveSimToFolder: async (folderSimObj, token) => {
            const options = RequestService.makeRequest("POST", token);
            options['body'] = JSON.stringify(folderSimObj);

            const result = await fetch(SIM_BUILDER_API_END_POINT + '/simFolders', options);
            const response = await result.json();

            return response;
        },
        removeSimFromFolder: async (folderId, simId, token) => {
            const options = RequestService.makeRequest("DELETE", token);

            const result = await fetch(SIM_BUILDER_API_END_POINT + '/simFolders/' + folderId + "/" + simId, options);
            const response = await result.json();

            return response;
        },
    });
}


export default FolderService;