import React, { useEffect, useContext } from "react";
import { PlayerContext } from "../../context/PlayerContext";
import { getMarginTop, isNullOrEmpty } from "../../utils/utils";
import AlertDialog from "../generic/CustomizeAlertDilaog";
import { defultEditorText } from "../third-party/Editor";
import SlideTextDisplay from "./SlideTextDisplay";
import CustomizedNestedList from "../generic/CustomizedNestedList";

const VideoTrack = (props) => {
  const { setShowPlayButton, showFlashAlert, isPlayingFromTrack } =
    useContext(PlayerContext);

  useEffect(() => {
    if (
      (props.alertMessage === "" && props.flashTrackId === "") ||
      !showFlashAlert
    ) {
      if (props.pubUrl) {
        if (!props.debug) {
          const vid = document.getElementById("vid");
          const playPromise = vid.play();
          if (playPromise !== undefined) {
            playPromise
              .then(function () {
                console.debug("Video play started in Video Track");
                setShowPlayButton(false);
              })
              .catch(function (error) {
                console.debug("Video Play exception in Video Track:", error);
                setShowPlayButton(true, error);
              });
          }

          if (props.slideTimeout && !props.flashTrack) {
            vid.onended = () => {
              props.handleSlideTimeOut();
            };
          }
        }
      } else if (!props.pubUrl && !props.debug) {
        if (props.slideTimeout && !props.flashTrack) {
          props.handleSlideTimeOut();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.play]);

  const handleGoToFlashTrack = () => {
    props.handleGoToFlashTrack();
  };

  return (
    <>
      {!props.isPub && (
        <h1
          style={{ marginTop: getMarginTop(props.isPub, props.trackIdx) }}
        >
          Track: {props.order} -- {props.title} --{" "}
          {!props.isPub && !props.debug ? props.trackId : props.id}
        </h1>
      )}
      {props.visibilitySettings.length > 0 && isPlayingFromTrack && (
        <CustomizedNestedList
          text="Variables:"
          visibilitySettings={props.visibilitySettings}
        />
      )}
      <h1 style={{ marginTop: getMarginTop(props.isPub, props.trackIdx) }}>
        {props.header}
      </h1>
      {!isNullOrEmpty(props.pubUrl) ? (
        <div className="video_player">
          <video
            key={props.pubUrl}
            id="vid"
            controls
            className="video_ele"
            crossOrigin="anonymous"
            muted={props.isMuted}
            playsInline
          >
            {props.file && <source src={props.file} type="video/mp4" />}
            {props.pubUrl && <source src={props.pubUrl} type="video/mp4" />}
            {props.pubMetaUrl && <track default src={props.pubMetaUrl}></track>}
          </video>
        </div>
      ) : !isNullOrEmpty(props.body) && props.body !== defultEditorText ? (
        <SlideTextDisplay
          html={`<h3>Script:</h3>` + props.body}
          className="texttrack_text"
        />
      ) : (
        <div className="video_player">
          <video
            key={props.pubUrl}
            id="vid"
            controls
            className="video_ele"
            crossOrigin="anonymous"
            muted={props.isMuted}
            playsInline
          >
            {props.file && <source src={props.file} type="video/mp4" />}
            {props.pubUrl && <source src={props.pubUrl} type="video/mp4" />}
            {props.pubMetaUrl && <track default src={props.pubMetaUrl}></track>}
          </video>
        </div>
      )}
      {!props.debug &&
        !props.flashTrack &&
        showFlashAlert &&
        props.alertMessage !== "" &&
        props.flashTrackId !== "" && (
          <AlertDialog
            size="lg"
            isSticky={true}
            title={props.alertMessage}
            showGoToFlashTrack={true}
            handleGoToFlashTrack={handleGoToFlashTrack}
          />
        )}
    </>
  );
};

export default VideoTrack;
