import React, { useState } from "react";
import { NavigateBefore, NavigateNext, KeyboardArrowUp, KeyboardArrowDown } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    navigateIcon: {
        fontSize: "20px",
        transform: "translate(-50%, -50%)",
        position: "absolute",
        top: "50%",
        left: "50%"
    },
}));

const useExpandCollapse = (props) => {
    const classes = useStyles();
    const [isExpand, setIsExpand] = useState(true);
    const titleContent = isExpand ? "Collapse" : "Expand";
    let html = "";
    let cssClasses = `navigateBlock ${props.className ? props.className : ""}`;

    const toggleExpand = (toggle) => {
        if (toggle == false || toggle == true) {
            setIsExpand(toggle);
        } else {
            setIsExpand((prevState => !prevState));
        }
    }

    if (props.vertical) {
        cssClasses = `navigateBlockVertical ${cssClasses}`;
        html = isExpand ? <NavigateBefore className={classes.navigateIcon} />
            : <NavigateNext className={classes.navigateIcon} />;
    } else {
        html = isExpand ? <KeyboardArrowUp className={classes.navigateIcon} />
            : <KeyboardArrowDown className={classes.navigateIcon} />;
    }

    const markup = <div className={cssClasses} onClick={() => toggleExpand()} data-title={titleContent}>
        {html}
    </div>

    return [isExpand, markup, toggleExpand];
}

export default useExpandCollapse;