import React from "react";

const FeedbackReportItem = (props) => {
  const isFreeText = props.track.type;

  return (
    <div className="report_item">
      <h2>{props.prompt}</h2>
      {props.promptDetails && <p>{props.promptDetails}</p>}
      <h3>{isFreeText === 'question' ? "Learning Objective" : "Considerations"}</h3>
      <p>{props.lesson}</p>
      <h3>{isFreeText === 'question' ? "Your Choice(s):" : "Your Response:"}</h3>
      <ul>
        {props.responseList.map((choice, index) => {
          return <li key={index}>{choice}</li>
        }
        )}
      </ul>
      {(props.allResp && isFreeText === 'question') && (
        <>
          <h3>Other Choices</h3>
          <ul>
            {props.allResp &&
              props.allResp.map((choice, index) => {
                if (!props.responseList.includes(choice.response)) {
                  return <li key={index}>{choice.response}</li>;
                }
                return "";
              })}
          </ul>
        </>
      )}
      {props.timeForResponse && (
        <>
          <h3>Decision Time</h3>
          <ul>
            {props.timeForResponse}
          </ul>
        </>
      )}
      {props.feedbackList && (
        <>
          <h3>Feedback</h3>
          <ul>
            {props.feedbackList.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default FeedbackReportItem;