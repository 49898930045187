import HttpService from "./HttpService";

const VariableOperationService = () => {

    return Object.freeze({
        saveVariableOperation: async (dataResult, endPoint) => {
            return HttpService().postRequest(dataResult, endPoint);
        }
    })
}

export default VariableOperationService;