import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Collapse, List, ListItem, ListItemText } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { PlayerContext } from '../../context/PlayerContext';
import { evaluateVariable } from '../../utils/utils';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

function CustomizedNestedList(props) {
    const classes = useStyles();
    const { playerState } = useContext(PlayerContext);
    let listItemText = props.text ? props.text : "";
    let textColor = props.color ? props.color : "list-text-color";
    const [isExpand, setIsExpand] = useState(false);

    const handleNestedListClick = () => {
        setIsExpand(!isExpand);
    };

    const filterConditionalVisibilitySettings = () => {
        let show = true;
        let listItemClass;
        if (props.visibilitySettings.length > 0) {
            let items = '';
            // eslint-disable-next-line
            props.visibilitySettings.map((vs, i) => {
                const vizSetting = vs;
                const v = playerState.vars.filter((v) => {
                    return v.id === vizSetting.var.value;
                });
                if (v[0]) {
                    show = evaluateVariable(v[0], props.title, vizSetting);
                }
                listItemClass = (show) ? "trueVariables" : "falseVariables";
                items += `<p class=${listItemClass}>
                <span>
                    ${i + 1}. &nbsp; ${vizSetting.var.name} <b> ${vizSetting.operand} </b> ${vizSetting.val} <b> ${vizSetting.logic} 
                </span>
                <span class=${"actualVariables"}>
                   (<b>Actual Value = </b>
                    ${(v[0].currentValue !== undefined && v[0].currentValue !== null)
                        ? v[0].currentValue : v[0].startVal})
                </span >
                </p > `;
            });
            return items;
        }
    }

    return (
        <>
            <List component="nav" className={classes.root}>
                <ListItem button className={classes.nested} onClick={handleNestedListClick}>
                    <ListItemText primary={listItemText} className={textColor} disableTypography />
                    {isExpand ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={isExpand} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button className={classes.nested}>
                            <ListItemText>
                                <div dangerouslySetInnerHTML={{ __html: filterConditionalVisibilitySettings() }}></div>
                            </ListItemText>
                        </ListItem>
                    </List>
                </Collapse>
            </List>
        </>
    )
}

export default CustomizedNestedList;