import React, { useState, useContext, useEffect } from "react";
import Button from '@material-ui/core/Button';
import { TextField, Select, FormControl, InputLabel, } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CreateNewFolder } from '@material-ui/icons';
import { SimFoldersContext } from "../../context/SimFoldersContext";
import FolderSpecialIcon from "@material-ui/icons/FolderSpecial";
import FolderIcon from '@material-ui/icons/Folder';
import FormHelperText from '@material-ui/core/FormHelperText';
import Alert from "../generic/Alerts";

const FolderDialog = (props) => {

    const {
        addSimFolder, currentFolder, updateSimFolder,
        simFolders, moveToFolder, getFolderById
    } = useContext(SimFoldersContext);

    const [errorProps, setErrorProps] = useState(false);
    const [folderName, setFolderName] = useState("");
    const [dialogTitle, setDialogTitle] = useState("Add Folder");
    const [openAlert, setOpenAlert] = useState(false);
    const [alertProps] = useState(
        {
            msg: "You can not move this sim because it already exists in the folder!",
            type: "warning"
        }
    );

    useEffect(() => {
        if (props.isFolderEdit && !props.isMoveToFolder) {
            setDialogTitle("Update Folder");
            setFolderName(currentFolder.name);
        } else if (props.isFolderEdit && props.isMoveToFolder) {
            setDialogTitle("Move To Folder");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleTextChange = (e) => {
        setFolderName(e.target.value);
        setErrorProps(false);
    };

    const handleSelectChange = (e) => {
        if (e.target.value !== "") {
            setFolderName(e.target.value);
        } else {
            setFolderName("");
            setErrorProps(true);
        }
    }

    const handleClose = () => {
        props.setIsFolderEdit(false);
        props.handleCloseFolderDialog();
    }

    const addFolderToList = () => {
        if (folderName !== "" && folderName !== null) {
            addSimFolder(folderName);
            props.handleCloseFolderDialog();
        } else {
            setErrorProps(true);
        }
    }

    const updateFolder = () => {
        if (folderName !== "") {
            let isSimExist = false;
            if (props.isFolderEdit && props.isMoveToFolder) {
                const folder = getFolderById(folderName);
                if (folder.simFolders !== undefined) {
                    for (let i = 0; i < folder.simFolders.length; i++) {
                        if (props.simId === folder.simFolders[i].simId) {
                            isSimExist = true;
                            break;
                        }
                    }
                }
                if (!isSimExist) {
                    moveToFolder(props.simId, folder);
                    handleClose();
                } else {
                    setOpenAlert(true);
                }
            } else {
                updateSimFolder(folderName);
                handleClose();
            }
        } else {
            setErrorProps(true);
        }
    }

    return (
        <div>
            <Dialog
                open={true}
                fullWidth
                maxWidth="sm"
                onClose={(event, reason) => {
                    if (reason !== "escapeKeyDown" || reason !== "backdropClick") {
                        handleClose()
                    }
                }}
                aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    {!props.isFolderEdit && !props.isMoveToFolder && <CreateNewFolder fontSize="large" color="primary" />}
                    {props.isFolderEdit && !props.isMoveToFolder && <FolderIcon fontSize="large" color="primary" />}
                    {props.isFolderEdit && props.isMoveToFolder && <FolderSpecialIcon fontSize="large" color="primary" />}
                    &nbsp;&nbsp;&nbsp;{dialogTitle}
                </DialogTitle>
                <DialogContent>
                    {!props.isFolderEdit && !props.isMoveToFolder &&
                        <TextField
                            autoFocus
                            margin="dense"
                            id="folderName"
                            name="folderName"
                            label="Folder Name"
                            type="text"
                            error={errorProps && true}
                            helperText={errorProps && 'Folder name is required!'}
                            onChange={handleTextChange}
                            fullWidth
                            aria-disabled={true}
                            value={folderName}
                            inputProps={{ maxLength: 250 }}
                        />
                    }

                    {props.isFolderEdit && !props.isMoveToFolder &&
                        <TextField
                            autoFocus
                            margin="dense"
                            id="folderName"
                            name="folderName"
                            label="Folder Name"
                            type="text"
                            error={errorProps && true}
                            helperText={errorProps && 'Folder name is required!'}
                            onChange={handleTextChange}
                            fullWidth
                            aria-disabled={true}
                            value={folderName}
                            inputProps={{ maxLength: 250 }}
                        />
                    }
                    {props.isFolderEdit && props.isMoveToFolder &&
                        <div className="col-sm-12">
                            {openAlert && <Alert {...alertProps} openAlert={openAlert} setOpenAlert={setOpenAlert} />}
                            Selected sim: <span className="text-secondary"><b>{props.simTitle}</b></span>
                            <FormControl className="mt-2" variant="outlined" fullWidth={true} error={errorProps}>
                                <InputLabel htmlFor="selectedFolder">Folders</InputLabel>
                                <Select
                                    native
                                    label="Folders"
                                    name="selectedFolder"
                                    id="selectedFolder"
                                    onChange={handleSelectChange}
                                    style={{ width: '100%' }}
                                >
                                    <option aria-label="None" value="" />
                                    {simFolders.map((folder) => {
                                        return (
                                            <option key={folder.id} value={folder.id}>
                                                {folder.name}
                                            </option>
                                        );
                                    })}
                                </Select>
                                <FormHelperText>{errorProps ? "Please select folder!" : ""}</FormHelperText>
                            </FormControl>
                        </div>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button onClick={props.isFolderEdit ? updateFolder : addFolderToList} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}

export default FolderDialog;