import React, { useEffect, useContext, useState } from "react";
import PhoneRing from "../../assets/sounds/iphone_ring.mov";
import SlideTextDisplay from "./SlideTextDisplay";
import { PlayerContext } from "../../context/PlayerContext";
import { getMarginTop, isNullOrEmpty } from "../../utils/utils";
import AlertDialog from "../generic/CustomizeAlertDilaog";
import CustomizedNestedList from "../generic/CustomizedNestedList";
import IPhoneImage from "../../assets/images/iphone.png";

const PhoneTrack = (props) => {
  const { setShowPlayButton, showFlashAlert, isPlayingFromTrack } =
    useContext(PlayerContext);
  const [audioCallPlay, setAudioCallPlay] = useState(false);

  const getPhoneLabel = () => {
    if (!isNullOrEmpty(props.contactName)) {
      return props.contactName;
    }
    return props.contactNumber;
  };

  useEffect(() => {
    if (
      (props.alertMessage === "" && props.flashTrackId === "") ||
      !showFlashAlert
    ) {
      if (!props.url && !props.debug) {
        if (props.slideTimeout && !props.flashTrack) {
          props.handleSlideTimeOut();
        }
      } else {
        const ring = document.getElementById("ring");
        if (!props.debug && !props.skipRinger) {
          ring.play();
        }

        if (!props.debug) {
          //ring phone

          ring.onended = () => {
            //when done ringing play phone call recording
            const clip = document.getElementById("soundclip");
            if (props.slideTimeout && !props.flashTrack) {
              clip.onended = () => {
                props.handleSlideTimeOut();
              };
            }
            if (!props.manualAudio) {
              const playPromise = clip.play();
              if (playPromise !== undefined) {
                playPromise
                  .then(function () {
                    console.debug("Audio play started in Phone Track");
                    setShowPlayButton(false);
                    // Automatic playback started!
                  })
                  .catch(function (error) {
                    console.debug(
                      "Audio Play exception in Phone Track:",
                      error
                    );
                    setShowPlayButton(true, error);
                  });
              }
            }
          };

          //play the clip in the case no ringer to play
          if (!props.manualAudio && props.skipRinger) {
            const audioTrack = document.getElementById("soundclip");
            if (props.slideTimeout && !props.flashTrack) {
              audioTrack.onended = () => {
                props.handleSlideTimeOut();
              };
            }
            const playPromise = audioTrack.play();
            if (playPromise !== undefined) {
              playPromise
                .then(function () {
                  console.debug("Audio play started in Phone Track");
                  setShowPlayButton(false);
                  // Automatic playback started!
                })
                .catch(function (error) {
                  console.debug("Audio Play exception in Phone Track:", error);
                  setShowPlayButton(true, error);
                });
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.play]);

  useEffect(() => {
    if (props.manualAudio && audioCallPlay && props.url) {
      if (props.slideTimeout && !props.flashTrack) {
        const audioTrack = document.getElementById("soundclip");
        audioTrack.onended = () => {
          props.handleSlideTimeOut();
        };
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioCallPlay]);

  const handleGoToFlashTrack = () => {
    props.handleGoToFlashTrack();
  };

  return (
    <>
      {!props.isPub && (
        <h1
          style={{ marginTop: getMarginTop(props.isPub, props.trackIdx) }}
        >
          Track: {props.order} -- {props.title} --{" "}
          {!props.isPub && !props.debug ? props.trackId : props.id}
        </h1>
      )}
      {props.visibilitySettings.length > 0 && isPlayingFromTrack && (
        <CustomizedNestedList
          text="Variables:"
          visibilitySettings={props.visibilitySettings}
        />
      )}
      <h1 style={{ marginTop: getMarginTop(props.isPub, props.trackIdx) }}>
        {props.header}
      </h1>
      <div className="phone_track">
        <div className="phone">
          <img
            src={`${window.location.origin}${IPhoneImage}`}
            alt={props.title}
          />
          <span className="phone_contact">{getPhoneLabel()}</span>
        </div>
        <div className="phone_track_content">
          <audio id="ring" controls={false} muted={props.isMuted}>
            <source src={PhoneRing} type="audio/mpeg" />
          </audio>
          {props.url && (
            <audio
              key={props.url}
              id="soundclip"
              controls={props.debug || props.manualAudio}
              onPlay={() => setAudioCallPlay(true)}
              muted={props.isMuted}
            >
              {props.file && <source src={props.file} type="audio/mpeg" />}
              {props.url && <source src={props.pubUrl} type="audio/mpeg" />}
            </audio>
          )}
          <SlideTextDisplay html={props.transcript} />
        </div>
      </div>
      {!props.debug &&
        !props.flashTrack &&
        showFlashAlert &&
        props.alertMessage !== "" &&
        props.flashTrackId !== "" && (
          <AlertDialog
            size="lg"
            isSticky={true}
            title={props.alertMessage}
            showGoToFlashTrack={true}
            handleGoToFlashTrack={handleGoToFlashTrack}
          />
        )}
    </>
  );
};

export default PhoneTrack;
