import React, { useContext } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./Login";
import NotFound from "./NotFound";
import Dashboard from "./Dashboard";
import Users from "./users/users";
import AppContainer from "./AppContainer";
import AssetManager from "./AssetManager";
import PubSimCreator from "./PubSimCreator";
import InfoPage from "./InfoPage";
import SimulationLibrary from "./SimulationLibrary";
import LibraryContextProvider from "../context/LibraryContext";
import SimBuilder from "./builder/SimBuilder";
import SimLoading from "./SimLoading";
import EditSimContextProvider from "../context/EditSimContext";
import Player from "./player/Player";
import { UserContext } from "../context/UserContext";
import VariableContextProvider from "../context/VariableContext";
import MediaContextProvider from "../context/MediaContext";
import PlayerContextProvider from "../context/PlayerContext";
import CharacterContextProvider from "../context/CharacterContext";
import ProtectedRoute from "./ProtectedRoute";
import SimIdeasContextProvider from "../context/SimIdeasContext";
import SimFoldersContextProvider from "../context/SimFoldersContext";
import SimIdeas from "./simIdeas/SimIdeas";
import ViewSimIdea from "./simIdeas/ViewIdea";
import EditSimIdea from "./simIdeas/EditSimIdea";
import ViewDocs from "./simIdeas/ViewDocs";
import UsersContextProvider from "../context/UsersContext";
import { createBrowserHistory } from "history";
import PromtPlaySimulation from "./generic/PromtPlaySimulation";
import EditTrackContextProvider from "../context/EditTrackContext";
import InternalSimulations from "./exportInternalSimulation/InternalSimulations";
import AddInternalSim from "./exportInternalSimulation/AddInternalSim";
import ViewInternalSim from "./exportInternalSimulation/ViewInternalSim";

const App = () => {
  const PUB_PAGE = "/publicSimCreator";
  const ctx = useContext(UserContext);
  const loc = window.location;
  const history = createBrowserHistory();

  return (
    <>
      {!ctx.user && loc.pathname !== PUB_PAGE && <Login />}
      {loc.pathname === PUB_PAGE && <PubSimCreator />}
      {ctx.user && ctx.user.status && ctx.user.active && (
        <Router history={history}>
          <AppContainer>
            <SimFoldersContextProvider>
              <LibraryContextProvider>
                <UsersContextProvider>
                  <PlayerContextProvider>
                    <EditSimContextProvider>
                      <EditTrackContextProvider>
                        <VariableContextProvider>
                          <CharacterContextProvider>
                            <MediaContextProvider>
                              <SimIdeasContextProvider>
                                <Switch>
                                  <ProtectedRoute exact path="/" uContext={ctx}>
                                    <Dashboard />
                                  </ProtectedRoute>
                                  <Route path="/play">
                                    <Player />
                                  </Route>
                                  <ProtectedRoute
                                    path="/dashboard"
                                    uContext={ctx}
                                  >
                                    <Dashboard />
                                  </ProtectedRoute>
                                  <ProtectedRoute path="/users" uContext={ctx}>
                                    <Users />
                                  </ProtectedRoute>
                                  <ProtectedRoute path="/assets" uContext={ctx}>
                                    <AssetManager />
                                  </ProtectedRoute>
                                  <ProtectedRoute
                                    path="/library"
                                    uContext={ctx}
                                  >
                                    <SimulationLibrary />
                                  </ProtectedRoute>
                                  <ProtectedRoute path="/tracks" uContext={ctx}>
                                    <SimBuilder currentTab="Tracks" />
                                  </ProtectedRoute>
                                  <ProtectedRoute
                                    path="/builder"
                                    uContext={ctx}
                                  >
                                    <SimBuilder />
                                  </ProtectedRoute>
                                  <ProtectedRoute
                                    path="/simIdeas"
                                    uContext={ctx}
                                  >
                                    <SimIdeas />
                                  </ProtectedRoute>
                                  <ProtectedRoute
                                    path="/viewSimIdea"
                                    uContext={ctx}
                                  >
                                    <ViewSimIdea />
                                  </ProtectedRoute>
                                  <ProtectedRoute
                                    path="/editSimIdea"
                                    uContext={ctx}
                                  >
                                    <EditSimIdea />
                                  </ProtectedRoute>
                                  <ProtectedRoute
                                    path="/viewDocs"
                                    uContext={ctx}
                                  >
                                    <ViewDocs />
                                  </ProtectedRoute>
                                  <ProtectedRoute
                                    path="/internalSimulations"
                                    uContext={ctx}
                                  >
                                    <InternalSimulations />
                                  </ProtectedRoute>
                                  <ProtectedRoute
                                    path="/addInternalSim"
                                    uContext={ctx}
                                  >
                                    <AddInternalSim />
                                  </ProtectedRoute>
                                  <Route
                                    path="/viewInternalSim/:simId"
                                    component={ViewInternalSim}
                                    uContext={ctx}
                                  />
                                  <Route exact path="/sso">
                                    <SimLoading />
                                  </Route>
                                  <Route path="/loading">
                                    <SimLoading />
                                  </Route>
                                  <Route path="/promptPlay">
                                    <PromtPlaySimulation />
                                  </Route>
                                  <Route path="?">
                                    <NotFound />
                                  </Route>
                                  <Route path="/infoPage">
                                    <InfoPage />
                                  </Route>
                                </Switch>
                              </SimIdeasContextProvider>
                            </MediaContextProvider>
                          </CharacterContextProvider>
                        </VariableContextProvider>
                      </EditTrackContextProvider>
                    </EditSimContextProvider>
                  </PlayerContextProvider>
                </UsersContextProvider>
              </LibraryContextProvider>
            </SimFoldersContextProvider>
          </AppContainer>
        </Router>
      )}
    </>
  );
};

export default App;
