import { useContext, useState } from 'react';
import { EditTrackContext } from '../../../context/EditTrackContext';

import { Button, FormControl, Grid, InputLabel, Select } from '@material-ui/core';
import { MediaContext } from '../../../context/MediaContext';
import AITextPrompt from '../../generic/AITextPrompt';

const TrackImage = () => {
    const { images } = useContext(MediaContext);
    //Current track being edited
    const { editTrackState, setEditTrackState } = useContext(EditTrackContext);
    const [openAIPrompt, setOpenAIPrompt] = useState(false);
  

    const handleSelectChange = (e) => {
        const { name, value } = e.target;
        setEditTrackState({
            ...editTrackState,
            pubTrackImageUrl: value !== "" ? images.find((img) => img.id === value).url : null,
            [name]: value !== "" ? value : null,
        });
    };

    return (
    <>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={8}>
                <FormControl variant="outlined">        
                    <InputLabel htmlFor="responseType">Slide Image Asset</InputLabel>
                    <Select
                        native
                        name="trackImgURL"
                        id="trackImgURL"
                        value={editTrackState.trackImgURL ? editTrackState.trackImgURL : ""}
                        onChange={handleSelectChange}
                    >
                        <option aria-label="None" value="" />
                        {
                            images.map((media) => {
                                return <option key={media.id} value={media.id}>{media.name}</option>;
                            })
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <Button variant="contained" color="primary" onClick={()=>setOpenAIPrompt(true)}>
                    Generate New Image
                </Button>
            </Grid>    
        </Grid>
        
        {openAIPrompt && <AITextPrompt open={openAIPrompt} handleClose={()=>setOpenAIPrompt(false)} isRadio={true} />}
    </>
    );
}

export default TrackImage;