import {
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Select,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { useEffect, useState } from "react";
import { isNullOrEmpty } from "../../utils/utils";
import formLabelsTheme from "../generic/FormLabelsThem";
import { MuiThemeProvider } from "@material-ui/core";
import { UserContext } from "../../context/UserContext";
import { useContext } from "react";
import MultiSelect from "../generic/MultiSelect";
import StandardsService from "../../services/StandardsService";

const initialLocalState = {
  id: null,
  name: "",
  displayName: "",
  desc: "",
  type: "",
  variableContext: false,
  startVal: "",
  showOnReport: false,
  useAvg: false,
  minRange: "",
  maxRange: "",
  step: "",
  sliderLabels: null,
  simId: null,
  varsStandards: [],
};

const allStandards = { value: "*", label: "Select All Standards" };

const EditVariable = (props) => {
  const [localState, setLocalState] = useState(initialLocalState);
  const [sliderLabelValue, setSliderLabelValue] = useState("");
  const [sliderLabelText, setSliderLabelText] = useState("");
  const [varErrorFlag, setVarErrorFlag] = useState(false);
  const [standards, setStandards] = useState([]);

  const handleStandardsChnage = (selectedStandards) => {
    if (
      selectedStandards &&
      selectedStandards.some((option) => option.value === "*")
    ) {
      setLocalState({
        ...localState,
        varsStandards: standards,
      });
    } else {
      setLocalState({
        ...localState,
        varsStandards: selectedStandards,
      });
    }
  };

  const getStandardsOptions = () => {
    if (standards != [] && standards != undefined) {
      if (localState.varsStandards.length == standards.length) {
        return [...localState.varsStandards];
      } else {
        return [allStandards, ...standards];
      }
    }
  };

  const { token } = useContext(UserContext);

  useEffect(() => {
    StandardsService()
      .getStandardsFromPortal(token)
      .then((response) => {
        setStandards(
          response.data.standards.map((standard) => {
            return { ...standard, value: standard.uuid };
          })
        );
      })
      .catch((e) => {
        console.debug("Error Occurred", e.message);
      });

    if (!!props.val) {
      const selectedStandards = standards.filter((standard) =>
        props.val.varsStandards.some(
          (selected) => selected.uuid === standard.uuid
        )
      );
      setLocalState({
        ...localState,
        ...props.val,
        varsStandards: selectedStandards,
      });
    } else {
      setLocalState(initialLocalState);
    }
  }, [props]);

  const handleChange = (e) => {
    const name = e.target.name;
    setLocalState({ ...localState, [name]: e.target.value });
  };

  const handleSelectChange = (event) => {
    const name = event.target.name;
    setLocalState({
      ...localState,
      [name]: event.target.value,
    });
  };

  const handleCheckChanged = (e) => {
    const { name, checked } = e.target;
    setLocalState({ ...localState, [name]: checked });
  };

  const handleSave = () => {
    if (localState.type !== "number") {
      localState.variableContext = false;
    }
    props.handleSave(localState);
  };

  const getSliderLabels = () => {
    if (localState.sliderLabels !== null) {
      return localState.sliderLabels.map((l, i) => {
        return (
          <p className="listItem" key={i}>
            <span>
              {l.label} @ {l.value}
            </span>
            <IconButton
              onClick={() => props.deleteSliderLabel(localState, l.id, i)}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </p>
        );
      });
    }
  };

  const addSliderLabel = () => {
    if (!isNullOrEmpty(sliderLabelValue) && !isNullOrEmpty(sliderLabelText)) {
      const newSliderLabel = {
        value: sliderLabelValue,
        label: sliderLabelText,
      };
      const sliderLabels = localState.sliderLabels
        ? localState.sliderLabels
        : [];
      setLocalState({
        ...localState,
        sliderLabels: sliderLabels.concat(newSliderLabel),
      });

      setSliderLabelText("");
      setSliderLabelValue("");
      setVarErrorFlag(false);
    } else {
      setVarErrorFlag(true);
    }
  };

  return (
    <div className="variable_edit_form">
      <TextField
        name="name"
        label="Variable Name"
        variant="outlined"
        onChange={handleChange}
        value={localState.name}
      />
      {localState.type === "number" && (
        <p className="notes pl-1 mb-1 mt-1">
          <b>Note:</b> For accreditation functionality, Use a +/- sign in
          variable names to denote states (e.g., Emotional Response+ for a
          positive response and Emotional Response- for a negative response).
          Using these +/- signs will impact the calculation of the Positive to
          Negative Ratio Calculation in the Accreditation Dashboard and report..
        </p>
      )}
      <TextField
        name="displayName"
        label="Display Name"
        variant="outlined"
        onChange={handleChange}
        value={localState.displayName}
      />
      <FormControl variant="outlined">
        <InputLabel htmlFor="type">Variable Type</InputLabel>
        <Select
          native
          name="type"
          id="type"
          value={localState.type}
          onChange={handleSelectChange}
        >
          <option aria-label="None" value="" />
          <option value={"boolean"}>Boolean</option>
          <option value={"number"}>Number</option>
          <option value={"string"}>String</option>
        </Select>
      </FormControl>

      {localState.type === "number" && (
        <MultiSelect
          options={getStandardsOptions()}
          handleChange={handleStandardsChnage}
          selectedOptions={localState.varsStandards}
        />
      )}
      <TextField
        name="desc"
        label="Description"
        variant="outlined"
        onChange={handleChange}
        value={localState.desc}
        multiline
        minRows={3}
        maxRows={5}
      />
      <TextField
        name="startVal"
        label="Starting Value"
        variant="outlined"
        onChange={handleChange}
        value={localState.startVal}
      />
      {localState.type === "number" && (
        <>
          <h5 className="mt-1">Use of Variable</h5>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="Use of Variable"
              defaultValue={`${localState.variableContext}`}
              value={`${localState.variableContext}`}
              name="variableContext"
              onChange={handleChange}
            >
              <FormControlLabel
                value="true"
                control={<Radio color="primary" />}
                label="Use Sum Value"
              />
              <FormControlLabel
                value="false"
                control={<Radio color="primary" />}
                label="Use Current Value"
              />
            </RadioGroup>
          </FormControl>
        </>
      )}
      <h5>Reporting</h5>

      <div className="visibility_setting_row">
        <FormControlLabel
          control={
            <Switch
              id="showOnReport"
              name="showOnReport"
              checked={localState.showOnReport}
              onChange={handleCheckChanged}
            />
          }
          label="Show On Report"
        />
        <FormControlLabel
          control={
            <Switch
              id="useAvg"
              name="useAvg"
              checked={localState.useAvg}
              onChange={handleCheckChanged}
            />
          }
          label="Calculate Avg"
        />
      </div>
      <div className="visibility_setting_row">
        <TextField
          name="minRange"
          label="Min-Range"
          variant="outlined"
          onChange={handleChange}
          value={localState.minRange === null ? "" : localState.minRange}
        />
        <TextField
          name="maxRange"
          label="Max-Range"
          variant="outlined"
          onChange={handleChange}
          value={localState.maxRange === null ? "" : localState.maxRange}
        />
        <TextField
          name="step"
          label="Step"
          variant="outlined"
          onChange={handleChange}
          value={localState.step === null ? "" : localState.step}
        />
      </div>
      <MuiThemeProvider theme={formLabelsTheme}>
        <form noValidate autoComplete="off">
          <h5>Slider Labels</h5>
          <div className="visibility_setting_row">
            <TextField
              required
              error={
                (sliderLabelText === "" || sliderLabelText === undefined) &&
                varErrorFlag &&
                true
              }
              name="label"
              id="sliderLabel"
              label="Label"
              variant="outlined"
              onChange={(e) => setSliderLabelText(e.target.value)}
              value={sliderLabelText}
              className="visibility_setting_row_item"
              inputProps={{ maxLength: 50 }}
              helperText={
                (sliderLabelText === "" || sliderLabelText === undefined) &&
                varErrorFlag &&
                "The Label field is required."
              }
            />
            <TextField
              required
              error={
                (sliderLabelValue === "" || sliderLabelValue === undefined) &&
                varErrorFlag &&
                true
              }
              name="value"
              id="sliderValue"
              label="Value"
              variant="outlined"
              onChange={(e) => setSliderLabelValue(e.target.value)}
              value={sliderLabelValue}
              className="visibility_setting_row_item"
              helperText={
                (sliderLabelValue === "" || sliderLabelValue === undefined) &&
                varErrorFlag &&
                "The Value field is required."
              }
            />
            <IconButton onClick={addSliderLabel}>
              <AddCircleIcon />
            </IconButton>
          </div>
        </form>
      </MuiThemeProvider>
      {getSliderLabels()}
      <Button
        name="saveVariableDetails"
        onClick={handleSave}
        variant="contained"
        color="primary"
      >
        Save Variable Details
      </Button>
    </div>
  );
};

export default EditVariable;
