import React from "react";
import EmailHeader from "./EmailHeader";

import { IconButton, Tooltip } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";

const Inbox = (props) => {
    return (
        <>
            <div className="popup_background">
                <div className="popup">
                    <div className="popup_close">
                        <Tooltip title="Close Button">
                            <IconButton
                                onClick={props.handleClosePopup}
                                aria-label="Close Button"
                            >
                                <ClearIcon style={{ color: "black" }} />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div className="popup_content">
                        {props.emails.length < 1 && "Your inbox is empty."}
                        {props.emails.map((email) => {
                            return (
                                <>
                                    <EmailHeader {...email} popup={true} />
                                    <hr />
                                </>
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Inbox;
