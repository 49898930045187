import { SIM_BUILDER_API_END_POINT } from "../Config";
import RequestService, { HttpMethods } from "../utils/requestService";

const SimResultService = () => {
    return Object.freeze({
        saveSimResult: async (simResult) => {
            const options = RequestService.makeRequest(HttpMethods.POST);
            options['body'] = JSON.stringify(simResult);

            const result = await fetch(SIM_BUILDER_API_END_POINT + '/simResult/save', options);
            const response = await result.json();

            return response;
        }
    });
}

export default SimResultService;