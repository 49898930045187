import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { Print } from '@material-ui/icons';
import React from 'react';

const ReportDialog = (props) => {

    const handleClose = () => {
        props.handleCloseDiag();
    }

    const handlePrint = () => {
        window.print();
    }

    return(
        <>
            <Dialog open={true} fullWidth={true} maxWidth="lg">
            <DialogTitle id="reportDialogHeader">
                <div className="flexSpaceBetween">
                    <div>{props.reportTitle}</div>
                    <div>
                        <Button onClick={handlePrint} color="primary">
                            <Print className="report_print_icon" />
                        </Button>
                    </div>
                </div>
            </DialogTitle>
            <DialogContent>
                <div id="reportData">
                    {props.children}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    Close
                </Button>
            </DialogActions>
            </Dialog>
        </>
    );
}

export default ReportDialog;