import Alert from "../../../generic/Alerts";
import { default as React, useContext, useEffect, useState } from 'react';
import { EditTrackContext } from '../../../../context/EditTrackContext';
import { UserContext } from '../../../../context/UserContext';
import TrackService from "../../../../services/TrackService";
import ConfirmationDilaog from '../../../generic/ConfirmationDilaog';
import Editor, { defultEditorText } from '../../../third-party/Editor';
import SimBuilderScoreCardVariableSelector from './SimBuilderScoreCardVariableSelector';
import SimBuilderTrackConditionalVisibility from './SimBuilderTrackConditionalVisibility';
import SimBuilderTrackFormActions from './SimBuilderTrackFormActions';
import SimBuilderTrackGeneral from './SimBuilderTrackGeneral';
import SimBuilderTrackVisibility from './SimBuilderTrackVisibility';
import FlashSetting from "./SimBuilderFlashSetting";
import { EditSimContext } from "../../../../context/EditSimContext";
import { isNullOrEmpty } from "../../../../utils/utils";
import useExpandCollapse from "../../../../hooks/useExpandCollapse";
import { IsExpandAllContext } from "../../../../context/IsExpandAllContext";

const ScoreCardTrackEdit = (props) => {
    const [isExpandSlideControl, markupSlideControl, toggleExpandSlideControl] = useExpandCollapse({ vertical: false })
    const { editTrackState, setEditTrackState, prevTrackState, setPrevTrackState } = useContext(EditTrackContext);
    const { editSimState, setEditSimState } = useContext(EditSimContext);
    const { token } = useContext(UserContext);
    const [currentState, setCurrentState] = useState();
    const [msg, setMsg] = useState("");
    const [openCnfDia, setOpenCnfDia] = useState(false);
    const [handleDelete, setHandleDelete] = useState("");
    const [alertProps, setAlertProps] = useState({ msg: "", type: "" });
    const [openAlert, setOpenAlert] = useState(false);
    const [showVliadiationError, setShowValidationError] = useState(false);
    const isExpandALL = useContext(IsExpandAllContext);

    useEffect(() => {
        toggleExpandSlideControl(isExpandALL);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isExpandALL]);

    useEffect(() => {
        props.isExpand(markupSlideControl);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isExpandSlideControl]);

    useEffect(() => {
        return () => { props.isExpand("") }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleEditorChange = (name, value) => {
        setEditTrackState({ ...editTrackState, [name]: value });
        if (defultEditorText === value && (prevTrackState.body === defultEditorText || prevTrackState.body === "")) {
            setPrevTrackState({ ...prevTrackState, [name]: value });
        }
    }

    const handleClose = () => {
        setOpenCnfDia(false);
    }

    const deleteResponseVsSetting = () => {
        let id = editTrackState.visibilitySettings[currentState].id;

        if (id && id !== null && id !== "") {
            const trackByIdPredicate = (track) => track.id === editTrackState.id;
            const responseCondVisbIdPredicate = (vs) => vs.id === id;

            const currentTrackIndex = editSimState.tracks.findIndex(trackByIdPredicate);
            const currentVsIndex = editSimState.tracks[currentTrackIndex].visibilitySettings.findIndex(responseCondVisbIdPredicate);

            TrackService().deleteResponseVS(id, token)
                .then(response => {
                    if (response.status === 200) {
                        if (!isNullOrEmpty(prevTrackState.visibilitySettings)) {
                            setPrevTrackState({
                                ...prevTrackState,
                                visibilitySettings: prevTrackState.visibilitySettings.filter((val, index) => {
                                    return index !== currentState;
                                })
                            });
                        }
                        setEditTrackState({
                            ...editTrackState,
                            visibilitySettings: editTrackState.visibilitySettings.filter((val, index) => {
                                return index !== currentState;
                            }),
                        });

                        //always set simState after setting track state 
                        setEditSimState({
                            ...editSimState,
                            visibilitySettings: editSimState.tracks[currentTrackIndex].visibilitySettings.splice(currentVsIndex, 1)
                        });

                        setAlertProps({ ...alertProps, msg: "Success: deleted successfully!", type: 'success' });
                        setOpenAlert(true);
                        setOpenCnfDia(false);
                    } else {
                        setAlertProps({ ...alertProps, msg: "Error: while deleting!", type: 'error' });
                        setOpenAlert(true);
                    }
                })
                .catch(e => {
                    console.error(e.message);
                    setAlertProps({ ...alertProps, msg: "Error: while deleting!", type: 'error' });
                    setOpenAlert(true);
                });
        } else {
            if (!isNullOrEmpty(prevTrackState.visibilitySettings)) {
                setPrevTrackState({
                    ...prevTrackState,
                    visibilitySettings: prevTrackState.visibilitySettings.filter((val, index) => {
                        return index !== currentState;
                    })
                });
            }

            setEditTrackState({
                ...editTrackState,
                visibilitySettings: editTrackState.visibilitySettings.filter((val, index) => {
                    return index !== currentState;
                }),
            });
            setAlertProps({ ...alertProps, msg: "Success: deleted successfully!", type: 'success' });
            setOpenAlert(true);
            setOpenCnfDia(false);
        }
    };

    const deleteScoreCardVariable = () => {
        let id = editTrackState.scoreCardVars[currentState].id;

        if (id && id !== null && id !== "") {
            const trackByIdPredicate = (track) => track.id === editTrackState.id;
            const scorecardVarIdPredicate = (scv) => scv.id === id;

            const currentTrackIndex = editSimState.tracks.findIndex(trackByIdPredicate);
            const currentSCVIndex = editSimState.tracks[currentTrackIndex].scoreCardVars.findIndex(scorecardVarIdPredicate);

            TrackService().deleteScorecardVariable(id, token)
                .then(response => {
                    if (response.status === 200) {
                        if (!isNullOrEmpty(prevTrackState.scoreCardVars)) {
                            setPrevTrackState({
                                ...prevTrackState,
                                scoreCardVars: prevTrackState.scoreCardVars.filter((val, index) => {
                                    return index !== currentState;
                                })
                            });
                        }
                        setEditTrackState({
                            ...editTrackState,
                            scoreCardVars: editTrackState.scoreCardVars.filter((val, index) => {
                                return index !== currentState;
                            }),
                        });

                        //always set simState after setting track state
                        setEditSimState({
                            ...editSimState,
                            scoreCardVars: editSimState.tracks[currentTrackIndex].scoreCardVars.splice(currentSCVIndex, 1)
                        });

                        setAlertProps({ ...alertProps, msg: "Success: deleted successfully!", type: 'success' });
                        setOpenAlert(true);
                        setOpenCnfDia(false);
                    } else {
                        setAlertProps({ ...alertProps, msg: "Error: while deleting!", type: 'error' });
                        setOpenAlert(true);
                    }
                })
                .catch(e => {
                    console.error(e.message);
                    setAlertProps({ ...alertProps, msg: "Error: while deleting!", type: 'error' });
                    setOpenAlert(true);
                });
        } else {
            if (!isNullOrEmpty(prevTrackState.scoreCardVars)) {
                setPrevTrackState({
                    ...prevTrackState,
                    scoreCardVars: prevTrackState.scoreCardVars.filter((val, index) => {
                        return index !== currentState;
                    })
                });
            }

            setEditTrackState({
                ...editTrackState,
                scoreCardVars: editTrackState.scoreCardVars.filter((val, index) => {
                    return index !== currentState;
                }),
            });
            setAlertProps({ ...alertProps, msg: "Success: deleted successfully!", type: 'success' });
            setOpenAlert(true);
            setOpenCnfDia(false);
        }
    };

    const slideControlClass = isExpandSlideControl ? "expand" : "collapse";

    return (
        <>
            <div className={slideControlClass}>
                <SimBuilderTrackGeneral />
                <Editor name="body" id="body" title="Body" data={editTrackState.body} handleEditorChange={handleEditorChange} />
            </div>
            <SimBuilderScoreCardVariableSelector setMsg={setMsg} setOpenCnfDia={setOpenCnfDia} setHandleDelete={setHandleDelete} setCurrentSCV={setCurrentState} />
            <FlashSetting simpleValidator={props.simpleValidator} showVliadiationError={showVliadiationError} />
            <SimBuilderTrackVisibility simpleValidator={props.simpleValidator} />
            <SimBuilderTrackConditionalVisibility setMsg={setMsg} setOpenCnfDia={setOpenCnfDia} setHandleDelete={setHandleDelete} setCurrentVS={setCurrentState} />
            <SimBuilderTrackFormActions simpleValidator={props.simpleValidator} setShowValidationError={setShowValidationError} />

            {openCnfDia &&
                handleDelete === "deleteVS" &&
                <ConfirmationDilaog msg={msg} handleDelete={deleteResponseVsSetting} handleClose={handleClose} />
            }

            {openCnfDia &&
                handleDelete === "deleteSCV" &&
                <ConfirmationDilaog msg={msg} handleDelete={deleteScoreCardVariable} handleClose={handleClose} />
            }
            {openAlert && <Alert {...alertProps} openAlert={openAlert} setOpenAlert={setOpenAlert} />}
        </>
    )
}

export default ScoreCardTrackEdit;