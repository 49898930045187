const DragAndDropService = () => {
    return Object.freeze({
        hoverEffect: (dragEl, hoverEl, domEl, monitor, setDragListState) => {
            if (!domEl.current) {
                return;
            }
            const dragIndex = dragEl.listIndex;
            const hoverIndex = hoverEl.listIndex;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            // Determine rectangle on screen
            const hoverBoundingRect = domEl.current.getBoundingClientRect();

            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            // Determine mouse position
            const clientOffset = monitor.getClientOffset();

            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            // Time to actually perform the action
            setDragListState(prevState => {
                const track = prevState[dragIndex];
                const newItems = prevState;
                newItems.splice(dragIndex, 1);
                newItems.splice(hoverIndex, 0, track);
                return [...newItems];
            })

            //store the initial place of the drag element
            if (dragEl.isShuffle === false) {
                dragEl.isShuffle = true;
                dragEl.prevIndex = dragEl.listIndex;
            }

            // Note: we're mutating the monitor item here!
            dragEl.listIndex = hoverIndex;
        },
        drop: (updateOrder, confirmHandler, setConfirmHandlerState) => {
            updateOrder();
            confirmHandler(setConfirmHandlerState);
        },
        notDrop: (setDragListState, stateBeforeDrag, confirmHandler, setConfirmHandlerState) => {
            setDragListState(stateBeforeDrag);
            confirmHandler(setConfirmHandlerState);

        },
        dragEnd: (dragEl, monitor, setDragListState, stateBeforeDrag) => {
            if (!monitor.didDrop()) {
                setDragListState([...stateBeforeDrag]);
            }
        },
        collectInfo: (monitor) => {
            return { isDragging: monitor.isDragging() };
        }
    })
}

export default DragAndDropService;