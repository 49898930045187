import { SHORT_DATE_FORMAT } from "../Config";

class DateUtil {

    formatDate = (date, format) => {
        let mm = date.getMonth() + 1;
        const map = {
            mm: mm.toString().length === 1 ? "0" + mm : mm,
            dd: date.getDate().toString().length === 1 ? "0" + date.getDate() : date.getDate(),
            yy: date.getFullYear().toString().slice(-2),
            yyyy: date.getFullYear()
        }
        return format.replace(/dd|mm|yyyy/gi, matched => map[matched])
    };

    formatDateInYearMonthDay(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    getShortDate = (date) => {
        return this.formatDate(new Date(date), SHORT_DATE_FORMAT)
    };

    // will convert totalMilliSeconds to HoursMinsAndSecs 
    toHoursMinsAndSeconds = (totalTimeInSeconds) => {
        const timeInSec = totalTimeInSeconds / 1000;
        const hours = Math.floor(timeInSec / 3600);
        const mins = Math.floor((timeInSec - hours * 3600) / 60);
        const secs = timeInSec - hours * 3600 - mins * 60;
        return `${this.padZeroNotTwoDigits(hours)}:${this.padZeroNotTwoDigits(mins)}:${this.padZeroNotTwoDigits(secs)}`;
    }
    
    // it will add zero at the start when we don't have two digits in either case for hours,mins or secs
    padZeroNotTwoDigits = (t) => {
        return t.toString().padStart(2, '0');
    };
}

export default new DateUtil();