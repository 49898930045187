import React, { useRef, useState, useEffect, useContext } from 'react';

import SimBuilderGeneral from './SimBuilderGeneral';
import SimBuilderTabBar from './SimBuilderTabBar';
import SimBuilderCharacterTab from './character/SimBuilderCharacterTab';
import SimBuilderTrackTab from './track/SimBuilderTrackTab';
import SimBuilderMediaTab from './media/SimBuilderMediaTab';
import SimBuilderVariableTab from '../variable/SimBuilderVariableTab';
import { PlayerContext } from '../../context/PlayerContext';

const SimBuilder = (props) => {

    const [currentTab, setCurrentTab] = useState(props.currentTab ? 'tracks' : 'general');
    const tabIndexValue = useRef(props.currentTab ? 4 : 0);
    const { setIsPlayingFromTrack } = useContext(PlayerContext);

    useEffect(() => {
        setIsPlayingFromTrack(false);
    }, []);

    const showCurrentTab = () => {
        let tabContent = undefined;
        switch (currentTab.toLowerCase()) {
            case 'general':
                tabContent = <SimBuilderGeneral />;
                break;
            case 'characters':
                tabContent = <SimBuilderCharacterTab />;
                break;
            case 'tracks':
                tabContent = <SimBuilderTrackTab />;
                break;
            case 'media':
                tabContent = <SimBuilderMediaTab />;
                break;
            case 'variables':
                tabContent = <SimBuilderVariableTab />;
                break;
            default:
                tabContent = <h1>{currentTab}</h1>;
        }

        return tabContent;
    }

    return (
        <>
            <SimBuilderTabBar setCurrentTab={setCurrentTab} tabIndex={tabIndexValue.current} />
            {showCurrentTab()}
        </>
    )
}

export default SimBuilder;