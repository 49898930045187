import { useState, useContext } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  TextField,
  Box,
  Grid,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import LoaderComponent from "../loader";
import { base64ToImage } from "../../utils/utils";
import { EditSimContext } from "../../context/EditSimContext";
import { EditTrackContext } from "../../context/EditTrackContext";
import { MediaContext } from "../../context/MediaContext";
import LoaderWrapper from "./LoaderWrapper";
import AIService from "../../services/AIService";

const FormRow = ({ image, onChange, error, onEnter }) => {
  return (
    <Grid
      container
      item
      spacing={3}
      style={{
        margin: "15px",
        border: "1px solid grey",
        padding: "5px",
        borderRadius: "5px",
      }}
    >
      <Grid item xs={3} style={{ padding: "10px", margin: "10px" }}>
        <img src={image} className="gridImage" alt="generatedImages" />
      </Grid>
      <Grid item xs={6} style={{ marginTop: "35px" }}>
        <TextField
          required
          id="filled-basic"
          label="Name"
          variant="filled"
          fullWidth
          onBlur={onChange}
          onChange={onEnter}
          error={error}
          inputProps={{ maxLength: 250 }} // Set maxLength attribute
          helperText={error ? "Name is required" : ""}
        />
      </Grid>
    </Grid>
  );
};

const AIImagesNames = ({
  open,
  selectedImages,
  onCancel,
  isRadio,
  handleCloseAllPopups,
}) => {
  const [imageNames, setImageNames] = useState({});
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { editSimState } = useContext(EditSimContext);
  const { editTrackState, setEditTrackState } = useContext(EditTrackContext);
  const { addMediaList } = useContext(MediaContext);

  const onChange = () => {
    setErrors([]);
  };

  const onInputChange = (e, image) => {
    const value = e.target.value.trim(); // Trim to remove leading and trailing spaces

    // Check if the value is not empty
    if (value !== "") {
      // Update the imageNames state with the latest value for the image
      setImageNames((prevImageNames) => ({
        ...prevImageNames,
        [image]: value,
      }));
    } else {
      // If the value is empty, remove the image from the state
      setImageNames((prevImageNames) => {
        const { [image]: omit, ...updatedImageNames } = prevImageNames;
        return updatedImageNames;
      });
    }

    // Remove error when user starts typing
    if (errors.includes(image)) {
      setErrors(errors.filter((errorImage) => errorImage !== image));
    }
  };

  const handleSaveImage = async () => {
    setIsLoading(true);
    const newErrors = [];

    selectedImages.forEach((image) => {
      const imageExists = image in imageNames;
      if (!imageExists) {
        newErrors.push(image);
      }
    });

    setErrors(newErrors);

    if (newErrors.length === 0) {
      try {
        const imgaeFiles = base64ToImage(imageNames);
        await AIService().saveAIGeneratedImages(
          imgaeFiles,
          editSimState,
          isRadio,
          editTrackState,
          setEditTrackState,
          handleCloseAllPopups,
          addMediaList
        );
      } catch (error) {
        console.error("Error while saving images:", error);
      }
    }
    setIsLoading(false); // Make sure loading is turned off in case of errors as well
  };

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="md">
        <DialogTitle id="alert-dialog-title">Save Images</DialogTitle>
        <DialogContent>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
              {selectedImages.map((image, index) => (
                <FormRow
                  image={image}
                  onChange={(e) => onInputChange(e, image)}
                  key={index}
                  error={errors.includes(image)}
                  onEnter={onChange}
                />
              ))}
            </Grid>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            className="buttonStyle"
            onClick={handleSaveImage}
          >
            Save Images
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={onCancel}
            className="buttonStyle"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {isLoading && (
        <LoaderWrapper>
          <LoaderComponent />
        </LoaderWrapper>
      )}
    </>
  );
};

export default AIImagesNames;
