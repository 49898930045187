import React, { useState } from "react";

const ViewDoc = () => {
    const [simIdea] = useState(JSON.parse(localStorage.getItem('viewIdea')));

    const openFile = (document) => {
        window.open(document.pubUrl, "_blank");
    }

    return (
        <div className="card">
            <h3 className="card-header">Simulation Idea Documents</h3>
            <div className="card-body">
                <div className="row">
                    <div className="col-12 bg-heading">
                        <h4>Simulation Name: {simIdea.title}</h4>
                    </div>
                    {simIdea.documents === undefined &&
                        <>
                            <h4 className="mx-auto">No document exists!</h4>
                        </>
                    }
                    {simIdea.documents !== undefined &&
                        simIdea.documents.map((document) =>
                            <>
                                <div className="col-6 border-bottom">
                                    <h5>{document.fileName}</h5>
                                </div>
                                <div className="col-6 text-right pt-2 border-bottom">
                                    <button className="btn btn-primary" onClick={() => openFile(document)}>View</button>
                                </div>
                            </>
                        )
                    }
                </div>
            </div>
        </div >
    );
}

export default ViewDoc;