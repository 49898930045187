import React, { useContext, useEffect, useState } from 'react';
import { EditSimContext } from '../../../../context/EditSimContext';
import { EditTrackContext } from '../../../../context/EditTrackContext';
import { FormControl, InputLabel, Select, TextField } from '@material-ui/core';
import useExpandCollapse from "../../../../hooks/useExpandCollapse";
import { IsExpandAllContext } from "../../../../context/IsExpandAllContext";

const TrackVisibility = (props) => {
    const [isExpand, markup, toggleTrackVisibility] = useExpandCollapse({ vertical: false });
    //Used to get list of all tracks in sim
    const { editSimState } = useContext(EditSimContext);
    //Current track being edited
    const { editTrackState, setEditTrackState } = useContext(EditTrackContext);
    const [isValidSlideTimeOut, setIsValidSlideTimeOut] = useState(true);
    const isExpandALL = useContext(IsExpandAllContext);

    useEffect(() => {
        toggleTrackVisibility(isExpandALL);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isExpandALL]);

    const handleVisibilityChange = (e) => {
        const { name, value } = e.target;
        if (name === 'slideTimeout') {
            setEditTrackState({ ...editTrackState, [name]: value });
            if (value !== "" && value !== undefined && !props.simpleValidator.current.check(value, 'numeric|min:0,num')) {
                setIsValidSlideTimeOut(false);
                props.simpleValidator.current.showMessageFor('Slide Timeout');
            } else {
                setIsValidSlideTimeOut(true);
            }
        }
        else {
            setEditTrackState({ ...editTrackState, [name]: value });
        }
    }

    const trackVisibilityClass = isExpand ? "expand" : "collapse";

    return (
        <>
            <div className="heading">
                <h3>Track Visibility Settings</h3>
                {markup}
            </div>
            <div className={trackVisibilityClass}>
                <TextField
                    error={!isValidSlideTimeOut}
                    variant="outlined"
                    name='slideTimeout'
                    id='slideTimeout'
                    label="Slide Timeout (seconds to show slide)"
                    value={editTrackState.slideTimeout}
                    onChange={handleVisibilityChange}
                    helperText={props.simpleValidator.current.message('Slide Timeout', editTrackState.slideTimeout, 'numeric|min:0,num')}
                />

                <FormControl variant="outlined">
                    <InputLabel htmlFor="parent">Branch - Parent Track</InputLabel>
                    <Select
                        native
                        name="parent"
                        id="parent"
                        value={editTrackState.parent}
                        onChange={handleVisibilityChange}
                    >
                        <option aria-label="None" value="" />
                        {
                            editSimState.tracks.map((track) => {
                                return <option key={track.id} value={track.id}>{track.title}</option>;
                            })
                        }
                    </Select>
                </FormControl>
            </div>
        </>
    )
}

export default TrackVisibility;