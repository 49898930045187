import React, { useContext, useState } from 'react'
import SimBuilderCharacterRow from './SimBuilderCharacterRow';

import { CharacterContext } from '../../../context/CharacterContext';
import ConfirmationDilaog from '../../generic/ConfirmationDilaog';

const CharacterList = () => {

    const { characterState, selectCharacter, deleteCharacter } = useContext(CharacterContext);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [deleteId, setDeleteId] = useState(0);

    const handleSelectCharacter = (id) => {
        selectCharacter(id);
    }

    const handleClose = () => {
        setOpenConfirmDelete(false);
    }

    const handleDeleteCharacter = (id) => {
        setDeleteId(id);
        setOpenConfirmDelete(true);
    }

    const confirmDelete = () => {
        deleteCharacter(deleteId);
        handleClose();
    }

    return (
        <>
            <div className="simBuilder_actorList block-width">
                {
                    characterState.map((char) =>
                        <SimBuilderCharacterRow
                            key={char.charId} {...char}
                            handleSelectCharacter={handleSelectCharacter}
                            handleDeleteCharacter={handleDeleteCharacter}
                        />
                    )
                }
            </div>
            {openConfirmDelete &&
                <ConfirmationDilaog
                    msg="Are you sure you want to delete ?"
                    handleDelete={confirmDelete}
                    handleClose={handleClose}
                />
            }
        </>
    );
}

export default CharacterList;