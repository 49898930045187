import React, { useContext } from 'react';
import { EditTrackContext } from '../../../context/EditTrackContext';

import { FormControl, InputLabel, Select } from '@material-ui/core';
import { MediaContext } from '../../../context/MediaContext';

const TrackAudio = () => {

    //Used to get list of all tracks in sim
    const { sounds } = useContext(MediaContext);
    //Current track being edited
    const { editTrackState, setEditTrackState } = useContext(EditTrackContext);

    const handleSelectChange = (e) => {
        const { name, value } = e.target;

        setEditTrackState({
            ...editTrackState,
            pubAudioUrl: value !== "" ? sounds.find((audio) => audio.id === value).url : null,
            [name]: value !== "" ? value : null,
        });
    };

    return (
        <>
            <FormControl variant="outlined">
                <InputLabel htmlFor="responseType">Track Audio</InputLabel>
                <Select
                    native
                    name="audioURL"
                    id="audioURL"
                    value={editTrackState.audioURL ? editTrackState.audioURL : ""}
                    onChange={handleSelectChange}
                >
                    <option aria-label="None" value="" />
                    {
                        sounds.map((media) => {
                            return <option key={media.id} value={media.id}>{media.name}</option>;
                        })
                    }
                </Select>
            </FormControl>
        </>
    );
}

export default TrackAudio;