import React, { useContext, useState, useCallback } from "react";
import {
    AddCircle, CreateNewFolder, Menu, Edit, PlayCircleFilled, FileCopy,
    FolderSpecial, Delete, DeleteForever
} from '@material-ui/icons';
import { IconButton, ListItemIcon, ListItemText, Tooltip } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { LibraryContext } from "../context/LibraryContext";
import { EditSimContext } from '../context/EditSimContext';
import { UserContext } from "../context/UserContext";
import { PlayerContext } from "../context/PlayerContext";
import { SimFoldersContext } from "../context/SimFoldersContext";
import SimulationLibraryRow from './SimulationLibraryRow';
import DeleteSimDialog from "../../src/components/builder/CopySim/DeleteSimDialog";
import FolderDialog from "./folder/FolderDialog";
import ConfirmationDilaog from "./generic/ConfirmationDilaog";
import Alert from "../components/generic/Alerts";
import SimsFolderRow from "./SimsFolderRow";
import Loader from "./loader";
import CopyDialog from "./generic/CopyDialog";
import { isNullOrEmpty, StyledMenu, StyledMenuItem } from "../utils/utils";

const SimulationLibrary = () => {
    console.debug("Rendring Check: SimulationLibrary");
    const history = useHistory();
    const { token } = useContext(UserContext);
    const { sim, copySim, isLoading, getFullSim } = useContext(LibraryContext);
    const { resetEditSimContext, setSimToEdit } = useContext(EditSimContext);
    const { setSimToPlay, setLinkId, setShowFlashAlert, setIsPlayingFromTrack } = useContext(PlayerContext);
    const { removeSimFromFolder } = useContext(SimFoldersContext);

    const [openCopyDialog, setOpenCopyDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openFolderDialog, setOpenFolderDialog] = useState(false);
    const [openMoveSimDialog, setOpenMoveSimDialog] = useState(false);
    const [openRemoveFromFolderDialog, setOpenRemoveFromFolderDialog] = useState(false);
    const [selectedSim, setSelectedSim] = useState(null);
    const [openAlert, setOpenAlert] = useState(false);
    const [anchorEl, setAnchorEl] = useState(false);
    const [isFolderEdit, setIsFolderEdit] = useState(false);
    const initialMouseState = { mouseX: null, mouseY: null };
    const [mouseState, setMouseState] = useState(initialMouseState);

    const [alertProps] = useState({
        msg: "Sim link is generated and copied to clipboard successfully!",
        type: "success"
    });

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(false);
    };

    const addSim = () => {
        resetEditSimContext();
        history.push('/builder');
    };

    const addFolder = () => {
        setAnchorEl(false);
        setOpenFolderDialog(true);
    };

    const handleOpenCopyDialog = useCallback(() => {
        handleActionMenuClose();
        setOpenCopyDialog(true);
        // eslint-disable-next-line
    }, []);

    const handleCloseCopyDialog = () => {
        setOpenCopyDialog(false);
    }

    const handleOpenDeleteDialog = useCallback(() => {
        handleActionMenuClose();
        setOpenDeleteDialog(true);
        // eslint-disable-next-line
    }, []);

    const handleCloseFolderDialog = useCallback(() => {
        setOpenFolderDialog(false);
        setOpenMoveSimDialog(false);
    }, []);


    const handleRemoveFromFolder = () => {
        removeSimFromFolder(selectedSim.id, selectedSim.folderId);
        handleCloseRemoveFromFolderDialod();
    }

    const handleCloseRemoveFromFolderDialod = () => {
        setOpenRemoveFromFolderDialog(false);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    }

    const handleMoveToFolderDialog = useCallback(() => {
        handleActionMenuClose();
        setIsFolderEdit(true);
        setOpenMoveSimDialog(true);
        // eslint-disable-next-line
    }, []);

    const copySimAction = (title) => {
        copySim({ simId: selectedSim.simId, title: title });
    }

    const handleActionMenuClick = useCallback((event, props) => {
        event.preventDefault();
        setSelectedSim(props);
        setMouseState({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
        });
        // eslint-disable-next-line
    }, []);

    const handleActionMenuClose = () => {
        setMouseState(initialMouseState);
    };

    const setCurrentSim = () => {
        const sim = getFullSim(selectedSim.id);
        setIsPlayingFromTrack(false);
        setSimToEdit(sim, token);
        history.push("/builder");
    };

    const handleSimPlay = () => {
        try {
            localStorage.removeItem("playerState");
        } catch (error) {
            console.error(error.message);
        }
        const sim = getFullSim(selectedSim.id);
        setLinkId('');
        setIsPlayingFromTrack(false);
        setSimToPlay(sim);
        setShowFlashAlert(true);
        history.push("/play");
    };

    return (
        <>
            {isLoading &&
                <div className="overlay">
                    <Loader />
                </div>
            }
            <div className="sim_header">
                <Tooltip title="Menu" placement="bottom">
                    <IconButton onClick={handleMenu} >
                        <Menu color="primary" fontSize="large" />
                    </IconButton>
                </Tooltip>
                <input type="text" id="publicSimUrl" style={{ display: 'none' }} />
                <StyledMenu
                    id="addMenu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <StyledMenuItem onClick={addSim}>
                        <ListItemIcon>
                            <AddCircle fontSize="small" color="primary" />
                        </ListItemIcon>
                        <ListItemText primary="Add Sim" />
                    </StyledMenuItem>
                    <StyledMenuItem onClick={addFolder}>
                        <ListItemIcon>
                            <CreateNewFolder fontSize="small" color="primary" />
                        </ListItemIcon>
                        <ListItemText primary="Add Folder" />
                    </StyledMenuItem>
                </StyledMenu>
            </div>

            {openCopyDialog &&
                <CopyDialog
                    dialogTitle="Copy Sim"
                    id="copySim"
                    name="copySim"
                    lable="Sim Title"
                    type="text"
                    helperText="Title is required!"
                    cancel="Cancel"
                    save="Save"
                    handleCloseCopyDialog={handleCloseCopyDialog}
                    action={copySimAction}
                    check={(title) => !isNullOrEmpty(title)}
                    length={250}
                />
            }

            {openDeleteDialog &&
                <DeleteSimDialog
                    handleCloseDeleteDialog={handleCloseDeleteDialog}
                    currentSimId={selectedSim.simId}
                    currentSimTitle={selectedSim.title}
                />
            }

            {openFolderDialog &&
                <FolderDialog
                    handleCloseFolderDialog={handleCloseFolderDialog}
                    isFolderEdit={isFolderEdit}
                    setIsFolderEdit={setIsFolderEdit}
                    isMoveToFolder={false}
                />
            }

            {openMoveSimDialog &&
                <FolderDialog
                    simId={selectedSim.id}
                    simTitle={selectedSim.title}
                    handleCloseFolderDialog={handleCloseFolderDialog}
                    isFolderEdit={isFolderEdit}
                    setIsFolderEdit={setIsFolderEdit}
                    isMoveToFolder={true} />
            }

            {openRemoveFromFolderDialog &&
                <ConfirmationDilaog
                    msg={`<h4>Are you sure you want to remove <span class="text-secondary">${selectedSim.title}</span> from <span class="MuiButton-textSecondary">${selectedSim.folderName}</span> folder?</h4>`}
                    handleDelete={handleRemoveFromFolder}
                    handleClose={handleCloseRemoveFromFolderDialod}
                />
            }

            <SimsFolderRow
                handleOpenCopyDialog={handleOpenCopyDialog}
                handleOpenDeleteDialog={handleOpenDeleteDialog}
                setOpenAlert={setOpenAlert}
                setOpenRemoveFromFolderDialog={setOpenRemoveFromFolderDialog}
                setOpenFolderDialog={setOpenFolderDialog}
                setIsFolderEdit={setIsFolderEdit}
                handleActionMenuClick={handleActionMenuClick}
            />

            {sim.length > 0 && <h3 className="color-blue">Sims</h3>}
            {
                sim.map((sim) =>
                    <SimulationLibraryRow
                        key={sim.id}
                        {...sim}
                        handleOpenCopyDialog={handleOpenCopyDialog}
                        handleOpenDeleteDialog={handleOpenDeleteDialog}
                        setOpenAlert={setOpenAlert}
                        handleMoveToFolderDialog={handleMoveToFolderDialog}
                        isMoveToFolder={true}
                        isRemoveToFolder={false}
                        handleActionMenuClick={handleActionMenuClick}
                    />
                )
            }
            <StyledMenu
                keepMounted
                open={mouseState.mouseY !== null}
                onClose={handleActionMenuClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    mouseState.mouseY !== null && mouseState.mouseX !== null
                        ? { top: mouseState.mouseY, left: mouseState.mouseX }
                        : undefined
                }
            >
                <StyledMenuItem onClick={setCurrentSim}>
                    <ListItemIcon><Edit fontSize="small" color="primary" /></ListItemIcon>
                    <ListItemText primary="Edit Sim" />
                </StyledMenuItem>
                <StyledMenuItem onClick={handleSimPlay}>
                    <ListItemIcon><PlayCircleFilled fontSize="small" color="primary" /></ListItemIcon>
                    <ListItemText primary="Play Sim" />
                </StyledMenuItem>
                <StyledMenuItem onClick={handleOpenCopyDialog}>
                    <ListItemIcon><FileCopy fontSize="small" color="primary" /></ListItemIcon>
                    <ListItemText primary="Copy Sim" />
                </StyledMenuItem>
                {selectedSim && selectedSim.isMoveToFolder &&
                    <StyledMenuItem onClick={handleMoveToFolderDialog}>
                        <ListItemIcon><FolderSpecial fontSize="small" color="primary" /></ListItemIcon>
                        <ListItemText primary="Move To Folder" />
                    </StyledMenuItem>
                }
                {selectedSim && selectedSim.isRemoveToFolder &&
                    <StyledMenuItem
                        onClick={() => {
                            handleActionMenuClose();
                            setOpenRemoveFromFolderDialog(true);
                        }}>
                        <ListItemIcon><Delete fontSize="small" color="primary" /></ListItemIcon>
                        <ListItemText primary="Remove From Folder" />
                    </StyledMenuItem>
                }
                <StyledMenuItem onClick={handleOpenDeleteDialog}>
                    <ListItemIcon><DeleteForever fontSize="small" color="secondary" /></ListItemIcon>
                    <ListItemText primary="Delete Sim" />
                </StyledMenuItem>
            </StyledMenu>
            {openAlert && <Alert {...alertProps} openAlert={openAlert} setOpenAlert={setOpenAlert} />}
        </>
    );
}

export default SimulationLibrary;