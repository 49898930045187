import React, { useState, useContext, useEffect } from 'react';
import { FormControlLabel, Switch, FormControl, InputLabel, Select, TextField, FormHelperText } from '@material-ui/core';
import { EditSimContext } from '../../../../context/EditSimContext';
import { EditTrackContext } from '../../../../context/EditTrackContext';
import { isNullOrEmpty } from '../../../../utils/utils';
import AlertDialog from '../../../generic/CustomizeAlertDilaog';
import useExpandCollapse from "../../../../hooks/useExpandCollapse";
import { IsExpandAllContext } from "../../../../context/IsExpandAllContext";

const FlashSetting = (props) => {
    const [isExpand, markup, toggleFlashSetting] = useExpandCollapse({ vertical: false });
    const { editSimState } = useContext(EditSimContext);
    const { editTrackState, setEditTrackState, showValidationError } = useContext(EditTrackContext);
    const [flashDisabled, setFlashDisabled] = useState(false);
    const [alertDisabled, setAlertDisabled] = useState(false);
    const [isAlertFieldsShow, setIsAlertFieldsShow] = useState(false);
    const [alertChecked, setAlertChecked] = useState(false);
    const [showLocalError, setShowLocalErros] = useState(true);
    const [flashTrackWarning, setFlashTrackWarning] = useState(false);
    const [msg, setMsg] = useState("");
    const isExpandALL = useContext(IsExpandAllContext);

    useEffect(() => {
        toggleFlashSetting(isExpandALL);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isExpandALL]);

    const handleFlashCheckChange = (e) => {
        const { name, checked } = e.target;
        let msg = `<h4>Before disabling "Set Flash Track" for this track, please disable "Set Flash Alert"  or set "Flash Track" to another flash track for children's tracks (<span class='text-secondary'>`;
        if (name === "flashTrack") {
            if (!checked) {
                let isUesd = false;
                let tracks = "";
                editSimState.tracks.map((track) => {
                    if (!isNullOrEmpty(track.flashTrackId) && track.flashTrackId === editTrackState.id) {
                        tracks += track.order + ",";
                        isUesd = true;
                    }
                    return "";
                });

                if (isUesd) {
                    msg += tracks.slice(0, -1) + `</span>) before proceeding.</h4>`;
                    console.log(msg);
                    setFlashTrackWarning(true);
                    setMsg(msg);
                    return;
                }
            }
            setEditTrackState({ ...editTrackState, [name]: checked });
            setAlertDisabled(checked);
        } else if (name === "setAlert") {
            setAlertChecked(checked);
            setFlashDisabled(checked);
            setIsAlertFieldsShow(checked);
            if (!checked) {
                setShowLocalErros(false);
                // eslint-disable-next-line no-useless-computed-key
                setEditTrackState({ ...editTrackState, ["alertMessage"]: "", ["flashTrackId"]: "" });
            } else {
                setShowLocalErros(true);
            }
        }
    }

    const handleFlashChange = (e) => {
        const { name, value } = e.target;
        setEditTrackState({ ...editTrackState, [name]: value });
    };

    useEffect(() => {
        if (editTrackState.flashTrack) {
            setIsAlertFieldsShow(false);
            setAlertDisabled(true);
            setAlertChecked(false);
        } else if (editTrackState.alertMessage !== "" && editTrackState.flashTrackId !== "") {
            setFlashDisabled(true);
            setIsAlertFieldsShow(true);
            setAlertDisabled(false);
            setAlertChecked(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFlashTrackWarning = () => {
        setFlashTrackWarning(false);
    };

    const flashSettingClass = isExpand ? "expand" : "collapse";

    return (
        <>
            {props.simpleValidator.current.purgeFields()}
            <div className="heading">
                <h3>Flash Track Settings</h3>
                {markup}
            </div>
            <div className={flashSettingClass}>
                <div className="phone_toggles">
                    <FormControlLabel
                        control={
                            <Switch
                                id="flashTrack"
                                name="flashTrack"
                                disabled={flashDisabled}
                                checked={editTrackState.flashTrack}
                                onChange={handleFlashCheckChange}
                            />
                        }
                        label="Set Flash Track"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                id="setAlert"
                                name="setAlert"
                                disabled={alertDisabled}
                                checked={alertChecked}
                                onChange={handleFlashCheckChange}
                            />
                        }
                        label="Set Flash Alert"
                    />
                </div>
                {isAlertFieldsShow &&
                    <div className="visibility_setting_row">
                        <TextField
                            error={showValidationError && showLocalError && editTrackState.alertMessage === "" && true}
                            required
                            inputProps={{ maxLength: 250 }}
                            name="alertMessage"
                            id="alertMessage"
                            label="Alert Message"
                            variant="outlined"
                            value={editTrackState.alertMessage}
                            onChange={handleFlashChange}
                            helperText={props.simpleValidator.current.message('Alert Message', editTrackState.alertMessage, 'required')}
                        />
                        <FormControl
                            variant="outlined"
                            required>
                            <InputLabel
                                htmlFor="flashTrackId"
                                style={{ color: showValidationError && showLocalError && editTrackState.flashTrackId === "" ? "red" : "" }}
                            >Flash Track</InputLabel>
                            <Select
                                error={showValidationError && showLocalError && editTrackState.flashTrackId === "" && true}
                                native
                                name="flashTrackId"
                                id="flashTrackId"
                                value={editTrackState.flashTrackId}
                                onChange={handleFlashChange}
                            >
                                <option aria-label="None" value=""></option>
                                {!isNullOrEmpty(editSimState.tracks) &&
                                    editSimState.tracks.map((t, i) => {
                                        if (t.flashTrack && editTrackState.id !== t.id) {
                                            return (
                                                <option key={i} value={t.id} data={t.title}>
                                                    {t.title}
                                                </option>
                                            );
                                        }
                                        return "";
                                    })}
                            </Select>
                            <FormHelperText style={{ color: "red" }}>{props.simpleValidator.current.message('Flash Track', editTrackState.flashTrackId, 'required')}</FormHelperText>
                        </FormControl>
                    </div>
                }
                {flashTrackWarning &&
                    <AlertDialog
                        size="md"
                        isSticky={true}
                        textAlign={"text-left"}
                        title={"Children's Dependencies Detected!"}
                        msg={msg}
                        showInfoAction={true}
                        handleClose={handleFlashTrackWarning}
                    />
                }
            </div>
        </>
    )
}

export default FlashSetting;