import React from "react";

const Media = (props) => {
  return (
    <div className="row">
      <div className="col-sm-9">
        <h3>{props.name} ({props.type})</h3>
      </div>
      <div className="col-sm-3 text-right">
        <button tabIndex={0} className="btn btn-primary mt-2" onClick={() => props.getUrl(props.url)}>View</button>
      </div>
    </div>
  );
};

export default Media;