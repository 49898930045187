import React, { useState } from 'react';
import { IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmDialog from '../generic/ConfirmationDilaog';

const VariableListItem = (props) => {

    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [currentVariableId, setCurrentVariableId] = useState();
    const [msg, setMsg] = useState("");

    const handleVariableEdit = () => {
        props.handleVariableSelect(props.id);
    }

    const handleVariableConfirmation = (id, variableName) => {
        setCurrentVariableId(id);
        setMsg("<h4>Are you sure you want to delete <span class='text-secondary'>" + variableName + "</span> variable?");
        setOpenConfirmationDialog(true);
    };

    const handleVariableDelete = () => {
        props.handleVariableDelete(currentVariableId);
        handleClose();
    };

    const handleClose = () => {
        setOpenConfirmationDialog(false);
    }

    return (
        <>
            <div className="variableTab_list_item">
                <div className="flexCol">
                    <h5 className="name">{props.name}</h5>
                    <p className="desc">{props.desc}</p>
                    <p className="detail">{props.type && `Type: ${props.type} `}</p>
                    <p className="detail">{props.startVal && `Value: ${props.startVal} `}</p>
                </div>
                <div>
                    <IconButton onClick={handleVariableEdit}>
                        <EditIcon />
                    </IconButton>

                    <IconButton onClick={() => handleVariableConfirmation(props.id, props.name)}>
                        <DeleteIcon />
                    </IconButton>
                </div>
            </div>
            {openConfirmationDialog &&
                <ConfirmDialog
                    msg={msg}
                    handleDelete={handleVariableDelete}
                    handleClose={handleClose}
                />}
        </>
    );
}

export default VariableListItem;