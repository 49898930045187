import React, { useEffect, useContext } from "react";
import SlideTextDisplay from "./SlideTextDisplay";
import { PlayerContext } from "../../context/PlayerContext";
import AlertDialog from "../generic/CustomizeAlertDilaog";
import CustomizedNestedList from "../generic/CustomizedNestedList";
import { getMarginTop } from "../../utils/utils";

const TextTrack = (props) => {
  const { setShowPlayButton, showFlashAlert, isPlayingFromTrack } =
    useContext(PlayerContext);

  useEffect(() => {
    if (
      (props.alertMessage === "" && props.flashTrackId === "") ||
      !showFlashAlert
    ) {
      if (!props.audioURL && !props.debug) {
        if (props.slideTimeout && !props.flashTrack) {
          props.handleSlideTimeOut();
        }
      } else {
        if (props.audioURL !== undefined) {
          if (!props.debug) {
            const clip = document.getElementById("soundclip");
            if (props.slideTimeout && !props.flashTrack) {
              clip.onended = () => {
                props.handleSlideTimeOut();
              };
            }
            const playPromise = clip.play();

            if (playPromise !== undefined) {
              playPromise
                .then(function () {
                  console.debug("Audio play started in Text Track");
                  setShowPlayButton(false);
                  // Automatic playback started!
                })
                .catch(function (error) {
                  console.debug("Audio Play exception in Text Track:", error);
                  setShowPlayButton(true, error);
                });
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.play]);

  const handleGoToFlashTrack = () => {
    props.handleGoToFlashTrack();
  };

  return (
    <>
      {!props.isPub && (
        <h1 style={{ marginTop: getMarginTop(props.isPub, props.trackIdx) }}>
          Track: {props.order} -- {props.title} --{" "}
          {!props.isPub && !props.debug ? props.trackId : props.id}
        </h1>
      )}
      {props.visibilitySettings.length > 0 && isPlayingFromTrack && (
        <CustomizedNestedList
          text="Variables:"
          visibilitySettings={props.visibilitySettings}
        />
      )}
      <h1 style={{ marginTop: getMarginTop(props.isPub, props.trackIdx) }}>
        {props.header}
      </h1>

      <div className="texttrack_content">
        <SlideTextDisplay html={props.body} className="texttrack_text" />
        {props.trackImgURL && (
          <div className="imgContainer">
            <img src={props.pubTrackImageUrl} alt={props.title} />
          </div>
        )}
      </div>
      {props.audioURL && (
        <audio
          key={props.file + "_" + props.audioURL}
          id="soundclip"
          controls={props.debug}
          muted={props.isMuted}
        >
          {props.file && <source src={props.file} type="audio/mpeg" />}
          {props.audioURL && (
            <source src={props.pubAudioUrl} type="audio/mpeg" />
          )}
        </audio>
      )}
      {!props.debug &&
        !props.flashTrack &&
        showFlashAlert &&
        props.alertMessage !== "" &&
        props.flashTrackId !== "" && (
          <AlertDialog
            size="lg"
            isSticky={true}
            title={props.alertMessage}
            showGoToFlashTrack={true}
            handleGoToFlashTrack={handleGoToFlashTrack}
          />
        )}
    </>
  );
};

export default TextTrack;
