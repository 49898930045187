import React from "react";
import DateUtil from "../../utils/dateUtil";

const SimIdeas = () => {
    const simIdea = JSON.parse(localStorage.getItem('viewIdea'));
    return (
        <div className="card">
            <h3 className="card-header">View Simulation Idea</h3>
            <div className="card-body">
                <div className="row">
                    <div className="col-12 bg-heading mb-3">
                        <h4>General Info</h4>
                    </div>
                    <div className="col-2">
                        <p className="font-weight-bold">Creator Name:</p>
                    </div>
                    <div className="col-10">
                        <p>{simIdea.clientName}</p>
                    </div>
                    <div className="col-2">
                        <p className="font-weight-bold">Creator Email:</p>
                    </div>
                    <div className="col-10">
                        {simIdea.clientEmail}
                    </div>
                    <div className="col-2">
                        <p className="font-weight-bold">Sim Title:</p>
                    </div>
                    <div className="col-10">
                        {simIdea.title}
                    </div>
                    <div className="col-2">
                        <p className="font-weight-bold">Created Date:</p>
                    </div>
                    <div className="col-10">
                        {DateUtil.formatDate(new Date(simIdea.createdAt), "dd/mm/yyyy")}
                    </div>
                    <div className="col-2">
                        <p className="font-weight-bold">Description:</p>
                    </div>
                    <div className="col-10 text-justify">
                        {simIdea.desc}
                    </div>
                    <div className="col-12 bg-heading mb-3">
                        <h4>Tracks Info</h4>
                    </div>
                    {
                        simIdea.tracks && simIdea.tracks.map((track) =>
                            <>
                                <div className="col-2 border-bottom mb-2">
                                    <p className="font-weight-bold">Track {track.idx + 1}:</p>
                                </div>
                                <div className="col-10 border-bottom mb-2">
                                    <div className="row">
                                        <div className="col-2">
                                            <p className="font-weight-bold">Track Name:</p>
                                        </div>
                                        <div className="col-10">
                                            {track.name}
                                        </div>
                                        <div className="col-2">
                                            <p className="font-weight-bold">Track Type:</p>
                                        </div>
                                        <div className="col-10">
                                            {track.type}
                                        </div>
                                        <div className="col-2">
                                            <p className="font-weight-bold">Comments:</p>
                                        </div>
                                        <div className="col-10 text-justify">
                                            {track.comments}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default SimIdeas;