import { useEffect, useState } from "react";
import moment from "moment";

let startTime;

const TimeSpend = (props) => {
    const [timer, setTimer] = useState("00:00:00");

    props.getTimeFromTimer(timer);

    useEffect(() => {
        startTime = moment();
        const timerInterval = setInterval(function () {
            // Calculate the duration from start time to current time
            const duration = moment.duration(moment().diff(startTime));

            // Format the duration as HH:MM:SS
            const formattedTime = moment.utc(duration.as('milliseconds')).format('HH:mm:ss');

            setTimer(formattedTime);
        }, 1000);

        return () => {
            clearInterval(timerInterval)
        }
    }, []);

    return (
        <>
            {timer}
        </>
    )

}

export default TimeSpend;