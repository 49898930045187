import { SIM_BUILDER_API_END_POINT } from "../Config";
import RequestService, { HttpMethods } from "../utils/requestService";

const MediaService = () => {
    return Object.freeze({
        getMediasBySimId: async (simId, token) => {
            const options = RequestService.makeRequest("GET", token);

            const result = await fetch(SIM_BUILDER_API_END_POINT + '/medias/' + simId, options);
            const response = await result.json();

            return response;
        },
        addMedia: async (media, token) => {
            const options = RequestService.makeRequest("POST", token);
            options['body'] = JSON.stringify(media);

            const result = await fetch(SIM_BUILDER_API_END_POINT + '/medias', options);
            const response = await result.json();

            return response;
        },
        addMediaList: async (mediaList, token) => {
            const options = RequestService.makeRequest("POST", token);
            options['body'] = JSON.stringify(mediaList);
            const result = await fetch(SIM_BUILDER_API_END_POINT + '/medias/list', options);
            const response = await result.json();
            return response;
        },
        updateMedia: async (media, token) => {
            const options = RequestService.makeRequest(HttpMethods.PUT, token);
            options['body'] = JSON.stringify(media);

            const result = await fetch(SIM_BUILDER_API_END_POINT + '/medias', options);
            const response = await result.json();

            return response;
        },
        deleteMedia: async (id, token) => {
            const options = RequestService.makeRequest("DELETE", token);

            const result = await fetch(SIM_BUILDER_API_END_POINT + '/medias/' + id, options);
            const response = await result.json();

            return response;
        }
    });
}

export default MediaService;