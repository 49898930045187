import React, { createContext, useState, useMemo, useEffect, useContext } from 'react';
import { EditSimContext } from './EditSimContext';
import { UserContext } from './UserContext';
import CharacterService from "../services/CharacterService";

export const CharacterContext = createContext();

//Provide a cached list of characters for a simulation
//Automatically pull from DB as simEdit context chanegs
const CharacterContextProvider = (props) => {
    const { token } = useContext(UserContext);
    const { editSimState, setEditSimState } = useContext(EditSimContext);
    const [characterState, setCharacterState] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedCharacterState, setSelectedCharacterState] = useState({
        canAddNew: false,
        charId: null,
        id: null,
        simId: null,
        name: '',
        title: '',
        age: 0,
        desc: '',
        avatarFile: null,
        avatarChanged: false,
        audioFile: null,
        audioChanged: false,
    });

    const clearSelectedCharacterState = () => {
        setSelectedCharacterState({
            canAddNew: false,
            charId: null,
            id: null,
            simId: null,
            name: '',
            title: '',
            age: 0,
            desc: '',
            avatarFile: null,
            avatarChanged: false,
            audioFile: null,
            audioChanged: false,
        });
    }

    //Load from DB
    const loadContextFromDB = (id) => {
        CharacterService().getCharctersBySimId(id, token).then((response) => {
            if (response.status === 200) {
                setCharacterState(response.data);
                clearSelectedCharacterState();
            } else {
                console.log("Error: Could not load actors from DB!", response.message);
            }
        }).catch((e) => {
            console.log('Error: Could not load actors from DB!', e.message);
        });
    }

    const selectCharacter = (id) => {
        const selected = characterState.filter((c) => { return c.id === id });
        setSelectedCharacterState({ ...selected[0], audioChanged: false, avatarChanged: false });
    }

    const updateCharState = (char) => {
        const charList = characterState.map((a) => {
            return (a.id !== char.id ? a : char);
        });
        setCharacterState(charList);
    }

    const saveSelectedCharacter = () => {
        setLoading(true);
        if (!selectedCharacterState.canAddNew) {
            updateCharacter();
        } else {
            addCharacter();
        }
    }

    const addCharacter = () => {
        const { desc, id, name, age, title, avatarFile, audioFile } = selectedCharacterState;
        const avatarUrl = (avatarFile && avatarFile !== null) ? `${editSimState.id}/characters/${id}/${avatarFile.name}` : null;
        const audioUrl = (audioFile && audioFile !== null) ? `${editSimState.id}/characters/${id}/${audioFile.name}` : null;
        const charcterSet = { desc, id, name, age, title, simId: editSimState.id, avatarUrl, audioUrl };

        CharacterService().createCharcter(charcterSet, token).then((response) => {
            if (response.status === 200) {
                setCharacterState(characterState.concat({ ...response.data }));
                clearSelectedCharacterState();
                console.log('Success: Character added to DB');
                setLoading(false);
            } else {
                console.error('Error: while saving!', response.message);
            }
        }).catch((e) => {
            console.log('Error: could not save character', e.message);
        });
    }

    const updateCharacter = () => {
        const { charId, desc, id, name, age, title, simId, avatarFile, audioFile } = selectedCharacterState;
        const avatarUrl = (avatarFile && avatarFile !== null) ? `${editSimState.id}/characters/${id}/${avatarFile.name}` : null;
        const audioUrl = (audioFile && audioFile !== null) ? `${editSimState.id}/characters/${id}/${audioFile.name}` : null;
        const character = { charId, id, desc, name, age, title, simId, avatarUrl, audioUrl };
        CharacterService().updateCharcter(character, token).then((response) => {
            if (response.status === 200) {
                updateCharState({ ...selectedCharacterState, avatarUrl: response.data.avatarUrl, audioUrl: response.data.audioUrl });
                clearSelectedCharacterState();
                console.log('Success: Character updated in DB');
                setLoading(false);
            } else {
                console.error('Error: while saving!', response.message);
            }
        }).catch((e) => {
            console.log('Error: could not save character changes', e.message);
        })
    }

    const deleteCharacter = (id) => {
        setLoading(true);
        CharacterService().deleteCharacter(id, token).then((response) => {
            if (response.status === 200) {
                const charList = characterState.filter((a) => {
                    return a.id !== id;
                });
                setCharacterState(charList);
                setLoading(false);
                clearSelectedCharacterState();

            } else {
                console.log('Error: could not delete actor!', response.message)
            }
        }).catch((e) => {
            console.log('Error: could not delete actor!', e.message);
        })
    }


    //Attach to editSimState and load this subdomain for the variables state
    // updating this state as editSimState changes
    useEffect(() => {
        if (editSimState.id) {
            loadContextFromDB(editSimState.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editSimState, setEditSimState]);

    const characterStateProviderValue = useMemo(() => (
        {
            characterState,
            selectedCharacterState,
            selectCharacter,
            setSelectedCharacterState,
            clearSelectedCharacterState,
            saveSelectedCharacter,
            deleteCharacter,
            loading
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [characterState, setCharacterState, selectedCharacterState, setSelectedCharacterState, loading]
    );

    return (
        <CharacterContext.Provider value={characterStateProviderValue} >
            {props.children}
        </CharacterContext.Provider>
    );
}

export default CharacterContextProvider;