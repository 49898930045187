import React, { useContext } from 'react';

import { Dialog, DialogTitle, ListItem, List, ListItemText, DialogActions, Button } from "@material-ui/core"
import { EditSimContext } from '../../../context/EditSimContext';
import { EditTrackContext } from '../../../context/EditTrackContext';

const NewTrackDialog = (props) => {

    const { addTrack } = useContext(EditSimContext);

    const { setEditTrackState, setPrevTrackState } = useContext(EditTrackContext);

    const handleClose = () => {
        props.handleCloseDiag();
    }

    const handleSelectItem = async (val) => {
        await addTrack(val, (track) => {
            setEditTrackState({ ...track });
            setPrevTrackState({ ...track });
            props.handleCloseDiag();
        });
    }


    const options = [{ label: 'Text Track', val: 'text' },
    { label: 'Video Track', val: 'video' },
    { label: 'Email Track', val: 'email' },
    { label: 'SMS Track', val: 'sms' },
    { label: 'Phone Track', val: 'phone' },
    { label: 'PDF Track', val: 'pdf' },
    { label: 'Free Text Response', val: 'freetext' },
    { label: 'Question Response', val: 'question' },
    { label: 'Scorecard Track', val: 'scorecard' }
    ];
    return (
        <>
            <Dialog open={true}>
                <DialogTitle id="simple-dialog-title">Select A Track Type</DialogTitle>
                <List>
                    {
                        options.map((opt) => {
                            return (
                                <ListItem dense button name={opt.val} key={opt.val} onClick={() => handleSelectItem(opt.val)} className="dialogListItem">
                                    <ListItemText>{opt.label}</ListItemText>
                                </ListItem>
                            );
                        })
                    }

                </List>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default NewTrackDialog;