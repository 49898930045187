import React, { memo } from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import LinkIcon from '@material-ui/icons/InsertLink';
import { IconButton, Tooltip } from "@material-ui/core";
import { compareObjetcs, copyTextToClipBoard } from "../utils/utils";

const SimulationLibraryRow = (props) => {
  console.debug("Rendring Check: SimulationLibraryRow");
  const createSimPublicLink = () => {
    const publicSimUrl = window.location.origin + "/SSO?s=" + props.id;
    props.setOpenAlert(copyTextToClipBoard(publicSimUrl, "publicSimUrl"));
  }

  return (
    <>
      <div className="sim_row">
        <div className="sim_row_details">
          <h5 className="sim_row_title">
            {props.title}
            {/* commented for future use
            {props.time !== 0 &&
              <span className="sim_row_time">{`~ ${props.time} minutes`}</span>
            } */}
          </h5>
          <p className="sim_row_description">{props.desc}</p>
        </div>
        <div className="sim_row_actions">
          {!props.published ?
            <Tooltip title="Create Link" placement="top">
              <IconButton onClick={createSimPublicLink} aria-label="Create Link" disabled={props.published ? true : false}>
                <LinkIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            :
            <IconButton onClick={createSimPublicLink} aria-label="Create Link" disabled={props.published ? true : false}>
              <LinkIcon fontSize="small" />
            </IconButton>
          }

          <div onClick={(event) => props.handleActionMenuClick(event, props)}>
            <Tooltip title="Sim Menu" placement="top">
              <IconButton>
                <MoreVertIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(SimulationLibraryRow, compareObjetcs);