import React, { useEffect, useState, useContext, useMemo } from "react";
import { Button, Grid } from "@material-ui/core";
import SlideTextDisplay from "./SlideTextDisplay";
import QuestionAnswer from "../../components/player/QuestionAnswer";
import Slider from "../generic/Slider";
import { PlayerContext } from "../../context/PlayerContext";
import AlertDialog from "../generic/CustomizeAlertDilaog";
import CustomizedNestedList from "../generic/CustomizedNestedList";
import dateUtil from "../../utils/dateUtil";
import { getMarginTop } from "../../utils/utils";

const QuestionResponseTrack = (props) => {
  const [picked, setPicked] = useState(false);
  const [marks, setMarks] = useState([]);
  const [sliderValue, setSliderValue] = useState(null);
  const { setShowPlayButton, showFlashAlert, isPlayingFromTrack } =
    useContext(PlayerContext);
  const [isAudioPlayed, setIsAudioPlayed] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [timeForResponse, setTimeForResponse] = useState("");
  const [decisionTimeStarted, setDecisionTimeStarted] = useState("");

  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds((prevTime) => prevTime + 1000);
      setDecisionTimeStarted(props.timer);
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const conditionalRenderingOfCssClass = () => {
    let cssClass = "";
    if (props?.trackIdx || props?.trackIdx == 0) {
      if (props?.isPub) {
        cssClass = "submit-btn-align";
      } else {
        cssClass = "submit-btn-align-unpublish";
      }
    }
    return cssClass;
  };

  const displayOptions = useMemo(() => {
    const availableOptions =
      props.displayOptions && props.displayOptions.length > 0
        ? props.displayOptions
        : props.options;
    let unAvailableOptions = [];
    if (props.checkUnavailableOptions) {
      unAvailableOptions = props.options
        .filter((option) => {
          return !availableOptions.some(
            (availableOption) => availableOption.id === option.id
          );
        })
        .map((option) => ({ ...option, unAvail: true }));
    }
    const showOptions = availableOptions.concat(unAvailableOptions);
    return showOptions.sort((a, b) => {
      return a.optionOrder - b.optionOrder;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.options]);

  useEffect(() => {
    if (
      (props.alertMessage === "" && props.flashTrackId === "") ||
      !showFlashAlert
    ) {
      if (props.audioURL) {
        if (!props.debug) {
          const clip = document.getElementById("soundclip");

          if (!isAudioPlayed) {
            const playPromise = clip.play();
            if (playPromise !== undefined) {
              playPromise
                .then(function () {
                  console.debug(
                    "Audio play started in QuestionResponse Track:"
                  );
                  setIsAudioPlayed(true);
                  setShowPlayButton(false);
                  // Automatic playback started!
                })
                .catch(function (error) {
                  console.debug(
                    "Audio Play exception in QuestionResponse Track::",
                    error
                  );
                  setShowPlayButton(true, error);
                });
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.play]);

  useEffect(() => {
    if (props.responseType === "likerScale") {
      let newMark = [];
      if (displayOptions !== undefined) {
        displayOptions.forEach((option, i) => {
          newMark.push({
            value: i + 1,
            label: option.response,
            unAvail: option.unAvail,
          });
        });
      }
      setMarks(newMark);
    }
  }, [props.responseType, displayOptions, props.body]);

  const handleSliderChange = (sliderValue) => {
    setSliderValue(sliderValue - 1);
    setPicked(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let checked = [];
    let feedback = [];
    let responseOptionWithId = [];
    let timeForResponse = dateUtil.toHoursMinsAndSeconds(seconds);
    let decisionTimeEnded = props.timer;
    setTimeForResponse(timeForResponse);
    if (props.responseType !== "likerScale") {
      document
        .querySelectorAll("input[name=answer]:checked")
        .forEach((checkedOption) => {
          checked.push(checkedOption.value);
          feedback.push(checkedOption.getAttribute("feedback"));
          responseOptionWithId.push({
            option: checkedOption.value,
            id: checkedOption.id,
          });
        });
    } else {
      let option = displayOptions[sliderValue];
      checked.push(option.response);
      feedback.push(option.feedback);
      responseOptionWithId.push({ option: option.response, id: option.id });
    }

    if (!props.debug) {
      props.handleNextSlideWithAddAnswer(
        checked,
        feedback,
        timeForResponse,
        decisionTimeStarted,
        decisionTimeEnded,
        responseOptionWithId
      );
    } else {
      alert(checked);
      alert(feedback);
      alert(timeForResponse);
    }
  };

  const handleGoToFlashTrack = () => {
    props.handleGoToFlashTrack();
  };

  const conditionalRenderingOfLikerScale = () => {
    let likerScaleCss = "";
    if (props.responseType === "likerScale") {
      if (props.trackImgURL) {
        likerScaleCss = "liker_scale_with_img";
      } else {
        likerScaleCss = "liker_scale";
      }
    }
    return likerScaleCss;
  };

  return (
    <>
      {!props.isPub && (
        <h1
          style={{ marginTop: getMarginTop(props.isPub, props.trackIdx) }}
        >
          Track: {props.order} -- {props.title} --{" "}
          {!props.isPub && !props.debug ? props.trackId : props.id}
        </h1>
      )}
      <h3 style={{ marginTop: getMarginTop(props.isPub, props.trackIdx) }}>
        {props.header}
      </h3>
      <div className={props.trackImgURL ? "question-track" : "d-flex"}>
        <div
          className={
            props.trackImgURL
              ? "player_inner_conten_with_image"
              : "player_inner_content"
          }
        >
          <SlideTextDisplay html={props.promptDetails} />

          {props.visibilitySettings.length > 0 && isPlayingFromTrack && (
            <CustomizedNestedList
              text="Variables:"
              visibilitySettings={props.visibilitySettings}
            />
          )}
          <h3>{props.prompt}</h3>
          <form
            onSubmit={handleSubmit}
            className={conditionalRenderingOfLikerScale()}
          >
            {displayOptions &&
              props.responseType !== "likerScale" &&
              displayOptions.map((option, i) => {
                return (
                  <QuestionAnswer
                    key={option + "_" + i}
                    option={option}
                    responseType={props.responseType}
                    setPicked={setPicked}
                  />
                );
              })}
            {displayOptions && props.responseType === "likerScale" && (
              <Slider
                responseType={props.responseType}
                step={1}
                sliderLabels={marks}
                defaultValue={0}
                valueLabelDisplay="off"
                minRange={1}
                maxRange={marks.length}
                handleSliderChange={handleSliderChange}
              />
            )}
            <br />
            <br />
            {!props.flashTrack && (
              <Button
                type="submit"
                color="primary"
                variant="outlined"
                disabled={!picked}
                className={conditionalRenderingOfCssClass()}
              >
                Submit
              </Button>
            )}
          </form>
          {props.audioURL && (
            <audio
              key={props.file + "_" + props.audioURL}
              id="soundclip"
              controls={props.debug}
              muted={props.isMuted}
            >
              {props.file && <source src={props.file} type="audio/mpeg" />}
              {props.audioURL && (
                <source src={props.pubAudioUrl} type="audio/mpeg" />
              )}
            </audio>
          )}
        </div>
        {props.trackImgURL && (
          <div className="imgContainer">
            <img src={props.pubTrackImageUrl} alt={props.title} />
          </div>
        )}
      </div>
      {!props.debug &&
        !props.flashTrack &&
        showFlashAlert &&
        props.alertMessage !== "" &&
        props.flashTrackId !== "" && (
          <AlertDialog
            size="lg"
            isSticky={true}
            title={props.alertMessage}
            showGoToFlashTrack={true}
            handleGoToFlashTrack={handleGoToFlashTrack}
          />
        )}
    </>
  );
};

export default QuestionResponseTrack;
