import React, { useEffect, useState } from "react";
import { Slider } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

const SliderComponent = (props) => {
    const useStyles = makeStyles((theme) => ({
        markLabel: {
            width: 'min-content !important',
            whiteSpace: "unset !important"
        },
    }))();
    const useStylesForScoreCard = makeStyles((theme) => ({
        markLabel: {
            transform: "none !important"
        },
    }))();

    const [unAvailableOptions, setUnAvailableOptions] = useState([]);
    const [value, setValue] = useState(props.defaultValue);

    useEffect(() => {
        let unSelectedOptions = [];
        props.sliderLabels.forEach(option => {
            if (option.unAvail) {
                unSelectedOptions.push(option.value);
            }
        });
        setUnAvailableOptions(unSelectedOptions);
    },
        [props.sliderLabels]);

    const handleSliderChange = (event, newValue) => {
        if (props.handleSliderChange !== undefined && !unAvailableOptions.includes(newValue)) {
            props.handleSliderChange(newValue);
            setValue(newValue);
        }
    };

    return (
        <Slider
            min={parseInt(props.minRange)}
            max={parseInt(props.maxRange)}
            marks={props.sliderLabels}
            valueLabelDisplay={props.valueLabelDisplay}
            value={value}
            onChange={handleSliderChange}
            classes={props.responseType ?
                {
                    markLabel: useStyles.markLabel,
                } :
                {
                    markLabel: useStylesForScoreCard.markLabel
                }
            }
        />
    );
};

export default SliderComponent;