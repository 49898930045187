import { Box, Button, LinearProgress, Typography } from "@material-ui/core";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { StyledDropZone } from "react-drop-zone";
import { getAwsSignedUrlToUpload } from "../../services/AwsBackendServices";
import S3Upload from "react-s3-uploader/s3upload.js";
import { UserContext } from "../../context/UserContext";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

const styles = {
  mainContainer: {
    margin: 10,
    paddingBottom: 0,
  },
  button: {
    margin: 0,
  },
  progressContainer: {
    marginTop: 5,
  },
  input: {
    display: "none",
  },
  label: {
    margin: 0,
    padding: 0,
  },
  error: {
    color: "#f44336",
    fontSize: "0.75rem",
    marginTop: 3,
    fontWeight: 400,
    lineHeight: 1.66,
    textAlign: "left",
    letterSpacing: " 0.03333em",
  },
};

export const FileType = Object.freeze({
  IMAGE: "image/*",
  AUDIO: "audio/*",
  MEDIA_ALL: "video/mp4,audio/*,image/*,application/pdf,text/vtt",
  CABINET_FILES:
    ".pdf,.doc,.docx,.xlsx,audio/mp3,audio/m4a,video/mp4,video/m4v,image/png,image/jpg,image/jpeg",
  ZIP: ".zip",
  XLS: ".xlsx, .xls",
});

const FileUploader = forwardRef((props, ref) => {
  const fileType = props.fileType;
  // Get user auth jwt token for authorization
  const { token } = useContext(UserContext);

  const [isUploading, setIsUploading] = useState(false);
  const [fileName, setFileName] = useState("No file chosen");
  const [progressPercentage, setprogressPercentage] = useState(0);
  const [progressMessage, setprogressMessage] = useState("");
  const [error, setError] = useState("");
  const maxFileSize = 1000000 * 30; // 30 MB
  const maxVideoSize = 1000000 * 60; //60 MB

  let fileUploadPath = useImperativeHandle(ref, () => ({
    startUploading(path, file) {
      fileUploadPath = path;
      uploadFile(file);
    },
  }));

  let showProgress = progressPercentage > 0 && progressPercentage < 100;

  useEffect(() => {
    if (props.errorMsg) {
      setError(!_.isEmpty(props.errorMsg) ? props.errorMsg : "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const getSignedUrl = (file, callback) => {
    const path = `${fileUploadPath}${file.name}`;
    getAwsSignedUrlToUpload(props.userId, path, file, token, callback, () => {
      setIsUploading(false);
      setError(`Error while uploading...`);
    });
  };

  const onFinish = (data) => {
    setIsUploading(false);
    setprogressMessage("");
    setFileName("No file chosen");
    props.onFinish(data);
  };

  const onError = (message) => {
    console.debug("Error :", message);
    setError(`Error while uploading: ${message}`);
    setIsUploading(false);
    setprogressMessage("");
  };

  const uploadFile = async (file) => {
    new S3Upload({
      files: [file],
      getSignedUrl,
      onProgress: onProgress,
      onFinishS3Put: onFinish,
      preprocess: preprocess,
      onError: onError,
      uploadRequestHeaders: {},
    });
  };

  const preprocess = (file, next) => {
    if (file.size > maxVideoSize && file.type === "video/mp4") {
      setIsUploading(false);
      console.debug("File size is larger than 60MB");
      setprogressMessage(
        "File size is too big. Please choose video under 60MB."
      );
    } else if (file.size > maxFileSize) {
      setIsUploading(false);
      setprogressMessage(
        "File size is too big. Please choose file under 30MB."
      );
    } else {
      setError("");
      setprogressMessage("");
      return next(file);
    }
  };

  const onProgress = (percent, message) => {
    setprogressPercentage(percent);
    setprogressMessage(message);
    if (!isUploading && percent < 100) {
      setIsUploading(true);
    }

    if (props.onProgressChanged) {
      props.onProgressChanged(percent);
    }
  };

  const onFileSelected = async (file, text) => {
    setError("");
    setFileName(file.name);
    const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
    const uniqueName = `${uuidv4()}.${extension}`;
    const myFile = new File([file], uniqueName, { type: file.type });
    props.onFileSelected(myFile);
    props?.setSizeError && props.setSizeError("");

    if (file.size > maxVideoSize && file.type === "video/mp4") {
      setError("File size is too big. Please choose file under 60MB.");
      props?.setSizeError &&
        props.setSizeError(
          "File size is too big. Please choose file under 60MB."
        );
    } else if (file.size > maxFileSize) {
      setError("File size is too big. Please choose file under 30MB.");
      props?.setSizeError &&
        props.setSizeError(
          "File size is too big. Please choose file under 30MB."
        );
    }
  };

  return (
    <div style={styles.mainContainer} disabled={true}>
      {!showProgress && (
        <StyledDropZone
          style={{ width: "fit-content" }}
          onDrop={onFileSelected}
          accept={fileType}
        >
          <Button
            variant="contained"
            color="secondary"
            style={styles.button}
            startIcon={props.icon}
            component="span"
          >
            {props.title}
          </Button>
          {/* <p>Click or drop your image here!</p> */}
        </StyledDropZone>
      )}

      <Box minWidth={300}>
        <Typography
          variant="body2"
          color="textSecondary"
          className="break-word"
        >
          {fileName}
        </Typography>
      </Box>
      {
        <Box minWidth={300}>
          <Typography
            variant="body2"
            color="textSecondary"
            style={styles.error}
          >
            {error}
          </Typography>
        </Box>
      }
      {showProgress && (
        <div style={styles.progressContainer}>
          <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
              <LinearProgress
                variant="determinate"
                value={progressPercentage}
              />
            </Box>
            <Box minWidth={35}>
              <Typography variant="body2" color="textSecondary">{`${Math.round(
                progressPercentage
              )}%`}</Typography>
            </Box>
          </Box>
          {showProgress && !_.isEmpty(progressMessage) && (
            <Typography
              variant="body2"
              color="textSecondary"
            >{`${progressMessage} ...`}</Typography>
          )}
        </div>
      )}
    </div>
  );
});

export default FileUploader;
