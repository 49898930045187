import React, { useState } from "react";
import { IconButton, Select, TextField, FormControl, InputLabel } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import Tooltip from '@material-ui/core/Tooltip';

const SimWizardRow = (props) => {
  const [rowState, setRowState] = useState({
    idx: props.idx,
    id: props.id,
    name: props.name,
    comments: props.comments,
    type: props.type,
    isAdminAdded: props.isAdminAdded
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRowState({ ...rowState, [name]: value });
    props.handleChange(name, value, rowState.idx);
  };

  const handleSelectChange = (event) => {
    const name = event.target.name;
    setRowState({ ...rowState, [name]: event.target.value });
    props.handleChange(name, event.target.value, rowState.idx);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h3 className="text-left">
        Track #{rowState.idx + 1} <span className="sim_row_time" style={{ fontSize: 'small' }}>{rowState.isAdminAdded ? '~ Added by admin' : ''}</span>
        {rowState.idx + 1 > props.totalTracks && (
          <Tooltip title="Delete Track" placement="bottom">
            <IconButton className="float-right" onClick={() => props.handleDelete(rowState.idx)} id={rowState.idx} name={rowState.idx}>
              <Delete color="secondary" fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
      </h3>
      <TextField
        error={props.showError && rowState.name === '' && true}
        variant="outlined"
        required
        id="name"
        name="name"
        label="Name"
        value={rowState.name}
        onChange={handleChange}
        size={"small"}
        style={{ marginBottom: 15, fontSize: "12%" }}
        inputProps={{ maxLength: 250 }}
        helperText={props.simpleValidator.current.message('track name', rowState.name, 'required')}
      />
      <FormControl variant="outlined" style={{ marginBottom: 15 }}>
        <InputLabel htmlFor="type">Response</InputLabel>
        <Select
          native
          name="type"
          id="type"
          label="Response"
          onChange={handleSelectChange}
          value={rowState.type}
        >
          <option aria-label="Text" value="text">
            Text
          </option>
          <option aria-label="Video" value="video">
            Video
          </option>
          <option aria-label="Email" value="email">
            Email
          </option>
          <option aria-label="SMS" value="sms">
            SMS
          </option>
          <option aria-label="Phone Call" value="phone">
            Phone Call
          </option>
          <option aria-label="PDF Display" value="pdf">
            PDF Display
          </option>
          <option aria-label="Free Text Response" value="freetext">
            Free Text Response
          </option>
          <option aria-label="Question Response" value="question">
            Question Response
          </option>
          <option aria-label="Score Card" value="scorecard">
            Score Card
          </option>
        </Select>
      </FormControl>
      <TextField
        variant="outlined"
        id="comments"
        name="comments"
        label="Comments"
        size={"small"}
        value={rowState.comments}
        onChange={handleChange}
        minRows={5}
        maxRows={7}
        multiline={true}
        inputProps={{ maxLength: 5000 }}
      />
    </div>
  );
};

export default SimWizardRow;
