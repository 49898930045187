import React, { Fragment, useContext, useEffect, useState } from "react";
import {
    TextField,
    FormControl,
    FormControlLabel,
    InputLabel,
    Select,
    IconButton,
    Switch,
    Typography
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import SimBuilderTrackGeneral from "./SimBuilderTrackGeneral";
import SimBuilderTrackVisibility from "./SimBuilderTrackVisibility";
import SimBuilderTrackConditionalVisibility from "./SimBuilderTrackConditionalVisibility";
import SimBuilderOptionVisibility from "./SimBuilderOptionVisibility";
import SimBuilderTrackFormActions from "./SimBuilderTrackFormActions";
import FlashSetting from "./SimBuilderFlashSetting";
import { EditTrackContext } from "../../../../context/EditTrackContext";
import { UserContext } from "../../../../context/UserContext";
import TrackService from "../../../../services/TrackService";
import SimBuilderResponseEffect from "./SimBuilderResponseEffect";
import SimBuilderResponseLoopEffect from "./SimBuilderResponseLoopEffect";
import Editor, { defultEditorText } from "../../../third-party/Editor";
import ConfirmationDilaog from "../../../generic/ConfirmationDilaog";
import Alert from "../../../generic/Alerts";
import { EditSimContext } from "../../../../context/EditSimContext";
import { HtmlTooltip, isNullOrEmpty } from "../../../../utils/utils";
import { MuiThemeProvider } from '@material-ui/core/styles';
import formLabelsTheme from '../../../generic/FormLabelsThem';
import { useDrop } from "react-dnd";
import ResponseOptionList from "./ResponseOptionList";
import DragAndDropService from "../../../../services/DragAndDropService";
import FunctionService from "../../../../services/FunctionService";
import useExpandCollapse from "../../../../hooks/useExpandCollapse";
import { IsExpandAllContext } from "../../../../context/IsExpandAllContext";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import TrackImage from "../SimBuilderTrackImage";

const QuestionTrackEdit = (props) => {
    const [isExpandSlideControl, markupSlideControl, toggleExpandSlideControl] = useExpandCollapse({ vertical: false });
    const [isExpand, markup, toggleResponseOption] = useExpandCollapse({ vertical: false });
    const { editTrackState, setEditTrackState, prevTrackState, setPrevTrackState } = useContext(EditTrackContext);
    const { editSimState, setEditSimState, updateOptionsOrder } = useContext(EditSimContext);
    const { token } = useContext(UserContext);

    const [editFeedbackId, setEditFeedbackId] = useState(null);

    const [info, setInfo] = useState("");
    const [feedback, setFeedback] = useState("");
    const [response, setResponse] = useState("");

    const [msg, setMsg] = useState("");
    const [openCnfDia, setOpenCnfDia] = useState(false);
    const [currentOption, setCurrentOption] = useState();
    const [currentEffect, setCurrentEffect] = useState();
    const [currentLoop, setCurrentLoop] = useState();
    const [currentVS, setCurrentVS] = useState();
    const [currentOV, setCurrentOV] = useState();

    const [alertProps, setAlertProps] = useState({ msg: "", type: "" });
    const [openAlert, setOpenAlert] = useState(false);
    const [handleDelete, setHandleDelete] = useState("");

    // for validation of response options
    const [optionErrorFlag, setOptionErrorFlag] = useState(false);

    const optionList = isNullOrEmpty(editTrackState.options) ? [] : [...editTrackState.options];

    const [options, setOptions] = useState(optionList);

    const [openConfirmTrackChanges, setOpenConfirmTrackChanges] = useState(false);

    const confirmTrackChangesHandler = (setOpenConfirmTrackChanges) => {
        setOpenConfirmTrackChanges(prevState => !prevState);
    };
    const isExpandALL = useContext(IsExpandAllContext);

    useEffect(() => {
        toggleExpandSlideControl(isExpandALL);
        toggleResponseOption(isExpandALL);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isExpandALL]);

    useEffect(() => {
        props.isExpand(markupSlideControl);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isExpandSlideControl]);

    useEffect(() => {
        return () => { props.isExpand("") }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setOptions([...editTrackState.options]);
    }, [editTrackState.options]);

    const [, drop] = useDrop({
        accept: "option",
        drop(item, monitor) {
            if (item.listIndex === item.prevIndex || item.prevIndex === "") {
                return;
            }
            confirmTrackChangesHandler(setOpenConfirmTrackChanges);
        },
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditTrackState({ ...editTrackState, [name]: value });
    };

    const resetResponse = () => {
        setResponse("");
        setFeedback("");
        setInfo("");
        setEditFeedbackId(null);
        setOptionErrorFlag(false);
    };

    const addResponse = () => {
        if (!isNullOrEmpty(response) && !isNullOrEmpty(feedback)) {
            const resp = {
                response: response,
                feedback: feedback,
                info: info,
                optionOrder: editTrackState.options[editFeedbackId] ?
                    editTrackState.options[editFeedbackId].optionOrder : editTrackState.options.length + 1,
            };
            //Get array or create a new one if not found
            const respArr = !isNullOrEmpty(editTrackState.options) ? [...editTrackState.options] : [];

            if (editFeedbackId === null) {
                setEditTrackState({ ...editTrackState, options: respArr.concat(resp) });
            } else {
                let oldResp = respArr[editFeedbackId];

                let isUpdated = "0";
                if (editTrackState.body && editTrackState.body === "0") {
                    isUpdated = "1";
                }
                respArr.forEach((s, i) => {
                    if (i === editFeedbackId) {
                        respArr[i] = { ...s, ...resp };
                    }
                });

                //updating response effect setting when response option updated
                const respSettingArr = !isNullOrEmpty(editTrackState.responseEffects) ? editTrackState.responseEffects : [];

                if (!isNullOrEmpty(editTrackState.responseEffects)) {
                    editTrackState.responseEffects.forEach((s, i) => {
                        if (oldResp.response === s.resp) {
                            respSettingArr[i] = { ...s, resp: resp.response }
                        }
                    });
                }

                if (!isNullOrEmpty(editTrackState.responseLoops)) {
                    editTrackState.responseLoops.forEach(responseLoop => {
                        if (responseLoop.resp === oldResp.response) {
                            responseLoop.resp = resp.response;
                        }
                    });
                }

                if (!isNullOrEmpty(editTrackState.optionVisibilitySettings)) {
                    editTrackState.optionVisibilitySettings.forEach(ovSetting => {
                        if (ovSetting.optionId.name === oldResp.response) {
                            ovSetting.optionId.name = resp.response;
                        }
                    });
                }

                setEditTrackState({ ...editTrackState, options: respArr, responseEffects: respSettingArr, body: isUpdated });
            }
            resetResponse();
        } else {
            setOptionErrorFlag(true);
        }
    };

    const cancelEdit = () => {
        resetResponse();
    };

    const editResponse = (index, response, feedback, info) => {
        setResponse(response);
        setFeedback(feedback);
        setInfo(info);
        setEditFeedbackId(index);
    };

    const handleConfirmation = (index) => {
        setCurrentOption(index);
        const selectedOption = editTrackState.options[index];

        setMsg("<h4>Are you sure you want to delete <span class='text-secondary'>" + selectedOption.response + "</span> Response Option?");
        setHandleDelete("deleteResponse");
        setOpenCnfDia(true);
    };

    const handleClose = () => {
        setOpenCnfDia(false);
    };

    const removeValuesFromOVS = (ovs, value) => {
        let tempOvs = ovs.filter(function (ov) {
            return ov.optionId.value !== value;
        });

        return tempOvs;
    };

    const deleteResponse = () => {
        const selectedOption = editTrackState.options[currentOption];
        let optionOrder = 0;

        if (selectedOption.id && selectedOption.id !== null && selectedOption.id !== "") {
            const trackByIdPredicate = (track) => track.id === selectedOption.trackId;
            const optionByIdPredicate = (option) => option.id === selectedOption.id;
            const respEffectByIdPredicate = (responseEffect) => responseEffect.id === selectedOption.id;
            const loopEffectByIdPredicate = (responseLoop) => responseLoop.id === selectedOption.id;

            const currentTrackIndex = editSimState.tracks.findIndex(trackByIdPredicate);
            const currentOptionIndex = editSimState.tracks[currentTrackIndex].options.findIndex(optionByIdPredicate);

            const currentRespEffectIndex = !isNullOrEmpty(editSimState.tracks[currentTrackIndex].responseEffects)
                ? editSimState.tracks[currentTrackIndex].responseEffects.findIndex(respEffectByIdPredicate)
                : null;
            const currentLoopEffectIndex = !isNullOrEmpty(editSimState.tracks[currentTrackIndex].responseLoops)
                ? editSimState.tracks[currentTrackIndex].responseLoops.findIndex(loopEffectByIdPredicate)
                : null;

            TrackService().deleteResponseOption(selectedOption.id, token)
                .then(response => {
                    if (response.status === 200) {
                        if (!isNullOrEmpty(prevTrackState.options)) {
                            setPrevTrackState({
                                ...prevTrackState,
                                options: prevTrackState.options
                                    .filter((option) => {
                                        if (option.id !== selectedOption.id) {
                                            option.optionOrder = ++optionOrder;
                                            return true;
                                        }
                                        return false;
                                    }),
                                responseEffects: isNullOrEmpty(prevTrackState.responseEffects)
                                    ? prevTrackState.responseEffects
                                    : prevTrackState.responseEffects.filter((val, index) => {
                                        return val.resp !== selectedOption.response;
                                    }),
                                responseLoops: isNullOrEmpty(prevTrackState.responseLoops)
                                    ? prevTrackState.responseLoops
                                    : prevTrackState.responseLoops.filter((val, index) => {
                                        return val.resp !== selectedOption.response;
                                    }),
                                optionVisibilitySettings: isNullOrEmpty(editTrackState.optionVisibilitySettings)
                                    ? editTrackState.optionVisibilitySettings
                                    : editTrackState.optionVisibilitySettings.filter((val, index) => {
                                        return val.optionId.name !== selectedOption.response;
                                    })
                            });
                        }
                        optionOrder = 0;
                        setEditTrackState({
                            ...editTrackState,
                            options: isNullOrEmpty(editTrackState.options) ? []
                                : editTrackState.options
                                    .filter((option) => {
                                        if (option.id !== selectedOption.id) {
                                            option.optionOrder = ++optionOrder;
                                            return true;
                                        }
                                        return false;
                                    }),
                            responseEffects: isNullOrEmpty(editTrackState.responseEffects)
                                ? editTrackState.responseEffects
                                : editTrackState.responseEffects.filter((val, index) => {
                                    return val.resp !== selectedOption.response;
                                }),
                            responseLoops: isNullOrEmpty(editTrackState.responseLoops)
                                ? editTrackState.responseLoops
                                : editTrackState.responseLoops.filter((val, index) => {
                                    return val.resp !== selectedOption.response;
                                }),
                            optionVisibilitySettings: isNullOrEmpty(editTrackState.optionVisibilitySettings)
                                ? editTrackState.optionVisibilitySettings
                                : editTrackState.optionVisibilitySettings.filter((val, index) => {
                                    return val.optionId.name !== selectedOption.response;
                                })
                        });

                        optionOrder = 0;
                        editSimState.tracks[currentTrackIndex].options.forEach((option, index) => {
                            if (currentOptionIndex !== index) {
                                option.optionOrder = ++optionOrder;
                            }
                        });

                        setEditSimState({
                            ...editSimState,
                            options: editSimState.tracks[currentTrackIndex].options.splice(currentOptionIndex, 1),
                            responseEffects: !isNullOrEmpty(editSimState.tracks[currentTrackIndex].responseEffects)
                                ? editSimState.tracks[currentTrackIndex].responseEffects.splice(currentRespEffectIndex, 1)
                                : editSimState.tracks[currentTrackIndex].responseEffects,
                            responseLoops: !isNullOrEmpty(editSimState.tracks[currentTrackIndex].responseLoops)
                                ? editSimState.tracks[currentTrackIndex].responseLoops.splice(currentLoopEffectIndex, 1)
                                : editSimState.tracks[currentTrackIndex].responseLoops,
                            optionVisibilitySettings: !isNullOrEmpty(editSimState.tracks[currentTrackIndex].optionVisibilitySettings)
                                ? editSimState.tracks[currentTrackIndex].optionVisibilitySettings = removeValuesFromOVS(editSimState.tracks[currentTrackIndex].optionVisibilitySettings, selectedOption.id)
                                : editSimState.tracks[currentTrackIndex].optionVisibilitySettings
                        });
                        resetResponse();
                        setAlertProps({ ...alertProps, msg: "Deleted successfully!", type: 'success' });
                        setOpenAlert(true);
                        setOpenCnfDia(false);
                    } else {
                        setAlertProps({ ...alertProps, msg: "Error while deleting!", type: 'error' });
                        setOpenAlert(true);
                    }
                })
                .catch(e => {
                    console.error(e.message);
                    setAlertProps({ ...alertProps, msg: "Error while deleting!", type: 'error' });
                    setOpenAlert(true);
                });
        } else {
            optionOrder = 0;
            setEditTrackState({
                ...editTrackState,
                options: isNullOrEmpty(editTrackState.options) ? [] : editTrackState.options
                    .filter((val, index) => {
                        if (index !== currentOption) {
                            val.optionOrder = ++optionOrder;
                            return true;
                        }
                        return false;
                    }),
                responseEffects: isNullOrEmpty(editTrackState.responseEffects) ? editTrackState.responseEffects : editTrackState.responseEffects.filter((val, index) => {
                    return val.resp !== selectedOption.response;
                }),
                responseLoops: isNullOrEmpty(editTrackState.responseLoops) ? editTrackState.responseLoops : editTrackState.responseLoops.filter((val, index) => {
                    return val.resp !== selectedOption.response;
                }),
                optionVisibilitySettings: isNullOrEmpty(editTrackState.optionVisibilitySettings) ? editTrackState.optionVisibilitySettings : editTrackState.optionVisibilitySettings.filter((val, index) => {
                    return val.optionId.name !== selectedOption.response;
                })
            });

            if (prevTrackState.options.length > 0) {
                const prevOrder = { ...prevTrackState };

                prevOrder.options = prevOrder.options.map((option, index) => {
                    return { ...option, optionOrder: index + 1 };
                })

                TrackService().updateTrack(prevOrder, token);

                setPrevTrackState({ ...prevOrder });

                const trackIndex = editSimState.tracks.findIndex((track) => track.id === prevTrackState.options[0].trackId)

                setEditSimState((prevState) => {
                    const newState = { ...prevState };
                    newState.tracks[trackIndex] = { ...prevOrder };
                    return { ...newState };
                });
            }
            resetResponse();
            setAlertProps({ ...alertProps, msg: "Deleted successfully!", type: 'success' });
            setOpenAlert(true);
            setOpenCnfDia(false);
        }
    };

    const handleEditorChange = (name, value) => {
        setEditTrackState({ ...editTrackState, [name]: value });
        if (defultEditorText === value
            && (isNullOrEmpty(prevTrackState.promptDetails) || prevTrackState.promptDetails === defultEditorText)) {
            setPrevTrackState({ ...prevTrackState, [name]: value });
        }
    };

    const deleteResponseEffectSetting = () => {
        const selectedResponseEffect = editTrackState.responseEffects[currentEffect];

        if (selectedResponseEffect.id && selectedResponseEffect.id !== null && selectedResponseEffect.id !== "") {
            const trackByIdPredicate = (track) => track.id === editTrackState.id;
            const responseEffectByIdPredicate = (respEf) => respEf.id === selectedResponseEffect.id;

            const currentTrackIndex = editSimState.tracks.findIndex(trackByIdPredicate);
            const currentRespEffectIndex = editSimState.tracks[currentTrackIndex].responseEffects.findIndex(responseEffectByIdPredicate);

            TrackService().deleteResponseEffect(selectedResponseEffect.id, token)
                .then(response => {
                    if (response.status === 200) {
                        if (!isNullOrEmpty(prevTrackState.responseEffects)) {
                            setPrevTrackState({
                                ...prevTrackState,
                                responseEffects: prevTrackState.responseEffects.filter((val, index) => {
                                    return index !== currentEffect;
                                })
                            });
                        }

                        setEditTrackState({
                            ...editTrackState,
                            responseEffects: editTrackState.responseEffects.filter((val, index) => {
                                return index !== currentEffect;
                            })
                        });

                        setEditSimState({
                            ...editSimState,
                            responseEffects: editSimState.tracks[currentTrackIndex].responseEffects.splice(currentRespEffectIndex, 1)
                        });

                        setAlertProps({ ...alertProps, msg: "Success: deleted successfully!", type: 'success' });
                        setOpenAlert(true);
                        setOpenCnfDia(false);
                    } else {
                        setAlertProps({ ...alertProps, msg: "Error: while deleting!", type: 'error' });
                        setOpenAlert(true);
                    }
                })
                .catch(e => {
                    console.error(e.message);
                    setAlertProps({ ...alertProps, msg: "Error: while deleting!", type: 'error' });
                    setOpenAlert(true);
                });
        } else {
            if (!isNullOrEmpty(prevTrackState.responseEffects)) {
                setPrevTrackState({
                    ...prevTrackState,
                    responseEffects: prevTrackState.responseEffects.filter((val, index) => {
                        return index !== currentEffect;
                    })
                });
            }
            setEditTrackState({
                ...editTrackState,
                responseEffects: editTrackState.responseEffects.filter((val, index) => {
                    return index !== currentEffect;
                })
            });
            setAlertProps({ ...alertProps, msg: "Success: deleted successfully!", type: 'success' });
            setOpenAlert(true);
            setOpenCnfDia(false);
        }
    };

    const deleteResponseLoopSetting = () => {
        let id = editTrackState.responseLoops[currentLoop].id;

        if (id && id !== null && id !== "") {

            const trackByIdPredicate = (track) => track.id === editTrackState.id;
            const responseLoopByIdPredicate = (respLoop) => respLoop.id === id;

            const currentTrackIndex = editSimState.tracks.findIndex(trackByIdPredicate);
            const currentRespLoopIndex = editSimState.tracks[currentTrackIndex].responseLoops.findIndex(responseLoopByIdPredicate);

            TrackService().deleteResponseLoop(id, token)
                .then(response => {
                    if (response.status === 200) {
                        if (!isNullOrEmpty(prevTrackState.responseLoops)) {
                            setPrevTrackState({
                                ...prevTrackState,
                                responseLoops: prevTrackState.responseLoops.filter((val, index) => {
                                    return index !== currentLoop;
                                })
                            });
                        }

                        setEditTrackState({
                            ...editTrackState,
                            responseLoops: editTrackState.responseLoops.filter((val, index) => {
                                return index !== currentLoop;
                            })
                        });

                        setEditSimState({
                            ...editSimState,
                            responseLoops: editSimState.tracks[currentTrackIndex].responseLoops.splice(currentRespLoopIndex, 1)
                        });

                        setAlertProps({ ...alertProps, msg: "Success: deleted successfully!", type: 'success' });
                        setOpenAlert(true);
                        setOpenCnfDia(false);
                    } else {
                        setAlertProps({ ...alertProps, msg: "Error: while deleting!", type: 'error' });
                        setOpenAlert(true);
                    }
                })
                .catch(e => {
                    console.error(e.message);
                    setAlertProps({ ...alertProps, msg: "Error: while deleting!", type: 'error' });
                    setOpenAlert(true);
                });
        } else {
            if (!isNullOrEmpty(prevTrackState.responseLoops)) {
                setPrevTrackState({
                    ...prevTrackState,
                    responseLoops: prevTrackState.responseLoops.filter((val, index) => {
                        return index !== currentLoop;
                    })
                });
            }
            setEditTrackState({
                ...editTrackState,
                responseLoops: editTrackState.responseLoops.filter((val, index) => {
                    return index !== currentLoop;
                })
            });
            setAlertProps({ ...alertProps, msg: "Success: deleted successfully!", type: 'success' });
            setOpenAlert(true);
            setOpenCnfDia(false);
        }
    };

    const deleteResponseVsSetting = () => {
        let id = editTrackState.visibilitySettings[currentVS].id;

        if (id && id !== null && id !== "") {

            const trackByIdPredicate = (track) => track.id === editTrackState.id;
            const responseCondVisbIdPredicate = (vs) => vs.id === id;

            const currentTrackIndex = editSimState.tracks.findIndex(trackByIdPredicate);
            const currentVsIndex = editSimState.tracks[currentTrackIndex].visibilitySettings.findIndex(responseCondVisbIdPredicate);

            TrackService().deleteResponseVS(id, token)
                .then(response => {
                    if (response.status === 200) {
                        if (!isNullOrEmpty(prevTrackState.visibilitySettings)) {
                            setPrevTrackState({
                                ...prevTrackState,
                                visibilitySettings: prevTrackState.visibilitySettings.filter((val, index) => {
                                    return index !== currentVS;
                                })
                            });
                        }
                        setEditTrackState({
                            ...editTrackState,
                            visibilitySettings: editTrackState.visibilitySettings.filter((val, index) => {
                                return index !== currentVS;
                            })
                        });

                        //always set simState after setting track state 
                        setEditSimState({
                            ...editSimState,
                            visibilitySettings: editSimState.tracks[currentTrackIndex].visibilitySettings.splice(currentVsIndex, 1)
                        });

                        setAlertProps({ ...alertProps, msg: "Success: deleted successfully!", type: 'success' });
                        setOpenAlert(true);
                        setOpenCnfDia(false);
                    } else {
                        setAlertProps({ ...alertProps, msg: "Error: while deleting!", type: 'error' });
                        setOpenAlert(true);
                    }
                })
                .catch(e => {
                    console.error(e.message);
                    setAlertProps({ ...alertProps, msg: "Error: while deleting!", type: 'error' });
                    setOpenAlert(true);
                });
        } else {
            if (!isNullOrEmpty(prevTrackState.visibilitySettings)) {
                setPrevTrackState({
                    ...prevTrackState,
                    visibilitySettings: prevTrackState.visibilitySettings.filter((val, index) => {
                        return index !== currentVS;
                    })
                });
            }
            setEditTrackState({
                ...editTrackState,
                visibilitySettings: editTrackState.visibilitySettings.filter((val, index) => {
                    return index !== currentVS;
                }),
            });
            setAlertProps({ ...alertProps, msg: "Success: deleted successfully!", type: 'success' });
            setOpenAlert(true);
            setOpenCnfDia(false);
        }
    };

    const deleteResponseOvSetting = () => {
        let id = editTrackState.optionVisibilitySettings[currentOV].id;

        if (id && id !== null && id !== "") {

            const trackByIdPredicate = (track) => track.id === editTrackState.id;
            const responseOvIdPredicate = (vs) => vs.id === id;

            const currentTrackIndex = editSimState.tracks.findIndex(trackByIdPredicate);
            const currentVsIndex = editSimState.tracks[currentTrackIndex].optionVisibilitySettings.findIndex(responseOvIdPredicate);

            TrackService().deleteResponseOV(id, token)
                .then(response => {
                    if (response.status === 200) {
                        if (!isNullOrEmpty(prevTrackState.optionVisibilitySettings)) {
                            setPrevTrackState({
                                ...prevTrackState,
                                optionVisibilitySettings: prevTrackState.optionVisibilitySettings.filter((val, index) => {
                                    return index !== currentOV;
                                })
                            });
                        }
                        setEditTrackState({
                            ...editTrackState,
                            optionVisibilitySettings: editTrackState.optionVisibilitySettings.filter((val, index) => {
                                return index !== currentOV;
                            })
                        });

                        //always set simState after setting track state 
                        setEditSimState({
                            ...editSimState,
                            optionVisibilitySettings: editSimState.tracks[currentTrackIndex].optionVisibilitySettings.splice(currentVsIndex, 1)
                        });

                        setAlertProps({ ...alertProps, msg: "Success: deleted successfully!", type: 'success' });
                        setOpenAlert(true);
                        setOpenCnfDia(false);
                    } else {
                        setAlertProps({ ...alertProps, msg: "Error: while deleting!", type: 'error' });
                        setOpenAlert(true);
                    }
                })
                .catch(e => {
                    console.error(e.message);
                    setAlertProps({ ...alertProps, msg: "Error: while deleting!", type: 'error' });
                    setOpenAlert(true);
                });
        } else {
            if (!isNullOrEmpty(prevTrackState.optionVisibilitySettings)) {
                setPrevTrackState({
                    ...prevTrackState,
                    optionVisibilitySettings: prevTrackState.optionVisibilitySettings.filter((val, index) => {
                        return index !== currentOV;
                    })
                });
            }
            setEditTrackState({
                ...editTrackState,
                optionVisibilitySettings: editTrackState.optionVisibilitySettings.filter((val, index) => {
                    return index !== currentOV;
                }),
            });
            setAlertProps({ ...alertProps, msg: "Success: deleted successfully!", type: 'success' });
            setOpenAlert(true);
            setOpenCnfDia(false);
        }
    };

    const handleCheckChanged = (e) => {
        const { name, checked } = e.target;
        setEditTrackState({ ...editTrackState, [name]: checked });
    };

    const responseOptionClass = isExpand ? "expand" : "collapse";

    const slideControlClass = isExpandSlideControl ? "expand" : "collapse";

    return (
        <>
            <div className={slideControlClass}>
                <SimBuilderTrackGeneral prompt={true} />
                <TrackImage />
                <FormControl variant="outlined">
                    <InputLabel htmlFor="responseType">Response Type</InputLabel>
                    <Select
                        native
                        name="responseType"
                        id="responseType"
                        value={editTrackState.responseType}
                        onChange={handleChange}
                    >
                        <option aria-label="None" value="" />
                        <option value={"radio"}>Radio Button List</option>
                        <option value={"checkbox"}>Checkbox List</option>
                        <option value={"likerScale"}>Liker Scale</option>
                    </Select>
                </FormControl>
                <div className="d-flex">
                    <div className="phone_toggles">
                        <FormControlLabel
                            control={
                                <Switch
                                    id="checkPriorOptions"
                                    name="checkPriorOptions"
                                    checked={editTrackState.checkPriorOptions}
                                    onChange={handleCheckChanged}
                                />
                            }
                            label="Check Response Options From Prior Track"
                        />
                    </div>
                    <div className="phone_toggles">
                        <FormControlLabel
                            control={
                                <Switch
                                    id="checkUnavailableOptions"
                                    name="checkUnavailableOptions"
                                    checked={editTrackState.checkUnavailableOptions}
                                    onChange={handleCheckChanged}
                                />
                            }
                            label={<>
                                <HtmlTooltip className="ml-1" title={
                                    <Fragment>
                                        <Typography color="inherit">
                                            <p>When prior track options are referenced, available options will be clickable. Previously selected options will display, but be grayed out.</p>
                                        </Typography>
                                    </Fragment>
                                }>
                                    <InfoOutlinedIcon fontSize="inherit" className="tooltip-font-size" />
                                </HtmlTooltip>&nbsp;
                                Display all response options from prior track
                            </>}
                        />
                    </div>
                </div>
                <Editor
                    name="promptDetails"
                    id="promptDetails"
                    title="Prompt Details"
                    data={editTrackState.promptDetails}
                    handleEditorChange={handleEditorChange}
                />
                <TextField
                    name="lesson"
                    variant="outlined"
                    id="lesson"
                    label="Lesson Objective"
                    value={editTrackState.lesson}
                    onChange={handleChange}
                    multiline
                    minRows={3}
                    maxRows={5}
                />
            </div>
            <div className="heading">
                <h3>Response Options</h3>
                {markup}
            </div>
            <div className={responseOptionClass}>
                <MuiThemeProvider theme={formLabelsTheme}>
                    <form noValidate autoComplete="off">
                        <div className="optionForm">
                            <TextField
                                variant="outlined"
                                name="response"
                                id="response"
                                value={response}
                                onChange={(e) => setResponse(e.target.value)}
                                label="Add Response Option"
                                error={optionErrorFlag && isNullOrEmpty(response) && true}
                                required
                                helperText={optionErrorFlag && isNullOrEmpty(response) && "The add response option field is required."}
                            />
                            <TextField
                                variant="outlined"
                                name="info"
                                id="info"
                                value={info}
                                label="Add Additional Info"
                                onChange={(e) => setInfo(e.target.value)}
                            />
                            {editFeedbackId !== null && editFeedbackId !== undefined &&
                                <IconButton onClick={cancelEdit}>
                                    <CancelIcon />
                                </IconButton>
                            }
                            <IconButton onClick={addResponse}>
                                {editFeedbackId === null || editFeedbackId === undefined ? <AddCircleIcon /> : <SaveIcon />}
                            </IconButton>
                        </div>
                        <div>
                            <TextField
                                variant="outlined"
                                name="feedback"
                                id="feedback"
                                label="Choice Feedback"
                                value={feedback}
                                onChange={(e) => setFeedback(e.target.value)}
                                multiline
                                minRows={3}
                                maxRows={5}
                                error={optionErrorFlag && isNullOrEmpty(feedback) && true}
                                required
                                helperText={optionErrorFlag && isNullOrEmpty(feedback) && "The choice feedback field is required."}
                            />
                        </div>
                    </form>
                </MuiThemeProvider>
                <div ref={drop}>
                    {!isNullOrEmpty(options) ? options.map((val, index) =>
                        <ResponseOptionList key={val.optionOrder} listIndex={index} val={val} editTrackState={editTrackState} editResponse={editResponse} handleConfirmation={handleConfirmation} setOptions={setOptions} />)
                        : ""}
                </div>
            </div>
            <SimBuilderResponseEffect setMsg={setMsg} setOpenCnfDia={setOpenCnfDia} setHandleDelete={setHandleDelete} setCurrentEffect={setCurrentEffect} />
            <SimBuilderResponseLoopEffect setMsg={setMsg} setOpenCnfDia={setOpenCnfDia} setHandleDelete={setHandleDelete} setCurrentLoop={setCurrentLoop} />
            <SimBuilderOptionVisibility setMsg={setMsg} setOpenCnfDia={setOpenCnfDia} setHandleDelete={setHandleDelete} setCurrentOV={setCurrentOV} />
            <FlashSetting simpleValidator={props.simpleValidator} />
            <SimBuilderTrackVisibility simpleValidator={props.simpleValidator} />
            <SimBuilderTrackConditionalVisibility setMsg={setMsg} setOpenCnfDia={setOpenCnfDia} setHandleDelete={setHandleDelete} setCurrentVS={setCurrentVS} />
            <SimBuilderTrackFormActions simpleValidator={props.simpleValidator} />
            {openCnfDia &&
                handleDelete === "deleteResponse" &&
                <ConfirmationDilaog msg={msg} handleDelete={deleteResponse} handleClose={handleClose} />
            }
            {openCnfDia &&
                handleDelete === "deleteEffect" &&
                <ConfirmationDilaog msg={msg} handleDelete={deleteResponseEffectSetting} handleClose={handleClose} />
            }
            {openCnfDia &&
                handleDelete === "deleteLoop" &&
                <ConfirmationDilaog msg={msg} handleDelete={deleteResponseLoopSetting} handleClose={handleClose} />
            }
            {openCnfDia &&
                handleDelete === "deleteVS" &&
                <ConfirmationDilaog msg={msg} handleDelete={deleteResponseVsSetting} handleClose={handleClose} />
            }
            {openCnfDia &&
                handleDelete === "deleteOV" &&
                <ConfirmationDilaog msg={msg} handleDelete={deleteResponseOvSetting} handleClose={handleClose} />
            }
            {openAlert && <Alert {...alertProps} openAlert={openAlert} setOpenAlert={setOpenAlert} />}
            {openConfirmTrackChanges &&
                <ConfirmationDilaog
                    title="Confirmation!"
                    msg={`<h4>Are you sure you want to save the new order of the options?</h4>`}
                    handleDelete={() => DragAndDropService().drop(() => FunctionService().optionOrderChange(options, prevTrackState, setPrevTrackState, editSimState, setEditSimState, setEditTrackState, updateOptionsOrder, resetResponse), confirmTrackChangesHandler, setOpenConfirmTrackChanges)}
                    handleClose={() => DragAndDropService().notDrop(setOptions, optionList, confirmTrackChangesHandler, setOpenConfirmTrackChanges)}
                    isSticky={true}
                />
            }
        </>
    );
};

export default QuestionTrackEdit;