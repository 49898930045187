import React from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const ConfirmDialog = (props) => {

    const tittle = props.title ? props.title : "Warning!";

    return (
        <div>
            <Dialog
                open={true}
                fullWidth
                maxWidth="sm"
                onClose={props.isSticky === undefined ? () => props.handleClose() : () => { }}
                aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title"><strong>{tittle}</strong></DialogTitle>
                <DialogContent dangerouslySetInnerHTML={{ __html: props.msg }} />
                <DialogActions>
                    <Button onClick={() => props.handleClose()} color="primary">
                        No
                    </Button>
                    <Button onClick={() => props.handleDelete()} color="secondary">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}

export default ConfirmDialog;