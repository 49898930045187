import React, { createContext, useMemo, useState } from 'react';
export const UserContext = createContext();

const UserContextProvider = (props) => {

    const [user, setUser] = useState(() => {
        let user = null;
        try {
            user = JSON.parse(localStorage.getItem('user'));
        } catch (error) {
            console.error(error.message);
        }
        return user ? user : null;
    });

    const [token, setToken] = useState(() => {
        let token = null;
        try {
            token = localStorage.getItem('token');
        } catch (error) {
            console.error(error.message);
        }
        return token ? token : null;
    });

    const logout = () => {
        try {
            localStorage.clear();
            setUser(null);
            setToken(null);
        } catch (error) {
            console.error(error.message);
        }
    }

    const userContext = useMemo(() => (
        {
            user,
            setUser,
            token,
            setToken,
            logout
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [token]
    );

    return (
        <UserContext.Provider value={{ ...userContext }}>
            {props.children}
        </UserContext.Provider>
    );
}

export default UserContextProvider;