import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import React, { useState } from "react";
import 'regenerator-runtime/runtime';

const CopyDialog = (props) => {
    const [inputTitle, setInputTitle] = useState("");
    const [errorProps, setErrorProps] = useState(false);
    const [isOPend, setIsOpend] = useState(true);

    const handleTitleChange = (e) => {
        setInputTitle(e.target.value);
        setErrorProps(false);
    };

    const hanleCopyClick = () => {
        if (props.check(inputTitle)) {
            setIsOpend(false);
            props.action(inputTitle);
            props.handleCloseCopyDialog();
        } else {
            setInputTitle("");
            setErrorProps(true);
        }
    };

    const handleClose = () => {
        props.handleCloseCopyDialog();
    }

    return (
        <div>
            <Dialog
                open={isOPend}
                fullWidth
                maxWidth="sm"
                onClose={(event, reason) => {
                    if (reason !== "escapeKeyDown" || reason !== "backdropClick") {
                        handleClose()
                    }
                }}
                aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{props.dialogTitle}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id={props.id}
                        name={props.name}
                        label={props.lable}
                        type={props.type}
                        error={errorProps && true}
                        helperText={errorProps && props.helperText}
                        onChange={handleTitleChange}
                        value={inputTitle}
                        inputProps={{ maxLength: props.length }}
                        fullWidth
                        aria-disabled={true}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        {props.cancel}
                    </Button>
                    <Button onClick={hanleCopyClick} color="primary">
                        {props.save}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default CopyDialog;