import React, { useContext } from 'react';
import MediaAssetList from './builder/media/MediaAssetList';

import {MediaContext} from '../context/MediaContext';

const AssetManager = () =>{

    const {addMedia} = useContext(MediaContext);

    const buckets = ['video','images', 'audio'];

    const addAll = () => {
        const json = [{type:'video', order: 1},
            {type:'video', order: 2},
            {type:'video', order: 3},
            {type:'audio', order: 4},
            {type:'audio', order: 5},
            {type:'audio', order: 6},
            {type:'audio', order: 7},
            {type:'image', order: 8},
            {type:'image', order: 9},
            {type:'image', order: 10},
            {type:'image', order: 11}
        ]

        json.forEach((val) => {
            addMedia(val);
        });

    }

    return(
        <>
            {addAll}
            <h2>Manage Media Assets</h2>
            {buckets.map((val)=><MediaAssetList key={val} title={val} />)}
        </>
    );
}

export default AssetManager;