import S3Upload from 'react-s3-uploader/s3upload.js'
import { getAwsSignedUrlToUpload } from './AwsBackendServices';

export const UploadS3Files = (file, uploadPath, userId, token) => {
    const getSignedUrl = (file, callback) => {
        const path = `${uploadPath}${file.name}`;
        getAwsSignedUrlToUpload(
            userId,
            path,
            file,
            token,
            callback,
            () => { console.error("Error while uploading file :", path); }
        );
    }

    return new Promise((resolve) => new S3Upload({
        files: [file],
        getSignedUrl,
        // onProgress: onProgress,
        onFinishS3Put: (f) => resolve(f),
        // preprocess: preprocess,
        onError: (e) => { console.error("Upload error ", e) },
        uploadRequestHeaders: {}
    })

    );
}