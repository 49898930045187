import React from 'react';
import SimBuilderVariableListItem from './SimBuilderVariableListItem';

const VariableList = (props) => {
    return (
        <>
            {props.list.map((v) => {
                return <SimBuilderVariableListItem
                    key={v.id}
                    {...v}
                    handleVariableSelect={props.handleVariableSelect}
                    handleVariableDelete={props.handleVariableDelete} />
            }
            )}
        </>
    )
}

export default VariableList;