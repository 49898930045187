import { SIM_BUILDER_API_END_POINT } from "../Config";
import RequestService, { HttpMethods } from "../utils/requestService";
import { UploadS3Files } from "./S3UplodService";

const AIService = () => {
    return Object.freeze({
        getAIGeneratedImages: async (prompt, token) => {
            const options = RequestService.makeRequest(HttpMethods.POST, token);
            options['body'] = JSON.stringify(prompt);

            const result = await fetch(SIM_BUILDER_API_END_POINT + '/bedrock', options);
            const response = await result.json();
            return response;
        },
        
        saveAIGeneratedImages: async (files, editSimState, isRadio, editTrackState, setEditTrackState, handleCloseAllPopups, addMediaList) => {
          if (files.length !== 0) {
            const promises = files.map((file) =>
              UploadS3Files(file, `${editSimState.id}/`)
            );
      
            try {
              const uploadedFiles = await Promise.all(promises);
              const mediaUpload = files.map((file, i) => {
                const name = file.tempName;
                return {
                  name: name,
                  description: "",
                  extension: file.type.split("/")[1],
                  type: file.typeField,
                  simId: editSimState.id,
                  url: uploadedFiles[i].fileKey,
                };
              });
              addMediaList(mediaUpload, isRadio, editTrackState, setEditTrackState);
              handleCloseAllPopups();
            } catch (err) {
              console.error(err);
            }
          }
        }
    });
}

export default AIService;
