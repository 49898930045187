import { SIM_BUILDER_API_END_POINT } from "../Config";
import RequestService, { HttpMethods } from "../utils/requestService";

const path = "/internalSim";

const InternalSimService = () => {
  return Object.freeze({
    // function to save Internal Simulation
    saveInternalSim: async (data, token) => {
      const options = RequestService.makeRequest(HttpMethods.POST, token);
      options["body"] = JSON.stringify(data);
      const result = await fetch(SIM_BUILDER_API_END_POINT + path, options);
      const response = await result.json();
      return response;
    },

    // function to get all internal simualtions
    getAllInternalSims: async (token) => {
      const options = RequestService.makeRequest(HttpMethods.GET, token);
      const result = await fetch(SIM_BUILDER_API_END_POINT + path, options);
      const response = await result.json();
      return response;
    },

    // function to export internal simulation by id
    exportInternalSim: async (simId, token) => {
      const options = RequestService.makeRequest(HttpMethods.POST, token);
      options["body"] = JSON.stringify(simId);
      const result = await fetch(
        SIM_BUILDER_API_END_POINT + path + "/exportSim",
        options
      );
      const response = await result.json();
      return response;
    },

    // function to get internal simualtion by id
    getInternalSimById: async (simId, token) => {
      const options = RequestService.makeRequest(HttpMethods.GET, token);
      const result = await fetch(
        SIM_BUILDER_API_END_POINT + path + "/" + simId,
        options
      );
      const response = await result.json();
      return response;
    },

    // function to delete internal simualtion by id
    deleteInternalSimById: async (simId, token) => {
      const options = RequestService.makeRequest(HttpMethods.DELETE, token);
      const result = await fetch(
        SIM_BUILDER_API_END_POINT + path + "/" + simId,
        options
      );
      const response = await result.json();
      return response;
    },
  });
};

export default InternalSimService;
