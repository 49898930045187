const FunctionService = () => {
    return Object.freeze({
        trackOrderChange: (setTracks, updateTrackList, editSimState, setEditTrackState, setPrevTrackState) => {
            setTracks(prevState => {
                const newTrackList = prevState.map((track, index) => {
                    return { ...track, "order": index + 1 };
                });
                updateTrackList(newTrackList);
                editSimState.tracks = newTrackList;
                return [...newTrackList];
            });
            setEditTrackState(null);
            setPrevTrackState(null);
        },
        optionOrderChange: (options, prevTrackState, setPrevTrackState, editSimState, setEditSimState, setEditTrackState,
            updateOptionsOrder, resetResponse) => {
            const optionListNewOrder = options.map((option, index) => {
                return { ...option, optionOrder: index + 1 };
            });

            if (prevTrackState.options.length > 0) {
                const prevOptionListNewOrder = optionListNewOrder.filter((option) => {
                    if (option.id !== undefined) {
                        return true;
                    }
                    return false;
                }).sort((a, b) => { return a.id - b.id });

                const prevTrackStateLoc = { ...prevTrackState };

                prevTrackStateLoc.options = prevTrackStateLoc.options
                    .sort((a, b) => { return a.id - b.id })
                    .map((option, index) => {
                        const prevOption = prevOptionListNewOrder[index];

                        return { ...option, optionOrder: prevOption.optionOrder };
                    }).sort((a, b) => { return a.optionOrder - b.optionOrder });

                updateOptionsOrder(prevTrackStateLoc.options);

                setPrevTrackState({ ...prevTrackStateLoc });

                const trackIndex = editSimState.tracks.findIndex((track) => track.id === prevTrackState.options[0].trackId);

                setEditSimState((prevState) => {
                    const newState = { ...prevState };
                    newState.tracks[trackIndex] = { ...prevTrackStateLoc };
                    return { ...newState };
                });
            }
            setEditTrackState((prevState) => {
                return { ...prevState, options: optionListNewOrder };
            });
            resetResponse();
        },
    });
}

export default FunctionService;