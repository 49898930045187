import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import FullEditor from 'ckeditor5-custom-build/build/ckeditor';

export const defultEditorText = '<p><span style="font-size:22px;"></span></p>';

const Editor = (props) => {
  const defultFontSize = '<span style="font-size:22px;"></span>';

  return (
    <>
      <div className="editorWrapper">
        <span className="editorTitle">{props.title}</span>
        <CKEditor
          data={props.data}
          name={props.name}
          id={props.id}
          editor={FullEditor}
          config={{
            toolbar: [
              "fontFamily",
              "fontSize",
              "fontColor",
              "fontBackgroundColor",
              "heading",
              "|",
              "bold",
              "italic",
              "blockQuote",
              "link",
              "numberedList",
              "bulletedList",
              "insertTable",
              "tableColumn",
              "tableRow",
              "mergeTableCells",
              "|",
              "undo",
              "redo",
              "removeFormat",
            ],
            fontSize: {
              options: [
                10,
                12,
                14,
                16,
                18,
                20,
                22,
                24,
                26,
                28,
                36,
                48,
              ]
            },
            fontFamily: {
              options: [
                "default",
                "serif",
                "sans-serif",
                "monospace",
                "cursive",
                "fantasy",
                "system-ui",
                "ui-serif",
                "ui-sans-serif",
                "ui-monospace",
                "ui-rounded",
                "emoji",
                "math",
                "fangsong",
              ],
            },

          }}
          onChange={(event, editor) => {
            let data = editor.getData();
            if (data === '' || data === undefined || data === null) {
              data = defultFontSize;
            }
            props.handleEditorChange(props.name, data);
          }}
          onReady={editor => {
            // You can store the "editor" and use when it is needed.
            if (!props.data || props.data === "" || props.data === null) {
              editor.setData(defultFontSize)
            }
          }}
        />
      </div>
    </>
  );
};

export default Editor;