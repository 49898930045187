import React, { useContext } from 'react';

import { TextField, FormControl, InputLabel, Select } from '@material-ui/core';
import { EditTrackContext } from '../../../../context/EditTrackContext';
import SimBuilderTrackAudio from '../SimBuilderTrackAudio';
import { handlePaste } from '../../../../utils/utils';
import { HEADER_LENGTH_LIMITATION, PROMPT_LENGTH_LIMITATION, TITLE_LENGTH_LIMITATION } from '../../../../utils/fieldValidation';

const TrackGeneral = (props) => {
    const { editTrackState, setEditTrackState } = useContext(EditTrackContext);

    const handleChange = (e) => {
        const { name, value } = e.target;
        // If track type changed than remove existing audioURL, trackImgURL and url els update general info only.
        if (name === "type") {
            setEditTrackState({
                ...editTrackState, [name]: value,
                audioURL: null,
                trackImgURL: null,
                url: null,
                metaUrl: null,
                pubAudioUrl: null,
                pubMetaUrl: null,
                pubTrackImageUrl: null,
                pubUrl: null
            });
        } else {
            setEditTrackState({ ...editTrackState, [name]: value });
        }
    }

    return (
        <>
            <TextField variant="outlined" name='title' id='title' label='Title' value={editTrackState.title} onChange={handleChange} onPaste={(event) => handlePaste(event, TITLE_LENGTH_LIMITATION)} inputProps={{ maxLength: TITLE_LENGTH_LIMITATION }} />
            <TextField variant="outlined" name='desc' id='desc' label="Description (Shows On Track Hover)" value={editTrackState.desc} onChange={handleChange} multiline minRows={3} maxRows={5} />
            <TextField variant="outlined" name='header' id='header' label='Header' value={editTrackState.header} onChange={handleChange} onPaste={(event) => handlePaste(event, HEADER_LENGTH_LIMITATION)} inputProps={{ maxLength: HEADER_LENGTH_LIMITATION }} />
            {props.prompt &&
                <TextField variant="outlined" name='prompt' id='prompt' label='Prompt' value={editTrackState.prompt} onChange={handleChange} onPaste={(event) => handlePaste(event, PROMPT_LENGTH_LIMITATION)} inputProps={{ maxLength: PROMPT_LENGTH_LIMITATION }} />
            }
            <FormControl variant="outlined">
                <InputLabel htmlFor="responseType">Track Type</InputLabel>
                <Select
                    native
                    name="type"
                    id="type"
                    value={editTrackState.type}
                    onChange={handleChange}
                >
                    <option value="text">Text Track</option>
                    <option value="video">Video Track</option>
                    <option value="email">Email Track</option>
                    <option value="sms">SMS Track</option>
                    <option value="phone">Phone Track</option>
                    <option value="pdf">PDF Track</option>
                    <option value="freetext">Free Text Response</option>
                    <option value="question">Question Response</option>
                    <option value="scorecard">Scorecard Track</option>
                </Select>
            </FormControl>
            {!!!props.noAudio && <SimBuilderTrackAudio />}
        </>
    )
}

export default TrackGeneral;