import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { Roles } from "../utils/userUtil";
import LinkButton from "./generic/LinkButton";

const BackToLibrary = () => {
  const [role, setRole] = useState(null);
  const ctx = useContext(UserContext);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (ctx.user) {
      setRole(ctx.user.roleByRole.roleName);
    }
  }, [ctx.user]);

  const onBackClick = (routePath) => {
    try {
      localStorage.removeItem("playerState");
    } catch (error) {
      console.error(error.message);
    }
    history.push(routePath);
  }

  //Prevent users with simUser claims from accessing protected resources (Library, SimBuilder, Dashboard, etc)
  return role && role === Roles.Admin ?
    location.search === "?isPlayingFromTrack" ?
      (
        <div className="position_fixed">
          <LinkButton title="Back To Library" onClick={() => onBackClick("/library")} />
          <LinkButton title="Back To Sim Edit" onClick={() => onBackClick("/tracks")} />
        </div>
      )
      :
      (
        <div className="position_fixed">
          <LinkButton title="Back To Library" onClick={() => onBackClick("/library")} />
        </div>
      )
    : (<></>);
};

export default BackToLibrary;