import { SIM_BUILDER_API_END_POINT } from "../Config";
import RequestService, { HttpMethods } from "../utils/requestService";

const StandardsService = () => {
    return Object.freeze({
        getStandardsFromPortal: async (token) => {
            const options = RequestService.makeRequest(HttpMethods.GET, token);

            const result = await fetch(SIM_BUILDER_API_END_POINT + '/varsStandards/getStandards', options);
            const response = await result.json();

            return response;
        }
    });
}

export default StandardsService;