import { SIM_BUILDER_API_END_POINT } from "../Config";
import RequestService, { HttpMethods } from "../utils/requestService";

const TrackService = () => {
  return Object.freeze({
    getTracksBySimId: async (simId, token) => {
      const options = RequestService.makeRequest("GET", token);

      const result = await fetch(
        SIM_BUILDER_API_END_POINT + "/tracks/" + simId,
        options
      );
      const response = await result.json();

      return response;
    },
    addTrack: async (track, token) => {
      const options = RequestService.makeRequest("POST", token);
      options["body"] = JSON.stringify(track);

      const result = await fetch(
        SIM_BUILDER_API_END_POINT + "/tracks",
        options
      );
      const response = await result.json();

      return response;
    },
    updateTrack: async (track, token) => {
      const options = RequestService.makeRequest(HttpMethods.PUT, token);
      options["body"] = JSON.stringify(track);

      const result = await fetch(
        SIM_BUILDER_API_END_POINT + "/tracks",
        options
      );
      const response = await result.json();

      return response;
    },
    updateTrackList: async (track, token) => {
      const options = RequestService.makeRequest(HttpMethods.POST, token);
      options["body"] = JSON.stringify(track);

      const result = await fetch(
        SIM_BUILDER_API_END_POINT + "/tracks/updateTrackList",
        options
      );
      const response = await result.json();

      return response;
    },
    deleteTrack: async (trackId, token) => {
      const options = RequestService.makeRequest("DELETE", token);

      const result = await fetch(
        SIM_BUILDER_API_END_POINT + "/tracks/" + trackId,
        options
      );
      const response = await result.json();

      return response;
    },
    deleteResponseOption: async (optionId, token) => {
      const options = RequestService.makeRequest("DELETE", token);

      const result = await fetch(
        SIM_BUILDER_API_END_POINT + "/tracks/deleteResponseOption/" + optionId,
        options
      );
      const response = await result.json();

      return response;
    },
    deleteResponseEffect: async (effectId, token) => {
      const options = RequestService.makeRequest("DELETE", token);

      const result = await fetch(
        SIM_BUILDER_API_END_POINT + "/tracks/deleteResponseEffect/" + effectId,
        options
      );
      const response = await result.json();

      return response;
    },
    deleteResponseLoop: async (loopId, token) => {
      const options = RequestService.makeRequest("DELETE", token);

      const result = await fetch(
        SIM_BUILDER_API_END_POINT + "/tracks/deleteResponseLoop/" + loopId,
        options
      );
      const response = await result.json();

      return response;
    },
    deleteResponseVS: async (vsId, token) => {
      const options = RequestService.makeRequest("DELETE", token);

      const result = await fetch(
        SIM_BUILDER_API_END_POINT +
          "/tracks/deleteResponseVisibilitySetting/" +
          vsId,
        options
      );
      const response = await result.json();

      return response;
    },
    deleteResponseOV: async (vsId, token) => {
      const options = RequestService.makeRequest("DELETE", token);

      const result = await fetch(
        SIM_BUILDER_API_END_POINT + "/tracks/deleteOvSetting/" + vsId,
        options
      );
      const response = await result.json();

      return response;
    },
    deleteScorecardVariable: async (id, token) => {
      const options = RequestService.makeRequest("DELETE", token);

      const result = await fetch(
        SIM_BUILDER_API_END_POINT + `/tracks/deleteScorecardVariable/${id}`,
        options
      );
      const response = await result.json();

      return response;
    },
    updateOptionsOrder: async (trackOptions, token) => {
      const options = RequestService.makeRequest(HttpMethods.POST, token);
      options["body"] = JSON.stringify(trackOptions);

      const result = await fetch(
        SIM_BUILDER_API_END_POINT + "/tracks/updateOptionList",
        options
      );
      const response = await result.json();

      return response;
    },
    findWordOccurrencesAcrossTrack: async (simId, word, token) => {
      const options = RequestService.makeRequest(HttpMethods.POST, token);
      options["body"] = JSON.stringify({ simId, word });
      const result = await fetch(
        SIM_BUILDER_API_END_POINT + "/tracks/findWord",
        options
      );
      const response = await result.json();
      return response;
    },
    replaceWordAcrossTrack: async (
      simId,
      trackId,
      word,
      replaceWord,
      token
    ) => {
      const options = RequestService.makeRequest(HttpMethods.POST, token);
      options["body"] = JSON.stringify({ simId, trackId, word, replaceWord });
      const result = await fetch(
        SIM_BUILDER_API_END_POINT + "/tracks/replaceWord",
        options
      );
      const response = await result.json();
      return response;
    },
    exportTrackData: async (trackId, token) => {
      const options = RequestService.makeRequest(HttpMethods.POST, token);
      options["body"] = JSON.stringify({ trackId });
      const result = await fetch(
        SIM_BUILDER_API_END_POINT + "/reports/tracksData",
        options
      );
      const response = await result.blob();
      return response;
    },
  });
};

export default TrackService;
