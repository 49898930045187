import _ from "lodash";
import Config, { SIM_BUILDER_API_END_POINT } from "../Config";
import requestService, { HttpMethods } from "../utils/requestService";


export function getAwsSignedUrlToUpload(userId, fileName, file, authToken, callback, failCallback) {
    let bucketName = Config.aws.s3.bucketName;
    let objectKey = fileName;
    let contentType = file.type;

    const options = requestService.makeRequest(HttpMethods.POST, authToken);
    options.body = JSON.stringify({ userId, bucketName, objectKey, contentType });

    fetch(`${SIM_BUILDER_API_END_POINT}/awsS3/getSignedUrlForUpload`, options)
        .then((response) => response.json())
        .then((responseJson) => {
            let signedUrl = _.replace(responseJson.data.url, "http://", "https://");
            let result = {
                signedUrl,
                fileName,
                publicUrl: '/s3/uploads/' + fileName,
                fileKey: fileName,
            }
            callback(result);
        })
        .catch((error) => {
            console.error("Error getting signed url: " + error);
            failCallback();
        });
}