import { SIM_BUILDER_API_END_POINT } from "../Config";
import RequestService, { HttpMethods } from "../utils/requestService";

const SimService = () => {
    return Object.freeze({
        getAllSims: async (token) => {
            const options = RequestService.makeRequest(HttpMethods.GET, token);

            const result = await fetch(SIM_BUILDER_API_END_POINT + '/sims', options);
            const response = await result.json();

            return response;
        },
        getSimById: async (simId) => {
            const options = RequestService.makeRequest(HttpMethods.GET);

            const result = await fetch(SIM_BUILDER_API_END_POINT + '/sims/' + simId, options);
            const response = await result.json();

            return response;
        },
        createSim: async (sim, token) => {
            const options = RequestService.makeRequest(HttpMethods.POST, token);
            options['body'] = JSON.stringify(sim);

            const result = await fetch(SIM_BUILDER_API_END_POINT + '/sims', options);
            const response = await result.json();

            return response;
        },
        updateSim: async (sim, token) => {
            const options = RequestService.makeRequest(HttpMethods.PUT, token);
            options['body'] = JSON.stringify(sim);

            const result = await fetch(SIM_BUILDER_API_END_POINT + '/sims', options);
            const response = await result.json();

            return response;
        },
        deleteSim: async (simId, token) => {
            const options = RequestService.makeRequest(HttpMethods.DELETE, token);

            const result = await fetch(SIM_BUILDER_API_END_POINT + '/sims/' + simId, options);
            const response = await result.json();

            return response;
        },
        copySim: async (sim, token) => {
            const options = RequestService.makeRequest(HttpMethods.POST, token);
            options['body'] = JSON.stringify(sim);

            const result = await fetch(SIM_BUILDER_API_END_POINT + '/sims/copySimulation', options);
            const response = await result.json();

            return response;
        }
    });
}

export default SimService;