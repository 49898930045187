import React, { createContext, useState, useMemo, useEffect, useContext } from "react";
import { EditSimContext } from "./EditSimContext";
import { UserContext } from "./UserContext";
import MediaService from "../services/MediaService";

export const MediaContext = createContext();

//Provide a cached list of Media elements for a simulation
//Automatically pull from DB as simEdit context chanegs
const MediaContextProvider = (props) => {
    const { token } = useContext(UserContext);
    const { editSimState } = useContext(EditSimContext);
    const [mediaState, setMediaState] = useState([]);
    const [mediaFileState, setMediaFileState] = useState([]);

    const images = useMemo(() => {
        const rtn = mediaState.filter((media) => {
            return media.type === "image";
        });
        return rtn;
    }, [mediaState]);

    const videos = useMemo(() => {
        const rtn = mediaState.filter((media) => {
            return media.type === "video";
        });
        return rtn;
    }, [mediaState]);

    const sounds = useMemo(() => {
        const rtn = mediaState.filter((media) => {
            return media.type === "sound";
        });
        return rtn;
    }, [mediaState]);

    const pdfs = useMemo(() => {
        const rtn = mediaState.filter((media) => {
            return media.type === "pdf";
        });
        return rtn;
    }, [mediaState]);

    const captions = useMemo(() => {
        const rtn = mediaState.filter((media) => {
            return media.type === "caption";
        });
        return rtn;
    }, [mediaState]);

    //Load from DB
    const loadContextFromDB = (id) => {
        MediaService().getMediasBySimId(id, token).then((response) => {
            if (response.status === 200) {
                setMediaState(response.data);
            }
        }).catch((e) => {
            console.log("Error: Could not load variables from DB", e.message);
        });
    };

    const addMedia = (newMedia) => {
        let extension = null;
        if (newMedia.file !== null) {
            extension = newMedia.file.type.split("/")[1];
        }
        const media = { ...newMedia, simId: editSimState.id, extension: extension };
        MediaService().addMedia(media, token).then((response) => {
            if (response.status === 200) {
                setMediaFileState(
                    mediaFileState.concat({
                        file: newMedia.file,
                        name: newMedia.name,
                    })
                );
                setMediaState(
                    mediaState.concat({
                        ...response.data,
                    })
                );
            } else {
                console.error("Error: Could not save media to DB", response.message);
            }
        }).catch((e) => {
            console.log("Error: Could not save media to DB", e);
        });
    };

    const addMediaList = (mediaList, isRadio, editTrackState, setEditTrackState) => {
        MediaService().addMediaList(mediaList, token).then((response) => {
            if (response.status === 200) {
                setMediaState((prevState =>  [...prevState, ...response.data]))
                if(isRadio){
                    setEditTrackState({
                        ...editTrackState,
                        pubTrackImageUrl: response?.data[0].url ,
                        trackImgURL: response?.data[0].id
                    });
                }
            } else {
                console.error("Error: Could not save media to DB", response.message);
            }
        }).catch((e) => {
            console.error("Error: Could not save media to DB", e);
        });
    };

    const updateMedia = (updatedMedia) => {
        MediaService().updateMedia(updatedMedia, token).then((response) => {
            if (response.status === 200) {
                const mediaList = mediaState.map((m) => {
                    return m.id !== updatedMedia.id ? m : response.data;
                });
                setMediaState(mediaList);
                console.log("Updated media in DB");
            } else {
                console.error("Error: could not save media", response.message);
            }
        }).catch((e) => {

        });
    };

    const deleteMedia = (media) => {
        MediaService().deleteMedia(media.id, token).then((response) => {
            if (response.status === 200) {
                const mediaList = mediaState.filter((m) => {
                    return m.id !== media.id;
                });
                setMediaState(mediaList);
            } else {
                console.log("Error: could not delete media", response.message);
            }
        }).catch((error) => {
            console.log("Error: could not delete media", error.message);
        });
    };

    //Attach to editSimState and load this subdomain for the variables state
    // updating this state as editSimState changes
    useEffect(() => {
        if (editSimState.id) {
            loadContextFromDB(editSimState.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editSimState]);

    const mediaStateProviderValue = useMemo(
        () => ({
            mediaState,
            images,
            videos,
            sounds,
            pdfs,
            captions,
            addMedia,
            addMediaList,
            updateMedia,
            deleteMedia,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [mediaState, setMediaState]
    );

    return (
        <MediaContext.Provider value={mediaStateProviderValue}>
            {props.children}
        </MediaContext.Provider>
    );
};

export default MediaContextProvider;
