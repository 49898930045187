import React, { useContext, useEffect, useState } from "react";
import { FormControl, IconButton, InputLabel, Select, TextField, FormHelperText } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import { EditTrackContext } from "../../../../context/EditTrackContext";
import { VariableContext } from "../../../../context/VariableContext";
import { isNullOrEmpty } from "../../../../utils/utils";
import { MuiThemeProvider } from '@material-ui/core/styles';
import formLabelsTheme from '../../../generic/FormLabelsThem';
import useExpandCollapse from "../../../../hooks/useExpandCollapse";
import { IsExpandAllContext } from "../../../../context/IsExpandAllContext";

const ResponseEffect = (props) => {
  const [isExpand, markup, toggleResponseEffect] = useExpandCollapse({ vertical: false });
  //Used to get list of variables in the simulation
  const { variableState } = useContext(VariableContext);

  //State of track being edited
  const { editTrackState, setEditTrackState } = useContext(EditTrackContext);

  //Local form selections
  const [defSelectedVar, setDefSelectedVar] = useState("");
  const [selectedVar, setSelectedVar] = useState();
  const [selectedResp, setSelectedResp] = useState("");
  const [operand, setOperand] = useState("");
  const [equationValue, setEquationValue] = useState("");
  const [resErrorFlag, setResErrorFlag] = useState(false);
  const isExpandALL = useContext(IsExpandAllContext);

  useEffect(() => {
    toggleResponseEffect(isExpandALL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpandALL]);

  const handleSelectVarChange = (e) => {
    if (e.target.value !== "" && e.target.value !== undefined) {
      const [value, name] = e.target.value.split(":::");
      setSelectedVar({ value, name });
    } else {
      setSelectedVar(e.target.value);
    }
    setDefSelectedVar(e.target.value);
  };

  const handleSelectOpperandChange = (e) => {
    setOperand(e.target.value);
  };

  const handleSelectRespChange = (e) => {
    setSelectedResp(e.target.value);
  };

  const handleEquationValueChange = (e) => {
    setEquationValue(e.target.value);
  };

  const resetResEffectSetting = () => {
    setDefSelectedVar("");
    setSelectedVar("");
    setSelectedResp("");
    setOperand("");
    setEquationValue("");
    setResErrorFlag(false);
  }

  const addResponseEffectSetting = () => {
    let errorFlag = false;
    if (isNullOrEmpty(selectedResp) || isNullOrEmpty(selectedVar) || isNullOrEmpty(operand) || isNullOrEmpty(equationValue)) {
      setResErrorFlag(true);
      errorFlag = true;
    }
    if (!errorFlag) {
      const responseEffect = {
        resp: selectedResp,
        var: selectedVar,
        operand,
        val: equationValue,
      };

      const responseEffectsList = editTrackState.responseEffects
        ? editTrackState.responseEffects
        : [];
      setEditTrackState({
        ...editTrackState,
        responseEffects: responseEffectsList.concat(responseEffect),
      });
      resetResEffectSetting();
    }
  };

  const handleConfirmation = (id) => {
    props.setCurrentEffect(id);
    let currentEffect = editTrackState.responseEffects[id];
    let selectedFffectSetting = currentEffect.resp + " => " + currentEffect.var.name + " | " + currentEffect.operand + " | " + currentEffect.val;
    props.setMsg("<h4>Are you sure you want to delete <span class='text-secondary'>" + selectedFffectSetting + "</span> Response Effect Setting?");
    props.setHandleDelete("deleteEffect");
    props.setOpenCnfDia(true);
  };

  const getResponseEffectsList = () => {
    if (!isNullOrEmpty(editTrackState.responseEffects)) {
      return editTrackState.responseEffects.map((s, i) => {
        return (
          <p className="listItem" key={i}>
            <span>
              {s.resp} =&#62; {s.var.name} <b>|{s.operand}|</b> {s.val}
            </span>
            <IconButton onClick={() => handleConfirmation(i)}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          </p>
        );
      });
    }
  };

  const operandList = ["+", "-", "="];
  const responseEffectClass = isExpand ? "expand" : "collapse";

  return (
    <>
      <div className="heading">
        <h3>Response Effects Settings</h3>
        {markup}
      </div>
      <div className={responseEffectClass}>
        <MuiThemeProvider theme={formLabelsTheme}>
          <form noValidate autoComplete="off">
            <div className="visibility_setting_row">
              <FormControl
                variant="outlined"
                required
                error={isNullOrEmpty(selectedResp) && resErrorFlag && true}>
                <InputLabel htmlFor="respList">Response</InputLabel>
                <Select
                  native
                  name="respList"
                  id="respList"
                  value={selectedResp}
                  onChange={handleSelectRespChange}
                >
                  <option aria-label="None" value=""></option>
                  {!isNullOrEmpty(editTrackState.options) &&
                    editTrackState.options.map((opt, i) => {
                      return (
                        <option key={i} value={opt.response}>
                          {opt.response}
                        </option>
                      );
                    })}
                </Select>
                {isNullOrEmpty(selectedResp) && resErrorFlag && <FormHelperText style={{ color: "red" }}>The response option is required.</FormHelperText>}
              </FormControl>
              <FormControl
                variant="outlined"
                required
                error={isNullOrEmpty(selectedVar) && resErrorFlag && true}>
                <InputLabel htmlFor="varList">Sim Variable</InputLabel>
                <Select
                  native
                  name="varList"
                  id="varList"
                  value={defSelectedVar}
                  onChange={handleSelectVarChange}
                >
                  <option aria-label="None" value="" />
                  {variableState.map((v) => {
                    const newVal = v.id + ":::" + v.name;
                    return (
                      <option key={v.id} value={newVal} name={v.name} title={v.desc}>
                        {v.name}
                      </option>
                    );
                  })}
                </Select>
                {isNullOrEmpty(selectedVar) && resErrorFlag && <FormHelperText style={{ color: "red" }}>The sim variable option is required.</FormHelperText>}
              </FormControl>
              <FormControl
                variant="outlined"
                required
                error={isNullOrEmpty(operand) && resErrorFlag && true}>
                <InputLabel htmlFor="operandList">Operand</InputLabel>
                <Select
                  native
                  name="operandList"
                  id="operandList"
                  value={operand}
                  onChange={handleSelectOpperandChange}
                >
                  <option aria-label="None" value=""></option>
                  {operandList.map((v) => {
                    return (
                      <option key={v} value={v}>
                        {v}
                      </option>
                    );
                  })}
                </Select>
                {isNullOrEmpty(operand) && resErrorFlag && <FormHelperText style={{ color: "red" }}>The operand option is required.</FormHelperText>}
              </FormControl>
              <TextField
                required
                error={isNullOrEmpty(equationValue) && resErrorFlag && true}
                name="equationValue"
                id="equationValue"
                label="Value"
                variant="outlined"
                value={equationValue}
                onChange={handleEquationValueChange}
                helperText={isNullOrEmpty(equationValue) && resErrorFlag && "The value field is required."}
              />
              <IconButton onClick={addResponseEffectSetting}>
                <AddCircleIcon />
              </IconButton>
            </div>
          </form>
        </MuiThemeProvider>
        {getResponseEffectsList()}
      </div>
    </>
  );
};


export default ResponseEffect;