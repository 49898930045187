import React from 'react';
import { IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const SimBuilderCharacterRow = (props) => {

    return (
        <>
            <div className="simBuilder_actor_row">
                <div className="flexCol">
                    <h5 className="name">{props.name}</h5>
                    <p className="title">{props.title}</p>
                </div>
                <div>
                    <IconButton onClick={()=> props.handleSelectCharacter(props.id)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton onClick={()=>props.handleDeleteCharacter(props.id, props.name)}>
                        <DeleteIcon />
                    </IconButton>
                </div>
            </div>
        </>
    );
}

export default SimBuilderCharacterRow;