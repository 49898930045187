import React from "react";
const PersonalInfo = (props) => {
    return (
        <div className="report_item">
            <h3>Personal Information</h3>
            <div className={props.layout ? "personal-info-pdf" : "personal-info"}>
                <p>
                    <span className={props.layout ? "" : "font-weight-bolder"}>First Name:</span> <span>{props.simPlayedSummary?.firstName}</span>
                </p>
                <p>
                    <span className={props.layout ? "" : "font-weight-bolder"}>Last Name:</span> <span>{props.simPlayedSummary?.lastName}</span>
                </p>
                <p>
                    <span className={props.layout ? "" : "font-weight-bolder"}>Email:</span> <span>{props.simPlayedSummary?.email}</span>
                </p>
            </div>
            {props.simPlayedSummary?.assignmentSubmission &&
                <div>
                    <p className="mt-2">
                        <span className={props.layout ? "" : "font-weight-bolder"}> Final Assignment Submission:</span> <span>{props.simPlayedSummary?.assignmentSubmission}</span>
                    </p>
                </div>
            }
        </div>
    );
}

export default PersonalInfo;