import React from 'react';
import LinkButton from './generic/LinkButton';

const NotFoundPage = () => {

    const goHome = () => {
        this.props.history.replace('/app');
    }

    return (
        <>
            <h1>404 - Page not found.</h1>
            <p>The page or asset you were trying to access does not exist.</p>
            <LinkButton onClick={goHome} title="Go Home" />
        </>
    );
}

export default NotFoundPage;