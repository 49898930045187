import React, { useEffect, useState, useCallback, useRef, Fragment, useContext } from 'react';
import { IconButton, Tooltip, ListItemIcon, ListItemText } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from '@material-ui/icons/Visibility';
import DescriptionIcon from '@material-ui/icons/Description';
import ArchiveIcon from '@material-ui/icons/Archive';
import Table from '../generic/Table';
import Loader from "../loader";
import { SimIdeasContext } from "../../context/SimIdeasContext";
import { LibraryContext } from "../../context/LibraryContext";
import { UserContext } from '../../context/UserContext';
import { useHistory } from "react-router-dom";
import Alert from "../generic/Alerts";
import DateUtil from '../../utils/dateUtil';
import SimService from '../../services/SimService';
import TrackService from '../../services/TrackService';
import { StyledMenu, StyledMenuItem } from '../../utils/utils';

const SimIdeas = () => {
    console.debug("Rendring Check: SimIdeas");
    const history = useHistory();
    const { token } = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const { simIdeasList, setSimIdea, getAllIdeas } = useContext(SimIdeasContext);
    const [editSimState, setEditSimState] = useState(
        {
            enableTabs: false,
            title: "",
            desc: "",
            time: 0,
            published: false,
            archived: false,
            showCharacterIntro: false,
            showEmailReview: false,
            showSMSReview: false,
            showPhoneReview: false,
            createdDate: null,
            editDate: 0,
            actors: [],
            tracks: [],
            vars: [],
        });
    const libraryContext = useContext(LibraryContext);
    const [isSaved, setIsSaved] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertProps, setAlertProps] = useState({
        msg: "",
        type: "info"
    });
    const [localIdea, setLocalIdea] = useState({});
    const selectedIdea = useRef(null);
    const initialMouseState = { mouseX: null, mouseY: null };
    const [mouseState, setMouseState] = useState(initialMouseState);
    const columns = [
        {
            dataField: "clientName",
            text: "Creator Name",
            sort: true,
            classes: "content-body clientName",
            headerClasses: "table_header_color",
            align: "left",
        }, {
            dataField: "clientEmail",
            text: "Creator Email",
            sort: true,
            classes: "content-body clientName",
            headerClasses: "table_header_color",
            align: "left",
        },
        {
            dataField: "title",
            text: "Sim Name",
            sort: true,
            classes: "content-body title",
            headerClasses: "table_header_color",
            align: "left",
        },
        {
            dataField: "createdAt",
            text: "Created Date",
            sort: true,
            classes: "content-body",
            headerClasses: "table_header_color text-center",
            headerStyle: {minWidth: 150},
            align: "center",
            formatter: (cell, record) => {
                return DateUtil.formatDate(new Date(record.createdAt), "dd/mm/yyyy");
            }
        },
        {
            dataField: "action",
            text: "Action",
            classes: "content-body",
            headerClasses: "text-center",
            headerStyle: {minWidth: 210},
            align: "center",
            formatter: (cell,record) => {
                return (
                    <Fragment>
                        <div onClick={(event) => handleActionMenuClick(event, record)}>
                            <Tooltip title="Action Menu" placement="top">
                                <IconButton>
                                    <MoreVertIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </Fragment>
                );
            }
        }
    ];

    const editIdea = () => {
        setSimIdea(selectedIdea.current);
        history.push("/editSimIdea");
    };

    const viewIdea = () => {
        localStorage.setItem('viewIdea', JSON.stringify(selectedIdea.current));
        handleActionMenuClose();
        window.open("/viewSimIdea", "_blank");
    };

    const viewDocs = () => {
        if (selectedIdea.current.documents !== undefined && selectedIdea.current.documents.length > 0) {
            localStorage.setItem('viewIdea', JSON.stringify(selectedIdea.current));
            handleActionMenuClose();
            window.open("/viewDocs", "_blank");
        } else {
            const msg = `"${selectedIdea.current.title}" simulation idea have no documents!`;
            handleActionMenuClose();
            setOpenAlert(true);
            setAlertProps({ ...alertProps, msg: msg, type: 'info' });
        }
    };

    const exportIdea = () => {
        setLocalIdea(selectedIdea.current);
        setIsSaved(true);
        setEditSimState({ ...editSimState, title: selectedIdea.current.title, desc: selectedIdea.current.desc });
        handleActionMenuClose();
    };

    const handleActionMenuClick = useCallback((event, record) => {
        event.preventDefault();
        selectedIdea.current = record;
        setMouseState({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleActionMenuClose = useCallback(() => {
        setMouseState(initialMouseState);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setLoading(true);
        getAllIdeas((hideLoader) => {
            if (hideLoader) {
                setLoading(false);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [simIdeasList]);

    useEffect(() => {
        if (isSaved) {
            setLoading(true);
            SimService().createSim({ ...editSimState }, token)
                .then((response) => {
                    if (response.status === 200) {
                        libraryContext.addSimToLibrary(response.data);
                        let simId = response.data.id;
                        if (localIdea.tracks.length > 0) {
                            localIdea.tracks.forEach(track => {
                                TrackService().addTrack({
                                    simId: simId,
                                    body: track.comments,
                                    promptDetails: track.comments,
                                    transcript: track.comments,
                                    desc: track.comments,
                                    order: track.idx + 1,
                                    title: track.name,
                                    type: track.type,
                                    checkUnavailableOptions: false,
                                }, token).then((response) => {
                                    if (response.status === 200) {
                                        console.log('Saved tracker to DB');
                                    } else {
                                        console.error(track.name, "Error: while saving Sim Idea Track!");
                                    }
                                }).catch((e) => {
                                    console.log(track.name, 'Error: could not save track', e.message);
                                });
                            });
                        }
                        setIsSaved(false);
                        setLoading(false);
                        setOpenAlert(true);
                        const msg = "Sim Idea exported successfully!";
                        setAlertProps({ ...alertProps, msg: msg, type: 'success' });
                    }
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editSimState]);

    return (
        <div className="card">
            <h3 className="card-header h4 color-blue">Simulation Ideas</h3>
            <div className="card-body">
                {loading &&
                    <div className="overlay">
                        <Loader />
                    </div>
                }
                <div className="overflow-auto">
                    <Table
                        data={simIdeasList}
                        columns={columns}
                        remote={false}
                        id="simIdeaId"
                        select={false}
                    />
                </div>
                <StyledMenu
                    keepMounted
                    open={mouseState.mouseY !== null}
                    onClose={handleActionMenuClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        mouseState.mouseY !== null && mouseState.mouseX !== null
                            ? { top: mouseState.mouseY, left: mouseState.mouseX }
                            : undefined
                    }
                >
                    <StyledMenuItem onClick={editIdea}>
                        <ListItemIcon><EditIcon fontSize="small" color="primary" /></ListItemIcon>
                        <ListItemText primary="Edit Idea" />
                    </StyledMenuItem>
                    <StyledMenuItem onClick={viewIdea}>
                        <ListItemIcon><VisibilityIcon fontSize="small" color="primary" /></ListItemIcon>
                        <ListItemText primary="View Idea" />
                    </StyledMenuItem>
                    <StyledMenuItem onClick={viewDocs}>
                        <ListItemIcon><DescriptionIcon fontSize="small" color="primary" /></ListItemIcon>
                        <ListItemText primary="View Docs" />
                    </StyledMenuItem>
                    <StyledMenuItem onClick={exportIdea}>
                        <ListItemIcon><ArchiveIcon fontSize="small" color="primary" /></ListItemIcon>
                        <ListItemText primary="Export Idea" />
                    </StyledMenuItem>
                </StyledMenu>
                {openAlert && <Alert {...alertProps} openAlert={openAlert} setOpenAlert={setOpenAlert} />}
            </div>
        </div>
    );
}

export default SimIdeas;