import React from "react";
import logo from "../assets/images/logo.png";
import queryString from "query-string";

const InfoPage = () => {
    let params = queryString.parse(window.location.search);

    return (
        <div className="col-8 offset-2 text-center">
            <img src={logo} alt="SchoolSims Logo" />
            <h3>{params["msg"] ? params["msg"] : ""}</h3>
        </div>
    );
};


export default InfoPage;