import { TRACK_TYPE } from "../../utils/utils";

const InternalSimTracksInfo = ({ tracks }) => {
  return (
    <>
      <div className="col-12 bg-heading mb-3">
        <h4>Tracks Info</h4>
      </div>
      {tracks.length > 0 &&
        tracks.map((track) => (
          <>
            <div className="col-2 border-bottom mb-2">
              <p className="font-weight-bold">Track {track.order}:</p>
            </div>
            <div className="col-10 border-bottom mb-2">
              <div className="row">
                <div className="col-2">
                  <p className="font-weight-bold">Track Name:</p>
                </div>
                <div className="col-10">{track.title}</div>
                <div className="col-2">
                  <p className="font-weight-bold">Track Type:</p>
                </div>
                <div className="col-10">{track.type}</div>
                <div className="col-2">
                  <p className="font-weight-bold">Body:</p>
                </div>
                <div className="col-10 text-justify">
                  {track.type === TRACK_TYPE.Q
                    ? track.promptDetails
                    : track.body}
                </div>
              </div>
            </div>
          </>
        ))}
    </>
  );
};

export default InternalSimTracksInfo;
