import React, { useState, useContext } from 'react';
import { IconButton } from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle';
import VariableService from '../../services/VariableService';
import SimBuilderVariableEdit from './SimBuilderVariableEdit';
import SimBuilderVariableList from './SimBuilderVariableList';
import { VariableContext } from '../../context/VariableContext';
import { UserContext } from '../../context/UserContext';
import Alert from '../generic/Alerts';
import { isNullOrEmpty } from '../../utils/utils';

const VariableTab = () => {

    const { variableState, addVariable, updateVariable, deleteVariable } = useContext(VariableContext);
    const { token } = useContext(UserContext);
    const [selected, setSelected] = useState();
    const [openAlert, setOpenAlert] = useState(false);
    const [alertProps, setAlertProps] = useState({ msg: "", type: "" });

    const handleVariableSelect = (id) => {
        const rtn = variableState.filter((v) => { return v.id === id });
        setSelected({ ...rtn[0] });
    }

    const handleVariableDelete = async (id) => {
        await deleteVariable(id, (status) => {
            if (status) {
                setAlertProps({ msg: "Variable deleted successfully!", type: "success" })
            } else {
                setAlertProps({ msg: "Variable in use, please remove dependency from tracks!", type: "error" })
            }
            setOpenAlert(true);
        });
    }

    const handleSave = (val) => {
        if (!isNullOrEmpty(selected) && !isNullOrEmpty(selected.id)) {
            updateVariable({ ...val });
        } else {
            addVariable({ ...val });
        }
        clearSelect();
    }

    const clearSelect = () => {
        setSelected(undefined);
    }

    const deleteSliderLabel = (localState, id, indexSlideLabel) => {
        if (id !== undefined) {
            const varIdPredicate = (variable) => variable.id === selected.id;
            const sliderLabelIdPredicate = (SliderLabel) => SliderLabel.id === id;

            const currentVarIndex = variableState.findIndex(varIdPredicate);
            const currentSliderLabelIndex = variableState[currentVarIndex].sliderLabels.findIndex(sliderLabelIdPredicate);

            VariableService().deleteSliderLabel(id, token)
                .then(response => {
                    if (response.status === 200) {
                        setSelected({
                            ...localState,
                            sliderLabels: localState.sliderLabels.filter(
                                (val, index) => {
                                    return id !== undefined ? val.id !== id : indexSlideLabel !== index
                                }
                            )
                        });
                        //updating local state
                        variableState[currentVarIndex].sliderLabels.splice(currentSliderLabelIndex, 1);
                        setAlertProps({ ...alertProps, msg: "Success: deleted successfully!", type: 'success' });
                        setOpenAlert(true);
                    } else {
                        setAlertProps({ ...alertProps, msg: "Error: while deleting!", type: 'error' });
                        setOpenAlert(true);
                    }
                })
                .catch(e => {
                    console.error(e.message);
                    setAlertProps({ ...alertProps, msg: "Error: while deleting!", type: 'error' });
                    setOpenAlert(true);
                });
        } else {
            setSelected({
                ...localState,
                sliderLabels: localState.sliderLabels.filter(
                    (val, index) => {
                        return id !== undefined ? val.id !== id : indexSlideLabel !== index
                    }
                )
            });
            setAlertProps({ ...alertProps, msg: "Success: deleted successfully!", type: 'success' });
            setOpenAlert(true);
        }
    };

    return (
        <>
            <div className="variableTab">
                <div className="block-width">
                    <SimBuilderVariableList list={variableState} handleVariableSelect={handleVariableSelect} handleVariableDelete={handleVariableDelete} />
                </div>
                <div className="variable_edit">
                    {selected && <IconButton onClick={clearSelect}><AddCircleIcon color="primary" /></IconButton>}
                    <SimBuilderVariableEdit val={selected} handleSave={handleSave} deleteSliderLabel={deleteSliderLabel} />
                </div>
                {openAlert && <Alert {...alertProps} openAlert={openAlert} setOpenAlert={setOpenAlert} />}
            </div>
        </>
    );
}

export default VariableTab;