import React, { useContext } from "react";

import { EditSimContext } from "../../../context/EditSimContext";

const TrackReport = () => {
  const { editSimState } = useContext(EditSimContext);

  //computer the nav list only when nav list changes
  const getDisplayList = () => {
    return editSimState.tracks.filter((track) => track.type === "question" && !track.flashTrack).map((track) => {
      return (
        <div className="trackDetailReportRow" key={track.id}>
          <h3>#{track.order} {track.title} - <span>{track.header}</span></h3>
          <p>Lesson: {track.lesson}</p>
          <ul>
            {track.options.map((option) => {
              return (
                <li key={option.id}>
                  <h3>Response: {option.response}</h3>
                  <p><strong>Feedback</strong></p>
                  <p>{option.feedback ? option.feedback : 'N/A'}</p>
                  <p><strong>Additional</strong></p>
                  <p>{option.info ? option.info : 'N/A'}</p>
                </li>
              )
            })}
          </ul>
        </div>
      )
    })
  };

  return (
    <>
      <div>{getDisplayList()}</div>
    </>
  );
};


export default TrackReport;