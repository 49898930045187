import React, { useContext, useEffect, useState } from "react";
import { EditTrackContext } from "../../../context/EditTrackContext";
import EndTrack from "../../player/EndTrack";
import FreeTextResponseTrack from "../../player/FreeTextResponseTrack";
import QuestionResponseTrack from "../../player/QuestionResponseTrack";
import TextTrack from "../../player/TextTrack";
import VideoTrack from "../../player/VideoTrack";
import EmailTrack from "../../player/EmailTrack";
import PDFTrack from "../../player/PDFTrack";
import Scorecard from "../../player/Scorecard.js";
import PhoneTrack from "../../player/PhoneTrack";
import SMSTrack from "../../player/SMSTrack";
import useExpandCollapse from "../../../hooks/useExpandCollapse";
import {Button, Slider} from "@material-ui/core";
import NewWindow from "react-new-window";

const TrackPreview = () => {
  const [isExpandPreview, markupExpandPreview, toggleExpand] = useExpandCollapse({ vertical: false });
  const { editTrackState } = useContext(EditTrackContext);
  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => {
    toggleExpand(true);
    setIsFullScreen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editTrackState?.order]);

  const preview = () => {
    let view = null;
    if (editTrackState) {
      switch (editTrackState.type) {
        case "video":
          view = <VideoTrack {...editTrackState} debug={true} />;
          break;
        case "text":
          view = <TextTrack {...editTrackState} debug={true} />;
          break;
        case "question":
          view = <QuestionResponseTrack {...editTrackState} debug={true} />;
          break;
        case "freetext":
          view = <FreeTextResponseTrack {...editTrackState} debug={true} />;
          break;
        case "email":
          view = <EmailTrack {...editTrackState} debug={true} />;
          break;
        case "sms":
          view = <SMSTrack {...editTrackState} debug={true} />;
          break;
        case "end":
          view = <EndTrack {...editTrackState} debug={true} />;
          break;
        case "pdf":
          view = <PDFTrack {...editTrackState} debug={true} />;
          break;
        case "scorecard":
          view = <Scorecard {...editTrackState} debug={true} />;
          break;
        case "phone":
          view = <PhoneTrack {...editTrackState} debug={true} />;
          break;
        default:
          view = <></>
      }
    }
    return view;
  };

  const previewClass = isExpandPreview ? "expand" : "collapse";
  return <>
    {
      editTrackState?.type ?
        <div className="heading track_preview_expand">
          <h1>Track Preview</h1>{markupExpandPreview}
        </div>
        :
        ""
    }
    <div className={previewClass}>
      <div className="track_preview_inner">
        {!isFullScreen &&
          editTrackState?.type ?
          <Button className="preview_fullscreen_button" variant="contained" color="success" onClick={() => setIsFullScreen(true)}>Preview FullScreen</Button>
          :
          ""}
        {preview()}
      </div>
    </div>
    {isFullScreen &&
      <NewWindow copyStyles={true} title="Track Preview" onUnload={() => setIsFullScreen(false)}>
        <div className="track_preview">
          <div className="d-none">
            <Slider />
          </div>
          <div className="track_preview_inner track_preview_inner_fullscreen">
            {preview()}
          </div>
        </div>
      </NewWindow>
    }
  </>;
};


export default TrackPreview;