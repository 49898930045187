import { TextField } from "@material-ui/core";
import Alert from "../../../generic/Alerts";
import { default as React, useContext, useEffect, useState } from "react";
import { EditTrackContext } from "../../../../context/EditTrackContext";
import { UserContext } from '../../../../context/UserContext';
import TrackService from "../../../../services/TrackService";
import ConfirmationDilaog from '../../../generic/ConfirmationDilaog';
import Editor, { defultEditorText } from "../../../third-party/Editor";
import SimBuilderTrackConditionalVisibility from "./SimBuilderTrackConditionalVisibility";
import SimBuilderTrackFormActions from "./SimBuilderTrackFormActions";
import SimBuilderTrackGeneral from "./SimBuilderTrackGeneral";
import SimBuilderTrackVisibility from "./SimBuilderTrackVisibility";
import FlashSetting from "./SimBuilderFlashSetting";
import { EditSimContext } from "../../../../context/EditSimContext";
import { isNullOrEmpty } from "../../../../utils/utils";
import useExpandCollapse from "../../../../hooks/useExpandCollapse";
import { IsExpandAllContext } from "../../../../context/IsExpandAllContext";

const SMSTrackEdit = (props) => {
    const [isExpandSlideControl, markupSlideControl, toggleExpandSlideControl] = useExpandCollapse({ vertical: false });
    const { editTrackState, setEditTrackState, prevTrackState, setPrevTrackState } = useContext(EditTrackContext);
    const { editSimState, setEditSimState } = useContext(EditSimContext);
    const { token } = useContext(UserContext);
    const [currentVS, setCurrentVS] = useState();
    const [msg, setMsg] = useState("");
    const [openCnfDia, setOpenCnfDia] = useState(false);
    const [handleDelete, setHandleDelete] = useState("");
    const [alertProps, setAlertProps] = useState({ msg: "", type: "" });
    const [openAlert, setOpenAlert] = useState(false);
    const [showVliadiationError, setShowValidationError] = useState(false);
    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
    const isExpandALL = useContext(IsExpandAllContext);

    useEffect(() => {
        toggleExpandSlideControl(isExpandALL);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isExpandALL]);

    useEffect(() => {
        props.isExpand(markupSlideControl);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isExpandSlideControl]);

    useEffect(() => {
        return () => { props.isExpand("") }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'contactNumber') {
            setEditTrackState({ ...editTrackState, [name]: value });
            if (!isNullOrEmpty(value) && !props.simpleValidator.current.check(value, 'numeric|min:0,num')) {
                setIsValidPhoneNumber(false);
                props.simpleValidator.current.showMessageFor('Sender Number');
            } else {
                setIsValidPhoneNumber(true);
            }
        }
        else {
            setEditTrackState({ ...editTrackState, [name]: value });
        }
    };

    const handleEditorChange = (name, value) => {
        setEditTrackState({ ...editTrackState, [name]: value });
        if (defultEditorText === value && (prevTrackState.body === defultEditorText || prevTrackState.body === "")) {
            setPrevTrackState({ ...prevTrackState, [name]: value });
        }
    };

    const handleClose = () => {
        setOpenCnfDia(false);
    }

    const deleteResponseVsSetting = () => {
        let id = editTrackState.visibilitySettings[currentVS].id;

        if (id && id !== null && id !== "") {
            const trackByIdPredicate = (track) => track.id === editTrackState.id;
            const responseCondVisbIdPredicate = (vs) => vs.id === id;

            const currentTrackIndex = editSimState.tracks.findIndex(trackByIdPredicate);
            const currentVsIndex = editSimState.tracks[currentTrackIndex].visibilitySettings.findIndex(responseCondVisbIdPredicate);

            TrackService().deleteResponseVS(id, token)
                .then(response => {
                    if (response.status === 200) {
                        if (!isNullOrEmpty(prevTrackState.visibilitySettings)) {
                            setPrevTrackState({
                                ...prevTrackState,
                                visibilitySettings: prevTrackState.visibilitySettings.filter((val, index) => {
                                    return index !== currentVS;
                                })
                            });
                        }

                        setEditTrackState({
                            ...editTrackState,
                            visibilitySettings: editTrackState.visibilitySettings.filter((val, index) => {
                                return index !== currentVS;
                            })
                        });

                        //always set simState after setting track state
                        setEditSimState({
                            ...editSimState,
                            visibilitySettings: editSimState.tracks[currentTrackIndex].visibilitySettings.splice(currentVsIndex, 1)
                        });
                        setAlertProps({ ...alertProps, msg: "Success: deleted successfully!", type: 'success' });
                        setOpenAlert(true);
                        setOpenCnfDia(false);
                    } else {
                        setAlertProps({ ...alertProps, msg: "Error: while deleting!", type: 'error' });
                        setOpenAlert(true);
                    }
                })
                .catch(e => {
                    console.error(e.message);
                    setAlertProps({ ...alertProps, msg: "Error: while deleting!", type: 'error' });
                    setOpenAlert(true);
                });
        } else {
            if (!isNullOrEmpty(prevTrackState.visibilitySettings)) {
                setPrevTrackState({
                    ...prevTrackState,
                    visibilitySettings: prevTrackState.visibilitySettings.filter((val, index) => {
                        return index !== currentVS;
                    })
                });
            }
            setEditTrackState({
                ...editTrackState,
                visibilitySettings: editTrackState.visibilitySettings.filter((val, index) => {
                    return index !== currentVS;
                })
            });
            setAlertProps({ ...alertProps, msg: "Success: deleted successfully!", type: 'success' });
            setOpenAlert(true);
            setOpenCnfDia(false);
        }
    };

    const slideControlClass = isExpandSlideControl ? "expand" : "collapse";

    return (
        <>
            <div className={slideControlClass}>
                <SimBuilderTrackGeneral />
                <TextField
                    variant="outlined"
                    name="senderName"
                    id="senderName"
                    label="Sender Name"
                    value={editTrackState.senderName}
                    onChange={handleChange}
                />
                <TextField
                    error={!isValidPhoneNumber}
                    variant="outlined"
                    name="contactNumber"
                    label="Sender Number"
                    value={editTrackState.contactNumber}
                    onChange={handleChange}
                    helperText={props.simpleValidator.current.message('Sender Number', editTrackState.contactNumber, 'numeric|min:0,num')}
                />
                <TextField
                    variant="outlined"
                    name="receiverList"
                    id="receiverList"
                    label="Receiver List"
                    value={editTrackState.receiverList}
                    multiline
                    minRows={1}
                    maxRows={4}
                    onChange={handleChange}
                />
                <TextField
                    variant="outlined"
                    name="receivedTime"
                    id="receivedTime"
                    label="Received Time (Minutes Before)"
                    value={editTrackState.receivedTime}
                    onChange={handleChange}
                />
                <Editor
                    name="body"
                    id="body"
                    title="SMS Body"
                    data={editTrackState.body}
                    handleEditorChange={handleEditorChange}
                />
            </div>
            <FlashSetting simpleValidator={props.simpleValidator} showVliadiationError={showVliadiationError} />
            <SimBuilderTrackVisibility simpleValidator={props.simpleValidator} />
            <SimBuilderTrackConditionalVisibility setMsg={setMsg} setOpenCnfDia={setOpenCnfDia} setHandleDelete={setHandleDelete} setCurrentVS={setCurrentVS} />
            <SimBuilderTrackFormActions simpleValidator={props.simpleValidator} setShowValidationError={setShowValidationError} />
            {openCnfDia &&
                handleDelete === "deleteVS" &&
                <ConfirmationDilaog msg={msg} handleDelete={deleteResponseVsSetting} handleClose={handleClose} />
            }
            {openAlert && <Alert {...alertProps} openAlert={openAlert} setOpenAlert={setOpenAlert} />}
        </>
    );
};

export default SMSTrackEdit;