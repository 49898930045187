import Select from "react-select";
import { TextField } from "@material-ui/core";
import { useRef } from "react";

const customStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: "250px", // Fixed height for the dropdown menu
    overflowY: "auto", // Makes the dropdown scrollable if content exceeds the fixed height
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  control: (provided) => ({
    ...provided,
    minHeight: "50px",
  }),
};

const MultiSelect = ({ options, selectedOptions, handleChange }) => {
  const selectRef = useRef(null);

  return (
    <TextField
      variant="outlined"
      label={selectedOptions.length > 0 && "Standards"}
      className="mt-2"
      sx={{ width: "98%" }}
      InputLabelProps={{
        shrink: selectedOptions.length > 0,
      }}
      InputProps={{
        inputComponent: ({ inputRef }) => (
          <Select
            ref={selectRef}
            inputRef={inputRef}
            isMulti
            value={selectedOptions}
            onChange={handleChange}
            options={options}
            placeholder="Standards"
            styles={customStyles}
            className="w-100"
          />
        ),
      }}
    />
  );
};

export default MultiSelect;
