import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
  DialogContentText
} from "@material-ui/core";
import { useState } from "react";


const SimPlayTypeDialog = ({open, handleClose, handlePlaySim, setIsPlaySimLineraly}) => {
  const [isRadioSelected, setIsRadioSelected] = useState(false);

  const handleRadioButtonChange=(e)=>{
    setIsRadioSelected(e.target.value !== '');
    setIsPlaySimLineraly(JSON.parse(e.target.value))
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className="color-blue">
        Play Sim By
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Select Play Simulation Method
          </DialogContentText>
        <FormControl>
          <RadioGroup onChange={handleRadioButtonChange}>
            <FormControlLabel value="false" control={<Radio />} label="Branching" />
            <FormControlLabel value="true" control={<Radio />} label="Sequence" />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}  
          variant="contained"
          color="secondary"
          className="buttonStyle"
        >
          Close
        </Button>
        <Button onClick={handlePlaySim}
          autoFocus
          variant="contained"
          color="primary"
          className="buttonStyle"
          disabled={!isRadioSelected}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SimPlayTypeDialog;