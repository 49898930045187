import RequestService, { HttpMethods } from "../utils/requestService";
import { SIM_BUILDER_API_END_POINT } from "../Config";

const SimSessionService = () => {
    return Object.freeze({
        saveOrUpdateSimSession: async (simSession) => {
            const options = RequestService.makeRequest(HttpMethods.POST);
            options['body'] = JSON.stringify(simSession);

            const result = await fetch(SIM_BUILDER_API_END_POINT + '/simSession/save', options);
            const response = await result.json();

            return response;
        },
        retrieveSimSessionData: async (simSession) => {
            const options = RequestService.makeRequest(HttpMethods.POST);
            options['body'] = JSON.stringify(simSession);

            const result = await fetch(SIM_BUILDER_API_END_POINT + '/simSession/retrieveSimSessionData', options);
            let response = await result.text();
            if (response !== "") {
                response = JSON.parse(response);
            }
            return response;
        },
        simNotPlayAgain: async (simSession) => {
            const options = RequestService.makeRequest(HttpMethods.POST);
            options['body'] = JSON.stringify(simSession);

            const result = await fetch(SIM_BUILDER_API_END_POINT + '/simSession/notPlayAgain', options);
            const response = await result.text();
            return response;
        }
    });
}

export default SimSessionService;