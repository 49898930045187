import { Paper, Tab, Tabs } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { EditSimContext } from '../../context/EditSimContext';

const SimBuilderTabBar = (props) => {

    const [value, setValue] = useState(props.tabIndex);
    const { editSimState } = useContext(EditSimContext);

    const tabList = ["General", "Characters", "Media", "Variables", "Tracks"]

    const handleChange = (event, newValue) => {
        setValue(newValue);
        props.setCurrentTab(tabList[newValue]);
    };

    return (
        <div className="builderTabs">
            <Paper square>
                <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                >
                    {tabList.map((val) => <Tab key={val} label={val} disabled={!(val === 'General' || editSimState.enableTabs)} />)}
                </Tabs>
            </Paper>
        </div>
    );
}

export default SimBuilderTabBar;