import React, { useContext, useEffect, useState } from "react";
import { FormControl, IconButton, InputLabel, Select, FormHelperText } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import { EditTrackContext } from "../../../../context/EditTrackContext";
import { EditSimContext } from "../../../../context/EditSimContext";
import { isNullOrEmpty } from "../../../../utils/utils";
import { MuiThemeProvider } from '@material-ui/core/styles';
import formLabelsTheme from '../../../generic/FormLabelsThem';
import useExpandCollapse from "../../../../hooks/useExpandCollapse";
import { IsExpandAllContext } from "../../../../context/IsExpandAllContext";

const ResponseLoopEffect = (props) => {
  const [isExpand, markup, toggleResponseLoopEffect] = useExpandCollapse({ vertical: false });
  //State of track being edited
  const { editTrackState, setEditTrackState } = useContext(EditTrackContext);

  const { editSimState } = useContext(EditSimContext);

  //Local form selections
  const [rlsErrorFlag, setRlsErrorFlag] = useState(false);
  const [selectedResp, setSelectedResp] = useState("");
  const [selectedGoToTrackId, setSelectedGoToTrackId] = useState("");
  const [selectedGoToTrackName, setSelectedGoToTrackName] = useState();
  const isExpandALL = useContext(IsExpandAllContext);

  useEffect(() => {
    toggleResponseLoopEffect(isExpandALL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpandALL]);

  const handleSelectRespChange = (e) => {
    setSelectedResp(e.target.value);
  };

  const handleSelectTrackChange = (e) => {
    const { options, selectedIndex } = e.target;

    setSelectedGoToTrackId(e.target.value);
    setSelectedGoToTrackName(options[selectedIndex].innerHTML);
  };

  const resetResLoopSetting = () => {
    setSelectedResp("");
    setSelectedGoToTrackId("");
    setSelectedGoToTrackName("");
    setRlsErrorFlag(false);
  };

  const addVisiblitySetting = () => {
    let errorFlag = false;
    if (isNullOrEmpty(selectedResp) || isNullOrEmpty(selectedGoToTrackId)) {
      setRlsErrorFlag(true);
      errorFlag = true;
    }

    if (!errorFlag) {
      const newResponseLoop = {
        resp: selectedResp,
        goTo: { id: selectedGoToTrackId, name: selectedGoToTrackName },
      };

      const responseLoopList = editTrackState.responseLoops
        ? editTrackState.responseLoops
        : [];
      setEditTrackState({
        ...editTrackState,
        responseLoops: responseLoopList.concat(newResponseLoop),
      });
      resetResLoopSetting();
    }
  };

  const handleConfirmation = (id) => {
    props.setCurrentLoop(id);
    let currentLoop = editTrackState.responseLoops[id];
    props.setMsg("<h4>Are you sure you want to delete <span class='text-secondary'>" + currentLoop.resp + "</span> Response Loop Setting?");
    props.setHandleDelete("deleteLoop");
    props.setOpenCnfDia(true);
  };

  const getResponseEffectsLoop = () => {
    if (!isNullOrEmpty(editTrackState.responseLoops)) {
      return editTrackState.responseLoops.map((s, i) => {
        return (
          <p className="listItem" key={"respLoop_" + s.rep + "_" + s.goTo.id}>
            <span>
              {s.resp} ={">"} {s.goTo.name}
            </span>
            <IconButton onClick={() => handleConfirmation(i)}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          </p>
        );
      });
    }
  };
  const responseLoopEffectClass = isExpand ? "expand" : "collapse";

  return (
    <>
      <div className="heading">
        <h3>Response Loop Settings</h3>
        {markup}
      </div>
      <div className={responseLoopEffectClass}>
        <MuiThemeProvider theme={formLabelsTheme}>
          <form noValidate autoComplete="off">
            <div className="visibility_setting_row">
              <FormControl
                variant="outlined"
                required
                error={isNullOrEmpty(selectedResp) && rlsErrorFlag && true}>
                <InputLabel htmlFor="varList">Response</InputLabel>
                <Select
                  native
                  name="respList"
                  id="respList"
                  onChange={handleSelectRespChange}
                  value={selectedResp}
                >
                  <option aria-label="None" value=""></option>
                  {!isNullOrEmpty(editTrackState.options) &&
                    editTrackState.options
                      .filter((opt) => {
                        //Remove already selected response items
                        let found = false;
                        if (editTrackState.responseLoops) {
                          editTrackState.responseLoops.map((loopEffect) => {
                            if (loopEffect.resp === opt.response) {
                              found = true;
                            }
                            return "";
                          });
                        }
                        return !found;
                      })
                      .map((opt, i) => {
                        return (
                          <option key={i} value={opt.response}>
                            {opt.response}
                          </option>
                        );
                      })}
                </Select>
                {isNullOrEmpty(selectedResp) && rlsErrorFlag && <FormHelperText style={{ color: "red" }}>The response option is required.</FormHelperText>}
              </FormControl>
              <FormControl
                variant="outlined"
                required
                error={isNullOrEmpty(selectedGoToTrackId) && rlsErrorFlag && true}>
                <InputLabel htmlFor="varList">Go To Track</InputLabel>
                <Select
                  native
                  name="respList"
                  id="respList"
                  onChange={handleSelectTrackChange}
                  value={selectedGoToTrackId}
                >
                  <option aria-label="None" value=""></option>
                  {!isNullOrEmpty(editSimState.tracks) &&
                    editSimState.tracks.map((t, i) => {
                      if (!t.flashTrack) {
                        return (
                          <option key={i} value={t.id} data={t.title}>
                            {t.title}
                          </option>
                        );
                      }
                      return "";
                    })}
                </Select>
                {isNullOrEmpty(selectedGoToTrackId) && rlsErrorFlag && <FormHelperText style={{ color: "red" }}>The goToTrack option is required.</FormHelperText>}
              </FormControl>
              <IconButton onClick={addVisiblitySetting}>
                <AddCircleIcon />
              </IconButton>
            </div>
          </form>
        </MuiThemeProvider>
        {getResponseEffectsLoop()}
      </div>
    </>
  );
};

export default ResponseLoopEffect;