export const INTERNAL_SIM_ALERTS = Object.freeze({
  bothXLSSheetsMissing:
    "The 'Tracks' and 'Characters' sheets are missing. Could you please upload a file that includes either of these sheets?",

  missingSheet: (sheetName) =>
    `${sheetName} sheet is missing. Are you sure you want to proceed without ${sheetName}?`,

  bothFoldersMissing:
    "The 'characters' and 'media' folders are missing from the ZIP file. Could you please include either of these folders?",

  missingFolder: (folderName) =>
    `${folderName} folder is missing. Do you want to proceed without ${folderName} folder?`,

  errorDeleteInternalSimMessage: {
    msg: "Error while deleting internal simulation.",
    type: "error",
  },

  errorExportInternalSimMessage: {
    msg: "Error while exporting internal simulation.",
    type: "error",
  },

  errorGetInternalSimsMessage: {
    msg: "Your session has been expired. Please login again.",
    type: "error",
  },

  errorSaveInternalSimulation: {
    msg: "Error: Some columns contain text that exceeds the character limits set by the already implemented functionality in the track fields. Please ensure the file follows the specified format.",
    type: "error",
  },

  continueWithoutMediaAlertMessage:
    "Would you like to proceed without media? If Yes, then you can still add media manually after exporting the simulation to the Simulation Library as we add media to any of the simulations in the Media tab.",

  trackHeaderColumnMissing:
    "Title/Header column is missing in Tracks sheet. Please attach the correct file.",

  charcterNameColumnMissing:
    "Character's Name column is missing in Charcaters Sheet. Please attach the correct file.",

  emptyFileError:
    "Your file is empty and contains no data. Please upload a file with data included.",

  xlsFileNotValid: "Only xls file is allowed.",
    
  zipFileNotValid: "Only zip files are allowed.",
});
