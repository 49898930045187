import { SIM_BUILDER_API_END_POINT } from "../Config";
import RequestService, { HttpMethods } from "../utils/requestService";

const SimIdeaService = () => {
    return Object.freeze({
        getAllSimIdeas: async (token) => {
            const options = RequestService.makeRequest(HttpMethods.GET, token);

            const result = await fetch(SIM_BUILDER_API_END_POINT + '/simIdeas', options);
            const response = await result.json();

            return response;
        },
        saveSimIIdea: async (simIdea) => {
            const options = RequestService.makeRequest(HttpMethods.POST);
            options['body'] = JSON.stringify(simIdea);

            const result = await fetch(SIM_BUILDER_API_END_POINT + '/simIdeas/save', options);
            const response = await result.json();

            return response;
        },
        updateSimIdea: async (token, simIdea) => {
            const options = RequestService.makeRequest(HttpMethods.POST, token);
            options['body'] = JSON.stringify(simIdea);

            const result = await fetch(SIM_BUILDER_API_END_POINT + '/simIdeas/update', options);
            const response = await result.json();

            return response;
        },
    });
}

export default SimIdeaService;