import React, { useContext, useEffect, useState } from "react";
import { FormControl, FormControlLabel, InputLabel, Select, Switch, TextField } from "@material-ui/core";
import Alert from "../../../generic/Alerts";
import { EditTrackContext } from '../../../../context/EditTrackContext';
import { MediaContext } from "../../../../context/MediaContext";
import { UserContext } from '../../../../context/UserContext';
import TrackService from "../../../../services/TrackService";
import ConfirmationDilaog from '../../../generic/ConfirmationDilaog';
import Editor, { defultEditorText } from "../../../third-party/Editor";
import SimBuilderTrackConditionalVisibility from "./SimBuilderTrackConditionalVisibility";
import SimBuilderTrackFormActions from "./SimBuilderTrackFormActions";
import SimBuilderTrackGeneral from "./SimBuilderTrackGeneral";
import SimBuilderTrackVisibility from "./SimBuilderTrackVisibility";
import FlashSetting from "./SimBuilderFlashSetting";
import { EditSimContext } from "../../../../context/EditSimContext";
import { isNullOrEmpty } from "../../../../utils/utils";
import useExpandCollapse from "../../../../hooks/useExpandCollapse";
import { IsExpandAllContext } from "../../../../context/IsExpandAllContext";

const PhoneTrackEdit = (props) => {
    const [isExpandSlideControl, markupSlideControl, toggleExpandSlideControl] = useExpandCollapse({ vertical: false });
    const { sounds } = useContext(MediaContext);
    const { editTrackState, setEditTrackState, prevTrackState, setPrevTrackState } = useContext(EditTrackContext);
    const { editSimState, setEditSimState } = useContext(EditSimContext);
    const { token } = useContext(UserContext);
    const [currentVS, setCurrentVS] = useState();
    const [msg, setMsg] = useState("");
    const [openCnfDia, setOpenCnfDia] = useState(false);
    const [handleDelete, setHandleDelete] = useState("");
    const [alertProps, setAlertProps] = useState({ msg: "", type: "" });
    const [openAlert, setOpenAlert] = useState(false);
    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
    const [isValidCl, setIsValidCl] = useState(true);
    const isExpandALL = useContext(IsExpandAllContext);

    useEffect(() => {
        toggleExpandSlideControl(isExpandALL);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isExpandALL]);

    useEffect(() => {
        props.isExpand(markupSlideControl);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isExpandSlideControl]);

    useEffect(() => {
        return () => { props.isExpand("") }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'callLength') {
            setEditTrackState({ ...editTrackState, [name]: value });
            if (!isNullOrEmpty(value) && !props.simpleValidator.current.check(value, 'numeric|min:0,num')) {
                setIsValidCl(false);
                props.simpleValidator.current.showMessageFor('Call Length');
            } else {
                setIsValidCl(true);
            }
        }
        if (name === 'contactNumber') {
            setEditTrackState({ ...editTrackState, [name]: value });
            if (!isNullOrEmpty(value) && !props.simpleValidator.current.check(value, 'numeric|min:0,num')) {
                setIsValidPhoneNumber(false);
                props.simpleValidator.current.showMessageFor('Contact Number');
            } else {
                setIsValidPhoneNumber(true);
            }
        }
        else {
            setEditTrackState({ ...editTrackState, [name]: value });
        }
    };

    const handleSoundChange = (e) => {
        const { name, value } = e.target;

        setEditTrackState({
            ...editTrackState,
            pubUrl: value !== "" ? sounds.find((audio) => audio.id === value).url : null,
            [name]: value !== "" ? value : null
        });
    };

    const handleEditorChange = (name, value) => {
        setEditTrackState({ ...editTrackState, [name]: value });
        if (defultEditorText === value
            && (isNullOrEmpty(prevTrackState.transcript) || prevTrackState.transcript === defultEditorText)) {
            setPrevTrackState({ ...prevTrackState, [name]: value });
        }
    };

    const handleCheckChanged = (e) => {
        const { name, checked } = e.target;
        setEditTrackState({ ...editTrackState, [name]: checked });
    };

    const handleClose = () => {
        setOpenCnfDia(false);
    }

    const deleteResponseVsSetting = () => {
        let id = editTrackState.visibilitySettings[currentVS].id;

        if (id && id !== null && id !== "") {
            const trackByIdPredicate = (track) => track.id === editTrackState.id;
            const responseCondVisbIdPredicate = (vs) => vs.id === id;

            const currentTrackIndex = editSimState.tracks.findIndex(trackByIdPredicate);
            const currentVsIndex = editSimState.tracks[currentTrackIndex].visibilitySettings.findIndex(responseCondVisbIdPredicate);

            TrackService().deleteResponseVS(id, token)
                .then(response => {
                    if (response.status === 200) {
                        if (!isNullOrEmpty(prevTrackState.visibilitySettings)) {
                            setPrevTrackState({
                                ...prevTrackState,
                                visibilitySettings: prevTrackState.visibilitySettings.filter((val, index) => {
                                    return index !== currentVS;
                                })
                            });
                        }
                        setEditTrackState({
                            ...editTrackState,
                            visibilitySettings: editTrackState.visibilitySettings.filter((val, index) => {
                                return index !== currentVS;
                            }),
                        });

                        //always set simState after setting track state 
                        setEditSimState({
                            ...editSimState,
                            visibilitySettings: editSimState.tracks[currentTrackIndex].visibilitySettings.splice(currentVsIndex, 1)
                        });

                        setAlertProps({ ...alertProps, msg: "Success: deleted successfully!", type: 'success' });
                        setOpenAlert(true);
                        setOpenCnfDia(false);
                    } else {
                        setAlertProps({ ...alertProps, msg: "Error: while deleting!", type: 'error' });
                        setOpenAlert(true);
                    }
                })
                .catch(e => {
                    console.error(e.message);
                    setAlertProps({ ...alertProps, msg: "Error: while deleting!", type: 'error' });
                    setOpenAlert(true);
                });
        } else {
            if (!isNullOrEmpty(prevTrackState.visibilitySettings)) {
                setPrevTrackState({
                    ...prevTrackState,
                    visibilitySettings: prevTrackState.visibilitySettings.filter((val, index) => {
                        return index !== currentVS;
                    })
                });
            }
            setEditTrackState({
                ...editTrackState,
                visibilitySettings: editTrackState.visibilitySettings.filter((val, index) => {
                    return index !== currentVS;
                }),
            });
            setAlertProps({ ...alertProps, msg: "Success: deleted successfully!", type: 'success' });
            setOpenAlert(true);
            setOpenCnfDia(false);
        }
    };

    const slideControlClass = isExpandSlideControl ? "expand" : "collapse";

    return (
        <>
            <div className={slideControlClass}>
                <SimBuilderTrackGeneral noAudio={true} />
                <div className="phone_toggles">
                    <FormControlLabel
                        control={
                            <Switch
                                id="skipRinger"
                                name="skipRinger"
                                checked={editTrackState.skipRinger}
                                onChange={handleCheckChanged}
                            />
                        }
                        label="Skip Phone Ring"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                id="manualAudio"
                                name="manualAudio"
                                checked={editTrackState.manualAudio}
                                onChange={handleCheckChanged}
                                defaultValue={true}
                            />
                        }
                        label="Manually Play Audio"
                    />
                </div>
                <TextField
                    variant="outlined"
                    name="contactName"
                    label="Contact Name"
                    value={editTrackState.contactName}
                    onChange={handleChange}
                />
                <TextField
                    error={!isValidPhoneNumber}
                    variant="outlined"
                    name="contactNumber"
                    label="Contact Number"
                    value={editTrackState.contactNumber}
                    onChange={handleChange}
                    helperText={props.simpleValidator.current.message('Contact Number', editTrackState.contactNumber, 'numeric|min:0,num')}
                />
                <TextField
                    error={!isValidCl}
                    variant="outlined"
                    name="callLength"
                    label="Call Length (In Seconds)"
                    value={editTrackState.callLength}
                    onChange={handleChange}
                    helperText={props.simpleValidator.current.message('Call Length', editTrackState.callLength, 'numeric|min:0,num')}
                />
                <Editor
                    name="transcript"
                    id="transcript"
                    title="Phone Call Transcript"
                    data={editTrackState.transcript}
                    handleEditorChange={handleEditorChange}
                />
                <FormControl variant="outlined">
                    <InputLabel htmlFor="responseType">Sound Asset</InputLabel>
                    <Select
                        native
                        name="url"
                        id="url"
                        value={editTrackState.url ? editTrackState.url : ""}
                        onChange={handleSoundChange}
                    >
                        <option aria-label="None" value="" />
                        {sounds.map((media) => {
                            return (
                                <option key={media.id} value={media.id}>
                                    {media.name}
                                </option>
                            );
                        })}
                    </Select>
                </FormControl>
            </div>
            <FlashSetting simpleValidator={props.simpleValidator} />
            <SimBuilderTrackVisibility simpleValidator={props.simpleValidator} />
            <SimBuilderTrackConditionalVisibility setMsg={setMsg} setOpenCnfDia={setOpenCnfDia} setHandleDelete={setHandleDelete} setCurrentVS={setCurrentVS} />
            <SimBuilderTrackFormActions simpleValidator={props.simpleValidator} />

            {openCnfDia &&
                handleDelete === "deleteVS" &&
                <ConfirmationDilaog msg={msg} handleDelete={deleteResponseVsSetting} handleClose={handleClose} />
            }
            {openAlert && <Alert {...alertProps} openAlert={openAlert} setOpenAlert={setOpenAlert} />}
        </>
    );
};


export default PhoneTrackEdit;