import { SIM_BUILDER_API_END_POINT } from "../Config";
import RequestService, { HttpMethods } from "../utils/requestService";

const VariableService = () => {
    return Object.freeze({
        getVariablesBySimId: async (simId, token) => {
            const options = RequestService.makeRequest(HttpMethods.GET, token);

            const result = await fetch(SIM_BUILDER_API_END_POINT + '/vars/' + simId, options);
            const response = await result.json();

            return response;
        },
        addVariable: async (vars, token) => {
            const options = RequestService.makeRequest(HttpMethods.POST, token);
            options['body'] = JSON.stringify(vars);

            const result = await fetch(SIM_BUILDER_API_END_POINT + '/vars', options);
            const response = await result.json();

            return response;
        },
        updateVariable: async (vars, token) => {
            const options = RequestService.makeRequest(HttpMethods.POST, token);
            options['body'] = JSON.stringify(vars);

            const result = await fetch(SIM_BUILDER_API_END_POINT + '/vars', options);
            const response = await result.json();

            return response;
        },
        deleteVariable: async (id, token) => {
            const options = RequestService.makeRequest(HttpMethods.DELETE, token);

            const result = await fetch(SIM_BUILDER_API_END_POINT + '/vars/' + id, options);
            const response = await result.json();

            return response;
        },
        deleteSliderLabel: async (id, token) => {
            const options = RequestService.makeRequest(HttpMethods.DELETE, token);

            const result = await fetch(SIM_BUILDER_API_END_POINT + '/vars/sliderLabel/' + id, options);
            const response = await result.json();

            return response;
        }
    });
}

export default VariableService;