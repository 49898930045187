import React from "react";

import ClearIcon from "@material-ui/icons/Clear";
import CallReceivedIcon from "@material-ui/icons/CallReceived";
import { IconButton, Tooltip } from "@material-ui/core";

const RecentCalls = (props) => {
    const getCallTime = (time) => {
        if (time) {
            const hr = Math.floor((time / 3660) % 60);
            const min = Math.floor((time / 60) % 60);
            const sec = Math.floor(time % 60);
            return (
                "(" +
                (hr > 0 ? ` ${hr} hrs` : "") +
                "" +
                (min > 0 ? ` ${min} Min` : "") +
                "" +
                (sec > 0 ? ` ${sec} Sec` : "") +
                " )"
            );
        } else {
            return "--";
        }
    };
    return (
        <div>
            <div className="popup_background">
                <div className="popup">
                    <div className="popup_close">
                        <Tooltip title="Close Button">
                            <IconButton
                                onClick={props.handleClosePopup}
                                aria-label="Close Button"
                            >
                                <ClearIcon style={{ color: "black" }} />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div className="popup_content">
                        {props.calls.length < 1 &&
                            "Your phone has not buzzed all day, what a relief!"}
                        {props.calls.map((call) => {
                            return (
                                <div className="call_log_item">
                                    <CallReceivedIcon />
                                    <span className="from">
                                        {call.contactName || call.contactNumber}
                                    </span>
                                    <span>{getCallTime(call.callLength)}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RecentCalls;
