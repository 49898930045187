import React, { useContext, useEffect } from "react";
import SimBuilderTextTrackEdit from "./edit/SimBuilderTextTrackEdit";
import SimBuilderFreeTextTrackEdit from "./edit/SimBuilderFreeTextTrackEdit";
import SimBuilderQuestionTrackEdit from "./edit/SimBuilderQuestionTrackEdit";
import SimBuilderVideoTrackEdit from "./edit/SimBuilderVideoTrackEdit";
import SimBuilderEmailTrackEdit from "./edit/SimBuilderEmailTrackEdit";
import SimBuilderPhoneTrackEdit from "./edit/SimBuilderPhoneTrackEdit";
import SimBuilderPDFTrackEdit from "./edit/SimBuilderPDFTrackEdit";
import SimBuilderScorecardTrackEdit from "./edit/SimBuilderScorecardTrackEdit";
import { EditTrackContext } from "../../../context/EditTrackContext";
import SimBuilderSMSTrackEdit from "./edit/SimBuilderSMSTrackEdit";
import useExpandCollapse from "../../../hooks/useExpandCollapse";

const TrackControlPannel = (props) => {
    const [isExpandALL, markupExpandALL, toggleExpandALL] = useExpandCollapse({ vertical: false });
    const { editTrackState } = useContext(EditTrackContext);
    const isExpand = props.setMarkupSetting;
    const expandALL = props.setMarkupExapndALL;

    useEffect(() => {
        if (editTrackState?.order) {
            expandALL({ isExpandALL: isExpandALL, markupExpandALL: markupExpandALL });
            toggleExpandALL(true);
        } else {
            expandALL({ isExpandALL: isExpandALL, markupExpandALL: "" });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editTrackState?.order]);

    useEffect(() => {
        if (editTrackState?.order) {
            expandALL({ isExpandALL: isExpandALL, markupExpandALL: markupExpandALL });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isExpandALL]);

    useEffect(() => {
        document.querySelector(".content").style.overflowY = "hidden";
        return () => { document.querySelector(".content").style.overflowY = "auto"; }
    }, []);

    const controlPanelView = () => {
        let view = null;
        if (editTrackState) {
            switch (editTrackState.type) {
                case "text":
                    view = (
                        <SimBuilderTextTrackEdit
                            key={editTrackState.id + "_" + editTrackState.type}
                            simpleValidator={props.simpleValidator}
                            isExpand={isExpand}
                            isExpandALL={isExpandALL}
                        />
                    );
                    break;
                case "freetext":
                    view = (
                        <SimBuilderFreeTextTrackEdit
                            key={editTrackState.id + "_" + editTrackState.type}
                            simpleValidator={props.simpleValidator}
                            isExpand={isExpand}
                            isExpandALL={isExpandALL}
                        />
                    );
                    break;
                case "question":
                    view = (
                        <SimBuilderQuestionTrackEdit
                            key={editTrackState.id + "_" + editTrackState.type}
                            simpleValidator={props.simpleValidator}
                            isExpand={isExpand}
                            isExpandALL={isExpandALL}
                        />
                    );
                    break;
                case "video":
                    view = (
                        <SimBuilderVideoTrackEdit
                            key={editTrackState.id + "_" + editTrackState.type}
                            simpleValidator={props.simpleValidator}
                            isExpand={isExpand}
                            isExpandALL={isExpandALL}
                        />
                    );
                    break;
                case "email":
                    view = (
                        <SimBuilderEmailTrackEdit
                            key={editTrackState.id + "_" + editTrackState.type}
                            simpleValidator={props.simpleValidator}
                            isExpand={isExpand}
                            isExpandALL={isExpandALL}
                        />
                    );
                    break;
                case "sms":
                    view = (
                        <SimBuilderSMSTrackEdit
                            key={editTrackState.id + "_" + editTrackState.type}
                            simpleValidator={props.simpleValidator}
                            isExpand={isExpand}
                            isExpandALL={isExpandALL}
                        />
                    );
                    break;
                case "phone":
                    view = (
                        <SimBuilderPhoneTrackEdit
                            key={editTrackState.id + "_" + editTrackState.type}
                            simpleValidator={props.simpleValidator}
                            isExpand={isExpand}
                            isExpandALL={isExpandALL}
                        />
                    );
                    break;
                case "pdf":
                    view = (
                        <SimBuilderPDFTrackEdit
                            key={editTrackState.id + "_" + editTrackState.type}
                            simpleValidator={props.simpleValidator}
                            isExpand={isExpand}
                            isExpandALL={isExpandALL}
                        />
                    );
                    break;
                case "scorecard":
                    view = (
                        <SimBuilderScorecardTrackEdit
                            key={editTrackState.id + "_" + editTrackState.type}
                            simpleValidator={props.simpleValidator}
                            isExpand={isExpand}
                            isExpandALL={isExpandALL}
                        />
                    );
                    break;
                default:
                    view = <h4>Control panel not available for this track type</h4>;
                    break;
            }
        }
        return view;
    };

    return <div className="controlPanel">{controlPanelView()}</div>;
};


export default TrackControlPannel;