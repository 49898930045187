import React, { createContext, useState, useMemo } from 'react';

export const EditTrackContext = createContext();

//Provide a cached list of simulations in the library.
const EditTrackContextProvider = (props) => {

    const [editTrackState, setEditTrackState] = useState(null);
    const [prevTrackState, setPrevTrackState] = useState(null);
    const [showValidationError, setShowValidationError] = useState(false);

    const editTrackProviderValue = useMemo(() => (
        { editTrackState, setEditTrackState, prevTrackState, setPrevTrackState, showValidationError, setShowValidationError }
    ), [editTrackState, setEditTrackState, prevTrackState, setPrevTrackState, showValidationError, setShowValidationError]);

    return (
        <EditTrackContext.Provider value={editTrackProviderValue} >
            {props.children}
        </EditTrackContext.Provider>
    );
}

export default EditTrackContextProvider;