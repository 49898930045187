import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import { useState, useContext } from "react";
import AIImagesLayout from "./AIImagesLayout";
import LoaderComponent from "../loader";
import { UserContext } from "../../context/UserContext";
import AIService from "../../services/AIService";
import AlertDialog from "./Alerts";
import LoaderWrapper from "./LoaderWrapper";
import { AI_IMAGES_ERROR_MSG } from "../../utils/utils";
import { AI_TEXT_LENGTH_LIMITATION } from "../../utils/fieldValidation";

const AITextPrompt = ({ open, handleClose, isRadio }) => {
  const [openImageBox, setOpenImageBox] = useState(false);
  const [searchedImages, setSearchedImages] = useState([]);
  const [textValue, setTextValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useContext(UserContext);
  const [alertProps, setAlertProps] = useState({ msg: "", type: "" });
  const [openAlert, setOpenAlert] = useState(false);

  const handleOpenImageBox = () => {
    setOpenImageBox(!openImageBox);
  };

  const handleTextBoxChange = (e) => {
    if (e.target.value.trim().length >= AI_TEXT_LENGTH_LIMITATION) {
      e.preventDefault();
    }
    setTextValue(e.target.value.trim());
  };

  const handleImageGeneration = async () => {
    try {
      const response = await AIService().getAIGeneratedImages(
        { prompt: textValue.trim(), seed: 0 },
        token
      );

      if (response.status === 200) {
        setSearchedImages(response.data);
        setOpenImageBox(true);
      } else if (response.status >= 400 && response.status < 500) {
        setAlertProps(AI_IMAGES_ERROR_MSG.alertMessage);
        setOpenAlert(true);
      } else if (response.status >= 500) {
        setAlertProps(AI_IMAGES_ERROR_MSG.timedOutAlert);
        setOpenAlert(true);
      } else {
          setAlertProps(AI_IMAGES_ERROR_MSG.unableAlert);
          setOpenAlert(true);
        }
    } catch (error) {
      setAlertProps(AI_IMAGES_ERROR_MSG.timedOutAlert);
      setOpenAlert(true);
    }
  };

  const handleSearchImages = async () => {
    setIsLoading(true);
    await handleImageGeneration();
    setIsLoading(false);
  };

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="md">
        <DialogTitle id="alert-dialog-title">Find Images</DialogTitle>
        <DialogContent>
          <textarea
            name="description"
            rows={6}
            maxLength={AI_TEXT_LENGTH_LIMITATION}
            placeholder="Enter Image Description...."
            className="form-control form-control-success fields-font"
            onChange={handleTextBoxChange}
          />
          {textValue.trim() !== "" && textValue.trim().length < 3 && (
            <p className="text-danger m-1">
             {AI_IMAGES_ERROR_MSG.textValidationMessage}
            </p>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            className="buttonStyle"
            disabled={textValue.trim().length < 3}
            onClick={handleSearchImages}
          >
            Search for Images
          </Button>

          <Button
            variant="contained"
            color="secondary"
            onClick={handleClose}
            className="buttonStyle"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {openImageBox && (
        <AIImagesLayout
          open={openImageBox}
          value={textValue}
          isRadio={isRadio}
          handleOpenImageBox={handleOpenImageBox}
          closeTextPrompt={handleClose}
          searchedImages={searchedImages}
        />
      )}
      {openAlert && (
        <LoaderWrapper>
          <AlertDialog
            {...alertProps}
            openAlert={openAlert}
            setOpenAlert={setOpenAlert}
          />
        </LoaderWrapper>
      )}
      {isLoading && (
        <LoaderWrapper>
          <LoaderComponent />
        </LoaderWrapper>
      )}
    </>
  );
};

export default AITextPrompt;
