import React, { useContext } from "react";

import CharacterList from "./CharacterList";
import { IconButton, Tooltip } from "@material-ui/core";

import ClearIcon from "@material-ui/icons/Clear";
import { PlayerContext } from "../../context/PlayerContext";

const CharacterPopup = (props) => {
    const { playerState } = useContext(PlayerContext);

    return (
        <>
            <div className="popup_background">
                <div className="popup">
                    <div className="popup_close">
                        <Tooltip title="Close Button">
                            <IconButton
                                onClick={props.handleClosePopup}
                                aria-label="Close Button"
                            >
                                <ClearIcon style={{ color: "black" }} />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div className="popup_content">
                        <h1></h1>
                        {playerState.characters.map((character) => {
                            return (
                                <>
                                    <CharacterList {...character} popup={true} />
                                    <hr />
                                </>
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
};

export default CharacterPopup;
