import React, { useState, useMemo, useEffect, useRef, useContext } from "react";
import { Button, IconButton, TextField } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloudUpload from "@material-ui/icons/CloudUpload";
import FileUploader, { FileType } from "../../generic/FileUploader";
import { EditSimContext } from "../../../context/EditSimContext";
import { checkFileType, isNullOrEmpty } from "../../../utils/utils";
import Tooltip from "@material-ui/core/Tooltip";
import { Delete, Visibility } from "@material-ui/icons";
import { UploadS3Files } from "../../../services/S3UplodService";
import Loader from "../../loader";
import { MediaContext } from "../../../context/MediaContext";
import { v4 as uuidv4 } from "uuid";
import AITextPrompt from "../../generic/AITextPrompt";

const MediaAssetEdit = (props) => {
  const { addMediaList } = useContext(MediaContext);
  const [showError, setShowError] = useState(false);
  const [state, setState] = useState({
    name: "",
    description: "",
    lastUploadTime: "",
    loading: false,
  });
  const [url, setUrl] = useState("");
  const [file, setFile] = useState("");
  const [isBatch, setIsBatch] = useState(false);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isLoading] = useState(false);
  const [mediaFileError, setMediaFileError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const { editSimState } = useContext(EditSimContext);
  const [fileSizeError, setFileSizeError] = useState("");
  const [openAIPrompt, setOpenAIPrompt] = useState(false);

  const mediaFileRef = useRef();

  //set state when props change
  useEffect(() => {
    if (props.val === null) {
      setState({
        name: "",
        description: "",
        lastUploadTime: "",
        loading: false,
      });
      setFile(null);
      setUrl(null);
    } else if (!isNullOrEmpty(props.val)) {
      setState({ ...state, ...props.val });
      setUrl(props.val.url);
      setShowError(false);
      setIsBatch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.val]);

  const setIsBatchHandler = (isBatch) => {
    setIsBatch(isBatch);
    setFiles([]);
    setFile("");
    setState({
      name: "",
      description: "",
      lastUploadTime: "",
      loading: false,
    });
    setMediaFileError(false);
    setErrorMsg("");
    setShowError(false);
    setFileSizeError("");
  };

  const onFileChange = (e) => {
    let isError = false;
    let errorMsg = "";
    let tempFiles = files;
    for (let i = 0; i < e.target.files.length; i++) {
      let newFile = e.target.files[i];
      const dataFile = checkFileType(newFile);
      if (dataFile.type !== null) {
        const maxFileSize = dataFile.type === "video" ? 60 : 30;
        newFile = dataFile.tempFile !== null ? dataFile.tempFile : newFile;
        const uniqueName = `${uuidv4()}.${dataFile.type}`;
        const myFile = new File([newFile], uniqueName, { type: newFile.type });
        myFile.typeField = dataFile.type;
        myFile.isupload = newFile.size > 1000000 * maxFileSize ? false : true;
        myFile.maxSize = maxFileSize;
        myFile.tempName = newFile.name;
        tempFiles[tempFiles.length] = myFile;
      } else {
        isError = true;
        errorMsg += "[ " + newFile.name + " ]\n";
      }
    }

    tempFiles.length > 0 && setShowError(false);
    if (isError) {
      setMediaFileError(true);
      const markupErrorMessage = (
        <>
          <strong>Following files are not supported!</strong>
          <h4>{errorMsg}</h4>
        </>
      );
      setErrorMsg(markupErrorMessage);
    }
    else{
      setMediaFileError(false);
    }
    e.target.value = "";
    setFiles([...tempFiles]);
  };

  const deleteFile = (fileNmae) => {
    let newFiles = files;
    const index = newFiles.findIndex((a) => a.name === fileNmae);
    if (index === -1) return;
    newFiles.splice(index, 1);
    setFiles([...newFiles]);
  };

  const previewFile = (file) => {
    const url = URL.createObjectURL(file);
    document.getElementById("filePreview").setAttribute("href", url);
    document.getElementById("filePreview").click();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const type = useMemo(() => {
    let type = null;
    setUrl("");
    if (file) {
      const data = checkFileType(file);
      type = data.type !== null ? data.type : null;
      data.tempFile !== null && setFile(data.tempFile);
      data.type === null &&
        setErrorMsg(
          "The selected file is not supported. Only supported file types are [video/mp4, image/jpeg, image/png, image/gif, audio/mp3, audio/mp4, audio/mpeg, pdf, vtt]"
        );
      data.type === null ? setMediaFileError(true) : setMediaFileError(false);
    }
    return type;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  //File is uploaded and in memory
  const showPreview = useMemo(() => {
    let prev = null;
    if (file) {
      switch (file.type.toLowerCase()) {
        case "video/mp4":
          prev = (
            <video id="vid" controls>
              <source
                src={url ? url : URL.createObjectURL(file)}
                type="video/mp4"
              />
            </video>
          );
          break;
        case "image/jpeg":
        case "image/png":
        case "image/gif":
          prev = (
            <img
              className="previewImg"
              src={url ? url : URL.createObjectURL(file)}
              alt="Media Preview"
            />
          );
          break;
        case "audio/mp3":
        case "audio/mp4":
        case "audio/mpeg":
          prev = (
            <video controls>
              <source
                src={url ? url : URL.createObjectURL(file)}
                type={file.type}
              ></source>
            </video>
          );
          break;
        case "application/pdf":
        case "text/vtt":
          prev = (
            <object
              aria-label="Document"
              data={url ? url : URL.createObjectURL(file)}
              type="application/pdf"
              width="100%"
              height="100%"
            ></object>
          );
          break;
        default:
          break;
      }
    }

    if (!file && url) {
      const type = state.type;
      switch (type) {
        case "video":
          prev = (
            <video id="vid" controls key={url}>
              <source src={url} type="video/mp4" />
            </video>
          );
          break;
        case "image":
          prev = (
            <img
              className="previewImg"
              src={url}
              key={url}
              alt="Media Preview"
            />
          );
          break;
        case "sound":
          prev = (
            <video controls key={url}>
              <source src={url} type="audio/mp3"></source>
            </video>
          );
          break;
        case "pdf":
          prev = <embed src={url} width="100%" height="100%"></embed>;
          break;
        case "vtt":
          prev = (
            <object
              aria-label="Document"
              data={url}
              type="application/pdf"
              width="100%"
              height="100%"
            ></object>
          );
          break;
        default:
          break;
      }
    }

    return prev;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file, url]);

  const handleFileChange = (file) => {
    setFile(file);
  };

  const handleError = (error) => {
    setFileSizeError(error);
  };

  const handleFileUploadFinish = (data) => {
    setIsUploading(false);
    const url = data.fileKey;
    if (!isNullOrEmpty(props.val)) {
      const media = {
        ...props.val,
        ...state,
        file,
        url,
        type,
        extension: file.type.split("/")[1],
      };
      props.handleAdd(media);
    } else if (state.name !== "" && file !== "") {
      props.handleAdd({
        ...state,
        file,
        type,
        url,
        extension: file.type.split("/")[1],
      });
    }

    setFile("");
    setState({ ...state, name: "", description: "" });
    setMediaFileError(false);
    setShowError(false);
    setFileSizeError("");
  };

  const saveBatchHandler = () => {
    if (files.length !== 0) {
      setLoading(true);
      const promises = [];
      let batchFiles = files.filter((file) => file.isupload);
      batchFiles.forEach((file) => {
        const uploadTask = UploadS3Files(file, `${editSimState.id}/`);
        promises.push(uploadTask);
      });
      Promise.all(promises)
        .then((uploadedFiles) => {
          const nameMaxLength = 250;
          let mediaUpload = [];
          batchFiles.forEach((file, i) => {
            let name = file.tempName.split(".")[0];
            mediaUpload.push({
              name:
                name.length > nameMaxLength
                  ? name.substring(0, nameMaxLength)
                  : name,
              description: "",
              extension: file.type.split("/")[1],
              type: file.typeField,
              simId: editSimState.id,
              url: uploadedFiles[i].fileKey,
            });
          });
          addMediaList(mediaUpload);
          setIsBatchHandler(false);
          setLoading(false);
          setShowError(false);
        })
        .catch((err) => console.error(err));
    } else {
      setShowError(true);
    }
  };

  const onSaveClick = () => {
    setShowError(false);

    if (props.val && props.val.id !== "" && state.name !== "" && !file) {
      // Update an existing without file
      const media = { ...props.val, ...state, file: null, url: null };
      props.handleAdd(media);
      setFile("");
      setState({ ...state, name: "", description: "", url: "" });
      setMediaFileError(false);
      setShowError(false);
    } else if (props.val && props.val.id !== "" && state.name !== "" && file) {
      // Update an existing media with file
      const filePath = editSimState.id + "/";
      mediaFileRef.current.startUploading(filePath, file);
      setIsUploading(true);
    } else if (!props.val && state.name !== "" && file) {
      //Create a new media file
      const filePath = editSimState.id + "/";
      mediaFileRef.current.startUploading(filePath, file);
      setIsUploading(true);
    } else {
      setShowError(true);
    }
  };

  return (
    <div className="media_edit">
      {loading && (
        <div className="overlay">
          <Loader />
        </div>
      )}
      <a
        href="/"
        style={{ display: "none" }}
        id="filePreview"
        target={"_blank"}
      >
        Open File
      </a>
      <div className="d-flex">
        {isBatch === false && (
          <div className="inputForm">
            {state.loading && <CircularProgress />}
            {mediaFileError && <Alert severity="error">{errorMsg}</Alert>}
            <div className="">
              <FileUploader
                ref={mediaFileRef}
                title={"Choose File"}
                name="fileToUpload"
                id="fileToUpload"
                icon={<CloudUpload />}
                fileType={FileType.MEDIA_ALL}
                errorMsg={
                  showError &&
                  props.val == null &&
                  (file === "" || file === null)
                    ? "The file field is required."
                    : ""
                }
                onFileSelected={handleFileChange}
                onFinish={handleFileUploadFinish}
                setSizeError={handleError}
              />
            </div>
            <TextField
              error={showError && state.name === ""}
              type="text"
              name="name"
              label="File Name"
              value={state.name}
              onChange={handleChange}
              helperText={
                showError && state.name === "" && "The name field is required."
              }
            />
            <TextField
              type="text"
              name="description"
              value={state.description}
              onChange={handleChange}
              label="Short Description"
              multiline
              minRows={5}
              maxRows={7}
              className="description"
            />
            <Button
              variant="contained"
              color="primary"
              onClick={onSaveClick}
              disabled={
                state.loading || isUploading || mediaFileError || fileSizeError
              }
            >
              {!isNullOrEmpty(props.val)
                ? "Update Asset Details"
                : "Save Asset Details"}
            </Button>
          </div>
        )}
        {isNullOrEmpty(props.val) && isBatch === false && (
          <div className="inputForm">
            <Button
              variant="contained"
              color="primary"
              disabled={state.loading || isUploading}
              onClick={() => {
                setIsBatchHandler(true);
              }}
            >
              Add batch Asset
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={()=>setOpenAIPrompt(true)}
              disabled={state.loading || isUploading}
            >
              Generate New Image Asset
            </Button>
          </div>
        )}
        {isNullOrEmpty(props.val) && isBatch && (
          <div>
            <div className="inputForm">
              <input
                type="file"
                accept={FileType.MEDIA_ALL}
                className="mb-2"
                name="docs"
                id="docs"
                onChange={onFileChange}
                style={{ width: "30%", display: "none" }}
                multiple
              />
              <h3>
                <label htmlFor="docs" className="mb-0">
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<CloudUpload />}
                    component="span"
                  >
                    Choose File
                  </Button>
                </label>
                {files.length === 0 && (
                  <div className="no_file_chosen">No file chosen</div>
                )}
                {showError && (
                  <div className="error">The file field is required.</div>
                )}
              </h3>
            </div>
            <div className="d-flex">
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={saveBatchHandler}
                  disabled={mediaFileError}
                >
                  Save Batch Asset
                </Button>
              </div>
              <div className="ml-2">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setIsBatchHandler(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
            <div className="inputForm">
              {mediaFileError && (
                <Alert severity="error" className="break-word">
                  {errorMsg}
                </Alert>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="preview">
        {isBatch === false && (file || url) && showPreview}
        {isNullOrEmpty(props.val) && isBatch && (
          <ul className="list-group mb-2">
            {files.map((file) => (
              <div className="list-group-item">
                <li className="d-flex justify-content-between align-items-center pr-2 pl-2">
                  <div className="text-break text-left">
                    <h5 className="file_label">{file.tempName}</h5>
                  </div>
                  <div className="d-flex justify-content-end">
                    <Tooltip title="Preview File" placement="bottom-start">
                      <IconButton
                        onClick={() => previewFile(file)}
                        className="p-0 pr-2"
                      >
                        <Visibility color="primary" fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete File" placement="bottom-start">
                      <IconButton
                        onClick={() => deleteFile(file.name)}
                        className="p-0"
                      >
                        <Delete color="secondary" fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </div>
                </li>
                {!file.isupload && (
                  <span className="error">
                    File size is too big. Please choose file under{" "}
                    {file.maxSize}MB.
                  </span>
                )}
              </div>
            ))}
          </ul>
        )}
        {isLoading && <CircularProgress fontSize="x-large" />}
        {openAIPrompt && <AITextPrompt open={openAIPrompt} handleClose={()=>setOpenAIPrompt(false)} isRadio={false} />}
      </div>
    </div>
  );
};

export default MediaAssetEdit;
