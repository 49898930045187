import { SIM_BUILDER_API_END_POINT } from "../Config";
import RequestService, { HttpMethods } from "../utils/requestService";

const SimPlayedSummaryService = () => {
    return Object.freeze({
        saveSimPlayedSummaryResult: async (simPlayedSummaryResult) => {
            const options = RequestService.makeRequest(HttpMethods.POST);
            options['body'] = JSON.stringify(simPlayedSummaryResult);

            const result = await fetch(SIM_BUILDER_API_END_POINT + '/simPlayedSummary/save', options);
            const response = await result.json();

            return response;
        }
    });
}

export default SimPlayedSummaryService;
