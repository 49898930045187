const InternalSimMediaInfo = ({ medias }) => {
  return (
    <>
      <div className="col-12 bg-heading mb-3">
        <h4>Media Info</h4>
      </div>
      {medias.length > 0 &&
        medias.map((media, index) => (
          <>
            <div className="col-2 border-bottom mb-2">
              <p className="font-weight-bold">Media {index + 1}:</p>
            </div>
            <div className="col-10 border-bottom mb-2">
              <div className="row">
                <div className="col-2">
                  <p className="font-weight-bold">Name:</p>
                </div>
                <div className="col-10">{media.name}</div>
                <div className="col-2">
                  <p className="font-weight-bold">Type:</p>
                </div>
                <div className="col-10">{media.type}</div>
                <div className="col-2">
                  <p className="font-weight-bold">Extension:</p>
                </div>
                <div className="col-10 text-justify">{media.extension}</div>
              </div>
            </div>
          </>
        ))}
    </>
  );
};

export default InternalSimMediaInfo;
