import React, { createContext, useContext, useState } from 'react';
import UserService from '../services/UserService';
import { UserContext } from './UserContext';
export const UsersContext = createContext();

const UsersContextProvider = (props) => {
    const { token, logout } = useContext(UserContext)

    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);


    const loadUsers = () => {
        if (users.length === 0 && token) {
            setIsLoading(true);
            UserService().getAllUsers(token).then((response) => {
                if (response.status === 401) {
                    logout();
                } else if (response.status === 200) {
                    setUsers(response.data)
                } else {
                    console.error("Error: Could not load user.", response.message);
                }
                setIsLoading(false);
            }).catch((e) => {
                console.error('Error: Could not load users.', e.message);
                setIsLoading(false);
            });
        }
    }

    return (
        <UsersContext.Provider value={{ isLoading, users, loadUsers }}>
            {props.children}
        </UsersContext.Provider>
    );
}

export default UsersContextProvider;