import { useState } from "react";
import { useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import InternalSimService from "../../services/InternalSimService";
import { UserContext } from "../../context/UserContext";
import LoaderComponent from "../loader";
import { useHistory } from "react-router-dom";
import { ArrowBackIos } from "@material-ui/icons";
import InternalSimGeneralInfo from "./InternalSimGeneralInfo";
import InternalSimTracksInfo from "./InternalSimTracksInfo";
import InternalSimCharactersInfo from "./InternalSimCharcatersInfo";
import InternalSimMediaInfo from "./InternalSimMediaInfo";
import { IconButton } from "@material-ui/core";

const ViewInternalSim = () => {
  const { simId } = useParams();
  const history = useHistory();
  const { token } = useContext(UserContext);
  const [internalSim, setInternalSim] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    InternalSimService()
      .getInternalSimById(simId, token)
      .then((response) => {
        setInternalSim(response.data);
        setLoading(false);
      })
      .catch((e) => {
        console.debug("Error Occurred", e.message);
        setLoading(false);
      });
  }, []);

  return (
    <div className="card">
      <div className="card-header color-blue">
        <h3 className="h4">
          {" "}
          <IconButton
            onClick={() => {
              history.push("/internalSimulations");
            }}
          >
            <ArrowBackIos fontSize="small"/>
          </IconButton>
          View Internal Simulation
        </h3>
      </div>
      <div className="card-body">
        <div className="row">
          <InternalSimGeneralInfo internalSim={internalSim} />
          <InternalSimTracksInfo tracks={internalSim?.tracks || []} />
          <InternalSimCharactersInfo
            characters={internalSim?.characters || []}
          />
          <InternalSimMediaInfo medias={internalSim?.media || []} />
        </div>
        {loading && (
          <div className="overlay">
            <LoaderComponent />
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewInternalSim;
