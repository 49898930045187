import {
  Grid,
  TextField,
  Button,
  Checkbox,
  Box,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import { ArrowBackIos, CloudUpload } from "@material-ui/icons";
import { FileType } from "../generic/FileUploader";
import LoaderComponent from "../loader";
import {
  INTERNAL_SIM_DESC_LENGTH_LIMITATION,
  TITLE_LENGTH_LIMITATION,
} from "../../utils/fieldValidation";
import InternalSimAlertDialog from "../generic/InternalSimAlertDialog";
import { useAddInternalSimHook } from "./useAddInternalSimHook";
import AlertDialog from "../generic/Alerts";
import { INTERNAL_SIM_ALERTS } from "../../utils/AlertDialogMessages";
import LoaderWrapper from "../generic/LoaderWrapper";

const useStyles = makeStyles({
  root: {
    padding: "0px", // Set padding 0 for the checkbox
  },
});

const AddInternalSim = () => {
  const {
    simData,
    titleError,
    fileError,
    loading,
    showMediaAlert,
    xlsSheetMessage,
    showMissingXLSSheetAlert,
    handleInputChange,
    handleExportChange,
    handleInputXLSFile,
    backToInternalSimsList,
    setShowMediaAlert,
    handleMediaUpload,
    validateInternalSimData,
    setShowMissingXLSSheetAlert,
    showMissingZipFolderAlert,
    zipFolderMessage,
    fileNames,
    openAlert,
    alertProps,
    setOpenAlert,
    handleYesForWithoutMedia,
    handleNoForXLSFileAlert,
    handleNoForZipFileAlert,
    handleYesForZipFileAlert,
    inputKey,
    isColumnsPresent,
    emptyFileError,
    isXlsFileValid,
    isZipFileValid,
  } = useAddInternalSimHook();

  const classes = useStyles();

  return (
    <div className="card">
      <div className="card-header color-blue">
        <h3 className="h4">
          <IconButton onClick={backToInternalSimsList}>
            <ArrowBackIos fontSize="small" />
          </IconButton>
          Add Internal Simulation
        </h3>
      </div>

      <div className="card-body">
        <div className="overflow-auto">
          <div className="simBuilderForm">
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <h4 className="color-blue required">Title</h4>
              </Grid>
              <Grid item xs={9}>
                <TextField
                  error={titleError}
                  variant="outlined"
                  id="title"
                  name="title"
                  label="Title"
                  value={simData.title}
                  onChange={handleInputChange}
                  required
                  className="w-75"
                  inputProps={{ maxLength: TITLE_LENGTH_LIMITATION }}
                />
                {titleError && (
                  <p className="error ml-2">The title is required.</p>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <h4 className="color-blue">Description</h4>
              </Grid>
              <Grid item xs={9}>
                <TextField
                  variant="outlined"
                  id="desc"
                  name="description"
                  label="Description"
                  multiline
                  minRows={4}
                  maxRows={4}
                  className="w-75"
                  value={simData.description}
                  onChange={handleInputChange}
                  inputProps={{
                    maxLength: INTERNAL_SIM_DESC_LENGTH_LIMITATION,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <h4 className="color-blue required">
                  Upload Tracks and Characters(.xls)
                </h4>
              </Grid>
              <Grid item xs={9}>
                <div className="ml-1 mt-2">
                  <input
                    key={inputKey}
                    type="file"
                    accept={FileType.XLS}
                    name="docs"
                    id="docs"
                    onChange={handleInputXLSFile}
                    style={{ width: "30%", display: "none" }}
                  />
                  <label htmlFor="docs" className="mb-0">
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<CloudUpload />}
                      component="span"
                    >
                      Choose File
                    </Button>
                  </label>
                  {`  ${fileNames.xls}`}
                  {fileError && (
                    <div className="error">The file field is required.</div>
                  )}
                  {!isColumnsPresent.track && (
                    <div className="error">
                      {INTERNAL_SIM_ALERTS.trackHeaderColumnMissing}
                    </div>
                  )}
                  {!isColumnsPresent.characters && (
                    <div className="error">
                      {INTERNAL_SIM_ALERTS.charcterNameColumnMissing}
                    </div>
                  )}
                  {emptyFileError && (
                    <div className="error">
                      {INTERNAL_SIM_ALERTS.emptyFileError}
                    </div>
                  )}
                  {!isXlsFileValid && (
                    <div className="error">
                      {INTERNAL_SIM_ALERTS.xlsFileNotValid}
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <h4 className="color-blue">Upload Sim Media (.zip)</h4>
              </Grid>
              <Grid item xs={9}>
                <div className="ml-1">
                  <input
                    key={inputKey}
                    type="file"
                    accept={FileType.ZIP}
                    name="zip"
                    id="zip"
                    onChange={handleMediaUpload}
                    style={{ width: "30%", display: "none" }}
                  />
                  <label htmlFor="zip" className="mb-0">
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<CloudUpload />}
                      component="span"
                    >
                      Choose File
                    </Button>
                  </label>
                  {`  ${fileNames.zip}`}
                  {!isZipFileValid && (
                    <div className="error">
                      {INTERNAL_SIM_ALERTS.zipFileNotValid}
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <h4 className="color-blue">Export to Simulation Library</h4>
              </Grid>
              <Grid item xs={9} className="mt-3">
                <Checkbox
                  onChange={handleExportChange}
                  classes={{ root: classes.root }} // Apply custom padding
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 28,
                    },
                  }}
                />
              </Grid>
            </Grid>

            <Box textAlign="right">
              <Button
                color="secondary"
                variant="contained"
                className="justify-content-center"
                onClick={backToInternalSimsList}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                className="justify-content-center"
                onClick={validateInternalSimData}
              >
                Save Simulation
              </Button>
            </Box>
          </div>
        </div>
      </div>
      {loading && (
        <LoaderWrapper>
          <LoaderComponent />
        </LoaderWrapper>
      )}
      {openAlert && (
        <AlertDialog
          {...alertProps}
          openAlert={openAlert}
          setOpenAlert={setOpenAlert}
        />
      )}
      {showMediaAlert && (
        <InternalSimAlertDialog
          open={showMediaAlert}
          message={INTERNAL_SIM_ALERTS.continueWithoutMediaAlertMessage}
          handleNo={() => setShowMediaAlert(false)}
          handleYes={() => handleYesForWithoutMedia(true)}
        />
      )}
      {showMissingXLSSheetAlert && (
        <InternalSimAlertDialog
          open={showMissingXLSSheetAlert}
          message={xlsSheetMessage}
          handleNo={handleNoForXLSFileAlert}
          handleYes={() => setShowMissingXLSSheetAlert(false)}
        />
      )}
      {showMissingZipFolderAlert && (
        <InternalSimAlertDialog
          open={showMissingZipFolderAlert}
          message={zipFolderMessage}
          handleNo={handleNoForZipFileAlert}
          handleYes={handleYesForZipFileAlert}
        />
      )}
    </div>
  );
};

export default AddInternalSim;
