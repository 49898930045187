import React from "react";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const LoaderForModel = () => {
    return (
        <div className="overlayForModels">
            <div className="loaderForModels">
                <Loader type="Bars" color="#272370" height={100} width={100} />
                <h3>Please wait...</h3>
            </div>
        </div>
    );
};

export default LoaderForModel;