import { FormControl, InputLabel, Select } from "@material-ui/core";
import Alert from "../../../generic/Alerts";
import { default as React, useContext, useEffect, useState } from "react";
import { EditTrackContext } from '../../../../context/EditTrackContext';
import { MediaContext } from "../../../../context/MediaContext";
import { UserContext } from '../../../../context/UserContext';
import TrackService from "../../../../services/TrackService";
import ConfirmationDilaog from '../../../generic/ConfirmationDilaog';
import SimBuilderTrackConditionalVisibility from "./SimBuilderTrackConditionalVisibility";
import SimBuilderTrackFormActions from "./SimBuilderTrackFormActions";
import SimBuilderTrackGeneral from "./SimBuilderTrackGeneral";
import SimBuilderTrackVisibility from "./SimBuilderTrackVisibility";
import FlashSetting from "./SimBuilderFlashSetting";
import { EditSimContext } from "../../../../context/EditSimContext";
import { isNullOrEmpty } from "../../../../utils/utils";
import Editor, { defultEditorText } from "../../../third-party/Editor";
import useExpandCollapse from "../../../../hooks/useExpandCollapse";
import { IsExpandAllContext } from "../../../../context/IsExpandAllContext";

const VideoTrackEdit = (props) => {
  const [isExpandSlideControl, markupSlideControl, toggleExpandSlideControl] = useExpandCollapse({ vertical: false });
  const { videos, captions } = useContext(MediaContext);
  const { editTrackState, setEditTrackState, prevTrackState, setPrevTrackState } = useContext(EditTrackContext);
  const { editSimState, setEditSimState } = useContext(EditSimContext);
  const { token } = useContext(UserContext);
  const [currentVS, setCurrentVS] = useState();
  const [msg, setMsg] = useState("");
  const [openCnfDia, setOpenCnfDia] = useState(false);
  const [handleDelete, setHandleDelete] = useState("");
  const [alertProps, setAlertProps] = useState({ msg: "", type: "" });
  const [openAlert, setOpenAlert] = useState(false);
  const [showVliadiationError, setShowValidationError] = useState(false);
  const isExpandALL = useContext(IsExpandAllContext);

  useEffect(() => {
    toggleExpandSlideControl(isExpandALL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpandALL]);

  useEffect(() => {
    props.isExpand(markupSlideControl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpandSlideControl]);

  useEffect(() => {
    return () => { props.isExpand("") }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectChange = (e) => {
    const { name, value } = e.target;

    setEditTrackState({
      ...editTrackState,
      pubUrl: value !== "" ? videos.find((video) => video.id === value).url : null,
      [name]: value !== "" ? value : null,
    });
  };
  const handleCaptionSelectChange = (e) => {
    const { name, value } = e.target;

    setEditTrackState({
      ...editTrackState,
      pubMetaUrl: value !== "" ? captions.find((cap) => cap.id === value).url : null,
      [name]: value !== "" ? value : null,
    });
  };

  const handleEditorChange = (name, value) => {
    setEditTrackState({ ...editTrackState, [name]: value });
    if (value === defultEditorText && (prevTrackState.body === defultEditorText || prevTrackState.body === "")) {
      setPrevTrackState({ ...prevTrackState, [name]: value });
    }
  }

  const handleClose = () => {
    setOpenCnfDia(false);
  }

  const deleteResponseVsSetting = () => {
    let id = editTrackState.visibilitySettings[currentVS].id;

    if (id && id !== null && id !== "") {
      const trackByIdPredicate = (track) => track.id === editTrackState.id;
      const responseCondVisbIdPredicate = (vs) => vs.id === id;

      const currentTrackIndex = editSimState.tracks.findIndex(trackByIdPredicate);
      const currentVsIndex = editSimState.tracks[currentTrackIndex].visibilitySettings.findIndex(responseCondVisbIdPredicate);

      TrackService().deleteResponseVS(id, token)
        .then(response => {
          if (response.status === 200) {
            if (!isNullOrEmpty(prevTrackState.visibilitySettings)) {
              setPrevTrackState({
                ...prevTrackState,
                visibilitySettings: prevTrackState.visibilitySettings.filter((val, index) => {
                  return index !== currentVS;
                })
              });
            }
            setEditTrackState({
              ...editTrackState,
              visibilitySettings: editTrackState.visibilitySettings.filter((val, index) => {
                return index !== currentVS;
              }),
            });

            //always set simState after setting track state 
            setEditSimState({
              ...editSimState,
              visibilitySettings: editSimState.tracks[currentTrackIndex].visibilitySettings.splice(currentVsIndex, 1)
            });

            setAlertProps({ ...alertProps, msg: "Success: deleted successfully!", type: 'success' });
            setOpenAlert(true);
            setOpenCnfDia(false);
          } else {
            setAlertProps({ ...alertProps, msg: "Error: while deleting!", type: 'error' });
            setOpenAlert(true);
          }
        })
        .catch(e => {
          console.error(e.message);
          setAlertProps({ ...alertProps, msg: "Error: while deleting!", type: 'error' });
          setOpenAlert(true);
        });
    } else {
      if (!isNullOrEmpty(prevTrackState.visibilitySettings)) {
        setPrevTrackState({
          ...prevTrackState,
          visibilitySettings: prevTrackState.visibilitySettings.filter((val, index) => {
            return index !== currentVS;
          })
        });
      }
      setEditTrackState({
        ...editTrackState,
        visibilitySettings: editTrackState.visibilitySettings.filter((val, index) => {
          return index !== currentVS;
        }),
      });
      setAlertProps({ ...alertProps, msg: "Success: deleted successfully!", type: 'success' });
      setOpenAlert(true);
      setOpenCnfDia(false);
    }
  };

  const slideControlClass = isExpandSlideControl ? "expand" : "collapse";

  return (
    <>
      <div className={slideControlClass}>
        <SimBuilderTrackGeneral noAudio={true} />
        <FormControl variant="outlined">
          <InputLabel htmlFor="responseType">Video Asset</InputLabel>
          <Select
            native
            name="url"
            id="url"
            value={!isNullOrEmpty(editTrackState.url) ? editTrackState.url : ""}
            onChange={handleSelectChange}
          >
            <option aria-label="None" value="" />
            {videos.map((media) => {
              return (
                <option key={media.id} value={media.id}>
                  {media.name}
                </option>
              );
            })}
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel htmlFor="responseType">Video Caption</InputLabel>
          <Select
            native
            name="metaUrl"
            id="metaUrl"
            value={editTrackState.metaUrl ? editTrackState.metaUrl : ""}
            onChange={handleCaptionSelectChange}
          >
            <option aria-label="None" value="" />
            {captions.map((media) => {
              return (
                <option key={media.id} value={media.id}>
                  {media.name}
                </option>
              );
            })}
          </Select>
        </FormControl>
        <Editor
          name="body"
          id="body"
          title="Script"
          variant="outlined"
          data={editTrackState.body}
          handleEditorChange={handleEditorChange}
        />
      </div>
      <FlashSetting simpleValidator={props.simpleValidator} showVliadiationError={showVliadiationError} />
      <SimBuilderTrackVisibility simpleValidator={props.simpleValidator} />
      <SimBuilderTrackConditionalVisibility setMsg={setMsg} setOpenCnfDia={setOpenCnfDia} setHandleDelete={setHandleDelete} setCurrentVS={setCurrentVS} />
      <SimBuilderTrackFormActions simpleValidator={props.simpleValidator} setShowValidationError={setShowValidationError} />

      {openCnfDia &&
        handleDelete === "deleteVS" &&
        <ConfirmationDilaog msg={msg} handleDelete={deleteResponseVsSetting} handleClose={handleClose} />
      }
      {openAlert && <Alert {...alertProps} openAlert={openAlert} setOpenAlert={setOpenAlert} />}
    </>
  );
};

export default VideoTrackEdit;