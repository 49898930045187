import React from "react";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const LoaderComponent = () => {
    return (
        <div className="sim_loader">
            <div className="loadMessage">
                <Loader type="Bars" color="#272370" height={200} width={200} />
                <h3 className="text-white">Please wait...</h3>
            </div>
        </div>
    );
};

export default LoaderComponent;