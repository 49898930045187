import React, { useContext, useEffect, useState } from "react";
import { FormControl, IconButton, InputLabel, Select, TextField, FormHelperText } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import { EditTrackContext } from "../../../../context/EditTrackContext";
import { VariableContext } from "../../../../context/VariableContext";
import { MuiThemeProvider } from '@material-ui/core/styles';
import formLabelsTheme from '../../../generic/FormLabelsThem';
import { isNullOrEmpty } from "../../../../utils/utils";
import useExpandCollapse from "../../../../hooks/useExpandCollapse";
import { IsExpandAllContext } from "../../../../context/IsExpandAllContext";

const OptionVisibility = (props) => {
  const [isExpand, markup, toggleOptionVisibility] = useExpandCollapse({ vertical: false });
  //Used to get list of variables in the simulation
  const { variableState } = useContext(VariableContext);

  //State of track being edited
  const { editTrackState, setEditTrackState } = useContext(EditTrackContext);

  //Local form selections
  const [selectedResp, setSelectedResp] = useState("");
  const [selectedVar, setSelectedVar] = useState();
  const [operand, setOperand] = useState("");
  const [equationValue, setEquationValue] = useState("");
  const [ovErrorFlag, setOvErrorFlag] = useState(false);
  const isExpandALL = useContext(IsExpandAllContext);

  useEffect(() => {
    toggleOptionVisibility(isExpandALL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpandALL]);

  const handleSelectRespChange = (e) => {
    if (!isNullOrEmpty(e.target.value)) {
      const [name, value] = [e.target[e.target.selectedIndex].textContent, e.target.value];
      setSelectedResp({ value, name });
    } else {
      setSelectedResp("");
    }
  };

  const handleSelectVarChange = (e) => {
    if (!isNullOrEmpty(e.target.value)) {
      const [name, value] = [e.target[e.target.selectedIndex].text, e.target.value];
      setSelectedVar({ value, name });
    } else {
      setSelectedVar("");
    }
  };

  const handleSelectOpperandChange = (e) => {
    setOperand(e.target.value);
  };

  const handleEquationValueChange = (e) => {
    setEquationValue(e.target.value);
  };

  const resetOptionVisibilitySetting = () => {
    setSelectedResp("");
    setSelectedVar("");
    setOperand("");
    setEquationValue("");
    setOvErrorFlag(false);
  }

  const addVisiblitySetting = () => {
    let errorFlag = false;
    if (isNullOrEmpty(selectedResp) || isNullOrEmpty(selectedVar) || isNullOrEmpty(operand) || isNullOrEmpty(equationValue)) {
      setOvErrorFlag(true);
      errorFlag = true;
    }

    if (!errorFlag) {
      const newOvSetting =
      {
        operand: operand,
        val: equationValue,
        optionId: selectedResp,
        variableId: selectedVar,
      };

      const ovSettingList = editTrackState.optionVisibilitySettings
        ? editTrackState.optionVisibilitySettings
        : [];
      setEditTrackState({
        ...editTrackState,
        optionVisibilitySettings: ovSettingList.concat(newOvSetting),
      });
      resetOptionVisibilitySetting();
    }
  };

  const handleConfirmation = (id) => {
    props.setCurrentOV(id);
    let currentOV = editTrackState.optionVisibilitySettings[id];
    let selectedOV = currentOV.optionId.name + "=> " + currentOV.variableId.name + " | " + getOperandLabel(currentOV.operand) + " | " + currentOV.val;
    props.setMsg("<h4>Are you sure you want to delete <span class='text-secondary'>" + selectedOV + "</span> Option Visibility Setting?");
    props.setHandleDelete("deleteOV");
    props.setOpenCnfDia(true);
  };


  const getOptionVisibilitySettingList = () => {
    if (!isNullOrEmpty(editTrackState.optionVisibilitySettings)) {
      return editTrackState.optionVisibilitySettings.map((ov, i) => {
        return (
          <p className="listItem" key={i}>
            <span>
              {ov.optionId.name} =&#62; {ov.variableId.name} <b>| {getOperandLabel(ov.operand)} |</b> {ov.val}
            </span>
            <IconButton onClick={() => handleConfirmation(i)}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          </p>
        );
      });
    }
  };

  const operandList = ["==", "!=", "<", "<=", ">", ">="];

  const getOperandLabel = (val) => {
    let condition = null;
    switch (val) {
      case "==":
        condition = "Equal To";
        break;
      case "!=":
        condition = "Not Equal To";
        break;
      case "<":
        condition = "Less Than";
        break;
      case "<=":
        condition = "Less Than Or Equal To";
        break;
      case ">":
        condition = "Greater Than";
        break;
      case ">=":
        condition = "Greater Than Or Equal To";
        break;
      default:
        condition = null;
        break;
    }
    return condition;
  };

  const optionVisibilityClass = isExpand ? "expand" : "collapse";

  return (
    <>
      <div className="heading">
        <h3>Option Visibility Settings</h3>
        {markup}
      </div>
      <div className={optionVisibilityClass}>
        <MuiThemeProvider theme={formLabelsTheme}>
          <form noValidate autoComplete="off">
            <div className="visibility_setting_row">
              <FormControl
                variant="outlined"
                required
                error={isNullOrEmpty(selectedResp) && ovErrorFlag && true}>
                <InputLabel htmlFor="respList">Response</InputLabel>
                <Select
                  native
                  name="respList"
                  id="respList"
                  value={!isNullOrEmpty(selectedResp) ? selectedResp.value : ""}
                  onChange={handleSelectRespChange}
                >
                  <option aria-label="None" value=""></option>
                  {!isNullOrEmpty(editTrackState.options) &&
                    editTrackState.options.map((opt, i) => {
                      return (
                        <option key={i} value={opt.id}>
                          {opt.response}
                        </option>
                      );
                    })}
                </Select>
                {isNullOrEmpty(selectedResp) && ovErrorFlag &&
                  <FormHelperText style={{ color: "red" }}>The response option is required.</FormHelperText>
                }
              </FormControl>
              <FormControl
                variant="outlined"
                required
                error={isNullOrEmpty(selectedVar) && ovErrorFlag && true}>
                <InputLabel htmlFor="varList">Sim Variable</InputLabel>
                <Select
                  native
                  name="varList"
                  id="varList"
                  value={!isNullOrEmpty(selectedVar) ? selectedVar.value : ""}
                  onChange={handleSelectVarChange}
                >
                  <option aria-label="None" value="" />
                  {!isNullOrEmpty(variableState) &&
                    variableState.map((v, i) => {
                      return (
                        <option key={i} value={v.id}>
                          {v.name}
                        </option>
                      );
                    })}
                </Select>
                {isNullOrEmpty(selectedVar) && ovErrorFlag &&
                  <FormHelperText style={{ color: "red" }}>The sim variable option is required.</FormHelperText>
                }
              </FormControl>
              <FormControl
                variant="outlined"
                required
                error={isNullOrEmpty(operand) && ovErrorFlag && true}>
                <InputLabel htmlFor="operandList">Operand</InputLabel>
                <Select
                  native
                  name="operandList"
                  id="operandList"
                  value={operand}
                  onChange={handleSelectOpperandChange}
                >
                  <option aria-label="None" value=""></option>
                  {operandList.map((v) => {
                    return (
                      <option key={v} value={v}>
                        {getOperandLabel(v)}
                      </option>
                    );
                  })}
                </Select>
                {isNullOrEmpty(operand) && ovErrorFlag &&
                  <FormHelperText style={{ color: "red" }}>The operand option is required.</FormHelperText>
                }
              </FormControl>
              <TextField
                error={isNullOrEmpty(equationValue) && ovErrorFlag && true}
                required
                name="equationValue"
                id="equationValue"
                label="Value"
                variant="outlined"
                value={equationValue}
                onChange={handleEquationValueChange}
                helperText={isNullOrEmpty(equationValue) && ovErrorFlag && "The value field is required."}
              />
              <IconButton onClick={addVisiblitySetting}>
                <AddCircleIcon />
              </IconButton>
            </div>
          </form>
        </MuiThemeProvider>
        {getOptionVisibilitySettingList()}
      </div>
    </>
  );
};

export default OptionVisibility;