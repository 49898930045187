import React from "react";
import { useLocation } from "react-router-dom";
import '../styles/bootstrap.min.css';
import "../styles/style.css";
import BackToLibrary from "./BackToLibrary";
import LeftNavMenu from "./LeftNavMenu";
import TopNavMenu from "./TopNavMenu";
import useExpandCollapse from "../hooks/useExpandCollapse";

const AppContainer = (props) => {
  const location = useLocation();
  const [isExpand, markup] = useExpandCollapse({ vertical: true });
  let pathName = location.pathname.toLowerCase();
  let canHideMenu = pathName === "/play" || pathName === "/sso" || pathName === "/publicsimcreator" ||
    pathName === "/view" || pathName === "/loading" || pathName === "/promptplay" || pathName === "/infopage" || pathName === "/preview";
  const leftNavClass = isExpand ? "leftNav" : "leftNav leftNav_width";
  const contentClass = isExpand ? "content" : "content content_left_position";

  return (
    <>
      <div className={canHideMenu ? "hidden" : leftNavClass}>
        <div className="leftNav_content">
          <LeftNavMenu isExpand={isExpand} markup={markup} />
        </div>
      </div>

      {location.pathname === "/play" && <BackToLibrary />}

      <div className="main">
        <div className={canHideMenu ? "hidden" : "topNav"}>
          <TopNavMenu />
        </div>
        <div className={canHideMenu ? "fullContent" : contentClass}>
          {props.children}
        </div>
      </div>
    </>
  );
};

export default AppContainer;