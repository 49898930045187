import React, { useContext, useEffect } from "react";
import { VariableContext } from "../../context/VariableContext";
import SlideTextDisplay from "./SlideTextDisplay";
import Slider from "../generic/Slider";
import { PlayerContext } from "../../context/PlayerContext";
import AlertDialog from "../generic/CustomizeAlertDilaog";
import CustomizedNestedList from "../generic/CustomizedNestedList";
import { getMarginTop } from "../../utils/utils";

const ScoreCard = (props) => {
  const { setShowPlayButton, showFlashAlert, isPlayingFromTrack } = useContext(PlayerContext);

  useEffect(() => {
    if ((props.alertMessage === "" && props.flashTrackId === "") || !showFlashAlert) {
      if (!props.audioURL && !props.debug) {
        if (props.slideTimeout && !props.flashTrack) {
          props.handleSlideTimeOut();
        }
      } else if (props.audioURL && !props.debug) {
        const clip = document.getElementById("soundclip");
        if (props.slideTimeout && !props.flashTrack) {
          clip.onended = () => {
            props.handleSlideTimeOut();
          };
        }
        const playPromise = clip.play();
        if (playPromise !== undefined) {
          playPromise.then(function () {
            console.debug("Audio play started in ScoreCard Track");
            setShowPlayButton(false);
            // Automatic playback started!
          }).catch(function (error) {
            console.debug("Audio Play exception in ScoreCard Track:", error);
            setShowPlayButton(true, error);
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.trackId]);

  //Used to get list of variables in the simulation
  const { variableState } = useContext(VariableContext);

  //Used to get list of variables in the simulation

  const getSliders = () => {
    const varList = props.debug ? variableState : props.playerStateVars;
    return varList.map((variable) => {
      if (variable.showOnReport && props.scoreCardVars) {
        const found = props.scoreCardVars.filter(
          (val) => val.value === variable.id
        );

        const variableValue = props.debug
          ? parseInt((variable.maxRange - variable.minRange) / 2)
          : variable.useAvg
            ? parseInt(variable.avg)
            : parseInt(variable.currentValue);

        if (found.length > 0) {
          return (
            <div key={variable.id}>
              <h5>{variable.displayName}</h5>
              <p>{variable.desc}</p>
              <Slider
                step={variable.step || 0}
                sliderLabels={variable.sliderLabels}
                valueLabelDisplay="on"
                minRange={variable.minRange || 0}
                maxRange={variable.maxRange || 0}
                defaultValue={
                  isNaN(variableValue) ? 0 : variableValue
                }
              />
            </div>
          );
        }
      }
    });
  };

  const handleGoToFlashTrack = () => {
    props.handleGoToFlashTrack();
  }

  return (
    <>
      {!props.isPub && (
        <h1 style={{ marginTop: getMarginTop(props.isPub, props.trackIdx) }}>
          Track: {props.order} -- {props.title} -- {(!props.isPub && !props.debug) ? props.trackId : props.id}
        </h1>
      )}
      {(props.visibilitySettings.length > 0 && isPlayingFromTrack) && (
        <CustomizedNestedList
          text="Variables:"
          visibilitySettings={props.visibilitySettings}
        />
      )}
      <h1 style={{ marginTop: getMarginTop(props.isPub, props.trackIdx) }}>{props.header}</h1>

      <SlideTextDisplay html={props.body} />
      {props.audioURL && (
        <audio
          key={props.file + "_" + props.audioURL}
          id="soundclip"
          controls={props.debug}
          muted={props.isMuted}
        >
          {props.file && <source src={props.file} type="audio/mpeg" />}
          {props.audioURL && <source src={props.pubAudioUrl} type="audio/mpeg" />}
        </audio>
      )}
      <div className="sliders">{getSliders()}</div>
      {!props.debug && !props.flashTrack && showFlashAlert
        && props.alertMessage !== "" && props.flashTrackId !== "" &&
        <AlertDialog
          size="lg"
          isSticky={true}
          title={props.alertMessage}
          showGoToFlashTrack={true}
          handleGoToFlashTrack={handleGoToFlashTrack}
        />
      }
    </>
  );
};


export default ScoreCard;